import CustomLayout from '@/common/Layout';
import GatepassRequest_SAP from './components/gatepassrequest_SAP';

const CreateGP_SAP = () => {
  return (
    <>
      <CustomLayout sider="true">
        <GatepassRequest_SAP />
      </CustomLayout>
    </>
  );
};

export default CreateGP_SAP;
