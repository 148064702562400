import React, { useState, useEffect } from 'react';
import CustomLayout from '@/common/Layout';
import ViewDetails from './components/viewdetails';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestById, getRequestById_Approver } from './redux/slice';
import { useParams } from 'react-router-dom';
import { getGatepassItems } from '../editGP/redux/slice';

const ViewRequest = () => {
  const { requester_data, getRequestByIdLoading, requester_data_Approver } =
    useSelector((state) => state.view);
  const { gatepassItems, itemsPagination } = useSelector((state) => state.edit);

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  //console.log('gatepassItems>>>>>>>>>>', gatepassItems);

  //console.log('requester_data', requester_data);

  const params = useParams();
  useEffect(() => {
    handleGetRequestById();
  }, []);
  const handleGetRequestById = () => {
    const payload = { gpid: params.id };
    dispatch(getRequestById(payload));
    dispatch(getRequestById_Approver(payload));
  };

  useEffect(() => {
    dispatch(
      getGatepassItems({
        gpid: parseInt(params.id),
        page: page,
        per_page: pageSize
        // search: itemSearchValue === undefined ? '' : itemSearchValue
      })
    );
  }, [page]);

  return (
    <>
      <CustomLayout sider="true">
        <ViewDetails
          requester_data1={requester_data}
          requester_data_Approver1={requester_data_Approver}
          gatepassItems={gatepassItems}
          getRequestByIdLoading={getRequestByIdLoading}
          itemsPagination={itemsPagination}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </CustomLayout>
    </>
  );
};

export default ViewRequest;
