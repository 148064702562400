import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { approver_sap, issuer_sap, print } from './api';

const initialState = {
  loading: false,
  getAllApproverLoading: false,
  getAllIssuerList_RequesterLoading: false,
  allPendingRequests: [],
  allRequests: [],
  allRequestsIssuer: [],
  allRequestsIssuer_Requester: [],
  pagination: [],
  secondpagination: [],
  url: ''
};
const actions = {
  GET_ALL_PENDING_REQUESTS: 'getAllPendingRequests/GET_ALL_PENDING_REQUESTS',
  GET_ALL_ISSUER_PENDING_REQUESTS:
    'getAllIssuerPendingRequests/GET_ALL_ISSUER_PENDING_REQUESTS',
  GET_ALL_ISSUER_REQUESTS: 'getAllIssuerRequests/GET_ALL_ISSUER_REQUESTS',
  PRINT_REQUESTS: 'printRequest/PRINT_REQUESTS',
  SEARCH_TICKET_ISSUER: 'searchvaluesIssuer/SEARCH_TICKET_ISSUER',
  GET_ALL_ISSUER_REQUESTS_REQUESTER:
    'getAllIssuerList_Requester/GET_ALL_ISSUER_REQUESTS_REQUESTER'
};

export const getAllPendingRequests = createAsyncThunk(
  actions.GET_ALL_PENDING_REQUESTS,
  async (payload) => {
    const response = await approver_sap(``, payload);
    return response;
  }
);

export const getAllIssuerPendingRequests = createAsyncThunk(
  actions.GET_ALL_ISSUER_PENDING_REQUESTS,
  async (payload) => {
    const response = await issuer_sap(`gatepasses`, payload);
    return response;
  }
);

export const getAllIssuerRequests = createAsyncThunk(
  actions.GET_ALL_ISSUER_REQUESTS,
  async (payload) => {
    const response = await issuer_sap(`requested-gatepasses`, payload);
    return response;
  }
);
export const getAllIssuerList_Requester = createAsyncThunk(
  actions.GET_ALL_ISSUER_REQUESTS_REQUESTER,
  async (payload) => {
    const response = await issuer_sap(`gatepasses`, payload);
    return response;
  }
);

export const printRequest = createAsyncThunk(
  actions.PRINT_REQUESTS,
  async (payload) => {
    const response = await print(`print/${payload.gpid}`, payload);
    return response;
  }
);

export const searchvalues = createAsyncThunk(
  actions.SEARCH_TICKET,
  async (payload) => {
    const response = await approver_sap(
      `gatepasses?status=${payload?.status}&search=${payload?.ticket}&page=${payload?.page}`
    );
    return response;
  }
);

export const searchvaluesIssuer = createAsyncThunk(
  actions.SEARCH_TICKET_ISSUER,
  async (payload) => {
    const response = await issuer_sap(
      `requested-gatepasses?status=${payload?.status}&search=${payload?.ticket}&page=${payload?.page}`
    );
    return response;
  }
);
export const issuerSliceSAP = createSlice({
  name: 'issuer_sap',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPendingRequests.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllPendingRequests.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allPendingRequests = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllPendingRequests.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllIssuerPendingRequests.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllIssuerPendingRequests.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allPendingRequests = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllIssuerPendingRequests.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllIssuerRequests.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllIssuerRequests.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequestsIssuer = data ? data : [];
        state.secondpagination = pagination || null;
      })
      .addCase(getAllIssuerRequests.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllIssuerList_Requester.pending, (state) => {
        state.getAllIssuerList_RequesterLoading = true;
      })
      .addCase(getAllIssuerList_Requester.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllIssuerList_RequesterLoading = false;
        state.allRequestsIssuer_Requester = data ? data : [];
        state.secondpagination = pagination || null;
      })
      .addCase(getAllIssuerList_Requester.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllIssuerList_RequesterLoading = false;

        message.error(msg);
      });

    builder
      .addCase(printRequest.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(printRequest.fulfilled, (state, action) => {
        const { success, url: url, msg: msg } = action.payload;
        state.url = url ? url : '';
        state.getAllApproverLoading = false;
        if (success) {
          // message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(printRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(searchvalues.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(searchvalues.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequestsIssuer_Requester = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          // message.error(msg);
        }
      })
      .addCase(searchvalues.rejected, (state) => {
        // const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        // message.error(msg);
      });
    builder
      .addCase(searchvaluesIssuer.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(searchvaluesIssuer.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequestsIssuer = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          //message.error(msg);
        }
      })
      .addCase(searchvaluesIssuer.rejected, (state) => {
        // const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        // message.error(msg);
      });
  }
});
export default issuerSliceSAP.reducer;
