import React from 'react';
import { Modal, Tooltip } from 'antd';
//import { getLoggedInUser } from 'utilities/helpers';
import CustomTable from 'common/Content/CustomTable';

const ItemsModal = ({ itemsModal, setItemsModal, requester_data }) => {
  // const user = getLoggedInUser();
  console.log('requester_data', requester_data);

  const columns = [
    {
      title: 'Item Code',
      dataIndex: 'item_code',
      key: 'item_code',
      render: (item_code) => {
        return (
          <>
            <Tooltip
              title={item_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {item_code}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Item Name',
      dataIndex: 'item_name',
      key: 'item_name',
      render: (item_name) => {
        return (
          <>
            <Tooltip
              title={item_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {item_name}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'UOM',
      dataIndex: 'uom',
      key: 'uom',
      render: (uom) => {
        return (
          <>
            <Tooltip
              title={uom}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {uom}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (quantity) => {
        return (
          <>
            <Tooltip
              title={quantity}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {quantity}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Item Description',
      dataIndex: 'item_description',
      key: 'item_description',
      render: (item_description) => {
        return (
          <>
            <Tooltip
              title={item_description}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {item_description}
            </Tooltip>
          </>
        );
      }
    }
  ];
  return (
    <>
      <Modal
        title={'Items Details : '}
        visible={itemsModal}
        // className={`${styles.check_circle_icon}`}
        width={'920px'}
        onCancel={() => setItemsModal(false)}
        onOk={() => setItemsModal(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <CustomTable columns={columns} dataSource={requester_data?.Items} />
      </Modal>
    </>
  );
};

export default ItemsModal;
