import LocalStorage from './localStorage';

export const apiUrl = process.env.REACT_APP_API_URL; //'https://gp-api.lexyslabs.com/api-v1';
export const apiUrl2 = process.env.REACT_APP_API_URL2; //'https://gp-api.lexyslabs.com/api-v2';
export const apiUrlERP = 'http://192.168.10.223/vendor_portal/public/api';

const encodeQueryData = (data = null) => {
  if (data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return '?' + ret.join('&');
  }
  return '';
};

const getLocalToken = () => {
  const user = LocalStorage.getItem('gatepass_user');
  const authToken = user?.token;
  if (authToken !== null && authToken !== '') {
    return `${authToken}`; //Bearer
  } else {
    return '';
  }
};

const apiClient = {
  Urls: {
    apiUrl,
    apiUrl2,
    signin: `${apiUrl}/signin`,
    requester: `${apiUrl}`,
    approver: `${apiUrl}`,
    issuer: `${apiUrl}`,
    reject: `${apiUrl}`,
    issuer_reject: `${apiUrl}`,
    requester_reject: `${apiUrl}/requester`,
    view: `${apiUrl}`,
    // approver_view: `${apiUrl}`,
    // issuer_view: `${apiUrl}`,
    // requester_view: `${apiUrl}`,
    issuer_genratedGP: `${apiUrl}/generate`,
    requester_genratedGP: `${apiUrl}/requester/generate`,
    approver_genratedGP: `${apiUrl}/approver/generate`,
    edit_request: `${apiUrl}/issuer/edit`,
    issuer_print: `${apiUrl}`,
    mark_received_items: `${apiUrl}/mark-received-items`,
    requester_create_draft: `${apiUrl}`,
    requester_create_items: `${apiUrl}/requester`,
    partiallyEdit_expected_date: `${apiUrl}/extend-expected-date`,
    gatepass_items: `${apiUrl}/gatepass-items`,
    gp_history: `${apiUrl}/gp-history`,
    item_history: `${apiUrl}/item-history`,
    hoRole: `${apiUrl}/gatepasses`,
    // ho_view: `${apiUrl}`,
    //ho_cancel: `${apiUrl}/ho`,
    deleteDocuments: `${apiUrl}`,
    // hoEdit: `${apiUrl}/ho`,
    erp_Document: `${apiUrlERP}`,

    //-------api-v2------------------------
    requester_sap: `${apiUrl2}`,
    approver_sap: `${apiUrl2}`,
    issuer_sap: `${apiUrl2}`,
    gatepass_items_sap: `${apiUrl2}/gatepass-items`,
    item_history_sap: `${apiUrl2}/item-history`,
    issuer_genratedGP_sap: `${apiUrl2}/generate`,
    requester_genratedGP_sap: `${apiUrl2}/requester/generate`,
    approver_genratedGP_sap: `${apiUrl2}/approver/generate`,
    mark_received_items_sap: `${apiUrl2}/mark-received-items`,
    partiallyEdit_expected_date_sap: `${apiUrl2}/extend-expected-date`,
    requester_create_items_sap: `${apiUrl2}/requester`,
    gp_history_sap: `${apiUrl2}/gp-history`,
    hoRole_sap: `${apiUrl2}/gatepasses`,
    admin: `${apiUrl2}/admin`
  },

  make: function (url, method, params, auth, type) {
    let headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    let authHeaders = {
      Authorization: getLocalToken()
    };

    if (auth) {
      headers = {
        ...headers,
        ...authHeaders
      };
    }

    if (method == 'GET') {
      let queryParams = '';
      if (Object.keys(params || {}).length) {
        queryParams = encodeQueryData(params);
      }
      return fetch(url + queryParams, {
        method,
        headers
      })
        .then((response) => response.json())
        .catch((e) => e);
    } else {
      return fetch(url, {
        method,
        headers: type === 'file' ? authHeaders : headers,
        body: type === 'file' ? params : JSON.stringify(params)
      })
        .then((response) => response.json())
        .catch((e) => e);
    }
  },

  get: function (url, params, auth) {
    return apiClient.make(url, 'GET', params, auth);
  },

  post: function (url, params, auth, type) {
    return apiClient.make(url, 'POST', params, auth, type);
  },

  put: function (url, params, auth) {
    return apiClient.make(url, 'PUT', params, auth);
  },

  patch: function (url, params, auth) {
    return apiClient.make(url, 'PATCH', params, auth);
  },

  delete: function (url, params, auth) {
    return apiClient.make(url, 'DELETE', params, auth);
  }
};

export default apiClient;
