import React, { useEffect, useState, useMemo } from 'react';
import styles from './index.module.less';
import * as XLSX from 'xlsx/xlsx.mjs';
import FormData from 'form-data';
import {
  Col,
  Row,
  Select,
  Form,
  Divider,
  Input,
  DatePicker,
  message,
  Button,
  Radio,
  Tag,
  Spin
} from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import FormItem from 'common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllPlants,
  getAllPlantLocations,
  getAllmethodsSAP,
  getAlltypes,
  getAllcategories,
  getAllreferenceDocs,
  getAllmaterils,
  getAllreasontosend,
  getAllapprovers,
  getAllVendors,
  getAllVendorLocation,
  getAllissuers,
  createDraftUpload,
  get_Check_DocumentNumber,
  vendorLocationAddresses,
  getAllPlants_forSAP
} from '../../redux/slice';
import { getLoggedInUser } from 'utilities/helpers';
import { history } from '@/app/history';
import moment from 'moment';
import { debounce } from 'lodash';
// import Vendor_IRISO_MRR from './Vendor_IRISO_MRR';
import VendorERP_Modal from './vendorERP_Modal';
import LocalStorage from 'utilities/localStorage';

const { Option } = Select;
const { TextArea } = Input;
const filters = [
  {
    id: 1,
    name: 'Gate Pass details',
    value: 'GP_information'
  },
  {
    id: 2,
    name: 'Item details',
    value: 'item_details'
  }
];

const Gatepassrequest_SAP = () => {
  const userToken = LocalStorage.getItem('gatepass_user');

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    plants,
    plantLocations,
    methods_sap,
    types,
    categories,
    docs,
    materials,
    reasons,
    approvers,
    vendors,
    vendorLocation,
    issuers,
    vendorLocationAddressList,
    plants_forSAP
  } = useSelector((state) => state.requests_sap);

  const user = getLoggedInUser();
  const [documentNumber, setDocumentNumber] = useState();
  const [documentID, setDocumentID] = useState();
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [formData1, setFormData1] = useState({});
  const [formItemData, setFormItemData] = useState({});

  const [itemVal, setItemVal] = useState({});
  const [vendorAddress, setVendorAddress] = useState([]);
  const [vendorName, setVendorName] = useState([]);
  // const [vendorAddressValue, setVendorAddressValue] = useState('');
  const [newDocs, setNewDocs] = useState([1]);
  const [supportingDoc, setSupportingDoc] = useState([]);
  const [draft, setDraft] = useState();
  const [submit, setSubmit] = useState();
  const [vendorList, setVendorList] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [itemsJsonData, setItemsJsonData] = useState(['']);
  //const [other, setOther] = useState('');
  const [otherVendorName, setOtherVendorName] = useState('');
  const [otherVendorLocation, setOtherVendorLocation] = useState('');
  const [otherVendorAddress, setOtherVendorAddress] = useState('');
  const [vendorSearch, setVendorSearch] = useState('');
  const [draftLoading, setDraftLoading] = useState(false);
  const [proccedLoading, setProccedLoading] = useState(false);
  const [value, setOnProceedValue] = useState({});
  const [fileValue, setFileValue] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [fetchedItems1, setFetchedItems1] = useState([]); //,
  const [entityNum, setEntityNum] = useState('');
  //const [fetchedType_of_issue, setFetchedType_of_issue] = useState('');
  // const [vendorERP] = useState([]); //, setVendorERP
  const [erpLoading, setErpLoading] = useState(false);
  const [erpVendorLoading] = useState(false); //, setErpVendorLoading
  // const [erpParseItems] = useState({}); //, setErpParseItems
  // const [vendorERPLocationName] = useState(''); //, setVendorERPLocationName
  const [vendorERPModal, setVendorERPModal] = useState(false);
  const [projectSearch, setProjectSearch] = useState('');
  const [projectLocationSearch, setProjectLocationSearch] = useState('');
  const [check_DocumentNumberLoading, setCheck_DocumentNumberLoading] =
    useState(false);
  const [approverSearch, setApproverSearch] = useState('');
  const [issuerSearch, setIssuerSearch] = useState('');

  const [disable, setDisable] = useState(false);
  const [disableFetch, setDisableFetch] = useState(false);

  //const [fetchedItems2, setFetchedItems2] = useState([]);
  // const [vendorERPShow] = useState(false); //, setVendorERPShow
  const [totalValue, setTotalValue] = useState(0);
  const [itemNewValue, setItemNewValue] = useState({});
  const [itemKey, setItemKey] = useState();
  const [company, setCompany] = useState('');
  const [SAPdata, setSAPdata] = useState({});
  const [SapTotalValue, setSapTotalValue] = useState(0);

  console.log('disableFetch:', disableFetch);

  //console.log('SAPdata==', Object.keys(SAPdata).length);

  //console.log('plants_forSAP:::::::', plants_forSAP);

  useEffect(() => {
    handlegetAllRequest();
  }, []);
  const handlegetAllRequest = () => {
    dispatch(getAlltypes());
    dispatch(getAllcategories());
    dispatch(getAllmethodsSAP());
    dispatch(getAllreferenceDocs());
    dispatch(getAllmaterils());
    // dispatch(getAllItems());
  };
  useEffect(() => {
    if (formData1?.methodType_id === 1) {
      const payload = {
        SapPlantId: SAPdata?.PlantId,
        SapStorageLocationId: SAPdata?.StorageLocationId
      };
      if (issuerSearch) payload['search'] = issuerSearch;
      dispatch(getAllissuers(payload));
    } else if (formData1?.location_id !== undefined) {
      const payload = {
        SapPlantId: formData1?.project_id,
        SapStorageLocationId: formData1?.location_id
      };
      if (issuerSearch) payload['search'] = issuerSearch;
      dispatch(getAllissuers(payload));
    }
  }, [issuerSearch, formData1?.location_id, SAPdata]);

  useEffect(() => {
    if (formData1?.methodType_id === 1) {
      const payload = {
        SapPlantId: SAPdata?.PlantId,
        SapStorageLocationId: SAPdata?.StorageLocationId
      };
      if (approverSearch) payload['search'] = approverSearch;
      dispatch(getAllapprovers(payload));
    } else if (formData1?.location_id !== undefined) {
      const payload = {
        SapPlantId: formData1?.project_id,
        SapStorageLocationId: formData1?.location_id
      };
      if (approverSearch) payload['search'] = approverSearch;
      dispatch(getAllapprovers(payload));
    }
  }, [approverSearch, formData1?.location_id, SAPdata]);

  useEffect(() => {
    if (formData1?.methodType_id === 1) {
      const payload = { id: SAPdata?.PlantId, page: 1, per_page: 1000 };
      if (projectSearch) payload['search'] = projectSearch;
      dispatch(getAllPlants_forSAP(payload));
    } else {
      const payload = { page: 1, per_page: 1000 };

      if (projectSearch) payload['search'] = projectSearch;
      dispatch(getAllPlants(payload));
    }
  }, [projectSearch, SAPdata]);

  useEffect(() => {
    let payload = {
      SapPlantId: formData1?.project_id
    };
    if (projectLocationSearch) payload['search'] = projectLocationSearch;
    formData1?.project_id === undefined
      ? ''
      : dispatch(getAllPlantLocations(payload));
  }, [formData1?.project_id, projectLocationSearch]);

  useEffect(() => {
    let selectedPlant = plants?.filter(
      (item) => item?.id === formData1?.project_id
    );
    setGstNumber(selectedPlant[0]?.gst);
    setCompany(selectedPlant[0]?.SapCompanyMaster?.company_name);
  }, [formData1?.project_id]);

  useEffect(() => {
    let payload = {
      //per_page: 20
    };
    if (vendorSearch) payload['search'] = vendorSearch;
    dispatch(getAllVendors(payload));
  }, [vendorSearch]);

  useEffect(() => {
    const vendors1 = [...vendors, { vendorName: 'other', id: 'other' }];
    setVendorList(vendors1);
  }, [vendors]);

  useEffect(() => {
    if (formData1?.type_id === 1) {
      dispatch(
        getAllreasontosend({
          category: 'RGP'
        })
      );
    } else {
      dispatch(
        getAllreasontosend({
          category: formData1?.category_id === 1 ? 'RGP' : 'NRGP'
        })
      );
    }
  }, [formData1?.category_id, formData1?.type_id]);

  useEffect(() => {
    if (formData1?.vendor_id) {
      formData1?.vendor_id === 'other'
        ? ''
        : dispatch(getAllVendorLocation({ vendorId: formData1?.vendor_id }));
    }
  }, [formData1?.vendor_id]);
  useEffect(() => {
    if (formData1?.vendor_location) {
      formData1?.vendor_id === 'other'
        ? ''
        : dispatch(
            vendorLocationAddresses({
              vendorSiteId: formData1?.vendor_location
            })
          );
    }
  }, [formData1?.vendor_location]);

  useEffect(() => {
    let VendorValues = vendors?.filter(
      (item) => item?.id === formData1?.vendor_id
    );
    setVendorName(VendorValues);
  }, [formData1?.vendor_id]);

  useEffect(() => {
    let locationValues = vendorLocation?.filter(
      (item) => item?.id === formData1?.vendor_location
    );
    setVendorAddress(locationValues);
    // setVendorAddressValue(locationValues[0]?.addressLine1);
  }, [formData1?.vendor_location]);

  useEffect(() => {
    form.setFieldsValue({
      material_id:
        formData1?.methodType_id === 1 ? materials[0]?.material_name : undefined
    });
  }, [formData1?.methodType_id]);

  useEffect(() => {
    if (formData1?.type_id === 1) {
      formData1['category_id'] = categories[0]?.id;
    }
    form.setFieldsValue({
      category_id: formData1?.type_id === 1 ? categories[0]?.id : undefined
    });
  }, [formData1?.type_id]);

  useEffect(() => {
    form.setFieldsValue({
      requester_name: user?.user_details?.fullname,
      requester_department: user?.user_details?.roles[0],
      document_number: documentNumber,
      date: moment() || ''
    });
  });

  const handleChangedocument = (values) => {
    setDocumentID(values);
    let doc_numbers = docs.filter((docs) => docs.id === values);

    setDocumentNumber(doc_numbers[0].document_number);

    form.setFieldsValue({
      vendor_id: undefined,
      vendor_location: undefined
    });

    form.setFieldsValue({
      sap_year: undefined,
      doc_number: undefined
    });
  };

  //console.log('setDocumentID::::', documentID);

  // Custom validation function to check if the entered number starts with "44"
  // const validateNumber = (_, value) => {
  //   if (documentID === 1) {
  //     if (value && !value.startsWith('44')) {
  //       return Promise.reject(new Error('Number must start with "44"'));
  //     } else {
  //       return Promise.resolve();
  //     }
  //   } else if (documentID === 2) {
  //     if (value && !value.startsWith('55')) {
  //       return Promise.reject(new Error('Number must start with "55"'));
  //     } else {
  //       return Promise.resolve();
  //     }
  //   }
  // };

  const handleOtherVendorName = (e) => {
    setOtherVendorName(e.target.value);
  };
  const handleOtherVendorLocation = (e) => {
    setOtherVendorLocation(e.target.value);
  };
  const handleOtherVendorAddress = (e) => {
    setOtherVendorAddress(e.target.value);
  };

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);
    if (slug === 'methodType_id') {
      form.setFieldsValue({
        vendor_id: undefined,
        vendor_location: undefined,
        location_id: undefined,
        vendor_location_address: undefined,
        document_id: undefined,
        sap_year: undefined,
        doc_number: undefined,
        reason_id: undefined,
        project_id: undefined,
        type_id: undefined,
        category_id: undefined
      });
    }

    if (slug === 'sap_year') {
      form.setFieldsValue({
        doc_number: undefined
      });
    }
    if (slug === 'project_id') {
      setGstNumber('');
      form.setFieldsValue({
        vendor_id: undefined,
        vendor_location: undefined,
        location_id: undefined,
        vendor_location_address: undefined
      });
    }

    if (slug === 'vendor_id') {
      // setVendorAddressValue('');
      form.setFieldsValue({
        vendor_location: undefined,
        vendor_location_address: undefined
      });
    }
    if (slug === 'vendor_location') {
      // setVendorAddressValue(vendorAddress[0]?.addressLine1);
      form.setFieldsValue({
        vendor_location_address: undefined
      });
    }
    if (slug === 'type_id') {
      form.setFieldsValue({
        document_id: undefined,
        sap_year: undefined,
        doc_number: undefined
      });
    }
    if (slug === 'category_id') {
      form.setFieldsValue({
        reason_id: undefined,
        document_id: undefined,
        sap_year: undefined,
        doc_number: undefined
      });
    }
    if (slug === 'location_id' || slug === 'project_id') {
      form.setFieldsValue({
        approver_id: undefined,
        issuer_id: undefined
      });
    }

    setFormData1({
      ...formData1,
      [slug]: value,
      ['files']: [supportingDoc],
      ['vendor_address']: vendorAddress[0]?.addressLine1
    });
  };

  const handleChangeItem = (value, slug, keyname, key) => {
    if (`${keyname}_${key}` === `value_${key}`) {
      setItemNewValue({
        ...itemNewValue,
        [slug]: value
      });
    }
    setItemVal({
      ...itemVal,
      [slug]: value
    });
  };

  //adding total value by BulkUpload
  useEffect(() => {
    const updatedData = itemsJsonData?.map((item, index) => ({
      [`value_${index}`]: parseFloat(item?.Item_value) || 0
    }));

    const resultObject = updatedData?.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});

    //console.log('resultObject', resultObject);
    setItemNewValue(resultObject);
    let total = 0;
    for (const key in itemNewValue) {
      if (Object.prototype.hasOwnProperty.call(itemNewValue, key)) {
        total += parseFloat(itemNewValue[key] || 0);
      }
    }
    setTotalValue(total);
  }, [itemsJsonData]);

  //adding total value
  useEffect(() => {
    let total = 0;
    for (const key in itemNewValue) {
      if (Object.prototype.hasOwnProperty.call(itemNewValue, key)) {
        total += parseFloat(itemNewValue[key] || 0);
      }
    }
    setTotalValue(total);
  }, [itemNewValue]);

  //reducing deleted field value from total value
  useEffect(() => {
    let total = totalValue;
    for (const key in itemNewValue) {
      if (key === `value_${itemKey}`) {
        total = totalValue - parseFloat(itemNewValue[key]);
        delete itemNewValue[key];
      }
    }
    setTotalValue(total);
  }, [itemKey]);

  /* --SAP Total Value-- */
  useEffect(() => {
    let total = 0;
    fetchedItems1.forEach((item) => {
      if (item?.value !== '') {
        total += parseFloat(item?.value);
      }
    });
    //console.log('TTTTTotal:', total);
    setSapTotalValue(total);
  }, [fetchedItems1]);

  const onFinish = (values) => {
    setFormItemData(values);
  };

  /* ERP function--------------------- */
  const handleGetERPData = () => {
    setCheck_DocumentNumberLoading(true);
    const check_DocumentNumbe_payload = {
      year: formData1?.sap_year,
      document_number: entityNum
    };

    dispatch(get_Check_DocumentNumber(check_DocumentNumbe_payload)).then(
      function (response) {
        if (response?.payload?.success) {
          setCheck_DocumentNumberLoading(false);

          setErpLoading(true);
          const apiUrl = `${process.env.REACT_APP_API_URL2}/sap-materials`; //'https://gp-api.lexyslabs.com/api-v2/sap-materials';

          var raw = JSON.stringify({
            document_details: {
              Year: formData1?.sap_year,
              Reference_Documents: entityNum
            }
          });

          fetch(apiUrl, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${userToken?.token}`,
              'Content-Type': 'application/json'
            },
            body: raw
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then((data) => {
              console.log('DATA:', data);
              const SAP_data = data?.data;
              setSAPdata(SAP_data);
              setErpLoading(false);

              if (
                SAP_data.VendorCode === '' ||
                SAP_data.VendorCode === null ||
                SAP_data.VendorCode === undefined
              ) {
                setVendorERPModal(true);
                console.log('Vendor is null', SAP_data.VendorCode);
              }
              if (
                (SAP_data?.HeaderToItems?.results?.length > 0 &&
                  SAP_data.VendorCode !== '') ||
                (SAP_data?.HeaderToItems?.results?.length > 0 &&
                  SAP_data.VendorCode !== null)
              ) {
                if (formData1?.methodType_id === 1) {
                  const itemsERP = SAP_data?.HeaderToItems?.results?.map(
                    (item) => ({
                      item_code: item?.MaterialCode,
                      uom: item?.UOM,
                      quantity: item?.quantity,
                      item_description: item?.description,
                      value: item?.Value === '' ? 0 : item?.Value,
                      item_remarks: item?.Remarks
                    })
                  );
                  if (documentID === 1) {
                    const movementTypeDec1 = [122, 161];
                    const movementTypeExist = movementTypeDec1?.includes(
                      parseInt(SAP_data?.MovementType)
                    );

                    if (movementTypeExist) {
                      setDisable(false);
                      setDisableFetch(true);
                      setFetchedItems1(itemsERP);
                      message.success('Item Details fetched successfully');
                    } else {
                      setDisable(true);
                      message.error(
                        'Document Type mismatched with Document Number'
                      );
                    }
                  } else if (documentID === 2) {
                    const movementTypeDec1 = [221, 261];
                    const movementTypeExist = movementTypeDec1?.includes(
                      parseInt(SAP_data?.MovementType)
                    );

                    if (movementTypeExist) {
                      setDisable(false);
                      setDisableFetch(true);
                      setFetchedItems1(itemsERP);
                      message.success('Item Details fetched successfully');
                    } else {
                      setDisable(true);
                      message.error(
                        'Document Type mismatched with Document Number'
                      );
                    }
                  } else if (documentID === 3) {
                    const movementTypeDec1 = [
                      541, 311, 313, 641, 643, 301, 303
                    ];
                    const movementTypeExist = movementTypeDec1?.includes(
                      parseInt(SAP_data?.MovementType)
                    );

                    if (movementTypeExist) {
                      setDisable(false);
                      setDisableFetch(true);
                      setFetchedItems1(itemsERP);
                      message.success('Item Details fetched successfully');
                    } else {
                      setDisable(true);
                      message.error(
                        'Document Type mismatched with Document Number'
                      );
                    }
                  } else if (documentID === 4) {
                    const movementTypeDec1 = [601];
                    const movementTypeExist = movementTypeDec1?.includes(
                      parseInt(SAP_data?.MovementType)
                    );

                    if (movementTypeExist) {
                      setDisable(false);
                      setDisableFetch(true);
                      setFetchedItems1(itemsERP);
                      message.success('Item Details fetched successfully');
                    } else {
                      setDisable(true);
                      message.error(
                        'Document Type mismatched with Document Number'
                      );
                    }
                  }
                }
              }
            })
            .catch((error) => {
              console.error(
                'Response payload did not fetch successfully. Contact your TPL admin',
                error
              );
            });

          //     fetch(
          //       'https://tpl-cpi-a0qwl4l9.it-cpi021-rt.cfapps.in30.hana.ondemand.com/http/MaterialMovement',
          //       requestOptions
          //     )
          //       .then((response) => response.text())
          //       .then((result) => {
          //         const parseItems = JSON.parse(result);
          //         setErpLoading(false);
          //         setErpParseItems(parseItems);
          //         setVendorERPShow(true);

          //         console.log('parseItems@@:', parseItems);
          //         console.log(
          //           'parseItems.vendorCode@@:',
          //           parseItems.vendor_code
          //         );
          //         // console.log('parseItems.itemdetails', parseItems.itemdetails);

          //         if (
          //           parseItems.vendor_code === '' ||
          //           parseItems.vendor_code === null ||
          //           parseItems.vendor_code === undefined
          //         ) {
          //           setVendorERPModal(true);
          //           console.log('Vendor is null', parseItems?.vendor_code);
          //         }
          //         if (
          //           (parseItems.itemdetails.length &&
          //             parseItems.vendor_code !== '') ||
          //           (parseItems.itemdetails.length &&
          //             parseItems.vendor_code !== null)
          //         ) {
          //           if (formData1?.methodType_id === 1) {
          //             setFetchedItems1(parseItems?.itemdetails);

          //             setFetchedItems2(
          //               parseItems?.itemdetails?.map((itm) => ({
          //                 item_code: itm?.item,
          //                 uom: itm?.UOM,
          //                 item_description: itm?.description,
          //                 quantity: itm?.quantity
          //                 //item_remarks: event.target.value
          //               }))
          //             );

          //             setFetchedType_of_issue(parseItems?.type_of_issue);
          //             if (
          //               documentID === 1 &&
          //               parseItems.itemdetails.length > 0 &&
          //               parseItems?.type_of_issue === 'FREE' &&
          //               formData1?.type_id === 2
          //             ) {
          //               setDisable(false);
          //               message.success('Item details fetched successfully');
          //               handleGetERPVendorData(parseItems);
          //             } else if (
          //               documentID === 1 &&
          //               parseItems.itemdetails.length > 0 &&
          //               parseItems?.type_of_issue === 'FREE' &&
          //               formData1?.type_id === 1 &&
          //               categories[0]?.id === 1
          //             ) {
          //               setDisable(false);
          //               message.success('Item details fetched successfully');
          //               handleGetERPVendorData(parseItems);
          //             } else if (
          //               (documentID === 1 &&
          //                 parseItems.itemdetails.length > 0 &&
          //                 formData1?.category_id === 2 &&
          //                 parseItems?.type_of_issue === 'CHARGEABLE' &&
          //                 formData1?.type_id === 2 &&
          //                 parseItems?.type === 'CONTRACTOR') ||
          //               (documentID === 1 &&
          //                 parseItems.itemdetails.length > 0 &&
          //                 formData1?.category_id === 2 &&
          //                 parseItems?.type_of_issue === 'CHARGEABLE' &&
          //                 formData1?.type_id === 2 &&
          //                 parseItems?.type === 'CLIENT')
          //             ) {
          //               setDisable(false);
          //               message.success('Item details fetched successfully');
          //               // handleGetERPVendorData(parseItems);
          //             } else if (
          //               documentID === 3 &&
          //               parseItems.itemdetails.length > 0
          //             ) {
          //               setDisable(false);
          //               message.success('Item details fetched successfully');
          //               // handleGetERPVendorData(parseItems);
          //             } else if (
          //               documentID === 2 &&
          //               parseItems?.itemdetails?.length > 0
          //             ) {
          //               setDisable(false);
          //               message.success('Item details fetched successfully');
          //               //handleGetERPVendorData(parseItems);
          //             } else {
          //               setVendorERPModal(true);
          //               console.log('OUTER_else_Trigger@@@@@@');
          //             }
          //           }
          //           // setOnProceedValue(value);
          //           // setSelectedTabId(filters?.[1]?.id);
          //         } else if (
          //           documentID === 2 &&
          //           parseItems.itemdetails?.length === 0
          //           //formData1?.category_id === 2 &&
          //         ) {
          //           message.error(
          //             'Item details could not fetched successfully. Please enter the correct document number'
          //           );
          //           setDisable(true);
          //         } else if (
          //           documentID === 3 &&
          //           parseItems.itemdetails.length === 0
          //         ) {
          //           setDisable(true);
          //           message.error(
          //             'Item details could not fetched successfully. Please enter the correct document number'
          //           );
          //         } else {
          //           //message.error(parseItems.message);
          //           message.error(
          //             'Item details could not fetched successfully. Please enter the correct document number'
          //           );
          //         }
          //       })

          //       .catch((error) => {
          //         const parseItems = JSON.parse(error);
          //         if (
          //           (parseItems.itemdetails.length === 0 && documentID === 3) ||
          //           (parseItems.itemdetails.length === 0 && documentID === 2)
          //         ) {
          //           message.error(
          //             'Item details could not fetched successfully. Please enter the correct document number'
          //           );
          //         } else {
          //           // message.error(parseItems.message);
          //           message.error(
          //             'Item details could not fetched successfully. Please enter the correct document number1111111111'
          //           );
          //         }
          //       });
          //     //-----END SAP enn point token------------
          //   })
          //   .catch((error) => {
          //     console.error('Error fetching token:', error);
          //   });
        } else {
          setCheck_DocumentNumberLoading(false);
          message.error(
            'The document number has already been used for another Gate Pass request. Verify the Document Number and enter again'
          );
          form.setFieldsValue({
            doc_number: undefined
          });
          // window.location.reload();
        }
      }
    );
  };

  const onFinishTabOne = (value) => {
    setOnProceedValue(value);
    setSelectedTabId(filters?.[1]?.id);
  };

  useEffect(() => {
    setSelectedTabId(filters?.[0]?.id || null);
  }, []);

  const handleOnClick = () => {
    let docsCopy = [...newDocs];

    docsCopy.push({
      supporting_docs: '',
      docs: ''
    });

    setNewDocs(docsCopy);
  };

  const handleRemove = (e, i) => {
    let uploadeddocs = [...newDocs];
    uploadeddocs.splice(i, 1);
    setNewDocs(uploadeddocs);
  };

  const handlechangeDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: e.target.files[0] };

    setSupportingDoc(newDocValues);
  };

  const handleclearDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: '' };

    setSupportingDoc(newDocValues);
  };

  const saveAsDraft = () => {
    setDraftLoading(true);
    let formdata = new FormData();
    formdata.append('is_submitting', 'no');

    formData1?.methodType_id === 1
      ? formdata.append(
          'items',
          fetchedItems1 === undefined ? [] : JSON.stringify(fetchedItems1) //----------3
        )
      : formdata.append(
          'items',
          formItemData?.items === undefined
            ? '[]'
            : JSON.stringify(formItemData?.items)
        );

    // formdata.append(
    //   'isIrisoVendor',
    //   formData1?.methodType_id === 1 && documentID === 3 ? true : false
    // );

    for (let i in supportingDoc) {
      formdata.append('files', supportingDoc[i]);
    }
    if (formData1?.methodType_id === 1) {
      formdata.append('all_items_value', SapTotalValue || 0);
      formdata.append(
        'company',
        plants_forSAP?.SapCompanyMaster?.company_name === undefined
          ? ''
          : plants_forSAP?.SapCompanyMaster?.company_name
      );
      formdata.append('SapPlantId', SAPdata?.PlantId || '');
      formdata.append('SapStorageLocationId', SAPdata?.StorageLocationId || '');
    } else {
      formdata.append('all_items_value', totalValue || 0);
      formdata.append('company', company === undefined ? '' : company);

      formdata.append(
        'SapPlantId',
        formData1?.project_id === undefined
          ? '' //plants[0]?.id
          : formData1?.project_id
      );
      formdata.append(
        'SapStorageLocationId',
        formData1?.location_id === undefined ? '' : formData1?.location_id
      );
    }
    formdata.append(
      'approver_id',
      formData1?.approver_id === undefined ? '' : formData1?.approver_id
    );
    formdata.append(
      'issuer_id',
      formData1?.issuer_id === undefined ? '' : formData1?.issuer_id
    );
    formdata.append(
      'SapTypeMasterId',
      formData1?.type_id === undefined ? '' : formData1?.type_id
    );
    formData1?.type_id === 1
      ? formdata.append('SapCategoryMasterId', categories[0]?.id)
      : formdata.append(
          'SapCategoryMasterId',
          formData1?.category_id === undefined ? '' : formData1?.category_id
        );
    formdata.append(
      'SapMethodTypeMasterId',
      formData1?.methodType_id === undefined ? '' : formData1?.methodType_id
    );
    formData1?.methodType_id === 1
      ? formdata.append(
          'SapDocumentMasterId',
          documentID === undefined ? '' : documentID
        )
      : '';
    formData1?.methodType_id === 1
      ? formdata.append(
          'document_number',
          entityNum === undefined ? '' : entityNum
        )
      : '';
    formData1?.methodType_id === 1
      ? formdata.append(
          'year',
          formData1?.sap_year === undefined ? '' : formData1?.sap_year
        )
      : '';
    formdata.append(
      'SapReasonToSendMasterId',
      formData1?.reason_id === undefined ? '' : formData1?.reason_id
    );

    if (formData1?.methodType_id === 1) {
      formdata.append(
        'vendor_name',
        SAPdata?.VendorName ? SAPdata?.VendorName : ''
      );
      formdata.append(
        'vendor_code',
        SAPdata?.VendorCode ? SAPdata?.VendorCode : ''
      );
      formdata.append(
        'vendor_location',
        SAPdata?.VendorLocation ? SAPdata?.VendorLocation : ''
      );
      formdata.append(
        'vendor_address',
        SAPdata?.vendor_address ? SAPdata?.vendor_address : ''
      );
    }
    if (formData1?.vendor_id === 'other') {
      formdata.append(
        'vendor_name',
        otherVendorName === undefined ? '' : otherVendorName
      );
      formdata.append(
        'vendor_location',
        otherVendorLocation === undefined ? '' : otherVendorLocation
      );
      formdata.append(
        'vendor_address',
        otherVendorAddress === undefined ? '' : otherVendorAddress
      );
    } else if (formData1?.methodType_id === 2) {
      formdata.append(
        'vendor_name',
        vendorName[0]?.vendorName === undefined ? '' : vendorName[0]?.vendorName
      );
      formdata.append(
        'vendor_code',
        vendorName[0]?.vendorCode === undefined ||
          vendorName[0]?.vendorCode === null
          ? ''
          : vendorName[0]?.vendorCode
      );
      formdata.append(
        'vendor_location',
        vendorAddress[0]?.vendorSite === undefined
          ? ''
          : vendorAddress[0]?.vendorSite
      ); //formData1?.vendor_location
      formdata.append(
        'vendor_address',
        formData1?.vendor_location_address === undefined
          ? ''
          : formData1?.vendor_location_address
      );
    }

    formdata.append(
      'date',
      moment().format()
      // formData1?.date === undefined
      //   ? moment().format()
      //   : moment(formData1?.date).format()
    );
    formData1?.methodType_id === undefined
      ? formdata.append('SapMaterialBelongsMasterId', '')
      : formData1?.methodType_id === 1
      ? formdata.append('SapMaterialBelongsMasterId', materials[0]?.id)
      : formData1?.material_id === undefined
      ? formdata.append('SapMaterialBelongsMasterId', '')
      : formdata.append('SapMaterialBelongsMasterId', formData1?.material_id);

    formData1?.category_id === 1 || formData1?.type_id === 1
      ? formdata.append(
          'expected_date_return',
          formData1?.expected_date_return === undefined
            ? ''
            : moment(formData1?.expected_date_return).format()
        )
      : '';
    formdata.append(
      'detailed_reason',
      formData1?.detailed_reason === undefined ? '' : formData1?.detailed_reason
    );
    formdata.append(
      'vehicle_number',
      formData1?.vehicle_number === undefined ? '' : formData1?.vehicle_number
    );
    formdata.append(
      'lr_number',
      formData1?.lr_number === undefined ? '' : formData1?.lr_number
    );
    formdata.append(
      'lr_date',
      formData1?.lr_date === undefined
        ? '' //moment().format()
        : moment(formData1?.lr_date).format()
    );
    formdata.append(
      'carrier_person_name',
      formData1?.carrier_person_name === undefined
        ? ''
        : formData1?.carrier_person_name
    );

    formdata.append(
      'remarks',
      formData1?.remarks === undefined ? '' : formData1?.remarks
    );
    //-----------------------------------------------
    for (let pair of formdata.entries()) {
      console.log('PAYLOAD', pair[0], pair[1]);
    }
    //-----------------------------------------------------------
    dispatch(createDraftUpload(formdata)).then(function (response) {
      if (response?.payload?.success) {
        message.success(response.payload.msg);
        setDraftLoading(false);
        user?.user_details?.roles[0] === 'issuer'
          ? history.push('/issuer-requester-sap')
          : history.push('/requesters-sap-dashboard');
      } else {
        //message.error(response.payload.err);
        message.error(response.payload.msg);
        setDraftLoading(false);
      }
    });
  };

  // console.log('fetchedItems2:::::', fetchedItems2);
  // console.log('fetchedItems111:::::', fetchedItems1);
  useEffect(() => {
    if (submit === 'yes') {
      setProccedLoading(true);
      let formdata = new FormData();
      formdata.append('is_submitting', 'yes');
      value['address'] = vendorAddress[0]?.addressLine1;

      // formdata.append(
      //   'isIrisoVendor',
      //   formData1?.methodType_id === 1 && documentID === 3 ? true : false
      // );

      formData1?.methodType_id === 1
        ? formdata.append(
            'items',
            fetchedItems1?.length === 0 ? [] : JSON.stringify(fetchedItems1)
          )
        : formdata.append(
            'items',
            formItemData?.items === undefined
              ? []
              : JSON.stringify(formItemData?.items)
          );

      for (let i in supportingDoc) {
        formdata.append('files', supportingDoc[i]);
      }
      if (formData1?.methodType_id === 1) {
        formdata.append('all_items_value', SapTotalValue || 0);
        formdata.append(
          'company',
          plants_forSAP?.SapCompanyMaster?.company_name
        );
        formdata.append('SapPlantId', SAPdata?.PlantId);
        formdata.append('SapStorageLocationId', SAPdata?.StorageLocationId);
      } else {
        formdata.append('all_items_value', totalValue || 0);
        formdata.append('company', company);
        formdata.append('SapPlantId', value?.project_id);
        formdata.append('SapStorageLocationId', value?.location_id);
      }

      formdata.append('SapTypeMasterId', value?.type_id);
      formData1?.type_id === 1
        ? formdata.append('SapCategoryMasterId', categories[0]?.id)
        : formdata.append('SapCategoryMasterId', value?.category_id);

      formdata.append('SapMethodTypeMasterId', value?.methodType_id);
      formData1?.methodType_id === 1
        ? formdata.append(
            'SapDocumentMasterId',
            documentID === undefined ? '' : documentID
          )
        : '';
      formData1?.methodType_id === 1
        ? formdata.append(
            'document_number',
            entityNum === undefined ? '' : entityNum
          )
        : '';
      formData1?.methodType_id === 1
        ? formdata.append(
            'year',
            formData1?.sap_year === undefined ? '' : formData1?.sap_year
          )
        : '';
      formdata.append('SapReasonToSendMasterId', value?.reason_id);

      if (formData1?.methodType_id === 1) {
        formdata.append('vendor_name', SAPdata?.VendorName);
        formdata.append('vendor_code', SAPdata?.VendorCode);
        formdata.append('vendor_location', SAPdata?.VendorLocation);
        formdata.append('vendor_address', SAPdata?.vendor_address);
      } else if (formData1?.vendor_id === 'other') {
        formdata.append('vendor_name', otherVendorName);
        formdata.append('vendor_location', otherVendorLocation);
        formdata.append('vendor_address', otherVendorAddress);
      } else if (formData1?.methodType_id === 2) {
        formdata.append(
          'vendor_name',
          vendorName[0]?.vendorName === undefined
            ? ''
            : vendorName[0]?.vendorName
        );
        formdata.append(
          'vendor_code',
          vendorName[0]?.vendorCode === undefined
            ? ''
            : vendorName[0]?.vendorCode
        );
        formdata.append(
          'vendor_location',
          vendorAddress[0]?.vendorSite === undefined
            ? ''
            : vendorAddress[0]?.vendorSite
        ); //formData1?.vendor_location
        formdata.append(
          'vendor_address',
          formData1?.vendor_location_address === undefined
            ? ''
            : formData1?.vendor_location_address
        );
      }

      formdata.append(
        'date',
        moment().format()
        // value?.date === undefined
        //   ? moment().format()
        //   : moment(value?.date).format()
      );

      formData1?.methodType_id === 1
        ? formdata.append('SapMaterialBelongsMasterId', materials[0]?.id)
        : formdata.append(
            'SapMaterialBelongsMasterId',
            value?.material_id === undefined ? '' : value?.material_id
          );

      formData1?.category_id === 1 || formData1?.type_id === 1
        ? formdata.append(
            'expected_date_return',
            value?.expected_date_return === undefined
              ? ''
              : moment(value?.expected_date_return).format()
          )
        : '';

      formdata.append(
        'detailed_reason',
        value?.detailed_reason === undefined ? '' : value?.detailed_reason
      );
      formdata.append(
        'vehicle_number',
        value?.vehicle_number === undefined ? '' : value?.vehicle_number
      );
      formdata.append(
        'lr_number',
        value?.lr_number === undefined ? '' : value?.lr_number
      );
      formdata.append(
        'lr_date',
        value?.lr_date === undefined
          ? '' // moment().format()
          : moment(value?.lr_date).format()
      );

      formdata.append(
        'carrier_person_name',
        value?.carrier_person_name === undefined
          ? ''
          : value?.carrier_person_name
      );
      formdata.append(
        'remarks',
        value?.remarks === undefined ? '' : value?.remarks
      );
      formdata.append('approver_id', value?.approver_id);
      formdata.append('issuer_id', value?.issuer_id);

      //-----------PAYLOAD---------------------------------
      for (let pair of formdata.entries()) {
        console.log(pair[0], pair[1]);
      }
      //----------------------------------------------------

      // if (
      //   value?.approver_id === undefined ||
      //   value?.issuer_id === undefined ||
      //   value?.project_id === undefined ||
      //   value?.location_id === undefined ||
      //   value?.type_id === undefined ||
      //   categories[0]?.id === undefined ||
      //   value?.methodType_id === undefined ||
      //   value?.reason_id === undefined ||
      //   value?.material_id === undefined ||
      //   (formData1?.methodType_id === 1 && fetchedItems1?.length === 0) ||
      //   (formData1?.methodType_id === 2 && formItemData?.items?.length === 0)
      // ) {
      //   message.error(
      //     'There are mandatory fields left blank. Please make sure all required fields contain data.'
      //   );
      //   setProccedLoading(false);
      // } else {
      dispatch(createDraftUpload(formdata)).then(function (response) {
        if (response?.payload?.success) {
          message.success(
            `${response.payload.msg}. Ticket ${response?.payload?.Ticket}`
          );
          user?.user_details?.roles[0] === 'issuer'
            ? history.push('/issuer-requester-sap')
            : history.push('/requesters-sap-dashboard');
          setProccedLoading(false);
        } else {
          //message.error(response.payload?.err);
          message.error(response.payload.msg);
          setProccedLoading(false);
        }
      });
      //}
    }
    if (draft === 'no') {
      setDraftLoading(true);
      let formdata = new FormData();
      formdata.append('is_submitting', 'no');
      value['address'] = vendorAddress[0]?.addressLine1;
      // formdata.append(
      //   'isIrisoVendor',
      //   formData1?.methodType_id === 1 && documentID === 3 ? true : false
      // );
      formData1?.methodType_id === 1
        ? formdata.append(
            'items',
            fetchedItems1 === undefined ? [] : JSON.stringify(fetchedItems1)
          )
        : formdata.append(
            'items',
            formItemData?.items === undefined
              ? []
              : JSON.stringify(formItemData?.items)
          );
      //formdata.append('items', JSON.stringify(formItemData?.items));

      for (let i in supportingDoc) {
        formdata.append('files', supportingDoc[i]);
      }

      if (formData1?.methodType_id === 1) {
        formdata.append('all_items_value', SapTotalValue || 0);
        formdata.append(
          'company',
          plants_forSAP?.SapCompanyMaster?.company_name
        );
        formdata.append('SapPlantId', SAPdata?.PlantId);
        formdata.append('SapStorageLocationId', SAPdata?.StorageLocationId);
      } else {
        formdata.append('all_items_value', totalValue);
        formdata.append('company', company);
        formdata.append(
          'SapPlantId',
          value?.project_id === undefined ? '' : value?.project_id
        );
        formdata.append(
          'SapStorageLocationId',
          value?.location_id === undefined ? '' : value?.location_id
        );
      }

      formdata.append(
        'SapTypeMasterId',
        value?.type_id === undefined ? '' : value?.type_id
      );
      formData1?.type_id === 1
        ? formdata.append('SapCategoryMasterId', categories[0]?.id)
        : formdata.append(
            'SapCategoryMasterId',
            value?.category_id === undefined ? '' : value?.category_id
          );
      formdata.append(
        'SapMethodTypeMasterId',
        value?.methodType_id === undefined ? '' : value?.methodType_id
      );
      formData1?.methodType_id === 1
        ? formdata.append(
            'SapDocumentMasterId',
            documentID === undefined ? '' : documentID
          )
        : '';
      formData1?.methodType_id === 1
        ? formdata.append(
            'document_number',
            entityNum === undefined ? '' : entityNum
          )
        : '';
      formData1?.methodType_id === 1
        ? formdata.append(
            'year',
            formData1?.sap_year === undefined ? '' : formData1?.sap_year
          )
        : '';
      formdata.append(
        'SapReasonToSendMasterId',
        value?.reason_id === undefined ? '' : value?.reason_id
      );

      if (formData1?.methodType_id === 1) {
        formdata.append('vendor_name', SAPdata?.VendorName);
        formdata.append('vendor_code', SAPdata?.VendorCode);
        formdata.append('vendor_location', SAPdata?.VendorLocation);
        formdata.append('vendor_address', SAPdata?.vendor_address);
      }
      if (formData1?.vendor_id === 'other') {
        formdata.append('vendor_name', otherVendorName);
        formdata.append('vendor_location', otherVendorLocation);
        formdata.append('vendor_address', otherVendorAddress);
      } else if (formData1?.methodType_id === 2) {
        formdata.append(
          'vendor_name',
          vendorName[0]?.vendorName === undefined
            ? ''
            : vendorName[0]?.vendorName
        );
        formdata.append(
          'vendor_code',
          vendorName[0]?.vendorCode === undefined ||
            vendorName[0]?.vendorCode === null
            ? ''
            : vendorName[0]?.vendorCode
        );
        formdata.append(
          'vendor_location',
          vendorAddress[0]?.vendorSite === undefined
            ? ''
            : vendorAddress[0]?.vendorSite
        ); //formData1?.vendor_location
        formdata.append(
          'vendor_address',
          formData1?.vendor_location_address === undefined
            ? ''
            : formData1?.vendor_location_address
        );
      }

      formdata.append('date', moment().format());

      formData1?.methodType_id === 1
        ? formdata.append('SapMaterialBelongsMasterId', materials[0]?.id)
        : formdata.append(
            'SapMaterialBelongsMasterId',
            value?.material_id === undefined ? '' : value?.material_id
          );

      formData1?.category_id === 1 || formData1?.type_id === 1
        ? formdata.append(
            'expected_date_return',
            value?.expected_date_return === undefined
              ? ''
              : moment(value?.expected_date_return).format()
          )
        : '';

      formdata.append(
        'detailed_reason',
        value?.detailed_reason === undefined ? '' : value?.detailed_reason
      );
      formdata.append(
        'vehicle_number',
        value?.vehicle_number === undefined ? '' : value?.vehicle_number
      );
      formdata.append(
        'lr_number',
        value?.lr_number === undefined ? '' : value?.lr_number
      );
      formdata.append(
        'lr_date',
        value?.lr_date === undefined
          ? '' //moment().format()
          : moment(value?.lr_date).format()
      );

      formdata.append(
        'carrier_person_name',
        value?.carrier_person_name === undefined
          ? ''
          : value?.carrier_person_name
      );
      formdata.append(
        'remarks',
        value?.remarks === undefined ? '' : value?.remarks
      );
      formdata.append(
        'approver_id',
        value?.approver_id === undefined ? '' : value?.approver_id
      );
      formdata.append(
        'issuer_id',
        value?.issuer_id === undefined ? '' : value?.issuer_id
      );

      //--------------------------------------------
      for (let pair of formdata.entries()) {
        console.log('PAYLOAD333333333333:', pair[0], pair[1]);
      }
      //----------------------------------------------------

      dispatch(createDraftUpload(formdata)).then(function (response) {
        if (response?.payload?.success) {
          message.success(response.payload.msg);
          user?.user_details?.roles[0] === 'issuer'
            ? history.push('/issuer-requester-sap')
            : history.push('/requesters-sap-dashboard');
        } else {
          // message.error(response.payload?.err);
          message.error(response.payload.msg);
          setDraftLoading(false);
        }
      });
    }
  }, [formItemData]);

  const submitHandler = () => {
    setSubmit('yes');
  };
  const draftHandler = () => {
    setDraft('no');
  };

  const handleChangeDocNumber = (e) => {
    setEntityNum(e.target.value);
    form.setFieldsValue({
      vendor_id: undefined,
      vendor_location: undefined
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      items: itemsJsonData?.map((item) => ({
        item_code: item?.Item_Code,
        uom: item?.UOM,
        quantity: item?.Item_Quantity,
        value: parseFloat(item?.Item_value).toFixed(2), //parseInt(item?.Value),
        item_description: item?.Item_Description,
        item_remarks: item?.Item_Remarks
      }))
    });
  }, [itemsJsonData]);

  const handleItemsTemplate = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const workSheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(workSheet);
    setItemsJsonData(jsonData);
    console.log(fileName);
    // e.target.value = '';
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (vendorSearch) => {
      setVendorSearch(vendorSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const debounceFetcherProject = useMemo(() => {
    const loadOptions = (projectSearch) => {
      setProjectSearch(projectSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const debounceFetcherProjectLocation = useMemo(() => {
    const loadOptions = (projectLocationSearch) => {
      setProjectLocationSearch(projectLocationSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const debounceFetcherApprover = useMemo(() => {
    const loadOptions = (approverSearch) => {
      setApproverSearch(approverSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const debounceFetcherIssuer = useMemo(() => {
    const loadOptions = (issuerSearch) => {
      setIssuerSearch(issuerSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  const downloadTemplate = () => {
    const downloadUrl = '/Item_Template_sap.xlsx'; // '/templates/template.xlsx';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'Item_Template_sap.xlsx'; //'template.xlsx';
    link.click();
  };

  const handleRemarksChange = (index, value) => {
    // console.log('index, event', index, value);
    const newItems = [...fetchedItems1]; // Create a copy of fetchedItems array
    newItems[index].item_remarks = value; // Update the Remarks of the specific item
    setFetchedItems1(newItems); // Update the state with the modified array
    // setFetchedItems1(prevItems => {
    //   const newItems = [...prevItems]; // Create a copy of fetchedItems array
    //   newItems[index].remarks = value; // Update the Remarks of the specific item
    //   return newItems; // Return the updated array
    // });
  };

  /* -----SAP entry for Item description and Item Valu----------- */
  // const handleRemarksChange1 = (index, value) => {
  //   const newItems = [...fetchedItems1]; // Create a copy of fetchedItems array
  //   newItems[index].item_description = value; // Update the Remarks of the specific item
  //   setFetchedItems1(newItems); // Update the state with the modified array
  // };
  // const handleRemarksChange2 = (index, value) => {
  //
  //   // const newItems = [...fetchedItems1];
  //   // newItems[index].value = value;
  //   // setFetchedItems1(newItems);
  // };

  console.log('fetchedItems1------', fetchedItems1);
  return (
    <>
      <div className={`mb-4 ${styles.container}`}>
        <div className={`${styles.page_title}`}>
          <span>Create new gate pass request</span>
          <span style={{ float: 'right', marginRight: '20px' }}>
            {' '}
            <a
              href={
                user?.user_details?.roles[0] === 'issuer'
                  ? '/issuer-requester-sap'
                  : '/requesters-sap-dashboard'
              }
            >
              <Button className={`${styles.back_button}`}>
                <ArrowLeftOutlined className={`${styles.back_icon}`} />
                Back
              </Button>
            </a>
          </span>
        </div>
        <div className={`${styles.page_sub_title}`}>
          Enter the following fields to create the request to issue the gate
          pass
        </div>
        <div>
          <Radio.Group
            value={selectedTabId}
            size="middle"
            optionType="button"
            buttonStyle="solid"
            className={`${styles.tab_btn_style}`}
          >
            {filters.map((item, id) => {
              return (
                <Radio.Button
                  name="radiogroup"
                  defaultValue={item[0]?.value}
                  value={item?.id}
                  key={id}
                  //onChange={() => setRadiofilter(item.id)}
                  className={`${styles.tab_broder_style}
                    ${selectedTabId === item?.id ? ` ${styles.selected}` : ''}
                  `}
                  onClick={() => {
                    setSelectedTabId(item?.id);
                  }}
                >
                  {item.name}
                </Radio.Button>
              );
            })}
          </Radio.Group>
          <Divider style={{ margin: 0, marginBottom: '20px' }}></Divider>
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
          initialValues={{ items: [''] }}
        >
          {selectedTabId === 1 && (
            <div>
              <Form form={form} onFinish={onFinishTabOne} autoComplete="off">
                <div
                  style={{
                    fontSize: '16px',
                    color: '#2364ad',
                    fontFamily: 'SFProText Bold',
                    marginBottom: '10px'
                  }}
                >
                  General details:
                </div>
                <Row gutter={16}>
                  <Col span={6}>
                    <FormItem
                      label="Method"
                      name="methodType_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        placeholder="Select Method"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('methodType_id', value)
                        }
                      >
                        {methods_sap?.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item?.method_type}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>

                  {formData1?.methodType_id === 1 ? (
                    <>
                      <Col span={6}>
                        <FormItem
                          label="Plant Code and Name"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            disabled
                            value={
                              SAPdata?.PlantCode === undefined
                                ? ''
                                : `${SAPdata?.PlantCode} (${SAPdata?.PlantName})`
                            }
                            allowClear
                            placeholder="Select Plant Code and Name"
                            onChange={(value) =>
                              handleFormDataOnChange('project_id', value)
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col span={6}>
                        <FormItem
                          label="Storage Location"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            disabled
                            value={
                              SAPdata?.StorageLocation === undefined
                                ? ''
                                : `${SAPdata?.StorageLocation} (${SAPdata?.StorageLocationName})`
                            }
                            allowClear
                            placeholder="Select Storage Location"
                            onChange={(value) =>
                              handleFormDataOnChange('location_id', value)
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col span={6}>
                        <FormItem
                          label="GST Number"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            value={
                              SAPdata?.PlantGSTNumber === undefined
                                ? ''
                                : `${plants_forSAP?.gst}`
                            }
                            allowClear
                            placeholder="Please enter GST number"
                            disabled
                            onChange={(e) =>
                              handleFormDataOnChange('gst', e.target.value)
                            }
                          />
                        </FormItem>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={6}>
                        <FormItem
                          label="Plant Code and Name"
                          name="project_id"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                          // onChange={(e) => onChangeProject(e.target.value)}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return option?.children
                                ?.toString()
                                ?.toLowerCase()
                                ?.includes(input?.toLowerCase());
                            }}
                            onSearch={debounceFetcherProject}
                            onChange={(value) =>
                              handleFormDataOnChange('project_id', value)
                            }
                            placeholder="Select Plant Code and Name"
                          >
                            {plants?.map((item, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {`${item?.plant_code || ''} (${
                                    item?.plant_name
                                  })`}
                                </Option>
                              );
                            })}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={6}>
                        <FormItem
                          label="Storage Location"
                          name="location_id"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            showSearch
                            disabled={formData1?.methodType_id === 1}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              // console.log('input', input);
                              return option?.children
                                ?.toString()
                                ?.toLowerCase()
                                ?.includes(input?.toLowerCase());
                            }}
                            onSearch={debounceFetcherProjectLocation}
                            onChange={(value) =>
                              handleFormDataOnChange('location_id', value)
                            }
                            placeholder="Select Storage Location"
                          >
                            {plantLocations?.map((item, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {item?.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={6}>
                        <FormItem
                          label="GST Number"
                          //name="gst"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            value={gstNumber}
                            allowClear
                            placeholder="Please enter GST number"
                            disabled
                            onChange={(e) =>
                              handleFormDataOnChange('gst', e.target.value)
                            }
                          />
                        </FormItem>
                      </Col>
                    </>
                  )}
                </Row>
                <Spin spinning={check_DocumentNumberLoading}>
                  <Row gutter={16}>
                    <Col span={8}>
                      <FormItem
                        label="Company"
                        //name="company"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input
                          value={
                            formData1?.methodType_id === 1
                              ? plants_forSAP?.SapCompanyMaster?.company_name
                              : company
                          }
                          disabled
                          allowClear
                          placeholder="Enter Company"
                          onChange={(e) =>
                            handleFormDataOnChange('company', e.target.value)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label="Type"
                        name="type_id"
                        rules={[
                          {
                            required: true,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          placeholder="Select Type"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            // console.log('input', input);
                            return option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                          onChange={(value) =>
                            handleFormDataOnChange('type_id', value)
                          }
                        >
                          {types.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {item.type}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label="Category"
                        name="category_id"
                        rules={[
                          {
                            required: true,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          disabled={formData1?.type_id === 1}
                          placeholder="Select Category"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                          onChange={(value) =>
                            handleFormDataOnChange('category_id', value)
                          }
                        >
                          {categories.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {item.category}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>
                  {formData1?.methodType_id === 1 ? (
                    <Row gutter={16}>
                      <Col span={8}>
                        <FormItem
                          label="Reference documents"
                          name="document_id"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            placeholder="Select Reference Document"
                            onChange={handleChangedocument}
                          >
                            {docs
                              ? docs.map((item, index) => {
                                  return (
                                    <Option value={item.id} key={index}>
                                      {item.document_name}
                                    </Option>
                                  );
                                })
                              : ''}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="Year"
                          name="sap_year"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.date_styles}`}
                        >
                          <DatePicker
                            format={'YYYY'}
                            picker="year"
                            onChange={(_, dateValue) =>
                              handleFormDataOnChange('sap_year', dateValue)
                            }
                            disabledDate={(current) => {
                              const currentYear = moment().year();
                              const previousYear = moment()
                                .add(-1, 'year')
                                .year();
                              const nextYear = moment().add(1, 'year').year();
                              const selectedYear = moment(current).year();
                              return (
                                selectedYear !== currentYear &&
                                selectedYear !== nextYear &&
                                selectedYear !== previousYear
                              );
                            }}
                          ></DatePicker>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="Document number"
                          name="doc_number"
                          /* rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]} */
                          rules={[
                            {
                              required: true,
                              message: 'Please enter document number!'
                            }
                            //{ validator: validateNumber }
                          ]}
                          className={`${styles.select_styles}`}
                          onChange={(e) => handleChangeDocNumber(e)}
                        >
                          <Input
                            //disabled
                            disabled={
                              (formData1?.category_id === 1 &&
                                formData1?.methodType_id === 1 &&
                                documentID === 1) ||
                              (formData1?.category_id === 1 &&
                                formData1?.methodType_id === 1 &&
                                documentID === 3) ||
                              (formData1?.category_id === 1 &&
                                formData1?.methodType_id === 1 &&
                                documentID === 4)
                            }
                            className={`${styles.input_lineHeight}`}
                            placeholder="Enter document number"
                          />
                        </FormItem>

                        <a
                          style={{
                            cursor: 'pointer',
                            float: 'right',
                            marginTop: '-20px',
                            fontWeight: '600'
                          }}
                          onClick={() =>
                            // (formData1?.category_id === 1 &&
                            //   formData1?.methodType_id === 1 &&
                            //   documentID === 2) ||
                            // (formData1?.category_id === 1 &&
                            //   formData1?.methodType_id === 1 &&
                            //   documentID === 3)
                            formData1?.methodType_id === 2
                              ? ''
                              : handleGetERPData()
                          }
                          disabled={
                            disableFetch === true
                            //   (formData1?.category_id === 1 &&
                            //     formData1?.methodType_id === 1 &&
                            //     documentID === 2) ||
                            //   (formData1?.category_id === 1 &&
                            //     formData1?.methodType_id === 1 &&
                            //     documentID === 3)
                          }
                        >
                          Fetch
                        </a>
                      </Col>
                    </Row>
                  ) : null}
                </Spin>
                <Divider className={`${styles.divider_style}`}></Divider>
                <div
                  style={{
                    fontWeight: 600,
                    color: '#000000',
                    fontSize: '14px',
                    lineHeight: ' 20px',
                    fontFamily: 'Cerebri Sans'
                  }}
                >
                  <span
                    style={{
                      fontSize: '16px',
                      color: '#2364ad',
                      fontFamily: 'SFProText Bold'
                    }}
                  >
                    Shipped to:
                  </span>
                </div>
                <br />

                <Spin spinning={erpLoading}>
                  <Spin spinning={erpVendorLoading}>
                    {formData1?.methodType_id === 1 ? (
                      <>
                        <Row gutter={16}>
                          <Col span={8}>
                            <FormItem
                              label={'Vendor name and code'}
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                disabled
                                value={
                                  SAPdata?.VendorCode === undefined
                                    ? ''
                                    : `${SAPdata?.VendorCode} (${SAPdata?.VendorName})`
                                }
                                allowClear
                                placeholder={'Project name and code'}
                                onChange={(value) =>
                                  handleFormDataOnChange('vendor_id', value)
                                }
                              />
                            </FormItem>
                          </Col>
                          <Col span={8}>
                            <FormItem
                              label="Location"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                disabled
                                value={
                                  SAPdata?.VendorLocation === undefined
                                    ? ''
                                    : `${SAPdata?.VendorLocation} `
                                }
                                allowClear
                                placeholder="Enter Location"
                                onChange={(value) =>
                                  handleFormDataOnChange(
                                    'vendor_location',
                                    value
                                  )
                                }
                              />
                            </FormItem>
                          </Col>
                          <Col span={8}>
                            <FormItem
                              label="Address"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                disabled
                                value={
                                  SAPdata?.vendor_address === undefined
                                    ? ''
                                    : `${SAPdata?.vendor_address} `
                                }
                                allowClear
                                placeholder="Enter Address"
                                onChange={(e) =>
                                  handleFormDataOnChange(
                                    'vendor_address',
                                    e.target.value
                                  )
                                }
                              />
                            </FormItem>
                          </Col>
                        </Row>{' '}
                      </>
                    ) : (
                      <Row gutter={16}>
                        <Col span={8}>
                          <FormItem
                            label={'Vendor name and code'}
                            name="vendor_id"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Select
                              placeholder="Select vendor name and code"
                              //mode="multiple"
                              allowClear={true}
                              showSearch
                              optionFilterProp="children"
                              onSearch={debounceFetcher}
                              onChange={(value) =>
                                handleFormDataOnChange('vendor_id', value)
                              }
                            >
                              {vendorList?.map((item) => (
                                <Option
                                  key={item?.id}
                                  value={item?.id}
                                  style={{
                                    fontSize: '12px',
                                    textTransform: 'uppercase'
                                  }}
                                >{`${item.vendorName} ${
                                  item?.vendorCode
                                    ? `(${item?.vendorCode})`
                                    : ''
                                }`}</Option>
                              ))}
                            </Select>
                          </FormItem>
                        </Col>
                        {formData1?.vendor_id === 'other' &&
                        formData1?.methodType_id === 2 ? (
                          ''
                        ) : (
                          <>
                            {' '}
                            <Col span={8}>
                              <FormItem
                                label="Location"
                                name="vendor_location"
                                rules={[
                                  formData1?.vendor_id === 'other'
                                    ? {
                                        required: false,
                                        message: 'Required field'
                                      }
                                    : {
                                        required: true,
                                        message: 'Required field'
                                      }
                                ]}
                                className={`${styles.select_styles}`}
                              >
                                <Select
                                  // disabled={
                                  //   (documentID === 1 &&
                                  //     formData1?.category_id === 1 &&
                                  //     fetchedType_of_issue === 'CHARGEABLE') ||
                                  //   (documentID === 1 &&
                                  //     fetchedType_of_issue ===
                                  //       'STOCK ADJUSTMENT')
                                  // }
                                  optionFilterProp="children"
                                  filterOption={(input, option) => {
                                    return option?.children
                                      ?.toString()
                                      ?.toLowerCase()
                                      ?.includes(input?.toLowerCase());
                                  }}
                                  onChange={(value) =>
                                    handleFormDataOnChange(
                                      'vendor_location',
                                      value
                                    )
                                  }
                                  placeholder="Select Location"
                                >
                                  {vendorLocation.map((item, index) => {
                                    return (
                                      <Option value={item.id} key={index}>
                                        <span
                                          style={{
                                            fontSize: '12px',
                                            textTransform: 'uppercase'
                                          }}
                                        >
                                          {' '}
                                          {item.vendorSite}, {item.city},{' '}
                                          {item.state}
                                        </span>
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </FormItem>
                            </Col>
                            <Col span={8}>
                              <FormItem
                                label="Address"
                                name="vendor_location_address"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.select_styles}`}
                              >
                                <Select
                                  optionFilterProp="children"
                                  filterOption={(input, option) => {
                                    return option?.children
                                      ?.toString()
                                      ?.toLowerCase()
                                      ?.includes(input?.toLowerCase());
                                  }}
                                  onChange={(value) =>
                                    handleFormDataOnChange(
                                      'vendor_location_address',
                                      value
                                    )
                                  }
                                  placeholder="Select Address"
                                >
                                  {vendorLocationAddressList?.map(
                                    (item, index) => {
                                      return (
                                        <Option
                                          value={item?.vendorSiteAddress}
                                          key={index}
                                        >
                                          <span
                                            style={{
                                              fontSize: '12px',
                                              textTransform: 'uppercase'
                                            }}
                                          >
                                            {' '}
                                            {item?.vendorSiteAddress}
                                          </span>
                                        </Option>
                                      );
                                    }
                                  )}
                                </Select>
                                {/* <Input
                                  disabled
                                  value={vendorAddressValue}
                                  allowClear
                                  placeholder="Enter Address"
                                  onChange={(e) =>
                                    handleFormDataOnChange(
                                      'vendor_address',
                                      e.target.value
                                    )
                                  }
                                /> */}
                              </FormItem>
                            </Col>
                          </>
                        )}
                      </Row>
                    )}
                    {formData1?.vendor_id === 'other' &&
                      formData1?.methodType_id === 2 && (
                        <Row gutter={16}>
                          <Col span={8}>
                            <FormItem
                              label="Vendor name"
                              name="vendorName"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                allowClear
                                placeholder="Please enter Vendor Name"
                                onChange={handleOtherVendorName}
                              />
                            </FormItem>
                          </Col>
                          <Col span={8}>
                            <FormItem
                              label="Location"
                              name="vendorLocation1"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                allowClear
                                placeholder="Please enter Location"
                                onChange={handleOtherVendorLocation}
                              />
                            </FormItem>
                          </Col>
                          <Col span={8}>
                            <FormItem
                              label="Address"
                              name="vendorAddress"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                // disabled
                                // value={vendorAddress[0]?.addressLine1}
                                allowClear
                                placeholder="Please enter Address"
                                onChange={handleOtherVendorAddress}
                              />
                            </FormItem>
                          </Col>
                        </Row>
                      )}
                  </Spin>
                </Spin>
                <Divider className={`${styles.divider_style}`}></Divider>
                <div
                  style={{
                    fontWeight: 600,
                    color: '#000000',
                    fontSize: '14px',
                    lineHeight: ' 20px',
                    fontFamily: 'Cerebri Sans'
                  }}
                >
                  <span
                    style={{
                      fontSize: '16px',
                      color: '#2364ad',
                      fontFamily: 'SFProText Bold'
                    }}
                  >
                    {' '}
                    Shipment details:
                  </span>
                </div>
                <br />
                <Row gutter={16}>
                  <Col span={8}>
                    <FormItem
                      label="Date"
                      name="date"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.date_styles}`}
                    >
                      <DatePicker
                        format={'DD-MMM-YYYY'}
                        onChange={(_, dateValue) =>
                          handleFormDataOnChange('date', dateValue)
                        }
                        disabledDate={(current) => {
                          return (
                            moment().add(-1, 'days') >= current ||
                            moment().add(0, 'month') <= current
                          );
                        }}
                      ></DatePicker>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem
                      label="Material belongs to"
                      name="material_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        // initialValues={materials[0]?.material_id}
                        placeholder="Select Material"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('material_id', value)
                        }
                        disabled={formData1?.methodType_id === 1}
                      >
                        {materials.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.material_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                    {/*  )} */}
                  </Col>
                  {formData1?.category_id === 1 || formData1?.type_id === 1 ? (
                    <>
                      <Col span={8}>
                        <FormItem
                          label="Expected date of return"
                          name="expected_date_return"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.date_styles}`}
                        >
                          <DatePicker
                            onChange={(_, dateValue) =>
                              handleFormDataOnChange(
                                'expected_date_return',
                                dateValue
                              )
                            }
                            disabledDate={(current) => {
                              return moment().add(-1, 'days') >= current;
                            }}
                            format={'DD-MMM-YYYY'}
                          ></DatePicker>
                        </FormItem>
                      </Col>
                      {/* <Col span={6}>
                        <FormItem
                          label="Actual return date"
                          name="actual_return_date"
                          rules={[
                            {
                              required: false,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.date_styles}`}
                        >
                          <DatePicker
                            disabled
                            defaultValue={moment()}
                            format={'DD-MMM-YYYY'}
                            onChange={(_, dateValue) =>
                              handleFormDataOnChange(
                                'actual_return_date',
                                dateValue
                              )
                            }
                          ></DatePicker>
                        </FormItem>
                      </Col> */}
                    </>
                  ) : (
                    ''
                  )}
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem
                      label="Reason for sending"
                      name="reason_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        placeholder="Select reason"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('reason_id', value)
                        }
                      >
                        {reasons?.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.reason_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label={
                        <span>
                          Detailed reason{' '}
                          <span
                            style={{
                              fontSize: '11px',
                              color: '#9E9E9E',
                              fontWeight: 500
                            }}
                          >
                            (150 characters)
                          </span>
                        </span>
                      }
                      name="detailed_reason"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.textarea_styles}`}
                    >
                      <TextArea
                        allowClear
                        maxLength={150}
                        placeholder="Enter detailed reason"
                        size="small"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'detailed_reason',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Divider className={`${styles.divider_style}`}></Divider>
                <Row gutter={16}>
                  <Col span={6}>
                    <FormItem
                      label="Vehicle Number"
                      name="vehicle_number"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        maxLength={20}
                        placeholder="Please enter vehicle number"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'vehicle_number',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="LR number"
                      name="lr_number"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter LR number"
                        onChange={(e) =>
                          handleFormDataOnChange('lr_number', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="LR Date"
                      name="lr_date"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.date_styles}`}
                    >
                      <DatePicker
                        //disabled
                        //defaultValue={moment() || ''}
                        format={'DD-MMM-YYYY'}
                        onChange={(_, dateValue) =>
                          handleFormDataOnChange('lr_date', dateValue)
                        }
                        disabledDate={(current) => {
                          return moment().add(0, 'month') <= current;
                        }}
                        // moment().add(-1, 'days') >= current ||
                      ></DatePicker>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Name of the carrier person"
                      name="carrier_person_name"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter name"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'carrier_person_name',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
                {newDocs.map((item, index) => (
                  <>
                    {supportingDoc[index] !== '' &&
                    supportingDoc[index] !== undefined ? (
                      <>
                        <div
                          key={index}
                          className={`mb-2`}
                          /* style={{
                              display: 'flex',
                              justifyContent: 'flex-start'
                            }} */
                        >
                          <span>
                            <Tag color="geekblue">
                              {supportingDoc[index].name}
                            </Tag>
                            <DeleteOutlined
                              style={{ color: ' rgb(215, 67, 67)' }}
                              onClick={(e) => handleclearDoc(e, index)}
                            />
                          </span>

                          {index === 0 ? (
                            <span>
                              <Button
                                className={`${styles?.upload_button}`}
                                onClick={handleOnClick}
                              >
                                Add Document
                              </Button>
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start'
                          }}
                        >
                          <FormItem
                            name="files"
                            rules={[
                              {
                                required: false,
                                message: 'Enter type'
                              }
                            ]}
                          >
                            <label
                              htmlFor="file-upload"
                              className={styles.custom_file_upload}
                            >
                              <UploadOutlined /> Upload
                            </label>
                            <Input
                              accept="*"
                              className={styles.upload}
                              type="file"
                              onChange={(e) => handlechangeDoc(e, index)}
                              id="file-upload"
                            />
                          </FormItem>

                          {index === 0 ? (
                            <span>
                              <Button
                                className={`${styles.upload_button}`}
                                onClick={handleOnClick}
                              >
                                Add Document
                              </Button>
                            </span>
                          ) : (
                            <span>
                              <Button
                                style={{
                                  padding: '6px !important',
                                  fontSize: '11px !important'
                                }}
                                className={`${styles.remove_button}`}
                                onClick={(e) => handleRemove(e, index)}
                              >
                                Remove
                              </Button>
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  </>
                ))}
                <Row gutter={16}>
                  <Col span={24}>
                    <FormItem
                      label={
                        <span>
                          Remarks{' '}
                          <span
                            style={{
                              fontSize: '11px',
                              color: '#9E9E9E',
                              fontWeight: 500
                            }}
                          >
                            (200 characters)
                          </span>
                        </span>
                      }
                      name="remarks"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.textarea_styles}`}
                    >
                      <TextArea
                        maxLength={200}
                        //rows={5}
                        allowClear
                        placeholder="Enter remarks"
                        size="small"
                        onChange={(e) =>
                          handleFormDataOnChange('remarks', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem
                      label="Approver"
                      name="approver_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        showSearch
                        placeholder="Select approver"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onSearch={debounceFetcherApprover}
                        onChange={(value) =>
                          handleFormDataOnChange('approver_id', value)
                        }
                      >
                        {approvers.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {`${item.name} (${item.email})`}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Issuer"
                      name="issuer_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        showSearch
                        placeholder="Select issuer"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onSearch={debounceFetcherIssuer}
                        onChange={(value) =>
                          handleFormDataOnChange('issuer_id', value)
                        }
                      >
                        {issuers.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {`${item.name} (${item.email})`}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  {/* <Col span={6}>
                    <FormItem
                      label="Vendor Code"
                      name="vendor_code"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter Vendor Code"
                        onChange={(e) =>
                          handleFormDataOnChange('vendor_code', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col> */}
                  {/* <Col span={6}>
                    <FormItem
                      label="Project"
                      name="project"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter Project"
                        onChange={(e) =>
                          handleFormDataOnChange('project', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col> */}
                  {/* <Col span={6}>
                    <FormItem
                      label="Location"
                      name="location"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter Location"
                        onChange={(e) =>
                          handleFormDataOnChange('location', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col> */}
                  {/* <Col span={6}>
                    <FormItem
                      label="Document Type"
                      name="document_type"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter document type"
                        onChange={(e) =>
                          handleFormDataOnChange('doc_type', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col> */}
                  {/* <Col span={6}>
                    <FormItem
                      label="Document Number"
                      name="doc_number"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter document number"
                        onChange={(e) =>
                          handleFormDataOnChange('doc_number', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col> */}
                </Row>
              </Form>
              <space>
                <span style={{ marginRight: '10px' }}>
                  <Button
                    loading={draftLoading}
                    // htmlType="submit"
                    onClick={saveAsDraft}
                    className={`${styles.Save_draft_button_style}`}
                  >
                    Save as draft
                  </Button>
                </span>
                <span>
                  <Button
                    //loading={proccedLoading}
                    htmlType="submit"
                    // onClick={onFinishTabOneNext}
                    className={`${styles.first_next_button_style}
                    ${
                      selectedTabId === filters?.[1]?.id
                        ? ` ${styles.selected}`
                        : ''
                    }
                  `}
                    disabled={disable === true}
                  >
                    Proceed to Item Details
                  </Button>
                </span>
              </space>
            </div>
          )}
          {selectedTabId === 2 && (
            <div className={`${styles.margin_grey_holder}`}>
              <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <div className={`${styles.items_title}`}>
                    Item details for the gate pass:
                  </div>
                </div>
                {formData1?.methodType_id === 1 ? (
                  ''
                ) : (
                  <div>
                    <span style={{ marginRight: '15px' }}>
                      <a onClick={downloadTemplate}>
                        Download item import template
                      </a>
                    </span>
                    <span>
                      <label
                        htmlFor="file-upload"
                        className={styles.custom_file_upload}
                      >
                        <UploadOutlined /> Browse
                      </label>
                      <Input
                        value={fileValue}
                        accept="*"
                        className={styles.upload}
                        type="file"
                        onChange={(e) => {
                          setFileValue('');
                          handleItemsTemplate(e);
                        }}
                        id="file-upload"
                      />
                    </span>
                  </div>
                )}
              </Row>
              {/* {fetchedItems1?.map((item, index) => {
                return (
                  <Row gutter={24} key={index}>
                    <Col span={4}>
                      <FormItem
                        label="Item"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input allowClear value={item?.item} />
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem
                        label="Description"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input allowClear value={item?.description} />
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input allowClear value={item?.UOM} />
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem
                        label="Quantity"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input allowClear value={item?.quantity} />
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem
                        label="Value"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input allowClear value={item?.value} />
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem
                        label="Remarks"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.textarea_styles} ${styles.padding_styles}`}
                      >
                        <TextArea
                          allowClear
                          placeholder="Enter item remarks"
                          size="small"
                          value={item.remarks}
                          onChange={(e) => handleRemarksChange(index, e)}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                );
              })} */}
              {formData1?.methodType_id === 1 ? (
                <>
                  {fetchedItems1?.map((item, index) => {
                    return (
                      <Row gutter={24} key={index}>
                        <Col span={4}>
                          <FormItem
                            label="Item"
                            rules={[
                              {
                                required: false,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input allowClear value={item?.item_code} />
                          </FormItem>
                        </Col>
                        <Col span={4}>
                          <FormItem
                            label="Description"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              allowClear
                              value={item?.item_description}
                              // onChange={(e) =>
                              //   handleRemarksChange1(index, e.target.value)
                              // }
                            />
                          </FormItem>
                        </Col>
                        <Col span={4}>
                          <FormItem
                            label="UOM"
                            rules={[
                              {
                                required: false,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input allowClear value={item?.uom} />
                          </FormItem>
                        </Col>
                        <Col span={4}>
                          <FormItem
                            label="Quantity"
                            rules={[
                              {
                                required: false,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input allowClear value={item?.quantity} />
                          </FormItem>
                        </Col>
                        <Col span={4}>
                          <FormItem
                            label="Value"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              allowClear
                              value={item?.value} // === '' ? 0 : item?.value
                              // onChange={(e) =>
                              //   handleRemarksChange2(index, e.target.value)
                              // }
                            />
                          </FormItem>
                        </Col>
                        <Col span={4}>
                          <FormItem
                            label="Remarks"
                            rules={[
                              {
                                required: false,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.textarea_styles} ${styles.padding_styles}`}
                          >
                            <TextArea
                              allowClear
                              placeholder="Enter item remarks"
                              size="small"
                              value={item.item_remarks}
                              onChange={(e) =>
                                handleRemarksChange(index, e.target.value)
                              }
                            />
                          </FormItem>
                        </Col>
                      </Row>
                    );
                  })}
                  <Row>
                    <FormItem
                      label="Total Value"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        value={parseFloat(SapTotalValue).toFixed(2)}
                      />
                    </FormItem>
                  </Row>
                </>
              ) : (
                <Form.List
                  name="items"
                  className={`${styles.form_list_margin}`}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields?.map(({ key, name, ...restField }) => (
                        <>
                          <Row
                            key={key}
                            gutter={8}
                            className={`${styles.grey_holder}  w-100 mt-1`}
                          >
                            <Col span={3}>
                              <FormItem
                                label="Item Code"
                                {...restField}
                                name={[name, 'item_code']}
                                rules={[
                                  {
                                    required: false,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles} ${styles.padding_styles}`}
                              >
                                <Input
                                  allowClear
                                  placeholder="Please enter item code"
                                  onChange={(e) =>
                                    handleChangeItem(
                                      e.target.value,
                                      'item_code'
                                    )
                                  }
                                />
                              </FormItem>
                            </Col>
                            <Col span={4}>
                              <FormItem
                                label="Item Description"
                                {...restField}
                                name={[name, 'item_description']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles} ${styles.padding_styles}`}
                              >
                                <Input
                                  allowClear
                                  placeholder="Enter item description"
                                  onChange={(e) =>
                                    handleChangeItem(
                                      e.target.value,
                                      'item_description'
                                    )
                                  }
                                />
                              </FormItem>
                            </Col>
                            <Col span={4}>
                              <FormItem
                                label="UOM"
                                {...restField}
                                name={[name, 'uom']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles} ${styles.padding_styles}`}
                              >
                                <Input
                                  allowClear
                                  placeholder="Please enter UOM"
                                  onChange={(e) =>
                                    handleChangeItem(e.target.value, 'uom')
                                  }
                                />
                              </FormItem>
                            </Col>
                            <Col span={4}>
                              <FormItem
                                label="Quantity"
                                {...restField}
                                name={[name, 'quantity']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles} ${styles.padding_styles}`}
                              >
                                <Input
                                  type="number"
                                  step="0.001"
                                  min={0}
                                  onKeyPress={preventMinus}
                                  allowClear
                                  placeholder="Please enter Quantity "
                                  onChange={(e) =>
                                    handleChangeItem(e.target.value, 'quantity')
                                  }
                                />
                              </FormItem>
                            </Col>
                            <Col span={4}>
                              <FormItem
                                label="Value"
                                {...restField}
                                name={[name, 'value']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles} ${styles.padding_styles}`}
                              >
                                <Input
                                  type="number"
                                  step="0.001"
                                  min={0}
                                  onKeyPress={preventMinus}
                                  allowClear
                                  placeholder="Please enter value"
                                  onChange={(e) => {
                                    handleChangeItem(
                                      parseFloat(e.target.value),
                                      `value_${restField?.fieldKey}`,
                                      'value',
                                      restField?.fieldKey
                                    );
                                  }}
                                />
                              </FormItem>
                            </Col>
                            <Col span={5}>
                              <Row gutter={2}>
                                <Col span={21}>
                                  <FormItem
                                    label="Item Remarks"
                                    {...restField}
                                    name={[name, 'item_remarks']}
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Required field'
                                      }
                                    ]}
                                    className={`${styles.textarea_styles} ${styles.padding_styles}`}
                                  >
                                    <TextArea
                                      allowClear
                                      placeholder="Enter item remarks"
                                      size="small"
                                      onChange={(e) =>
                                        handleChangeItem(
                                          e.target.value,
                                          'item_remarks'
                                        )
                                      }
                                    />
                                  </FormItem>
                                </Col>
                                <Col
                                  span={3}
                                  className={`${styles.delete_icon}`}
                                >
                                  {fields.length > 1 ? (
                                    <div className={`${styles.delete_styles}`}>
                                      <DeleteOutlined
                                        onClick={() => {
                                          setItemKey(restField?.fieldKey);
                                          remove(name);
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      ))}
                      <Row gutter={0}>
                        <Col span={3}>
                          <Form.Item
                            className={`${styles.formItem_marginBottom}`}
                          >
                            <Button
                              className={`${styles.add_button_style}`}
                              onClick={() => {
                                add();
                              }}
                              icon={<PlusOutlined />}
                            >
                              Add item
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <FormItem
                            label="Total Value"
                            rules={[
                              {
                                required: false,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              allowClear
                              value={parseFloat(totalValue).toFixed(2)}
                            />
                          </FormItem>
                        </Col>
                      </Row>
                    </>
                  )}
                </Form.List>
              )}
              <Row gutter={24} className={`${styles.button_margin_left}`}>
                <Button
                  // loading={createDraftLoading}
                  loading={draftLoading}
                  name="draft"
                  htmlType="submit"
                  className={`${styles.next_button_style}`}
                  onClick={draftHandler}
                >
                  Save as draft
                </Button>

                <FormItem>
                  <div className={`${styles.button_margin}`}>
                    <Button
                      //loading={createDraftLoading}
                      loading={proccedLoading}
                      name="submit"
                      className={`${styles.first_next_button_style}`}
                      htmlType="submit"
                      size="middle"
                      onClick={submitHandler}
                    >
                      Submit
                    </Button>
                  </div>
                </FormItem>
              </Row>
            </div>
          )}
        </Form>
        {vendorERPModal ? (
          <VendorERP_Modal
            vendorERPModal={vendorERPModal}
            setVendorERPModal={setVendorERPModal}
            SAPdata={SAPdata}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default Gatepassrequest_SAP;
