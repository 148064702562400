import apiClient from 'utilities/apiClient';

export const approver_sap = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.approver_sap}/${url}`, payload, true);
};

export const issuer_sap = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.issuer_sap}/${url}`, payload, true);
};

export const delete_requests = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.requester}/${url}`, payload, true);
};

export const print = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.issuer_sap}/${url}`, payload, true);
};

/* export const approverSearch = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.approverSearch}`, payload, true);
}; */
