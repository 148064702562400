import React, { useState, useEffect } from 'react';
import CustomLayout from '@/common/Layout';
import HoScreenPage from './hoScreenPage';
import styles from './index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRequests, printRequest } from './redux/slice';
import { getLoggedInUser } from 'utilities/helpers';
import printJS from 'print-js'; //yarn add print-js
import { Badge } from 'antd';

const HoScreen = () => {
  const { allRequests, secondpagination, getAllHoLoading } = useSelector(
    (state) => state.hoScreen
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  //const [secondpage, setSecondPage] = useState(1);
  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter')) === null
      ? ''
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter'))
  );
  //const [searchvalues, setSearchvalues] = useState('');
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [searchRequest, setSearchRequest] = useState('');

  const user = getLoggedInUser();
  console.log('radiofilter>>>>', radiofilter);

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllRequests();
  }, [page, pageSize]);

  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        handleGetAllRequests();
      } else {
        setPage(1);
      }
    }
  }, [radiofilter, searchRequest]);

  const handleGetAllRequests = () => {
    let payload = {
      page,
      per_page: pageSize,
      status: radiofilter,
      search: searchRequest
    };
    dispatch(getAllRequests(payload));
  };
  // const handleChange = (e) => {
  //   const value = {
  //     ticket: e.target.value,
  //     page,
  //     status: radiofilter
  //   };
  //   dispatch(searchvalues(value));
  // };

  const handlePrintRequest = (payload) => {
    const id = { gpid: payload.id };
    dispatch(printRequest(id)).then(function (response) {
      if (response) {
        console.log('printJS..', response?.payload?.url);
        printJS({
          printable: response?.payload?.url,
          type: 'pdf',
          onError: function (error) {
            alert('Error found => ' + error.message);
          }
        });
        // handlePrint();
        handleGetAllRequests();
      }
    });
  };
  return (
    <>
      <CustomLayout sider="true">
        <div className={`mb-4 ${styles.container}`}>
          <>
            <div className={`${styles.page_title}`}>
              {' '}
              Gate pass requests{' '}
              <Badge
                count={`${secondpagination?.total_records || 0} requests`}
                size="small"
                className={`${styles.badgestyles}`}
              />
            </div>

            <div className={`${styles.page_sub_title}`}>
              Review and re-generated on incoming gate pass requests
            </div>
          </>

          <HoScreenPage
            setRadiofilter={setRadiofilter}
            allRequests={allRequests}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            secondpagination={secondpagination}
            radiofilter={radiofilter}
            user={user}
            // handleChange={handleChange}
            handlePrintRequest={handlePrintRequest}
            showDisablePopup={showDisablePopup}
            setShowDisablePopup={setShowDisablePopup}
            setTriggerFilter={setTriggerFilter}
            getAllHoLoading={getAllHoLoading}
            setSearchRequest={setSearchRequest}
          />
        </div>
      </CustomLayout>
    </>
  );
};

export default HoScreen;
