import LocalStorage from './localStorage';

export const getLoggedInUser = () => {
  const loginUser = LocalStorage.getItem('gatepass_user') || '';
  const user = loginUser || { user: null };
  return {
    ...user,
    loggedIn: loginUser !== ''
  };
};

export function numIntStyleFormat(value1) {
  //console.log('value111111111111111111111', value1);
  let value = parseFloat(value1);
  //console.log('value@@@@@@@@@@@@@@@', value);
  value =
    value === undefined
      ? ''
      : value >= 0
      ? value === undefined
        ? ''
        : `${value?.toLocaleString(undefined, { maximumFractionDigits: 3 })}`
      : value === undefined
      ? ''
      : `-${Math.abs(value)?.toLocaleString(undefined, {
          maximumFractionDigits: 3
        })}`;
  //console.log('value22222222222222222', value);
  return value;
}

export const statusesData = [
  { label: 'All', is_active: '' },
  { label: 'Active', is_active: true },
  { label: 'Disabled', is_active: false }
];

export const internalPriorities = [
  { label: 'All', internal_priority: '' },
  { label: 'Very High', internal_priority: 'Very High' },
  { label: 'High', internal_priority: 'High' },
  { label: 'Medium', internal_priority: 'Medium' },
  { label: 'Low', internal_priority: 'Low' },
  { label: 'Very Low', internal_priority: 'Very Low' }
];

export const pharmacyinternalPriorities = [
  { label: 'All', internal_priority: '' },
  { label: 'Very High', internal_priority: 'very high' },
  { label: 'High', internal_priority: 'high' },
  { label: 'Medium', internal_priority: 'medium' },
  { label: 'Low', internal_priority: 'low' },
  { label: 'Very Low', internal_priority: 'very low' }
];

export const mrtype = [
  { label: 'All', type: '' },
  { label: 'Rx-MR', type: '1' },
  { label: 'OTC_MR', type: '2' }
];
