import styles from './index.module.less';
import { Col, Row, Select, Input } from 'antd';
import FormItem from 'common/FormItem';
const { Option } = Select;
//const { TextArea } = Input;

export default function Vendor_IRISO_MRR({
  debounceFetcher,
  handleFormDataOnChange,
  vendors,
  formData1,
  documentID,
  erpParseItems,
  vendorAddressValue,
  vendorLocation,
  requestbyid
}) {
  //const vendorIRISO = [{ vendorName: erpParseItems?.vendor_code, id: 1 }];
  return (
    <>
      {documentID === 2 ||
      documentID === 3 ||
      (documentID === undefined && requestbyid?.document_master_id === 2) ||
      (documentID === undefined && requestbyid?.document_master_id === 3) ? (
        /*  (documentID === 1 && erpParseItems?.type_of_issue !== 'FREE') ||
      (documentID === 1 && erpParseItems?.type_of_issue !== 'CHARGEABLE') */
        <Row gutter={16}>
          <Col span={8}>
            {documentID === 3 ||
            documentID === 2 ||
            (documentID === undefined &&
              requestbyid?.document_master_id === 3) ||
            (documentID === undefined &&
              requestbyid?.document_master_id === 2) ? (
              <FormItem
                label={
                  documentID === 3 || requestbyid?.Document_master?.id === 3
                    ? 'Project name and code'
                    : 'Vendor name and code'
                }
                //name="vendor_id"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  disabled
                  value={
                    erpParseItems?.vendor_code === undefined
                      ? requestbyid?.vendor_master_id === null
                        ? ''
                        : `${requestbyid?.Vendor_Master?.vendorName}(${requestbyid?.Vendor_Master?.vendorCode}) `
                      : `${erpParseItems?.vendor_name} (${erpParseItems?.vendor_code})`
                  }
                  allowClear
                  placeholder={
                    documentID === 3
                      ? 'Project name and code'
                      : 'Vendor name and code'
                  }
                  onChange={(value) =>
                    handleFormDataOnChange('vendor_id', value)
                  }
                />
              </FormItem>
            ) : (
              {
                /* <FormItem
                label={
                  documentID === 3 || requestbyid?.Document_master?.id === 3
                    ? 'Project name and code'
                    : 'Vendor name and code'
                }
                name="vendor_id"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  //showSearch
                  allowClear={true}
                  optionFilterProp="children"
                  placeholder={
                    documentID === 3
                      ? 'Select Project Name'
                      : 'Select Vendor Name'
                  }
                  onSearch={debounceFetcher}
                  onChange={(value) =>
                    handleFormDataOnChange('vendor_id', value)
                  }
                  filterOption={(input, option) => {
                    return (
                      option?.key
                        ?.toString()
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0 ||
                      option?.title
                        ?.toString()
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    );
                  }}
                >
                  {vendors?.map((item, index) => (
                    <Option
                      title={item.vendorName}
                      key={index}
                      value={item?.id}
                    >
                      <span
                        style={{
                          fontSize: '12px',
                          textTransform: 'uppercase'
                        }}
                      >
                        <span style={{ marginRight: '4px' }}>
                          {item?.vendorName}
                        </span>
                      </span>
                    </Option>
                  ))}
                </Select>
              </FormItem> */
              }
            )}
          </Col>
          <Col span={8}>
            <FormItem
              label="Location"
              // name="vendor_location"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
              /* className={`${styles.select_styles}`} */
              className={`${styles.input_styles}`}
            >
              <Input
                disabled
                value={
                  erpParseItems?.vendor_location === undefined
                    ? requestbyid?.vendor_location_name
                    : erpParseItems?.vendor_location
                }
                allowClear
                placeholder="Enter Location"
                onChange={(value) =>
                  handleFormDataOnChange('vendor_location', value)
                }
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label="Address"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
              className={`${styles.input_styles}`}
            >
              <Input
                disabled
                //value={vendorAddressValue}
                value={
                  erpParseItems?.vendor_address === undefined
                    ? requestbyid?.vendor_location_address
                    : erpParseItems?.vendor_address
                }
                allowClear
                placeholder="Enter Address"
                onChange={(e) =>
                  handleFormDataOnChange('vendor_address', e.target.value)
                }
              />
            </FormItem>
          </Col>
        </Row>
      ) : (
        <Row gutter={16}>
          <Col span={8}>
            <FormItem
              label={'Vendor name and code'}
              // name="vendor_id"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
              className={
                /*  requestbyid?.document_number === null && */
                requestbyid?.vendor_master_id !== null
                  ? `${styles.input_styles}`
                  : `${styles.select_styles}`
              }
            >
              {
                /* requestbyid?.document_number === null && */
                requestbyid?.vendor_master_id === null ? (
                  <Select
                    showSearch
                    allowClear={true}
                    optionFilterProp="children"
                    placeholder="Select Vendor Name"
                    onSearch={debounceFetcher}
                    onChange={(value) =>
                      handleFormDataOnChange('vendor_id', value)
                    }
                    filterOption={(input, option) => {
                      return (
                        option?.key
                          ?.toString()
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0 ||
                        option?.title
                          ?.toString()
                          ?.toLowerCase()
                          ?.indexOf(input?.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {vendors?.map((item, index) => (
                      <Option
                        title={item.vendorName}
                        key={index}
                        value={item?.id}
                      >
                        <span
                          style={{
                            fontSize: '12px',
                            textTransform: 'uppercase'
                          }}
                        >
                          <span style={{ marginRight: '4px' }}>
                            {item?.vendorName}
                          </span>
                        </span>
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    disabled
                    /* disabled={
                  documentID === 1 ||
                  (documentID === undefined &&
                    requestbyid?.document_master_id === 1)
                } */
                    value={
                      erpParseItems?.vendor_code === undefined
                        ? requestbyid?.vendor_master_id === null
                          ? ''
                          : `${requestbyid?.Vendor_Master?.vendorName}(${requestbyid?.Vendor_Master?.vendorCode}) `
                        : `${erpParseItems?.vendor_name} (${erpParseItems?.vendor_code})`
                    }
                    allowClear
                    placeholder={'Vendor name and code'}
                    onChange={(value) =>
                      handleFormDataOnChange('vendor_id', value)
                    }
                  />
                )
              }
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label="Location"
              name="vendor_location"
              rules={[
                formData1?.vendor_id === 'other'
                  ? {
                      required: false,
                      message: 'Required field'
                    }
                  : {
                      required: true,
                      message: 'Required field'
                    }
              ]}
              className={`${styles.select_styles}`}
            >
              <Select
                disabled={
                  requestbyid?.document_number !== null &&
                  requestbyid?.vendor_master_id !== null
                }
                /* disabled={
                  documentID === 1 ||
                  (documentID === undefined &&
                    requestbyid?.document_master_id === 1)
                } */
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option?.children
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase());
                }}
                onChange={(value) =>
                  handleFormDataOnChange('vendor_location', value)
                }
                placeholder="Select Location"
              >
                {vendorLocation.map((item, index) => {
                  return (
                    <Option value={item.id} key={index}>
                      <span
                        style={{
                          fontSize: '12px',
                          textTransform: 'uppercase'
                        }}
                      >
                        {' '}
                        {item.vendorSite}, {item.city}, {item.state}
                      </span>
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label="Address"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
              className={`${styles.input_styles}`}
            >
              <Input
                disabled
                //value={vendorAddressValue}
                value={
                  vendorAddressValue === undefined
                    ? requestbyid?.vendor_location_address
                    : vendorAddressValue
                }
                allowClear
                placeholder="Enter Address"
                onChange={(e) =>
                  handleFormDataOnChange('vendor_address', e.target.value)
                }
              />
            </FormItem>
          </Col>
        </Row>
      )}
    </>
  );
}
/* <Row gutter={16}>
          <Col span={8}>
            {documentID === 3 ? (
              <FormItem
                label={
                  documentID === 3
                    ? 'Project name and code'
                    : 'Vendor name and code'
                }
                //name="vendor_id"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  disabled
                  value={erpParseItems?.vendor_code}
                  allowClear
                  placeholder={
                    documentID === 3
                      ? 'Project name and code'
                      : 'Vendor name and code'
                  }
                  onChange={(value) =>
                    handleFormDataOnChange('vendor_id', value)
                  }
                />
              </FormItem>
            ) : (
              <FormItem
                label={
                  documentID === 3
                    ? 'Project name and code'
                    : 'Vendor name and code'
                }
                name="vendor_id"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  //showSearch
                  allowClear={true}
                  optionFilterProp="children"
                  placeholder={
                    documentID === 3
                      ? 'Select Project Name'
                      : 'Select Vendor Name'
                  }
                  onSearch={debounceFetcher}
                  onChange={(value) =>
                    handleFormDataOnChange('vendor_id', value)
                  }
                  filterOption={(input, option) => {
                    return (
                      option?.key
                        ?.toString()
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0 ||
                      option?.title
                        ?.toString()
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    );
                  }}
                >
                  {
                     vendors?.map((item, index) => (
                      <Option
                        title={item.vendorName}
                        key={index}
                        value={item?.id}
                      >
                        <span
                          style={{
                            fontSize: '12px',
                            textTransform: 'uppercase'
                          }}
                        >
                          <span style={{ marginRight: '4px' }}>
                            {item?.vendorName}
                          </span>
                        </span>
                      </Option>
                    ))
                  }
                </Select>
              </FormItem>
            )}
          </Col>
          <Col span={8}>
            <FormItem
              label="Location"
              // name="vendor_location"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
             
              className={`${styles.input_styles}`}
            >
              <Input
                disabled
                value={erpParseItems?.vendor_location}
                allowClear
                placeholder="Enter Location"
                onChange={(value) =>
                  handleFormDataOnChange('vendor_location', value)
                }
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label="Address"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
              className={`${styles.input_styles}`}
            >
              <Input
                disabled
                //value={vendorAddressValue}
                value={
                  erpParseItems?.vendor_address === undefined
                    ? requestbyid?.vendor_location_address
                    : erpParseItems?.vendor_address
                }
                allowClear
                placeholder="Enter Address"
                onChange={(e) =>
                  handleFormDataOnChange('vendor_address', e.target.value)
                }
              />
            </FormItem>
          </Col>
        </Row> */
