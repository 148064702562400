import CustomLayout from '@/common/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { message, Space, Row, Col, Button, Card, Divider } from 'antd';
import styles from './index.module.less';
import { history } from '@/app/history';
import tata from './images/tata.svg';
import LocalStorage from 'utilities/localStorage';
import moment from 'moment';
import { issuerGenrateGP_EditGP } from '../../redux/slice';
import './styles.css';
import { useRef, useState } from 'react';

function PreviewGenratedGP() {
  //console.log("requester_data",requester_data)
  const { issuerGenrateGPLoading } = useSelector((state) => state.view);
  const [initiate, setInitiate] = useState(false);
  const dispatch = useDispatch();

  const imgContainerRef = useRef(null);

  const vehicleData = LocalStorage.getItem('vehicleFormValue');

  console.log('vehicleData>>>>>', vehicleData);

  const handleFormSubmit = () => {
    setInitiate(true);
    console.log(initiate);
    const payloadIssuer = {
      gpid: vehicleData?.gpid,
      vehicle_number: vehicleData?.vehicle_number,
      lr_number: vehicleData?.lr_number,
      lr_date:
        vehicleData?.lr_date === undefined
          ? moment(new Date()).format('DD, MMM YYYY')
          : moment(vehicleData?.lr_date).format('DD MMM YYYY'), // moment(new Date()).format('DD, MMM YYYY'), // vehicleData?.lr_date,
      carrier_person_name: vehicleData?.carrier_person_name,
      waybill_number: vehicleData?.waybillNumber
    };
    dispatch(issuerGenrateGP_EditGP(payloadIssuer)).then(function (response) {
      if (response?.payload?.success) {
        setInitiate(false);
        // message.success(response?.payload?.msg);${response?.payload?.Gate_Pass_Number}
        message.success(
          `${response.payload.msg}. Gate Pass No: ${response?.payload?.Gate_Pass_Number}`
        );
        history.push('/issuer-gatepass');
      } else {
        message.error(response?.payload?.msg);
      }
    });
  };
  return (
    <>
      <CustomLayout sider="true">
        <div className={`mb-4 ${styles.container}`}>
          <Card
            className={` text-center ${styles.image_viewer_card}`}
            title={
              <div className="df-jc-ac sfprotext-bold font-18">
                Preview your document below
              </div>
            }
            bordered={true}
          >
            <div className="App" ref={imgContainerRef}>
              <div className="watermarked" data-watermark="DRAFT">
                <div>
                  <div className={`${styles.page_header}`}>
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div>
                        {' '}
                        <img src={tata} alt="logo" />
                      </div>
                      <div>
                        <h2>
                          TATA PROJECTS LIMITED
                          <br />{' '}
                          <p className={`${styles.header_sub}`}>
                            {vehicleData?.requester_data?.Type?.type ===
                            'External'
                              ? `${vehicleData?.requester_data?.Category?.category}`
                              : `${vehicleData?.requester_data?.Category?.category}`}
                          </p>
                        </h2>
                      </div>
                      <div style={{ fontWeight: 'bold', fontSize: '20px' }}>
                        Security Copy{' '}
                      </div>
                    </Row>
                  </div>
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginTop: '10px'
                    }}
                  >
                    <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      {vehicleData?.requester_data?.Type?.type}
                    </div>
                  </Row>
                  <Row style={{ display: 'flex', justifyContent: 'end' }}>
                    <h5>
                      {vehicleData?.requester_data?.Method_type?.method_type ===
                      'ERP' ? (
                        <span>
                          <span style={{ fontSize: '13px' }}>
                            {' '}
                            {
                              vehicleData?.requester_data?.Document_master
                                ?.document_name
                            }
                            :
                          </span>
                          <span style={{ marginLeft: '3px', fontSize: '13px' }}>
                            {vehicleData?.requester_data?.document_number}
                          </span>
                        </span>
                      ) : (
                        ''
                      )}{' '}
                    </h5>
                  </Row>
                  <Row>
                    <Col
                      span={12}
                      style={{ textAlign: 'left', marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: '500' }}>
                        <span
                          style={{
                            fontWeight: 600
                          }}
                        >
                          {' '}
                          Gate Pass:{' '}
                        </span>{' '}
                        Not generated
                      </span>
                      <span style={{ paddingLeft: '3px' }}></span>
                      <span style={{ paddingLeft: '3px' }}></span>
                    </Col>
                    <Col
                      span={12}
                      style={{ textAlign: 'right', marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: '600' }}>DATE:</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={12}
                      style={{ textAlign: 'left', marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: '500' }}>
                        <span
                          style={{
                            fontWeight: 600
                          }}
                        >
                          {' '}
                          CLIENT:{' '}
                        </span>
                        {
                          vehicleData?.requester_data?.project_master
                            ?.client_name
                        }
                      </span>
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px'
                          //fontSize: '13px',
                          // fontWeight: 'bold'
                        }}
                      ></span>{' '}
                      <span style={{ paddingLeft: '3px' }}></span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      style={{
                        textAlign: 'left',
                        marginTop: '5px',
                        //fontWeight: 600,
                        fontSize: '13px'
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 600
                        }}
                      >
                        {' '}
                        GST Number:{' '}
                      </span>
                      {
                        vehicleData?.requester_data?.project_location_master
                          ?.gst_number
                      }
                      <span style={{ paddingLeft: '3px' }}></span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={24}
                      style={{ textAlign: 'left', marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: '600' }}>PROJECT:</span>
                      <span style={{ paddingLeft: '3px' }}>
                        (
                        {
                          vehicleData?.requester_data?.project_master
                            ?.project_code
                        }
                        )
                      </span>
                      <span style={{ paddingLeft: '3px' }}>
                        {
                          vehicleData?.requester_data?.project_master
                            ?.project_name
                        }
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={24}
                      style={{ textAlign: 'left', marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: '600' }}>ADDRESS:</span>
                      <span style={{ paddingLeft: '3px' }}>
                        (
                        {
                          vehicleData?.requester_data?.project_location_master
                            ?.address
                        }
                        )
                      </span>
                    </Col>
                  </Row>
                  {vehicleData?.requester_data?.Type?.type === 'External' ? (
                    <Row className={`${styles.tataText_row}`}>
                      <div className={`${styles.tataText_div}`}>
                        The following materials are being released from Stores
                        for the reason mentioned in the declaration below:
                      </div>
                    </Row>
                  ) : vehicleData?.requester_data?.Type?.type === 'Internal' &&
                    vehicleData?.requester_data?.Method_type?.id === 2 ? (
                    <Row className={`${styles.tataText_row}`}>
                      <div className={`${styles.tataText_div}`}>
                        The following materials are being released from Stores
                        for the reason mentioned in the declaration below:
                      </div>
                    </Row>
                  ) : (
                    ''
                  )}
                  {vehicleData?.requester_data?.Type?.type === 'External' ? (
                    vehicleData?.requester_data?.Method_type?.id === 1 ? (
                      <div className={`${styles.tataText_row}`}>
                        <Row className={`${styles.tataText_row}`}>
                          <div className={`${styles.tataText_div}`}>
                            {vehicleData?.requester_data?.Document_master
                              ?.id === 2 ? (
                              <span>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    marginRight: '4px'
                                  }}
                                >
                                  Declaration:
                                </span>
                                This material is rejected and being returned to
                                the Vendor.
                              </span>
                            ) : vehicleData?.requester_data?.Document_master
                                ?.id === 1 ? (
                              <span>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    marginRight: '4px'
                                  }}
                                >
                                  Declaration:
                                </span>
                                This material is being issued from Stores and
                                moved out for own consumption in Construction /
                                Erection in the same project.
                              </span>
                            ) : vehicleData?.requester_data?.Document_master
                                ?.id === 3 ? (
                              <span>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    marginRight: '4px'
                                  }}
                                >
                                  {/* {
                                    vehicleData?.requester_data?.Document_master
                                      ?.document_name
                                  } */}
                                  Declaration:
                                </span>
                                This material is being transferred within the
                                organisation for own Use.
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </Row>
                      </div>
                    ) : (
                      <div className={`${styles.tataText_row}`}>
                        <Row className={`${styles.tataText_row}`}>
                          <div className={`${styles.tataText_div}`}>
                            <span>
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  marginRight: '4px'
                                }}
                              >
                                Declaration:
                              </span>
                              {vehicleData?.requester_data?.Reason?.reason_name}
                            </span>
                          </div>
                        </Row>
                      </div>
                    )
                  ) : vehicleData?.requester_data?.Type?.type === 'Internal' &&
                    vehicleData?.requester_data?.Method_type?.id === 2 ? (
                    <div className={`${styles.tataText_row}`}>
                      <Row className={`${styles.tataText_row}`}>
                        <div className={`${styles.tataText_div}`}>
                          <span>
                            <span
                              style={{
                                fontWeight: 'bold',
                                marginRight: '4px'
                              }}
                            >
                              Declaration:
                            </span>
                            {vehicleData?.requester_data?.Reason?.reason_name}
                          </span>
                        </div>
                      </Row>
                    </div>
                  ) : (
                    ''
                  )}
                  <Row className={`${styles.tataText_row}`}>
                    <div className={`${styles.tataText_div}`}>
                      Please permit M/S{' '}
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px',
                          fontSize: '13px',
                          fontWeight: 'bold'
                        }}
                      >
                        {vehicleData?.carrier_person_name}
                      </span>{' '}
                      to take out the following materials from{' '}
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px',
                          fontSize: '13px',
                          fontWeight: 'bold'
                        }}
                      >
                        {
                          vehicleData?.requester_data?.project_location_master
                            ?.name
                        }
                      </span>{' '}
                      to deliver the same to{' '}
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px',
                          fontSize: '13px',
                          fontWeight: 'bold'
                        }}
                      >
                        {vehicleData?.requester_data?.Document_master?.id === 3
                          ? vehicleData?.requester_data?.vendor_location_name
                          : vehicleData?.requester_data?.Vendor_Master
                              ?.vendorName}
                      </span>{' '}
                      vide L.R.No{' '}
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px',
                          fontSize: '13px',
                          fontWeight: 'bold'
                        }}
                      >
                        {vehicleData?.lr_number}
                      </span>{' '}
                      Dated{' '}
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px',
                          fontSize: '13px',
                          fontWeight: 'bold'
                        }}
                      >
                        {moment(vehicleData?.lr_date).format('DD MMM YYYY')}
                      </span>{' '}
                      in vehicle No.
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px',
                          fontSize: '13px',
                          fontWeight: 'bold'
                        }}
                      >
                        {vehicleData?.vehicle_number}
                      </span>{' '}
                      and Waybill No.
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px',
                          fontSize: '13px',
                          fontWeight: 'bold'
                        }}
                      >
                        {vehicleData?.waybillNumber}.
                      </span>{' '}
                    </div>
                  </Row>
                  <div className={`${styles.tatatable_margin}`}>
                    <table className={`${styles.tatatable}`}>
                      <tr className={`${styles.tatatable_header}`}>
                        <th className={`${styles.tatatable_header}`}>S.No</th>
                        <th className={`${styles.tatatable_header}`}>
                          Item Code
                        </th>
                        <th className={`${styles.tatatable_header}`}>
                          Item Description
                        </th>
                        {/*  <th className={`${styles.tatatable_header}`}>Item Name</th> */}
                        <th className={`${styles.tatatable_header}`}>UOM</th>
                        <th className={`${styles.tatatable_header}`}>
                          Quantity
                        </th>

                        {/*  {vehicleData?.requester_data?.methodType_id === 2 && ( */}
                        <th className={`${styles.tatatable_header}`}>
                          Item Rremarks
                        </th>
                        {/*   )} */}
                      </tr>
                      {vehicleData?.requester_data?.Items?.map(
                        (item, index) => (
                          <tr key={index} className={`${styles.tatatable_row}`}>
                            <td className={`${styles.tatatable_td}`}>
                              {index + 1}
                            </td>
                            <td className={`${styles.tatatable_td}`}>
                              {item.item_code}
                            </td>
                            <td
                              className={`${styles.tatatable_td} ${styles.tatatable_dis_width}`}
                            >
                              {item?.item_description}
                            </td>
                            {/*  <td className={`${styles.tatatable_td}`}>
                        {item?.item_name}
                      </td> */}
                            <td className={`${styles.tatatable_td}`}>
                              {item.uom}
                            </td>
                            <td className={`${styles.tatatable_td}`}>
                              {item?.quantity}
                            </td>

                            {/*  {vehicleData?.requester_data?.methodType_id ===
                              2 && ( */}
                            <td className={`${styles.tatatable_td}`}>
                              {item?.item_remarks}
                            </td>
                            {/*  )} */}
                          </tr>
                        )
                      )}
                    </table>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div>
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '50px'
                      }}
                    >
                      <Col>
                        <span
                          style={{
                            fontWeight: 600
                          }}
                        >
                          APPROVED BY
                        </span>
                      </Col>
                      <Col>
                        {' '}
                        <span
                          style={{
                            fontWeight: 600
                          }}
                        >
                          STORES IN-CHARGE
                        </span>
                      </Col>
                      <Col>
                        <span
                          style={{
                            fontWeight: 600
                          }}
                        >
                          RECEIVED BY SIGNATURE
                        </span>
                      </Col>
                    </Row>
                  </div>
                </div>
                <Divider />
                {/*  Transporter Copy{' '} */}
                {vehicleData?.requester_data?.Type?.type === 'Internal' ? (
                  ''
                ) : (
                  <div className="watermarked" data-watermark="DRAFT">
                    <div>
                      <div className={`${styles.page_header}`}>
                        <Row
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <div>
                            {' '}
                            <img src={tata} alt="logo" />
                          </div>
                          <div>
                            <h2>
                              TATA PROJECTS LIMITED
                              <br />{' '}
                              <p className={`${styles.header_sub}`}>
                                {vehicleData?.requester_data?.Type?.type ===
                                'External'
                                  ? `${vehicleData?.requester_data?.Category?.category}`
                                  : `${vehicleData?.requester_data?.Category?.category}`}
                              </p>
                            </h2>
                          </div>
                          <div style={{ fontWeight: 'bold', fontSize: '20px' }}>
                            Transporter Copy{' '}
                          </div>
                        </Row>
                      </div>
                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'end',
                          marginTop: '10px'
                        }}
                      >
                        <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                          {' '}
                          {vehicleData?.requester_data?.Type?.type}
                        </div>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'end' }}>
                        <h5>
                          {vehicleData?.requester_data?.Method_type
                            ?.method_type === 'ERP' ? (
                            <span>
                              <span style={{ fontSize: '13px' }}>
                                {' '}
                                {
                                  vehicleData?.requester_data?.Document_master
                                    ?.document_name
                                }
                                :
                              </span>
                              <span
                                style={{ marginLeft: '2px', fontSize: '13px' }}
                              >
                                {vehicleData?.requester_data?.document_number}
                              </span>
                            </span>
                          ) : (
                            ''
                          )}{' '}
                        </h5>
                      </Row>
                      {/*  <Row>
                        <Col
                          span={12}
                          style={{ textAlign: 'left', marginTop: '5px' }}
                        >
                          <span style={{ fontWeight: '500' }}>
                            CLIENT:{' '}
                            {
                              vehicleData?.requester_data?.project_master
                                ?.client_name
                            }
                          </span>
                          <span
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px'
                            }}
                          ></span>{' '}
                          <span style={{ paddingLeft: '3px' }}></span>
                        </Col>
                        <Col
                          span={12}
                          style={{ textAlign: 'right', marginTop: '5px' }}
                        >
                          <span style={{ fontWeight: '500' }}>
                            Gate Pass: Not generated
                          </span>
                          <span style={{ paddingLeft: '3px' }}></span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          span={12}
                          style={{ textAlign: 'left', marginTop: '5px' }}
                        >
                          <span style={{ fontWeight: '500' }}>PROJECT:</span>
                          <span style={{ paddingLeft: '3px' }}>
                            (
                            {
                              vehicleData?.requester_data?.project_master
                                ?.project_code
                            }
                            )
                          </span>
                          <span style={{ paddingLeft: '3px' }}>
                            {
                              vehicleData?.requester_data?.project_master
                                ?.project_name
                            }
                          </span>
                        </Col>
                        <Col
                          span={12}
                          style={{ textAlign: 'right', marginTop: '5px' }}
                        >
                          <span style={{ fontWeight: '500' }}>DATE:</span>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col
                          span={12}
                          style={{ textAlign: 'left', marginTop: '5px' }}
                        >
                          <span style={{ fontWeight: '500' }}>
                            <span
                              style={{
                                fontWeight: 600
                              }}
                            >
                              Gate Pass:
                            </span>{' '}
                            Not generated
                          </span>
                          <span style={{ paddingLeft: '3px' }}></span>
                          <span style={{ paddingLeft: '3px' }}></span>
                        </Col>
                        <Col
                          span={12}
                          style={{ textAlign: 'right', marginTop: '5px' }}
                        >
                          <span style={{ fontWeight: '600' }}>DATE:</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          span={12}
                          style={{ textAlign: 'left', marginTop: '5px' }}
                        >
                          <span style={{ fontWeight: '500' }}>
                            <span
                              style={{
                                fontWeight: 600
                              }}
                            >
                              {' '}
                              CLIENT:{' '}
                            </span>
                            {
                              vehicleData?.requester_data?.project_master
                                ?.client_name
                            }
                          </span>
                          <span
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px'
                              //fontSize: '13px',
                              // fontWeight: 'bold'
                            }}
                          ></span>{' '}
                          <span style={{ paddingLeft: '3px' }}></span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          style={{
                            textAlign: 'left',
                            marginTop: '5px',
                            //fontWeight: 600,
                            fontSize: '13px'
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 600
                            }}
                          >
                            {' '}
                            GST Number:{' '}
                          </span>
                          {
                            vehicleData?.requester_data?.project_location_master
                              ?.gst_number
                          }
                          <span style={{ paddingLeft: '3px' }}></span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          span={24}
                          style={{ textAlign: 'left', marginTop: '5px' }}
                        >
                          <span style={{ fontWeight: '600' }}>PROJECT:</span>
                          <span style={{ paddingLeft: '3px' }}>
                            (
                            {
                              vehicleData?.requester_data?.project_master
                                ?.project_code
                            }
                            )
                          </span>
                          <span style={{ paddingLeft: '3px' }}>
                            {
                              vehicleData?.requester_data?.project_master
                                ?.project_name
                            }
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          span={24}
                          style={{ textAlign: 'left', marginTop: '5px' }}
                        >
                          <span style={{ fontWeight: '600' }}>ADDRESS:</span>
                          <span style={{ paddingLeft: '3px' }}>
                            (
                            {
                              vehicleData?.requester_data
                                ?.project_location_master?.address
                            }
                            )
                          </span>
                        </Col>
                      </Row>
                      {vehicleData?.requester_data?.Type?.type ===
                      'External' ? (
                        <Row className={`${styles.tataText_row}`}>
                          <div className={`${styles.tataText_div}`}>
                            The following materials are being released from
                            Stores for the reason mentioned in the declaration
                            below:
                          </div>
                        </Row>
                      ) : vehicleData?.requester_data?.Type?.type ===
                          'Internal' &&
                        vehicleData?.requester_data?.Method_type?.id === 2 ? (
                        <Row className={`${styles.tataText_row}`}>
                          <div className={`${styles.tataText_div}`}>
                            The following materials are being released from
                            Stores for the reason mentioned in the declaration
                            below:
                          </div>
                        </Row>
                      ) : (
                        ''
                      )}
                      {vehicleData?.requester_data?.Type?.type ===
                      'External' ? (
                        vehicleData?.requester_data?.Method_type?.id === 1 ? (
                          <div className={`${styles.tataText_row}`}>
                            <Row className={`${styles.tataText_row}`}>
                              <div className={`${styles.tataText_div}`}>
                                {vehicleData?.requester_data?.Document_master
                                  ?.id === 2 ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        marginRight: '4px'
                                      }}
                                    >
                                      {/*  {
                                        vehicleData?.requester_data?.Document_master
                                          ?.document_name
                                      } */}
                                      Declaration:
                                    </span>
                                    This material is rejected and being returned
                                    to the Vendor.
                                  </span>
                                ) : vehicleData?.requester_data?.Document_master
                                    ?.id === 1 ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        marginRight: '4px'
                                      }}
                                    >
                                      {/*  {
                                        vehicleData?.requester_data?.Document_master
                                          ?.document_name
                                      } */}
                                      Declaration:
                                    </span>
                                    This material is being issued from Stores
                                    and moved out for own consumption in
                                    Construction / Erection in the same project.
                                  </span>
                                ) : vehicleData?.requester_data?.Document_master
                                    ?.id === 3 ? (
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        marginRight: '4px'
                                      }}
                                    >
                                      {/* {
                                        vehicleData?.requester_data?.Document_master
                                          ?.document_name
                                      } */}
                                      Declaration:
                                    </span>
                                    This material is being transferred within
                                    the organisation for own Use.
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            </Row>
                          </div>
                        ) : (
                          <div className={`${styles.tataText_row}`}>
                            <Row className={`${styles.tataText_row}`}>
                              <div className={`${styles.tataText_div}`}>
                                <span>
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                      marginRight: '4px'
                                    }}
                                  >
                                    Declaration:
                                  </span>
                                  {
                                    vehicleData?.requester_data?.Reason
                                      ?.reason_name
                                  }
                                </span>
                              </div>
                            </Row>
                          </div>
                        )
                      ) : vehicleData?.requester_data?.Type?.type ===
                          'Internal' &&
                        vehicleData?.requester_data?.Method_type?.id === 2 ? (
                        <div className={`${styles.tataText_row}`}>
                          <Row className={`${styles.tataText_row}`}>
                            <div className={`${styles.tataText_div}`}>
                              <span>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    marginRight: '4px'
                                  }}
                                >
                                  Declaration:
                                </span>
                                {
                                  vehicleData?.requester_data?.Reason
                                    ?.reason_name
                                }
                              </span>
                            </div>
                          </Row>
                        </div>
                      ) : (
                        ''
                      )}
                      <Row className={`${styles.tataText_row}`}>
                        <div className={`${styles.tataText_div}`}>
                          Please permit M/S{' '}
                          <span
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px',
                              fontSize: '13px',
                              fontWeight: 'bold'
                            }}
                          >
                            {vehicleData?.carrier_person_name}
                          </span>{' '}
                          to take out the following materials from{' '}
                          <span
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px',
                              fontSize: '13px',
                              fontWeight: 'bold'
                            }}
                          >
                            {
                              vehicleData?.requester_data
                                ?.project_location_master?.name
                            }
                          </span>{' '}
                          to deliver the same to{' '}
                          <span
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px',
                              fontSize: '13px',
                              fontWeight: 'bold'
                            }}
                          >
                            {vehicleData?.requester_data?.Document_master
                              ?.id === 3
                              ? vehicleData?.requester_data
                                  ?.vendor_location_name
                              : vehicleData?.requester_data?.Vendor_Master
                                  ?.vendorName}
                          </span>{' '}
                          vide L.R.No{' '}
                          <span
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px',
                              fontSize: '13px',
                              fontWeight: 'bold'
                            }}
                          >
                            {vehicleData?.lr_number}
                          </span>{' '}
                          Dated{' '}
                          <span
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px',
                              fontSize: '13px',
                              fontWeight: 'bold'
                            }}
                          >
                            {moment(vehicleData?.lr_date).format('DD MMM YYYY')}
                          </span>{' '}
                          in vehicle No.
                          <span
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px',
                              fontSize: '13px',
                              fontWeight: 'bold'
                            }}
                          >
                            {vehicleData?.vehicle_number}
                          </span>{' '}
                          and Waybill No.
                          <span
                            style={{
                              marginLeft: '2px',
                              marginRight: '2px',
                              fontSize: '13px',
                              fontWeight: 'bold'
                            }}
                          >
                            {vehicleData?.waybillNumber}.
                          </span>{' '}
                        </div>
                      </Row>
                      <div className={`${styles.tatatable_margin}`}>
                        <table className={`${styles.tatatable}`}>
                          <tr className={`${styles.tatatable_header}`}>
                            <th className={`${styles.tatatable_header}`}>
                              S.No
                            </th>
                            <th className={`${styles.tatatable_header}`}>
                              Item Code
                            </th>
                            <th className={`${styles.tatatable_header}`}>
                              Item Description
                            </th>
                            <th className={`${styles.tatatable_header}`}>
                              UOM
                            </th>
                            <th className={`${styles.tatatable_header}`}>
                              Quantity
                            </th>

                            {/*  {vehicleData?.requester_data?.methodType_id ===
                              2 && ( */}

                            <th className={`${styles.tatatable_header}`}>
                              Item Rremarks
                            </th>

                            {/*  )} */}
                          </tr>
                          {vehicleData?.requester_data?.Items?.map(
                            (item, index) => (
                              <tr
                                key={index}
                                className={`${styles.tatatable_row}`}
                              >
                                <td className={`${styles.tatatable_td}`}>
                                  {index + 1}
                                </td>
                                <td className={`${styles.tatatable_td}`}>
                                  {item.item_code}
                                </td>
                                <td
                                  className={`${styles.tatatable_td} ${styles.tatatable_dis_width}`}
                                >
                                  {item?.item_description}
                                </td>
                                <td className={`${styles.tatatable_td}`}>
                                  {item.uom}
                                </td>
                                <td className={`${styles.tatatable_td}`}>
                                  {item?.quantity}
                                </td>

                                {/*  {vehicleData?.requester_data?.methodType_id ===
                                  2 && ( */}
                                <td className={`${styles.tatatable_td}`}>
                                  {item?.item_remarks}
                                </td>
                                {/*   )} */}
                              </tr>
                            )
                          )}
                        </table>
                      </div>
                      <br />
                      <br />
                      <br />
                      <div>
                        <Row
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '50px'
                          }}
                        >
                          <Col>
                            <span
                              style={{
                                fontWeight: 600
                              }}
                            >
                              APPROVED BY
                            </span>
                          </Col>
                          <Col>
                            {' '}
                            <span
                              style={{
                                fontWeight: 600
                              }}
                            >
                              STORES IN-CHARGE
                            </span>
                          </Col>
                          <Col>
                            <span
                              style={{
                                fontWeight: 600
                              }}
                            >
                              RECEIVED BY SIGNATURE
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                )}
                <Divider />
                {/* Stores Copy{' '} */}
                <div>
                  <div className={`${styles.page_header}`}>
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div>
                        {' '}
                        <img src={tata} alt="logo" />
                      </div>
                      <div>
                        <h2>
                          TATA PROJECTS LIMITED
                          <br />{' '}
                          <p className={`${styles.header_sub}`}>
                            {vehicleData?.requester_data?.Type?.type ===
                            'External'
                              ? `${vehicleData?.requester_data?.Category?.category}`
                              : `${vehicleData?.requester_data?.Category?.category}`}
                          </p>
                        </h2>
                      </div>
                      <div style={{ fontWeight: 'bold', fontSize: '20px' }}>
                        Stores Copy{' '}
                      </div>
                    </Row>
                  </div>
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginTop: '10px'
                    }}
                  >
                    {/* <div style={{ fontWeight: 600, fontSize: '13px' }}>
                      GST Number:{' '}
                      {
                        vehicleData?.requester_data?.project_location_master
                          ?.gst_number
                      }
                    </div> */}
                    <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      {vehicleData?.requester_data?.Type?.type}
                    </div>
                  </Row>
                  <Row style={{ display: 'flex', justifyContent: 'end' }}>
                    <h5>
                      {vehicleData?.requester_data?.Method_type?.method_type ===
                      'ERP' ? (
                        <span>
                          <span style={{ fontSize: '13px' }}>
                            {' '}
                            {
                              vehicleData?.requester_data?.Document_master
                                ?.document_name
                            }
                            :
                          </span>
                          <span style={{ marginLeft: '2px', fontSize: '13px' }}>
                            {vehicleData?.requester_data?.document_number}
                          </span>
                        </span>
                      ) : (
                        ''
                      )}{' '}
                    </h5>
                  </Row>
                  {/* <Row>
                    <Col
                      span={12}
                      style={{ textAlign: 'left', marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: '500' }}>
                        CLIENT:{' '}
                        {
                          vehicleData?.requester_data?.project_master
                            ?.client_name
                        }
                      </span>
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px'
                        }}
                      >
                      </span>{' '}
                      <span style={{ paddingLeft: '3px' }}></span>
                    </Col>
                    <Col
                      span={12}
                      style={{ textAlign: 'right', marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: '500' }}>
                        Gate Pass: Not generated
                      </span>
                      <span style={{ paddingLeft: '3px' }}>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={12}
                      style={{ textAlign: 'left', marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: '500' }}>PROJECT:</span>
                      <span style={{ paddingLeft: '3px' }}>
                        (
                        {
                          vehicleData?.requester_data?.project_master
                            ?.project_code
                        }
                        )
                      </span>
                      <span style={{ paddingLeft: '3px' }}>
                        {
                          vehicleData?.requester_data?.project_master
                            ?.project_name
                        }
                      </span>
                    </Col>
                    <Col
                      span={12}
                      style={{ textAlign: 'right', marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: '500' }}>DATE:</span>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col
                      span={12}
                      style={{ textAlign: 'left', marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: '500' }}>
                        <span
                          style={{
                            fontWeight: 600
                          }}
                        >
                          {' '}
                          Gate Pass:{' '}
                        </span>
                        Not generated
                      </span>
                      <span style={{ paddingLeft: '3px' }}></span>
                      <span style={{ paddingLeft: '3px' }}></span>
                    </Col>
                    <Col
                      span={12}
                      style={{ textAlign: 'right', marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: '600' }}>DATE:</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={12}
                      style={{ textAlign: 'left', marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: '500' }}>
                        <span
                          style={{
                            fontWeight: 600
                          }}
                        >
                          {' '}
                          CLIENT:{' '}
                        </span>
                        {
                          vehicleData?.requester_data?.project_master
                            ?.client_name
                        }
                      </span>
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px'
                          //fontSize: '13px',
                          // fontWeight: 'bold'
                        }}
                      ></span>{' '}
                      <span style={{ paddingLeft: '3px' }}></span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      style={{
                        textAlign: 'left',
                        marginTop: '5px',
                        //fontWeight: 600,
                        fontSize: '13px'
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 600
                        }}
                      >
                        {' '}
                        GST Number:{' '}
                      </span>
                      {
                        vehicleData?.requester_data?.project_location_master
                          ?.gst_number
                      }
                      <span style={{ paddingLeft: '3px' }}></span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={24}
                      style={{ textAlign: 'left', marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: '600' }}>PROJECT:</span>
                      <span style={{ paddingLeft: '3px' }}>
                        (
                        {
                          vehicleData?.requester_data?.project_master
                            ?.project_code
                        }
                        )
                      </span>
                      <span style={{ paddingLeft: '3px' }}>
                        {
                          vehicleData?.requester_data?.project_master
                            ?.project_name
                        }
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={24}
                      style={{ textAlign: 'left', marginTop: '5px' }}
                    >
                      <span style={{ fontWeight: '600' }}>ADDRESS:</span>
                      <span style={{ paddingLeft: '3px' }}>
                        (
                        {
                          vehicleData?.requester_data?.project_location_master
                            ?.address
                        }
                        )
                      </span>
                    </Col>
                  </Row>
                  {vehicleData?.requester_data?.Type?.type === 'External' ? (
                    <Row className={`${styles.tataText_row}`}>
                      <div className={`${styles.tataText_div}`}>
                        The following materials are being released from Stores
                        for the reason mentioned in the declaration below:
                      </div>
                    </Row>
                  ) : vehicleData?.requester_data?.Type?.type === 'Internal' &&
                    vehicleData?.requester_data?.Method_type?.id === 2 ? (
                    <Row className={`${styles.tataText_row}`}>
                      <div className={`${styles.tataText_div}`}>
                        The following materials are being released from Stores
                        for the reason mentioned in the declaration below:
                      </div>
                    </Row>
                  ) : (
                    ''
                  )}
                  {vehicleData?.requester_data?.Type?.type === 'External' ? (
                    vehicleData?.requester_data?.Method_type?.id === 1 ? (
                      <div className={`${styles.tataText_row}`}>
                        <Row className={`${styles.tataText_row}`}>
                          <div className={`${styles.tataText_div}`}>
                            {vehicleData?.requester_data?.Document_master
                              ?.id === 2 ? (
                              <span>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    marginRight: '4px'
                                  }}
                                >
                                  Declaration:
                                </span>
                                This material is rejected and being returned to
                                the Vendor.
                              </span>
                            ) : vehicleData?.requester_data?.Document_master
                                ?.id === 1 ? (
                              <span>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    marginRight: '4px'
                                  }}
                                >
                                  {/*  {
                                    vehicleData?.requester_data?.Document_master
                                      ?.document_name
                                  } */}
                                  Declaration:
                                </span>
                                This material is being issued from Stores and
                                moved out for own consumption in Construction /
                                Erection in the same project.
                              </span>
                            ) : vehicleData?.requester_data?.Document_master
                                ?.id === 3 ? (
                              <span>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    marginRight: '4px'
                                  }}
                                >
                                  {/* {
                                    vehicleData?.requester_data?.Document_master
                                      ?.document_name
                                  } */}
                                  Declaration:
                                </span>
                                This material is being transferred within the
                                organisation for own Use.
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </Row>
                      </div>
                    ) : (
                      <div className={`${styles.tataText_row}`}>
                        <Row className={`${styles.tataText_row}`}>
                          <div className={`${styles.tataText_div}`}>
                            <span>
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  marginRight: '4px'
                                }}
                              >
                                Declaration:
                              </span>
                              {vehicleData?.requester_data?.Reason?.reason_name}
                            </span>
                          </div>
                        </Row>
                      </div>
                    )
                  ) : vehicleData?.requester_data?.Type?.type === 'Internal' &&
                    vehicleData?.requester_data?.Method_type?.id === 2 ? (
                    <div className={`${styles.tataText_row}`}>
                      <Row className={`${styles.tataText_row}`}>
                        <div className={`${styles.tataText_div}`}>
                          <span>
                            <span
                              style={{
                                fontWeight: 'bold',
                                marginRight: '4px'
                              }}
                            >
                              Declaration:
                            </span>
                            {vehicleData?.requester_data?.Reason?.reason_name}
                          </span>
                        </div>
                      </Row>
                    </div>
                  ) : (
                    ''
                  )}
                  <Row className={`${styles.tataText_row}`}>
                    <div className={`${styles.tataText_div}`}>
                      Please permit M/S{' '}
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px',
                          fontSize: '13px',
                          fontWeight: 'bold'
                        }}
                      >
                        {vehicleData?.carrier_person_name}
                      </span>{' '}
                      to take out the following materials from{' '}
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px',
                          fontSize: '13px',
                          fontWeight: 'bold'
                        }}
                      >
                        {
                          vehicleData?.requester_data?.project_location_master
                            ?.name
                        }
                      </span>{' '}
                      to deliver the same to{' '}
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px',
                          fontSize: '13px',
                          fontWeight: 'bold'
                        }}
                      >
                        {vehicleData?.requester_data?.Document_master?.id === 3
                          ? vehicleData?.requester_data?.vendor_location_name
                          : vehicleData?.requester_data?.Vendor_Master
                              ?.vendorName}
                      </span>{' '}
                      vide L.R.No{' '}
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px',
                          fontSize: '13px',
                          fontWeight: 'bold'
                        }}
                      >
                        {vehicleData?.lr_number}
                      </span>{' '}
                      Dated{' '}
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px',
                          fontSize: '13px',
                          fontWeight: 'bold'
                        }}
                      >
                        {moment(vehicleData?.lr_date).format('DD MMM YYYY')}
                      </span>{' '}
                      in vehicle No.
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px',
                          fontSize: '13px',
                          fontWeight: 'bold'
                        }}
                      >
                        {vehicleData?.vehicle_number}
                      </span>{' '}
                      and Waybill No.
                      <span
                        style={{
                          marginLeft: '2px',
                          marginRight: '2px',
                          fontSize: '13px',
                          fontWeight: 'bold'
                        }}
                      >
                        {vehicleData?.waybillNumber}.
                      </span>{' '}
                    </div>
                  </Row>
                  <div className={`${styles.tatatable_margin}`}>
                    <table className={`${styles.tatatable}`}>
                      <tr className={`${styles.tatatable_header}`}>
                        <th className={`${styles.tatatable_header}`}>S.No</th>
                        <th className={`${styles.tatatable_header}`}>
                          Item Code
                        </th>
                        <th className={`${styles.tatatable_header}`}>
                          Item Description
                        </th>
                        <th className={`${styles.tatatable_header}`}>UOM</th>
                        <th className={`${styles.tatatable_header}`}>
                          Quantity
                        </th>

                        {/* {vehicleData?.requester_data?.methodType_id === 2 && ( */}
                        <th className={`${styles.tatatable_header}`}>
                          Item Rremarks
                        </th>
                        {/*   )} */}
                      </tr>
                      {vehicleData?.requester_data?.Items?.map(
                        (item, index) => (
                          <tr key={index} className={`${styles.tatatable_row}`}>
                            <td className={`${styles.tatatable_td}`}>
                              {index + 1}
                            </td>
                            <td className={`${styles.tatatable_td}`}>
                              {item.item_code}
                            </td>
                            <td
                              className={`${styles.tatatable_td} ${styles.tatatable_dis_width}`}
                            >
                              {item?.item_description}
                            </td>
                            <td className={`${styles.tatatable_td}`}>
                              {item.uom}
                            </td>
                            <td className={`${styles.tatatable_td}`}>
                              {item?.quantity}
                            </td>

                            {/* {vehicleData?.requester_data?.methodType_id ===
                              2 && ( */}
                            <td className={`${styles.tatatable_td}`}>
                              {item?.item_remarks}
                            </td>
                            {/*  )} */}
                          </tr>
                        )
                      )}
                    </table>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div>
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '50px'
                      }}
                    >
                      <Col>
                        <span
                          style={{
                            fontWeight: 600
                          }}
                        >
                          APPROVED BY{' '}
                        </span>
                      </Col>
                      <Col>
                        {' '}
                        <span
                          style={{
                            fontWeight: 600
                          }}
                        >
                          STORES IN-CHARGE
                        </span>
                      </Col>
                      <Col>
                        <span
                          style={{
                            fontWeight: 600
                          }}
                        >
                          RECEIVED BY SIGNATURE
                        </span>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Space
          style={{
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            loading={issuerGenrateGPLoading}
            style={{ fontWeight: 'bold' }}
            className={`${styles.import_style_IssuerModal}`}
            onClick={() => handleFormSubmit()}
          >
            Generate Gate Pass
          </Button>
          <Button
            className={`${styles.import_style_cancel_IssuerModal}`}
            style={{ fontWeight: 'bold' }}
            onClick={() => history.push(`view-requests/${vehicleData?.gpid}`)}
          >
            Cancel
          </Button>
        </Space>
      </CustomLayout>
    </>
  );
}
export default PreviewGenratedGP;
