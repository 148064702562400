import { configureStore } from '@reduxjs/toolkit';
import loginReducer from 'features/login/redux/slice';
import dashboardReducer from 'features/dashboard/redux/slice';
import requestsReducer from 'features/createGP/redux/slice';
import approverReducer from 'features/approver_gatepass/redux/slice';
import viewReducer from 'features/viewRequest/redux/slice';
import editReducer from 'features/editGP/redux/slice';
import hoScreenReducer from 'features/hoScreen/redux/slice';
import issuerReducer from 'features/issuer_gatepass/redux/slice';

import dashboardSAPReducer from 'features/SAP/Requesters/dashboard_SAP/redux/slice';
import requests_sapReducer from 'features/SAP/Requesters/createGP_SAP/redux/slice';
import editSAPReducer from 'features/SAP/Requesters/editGP_SAP/redux/slice';
import viewSAPReducer from 'features/SAP/viewRequest_SAP/redux/slice';
import approverSAPReducer from 'features/SAP/Approvers/approver_dashboard_sap/redux/slice';
import issuerSAPReducer from 'features/SAP/Issuers/redux/slice';
import hoScreenSapReducer from 'features/SAP/HO_SAP/redux/slice';
import adminReducer from 'features/SAP/Admin/redux/slice';

// import storage from 'redux-persist/lib/storage';
// import { persistReducer } from 'redux-persist';
// import { combineReducers } from '@reduxjs/toolkit';
export const store = configureStore({
  reducer: {
    login: loginReducer,
    dashboard: dashboardReducer,
    requests: requestsReducer,
    approver: approverReducer,
    view: viewReducer,
    edit: editReducer,
    hoScreen: hoScreenReducer,
    issuer: issuerReducer,

    dashboard_sap: dashboardSAPReducer,
    requests_sap: requests_sapReducer,
    edit_sap: editSAPReducer,
    view_sap: viewSAPReducer,
    approver_sap: approverSAPReducer,
    issuer_sap: issuerSAPReducer,
    hoScreen_sap: hoScreenSapReducer,
    admin: adminReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
// const persistConfig = {
//   key: 'root',
//   version: 1,
//   storage
// };

// const rootReducer = combineReducers({
//   login: loginReducer,
//   dashboard: dashboardReducer,
//   requests: requestsReducer,
//   approver: approverReducer,
//   view: viewReducer,
//   edit: editReducer,
//   hoScreen: hoScreenReducer
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//   reducer: persistedReducer
//   // reducer: {
//   //   login: loginReducer,
//   //   dashboard: dashboardReducer,
//   //   requests: requestsReducer,
//   //   approver: approverReducer,
//   //   view: viewReducer,
//   //   edit: editReducer,
//   //   hoScreen: hoScreenReducer
//   // }
//});
