import React, { useState, useMemo, useEffect } from 'react';
import { Modal, Button, Image, Row, Col, Select, Input } from 'antd';

import styles from './plantModal.module.less';
import FormItem from 'common/FormItem';
import { Form, Space } from 'antd';

import { createPlant, getAllCompanies } from '../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import adminModal_image from 'assets/images/adminModal_image.svg';
import { debounce } from 'lodash';

const { Option } = Select;
//const { TextArea } = Input;

const PlantModal = ({
  setIsPlantModalOpen,
  isPlantModalOpen,
  handleGetRequests,
  selectedRequest,
  editMode,
  setEditMode
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { createPlantLoading, allCompanies } = useSelector(
    (state) => state.admin
  );

  const [formData1, setFormData1] = useState({});
  const [companySearch, setCompanySearch] = useState('');

  useEffect(() => {
    const payload = {
      page: 1,
      per_page: 100,
      search: companySearch
    };
    // if (companySearch) payload['search'] = companySearch;
    dispatch(getAllCompanies(payload));
  }, [companySearch]);

  useEffect(() => {
    if (editMode === true) {
      form.setFieldsValue({
        plant_name: selectedRequest?.plant_name,
        plant_code: selectedRequest?.plant_code,
        gst: selectedRequest?.gst,
        plant_address: selectedRequest?.plant_address,
        company_name: selectedRequest?.SapCompanyId
      });
    }
  }, [selectedRequest]);

  const debounceFetcherProject = useMemo(() => {
    const loadOptions = (companySearch) => {
      setCompanySearch(companySearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    setFormData1({
      ...formData1,
      [slug]: value
    });
  };
  const onFinish = () => {
    const payload = {
      id: editMode === true ? selectedRequest?.id : null,
      plant_name:
        editMode === true && formData1.plant_name === undefined
          ? selectedRequest?.plant_name
          : formData1.plant_name,
      plant_code:
        editMode === true && formData1.plant_code === undefined
          ? selectedRequest?.plant_code
          : formData1.plant_code,
      gst:
        editMode === true && formData1.gst === undefined
          ? selectedRequest?.gst
          : formData1.gst,
      address:
        editMode === true && formData1.plant_address === undefined
          ? selectedRequest?.plant_address
          : formData1.plant_address,
      company_id:
        editMode === true && formData1.company_name === undefined
          ? selectedRequest?.SapCompanyId
          : formData1.company_name
    };
    dispatch(createPlant(payload)).then(function (response) {
      if (response) {
        setIsPlantModalOpen(false);
        setEditMode(false);
        if (response?.payload?.success) {
          // message.success(response?.payload?.msg);
          handleGetRequests();
        }
      }
    });
  };

  return (
    <>
      <Modal
        title={
          <span>
            <Image src={adminModal_image} alt="icon" preview={false}></Image>
          </span>
        }
        visible={isPlantModalOpen}
        footer={false}
        className={`${styles.check_circle_icon}`}
        width={'820px'}
        onCancel={() => setIsPlantModalOpen(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                label="Plant Name"
                name="plant_name"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter plant name"
                  onChange={(e) =>
                    handleFormDataOnChange('plant_name', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Plant Code"
                name="plant_code"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  },
                  {
                    pattern: /^[a-zA-Z0-9]{4}$/,
                    message:
                      'Plant code must be exactly 4 alphanumeric characters'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter plant code"
                  onChange={(e) =>
                    handleFormDataOnChange('plant_code', e.target.value)
                  }
                  // maxLength={4} // Restricts the input length to 4 characters
                  // onChange={(e) => {
                  //   const value = e.target.value;
                  //   // Validate for alphanumeric characters only and slice to 4 characters
                  //   const formattedValue = value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 4);
                  //   handleFormDataOnChange('plant_code', formattedValue);
                  // }}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                label="Company Name"
                name="company_name"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onSearch={debounceFetcherProject}
                  onChange={(value) =>
                    handleFormDataOnChange('company_name', value)
                  }
                  placeholder="Select company name"
                >
                  {allCompanies.map((item, index) => {
                    return (
                      <Option
                        value={item.id}
                        key={index}
                        className={`${styles.cell_style}`}
                      >
                        {`${item.company_name} (${item.code})`}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="GST"
                name="gst"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  },

                  {
                    validator: (_, value) => {
                      const gstRegex = /^[a-zA-Z0-9]{15}$/;
                      return value && gstRegex.test(value)
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              'GST number must be 15 alphanumeric characters without special characters'
                            )
                          );
                    }
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter GST"
                  onChange={(e) =>
                    handleFormDataOnChange('gst', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Plant Address"
                name="plant_address"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter plant address"
                  onChange={(e) =>
                    handleFormDataOnChange('plant_address', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <FormItem>
            <Space className={`${styles.button_container}`}>
              <Button
                className={`${styles.import_style_cancel}`}
                size="small"
                onClick={() => setIsPlantModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                loading={createPlantLoading}
                className={`${styles.approve_modal_button}`}
                htmlType="submit"
                size="small"
              >
                {editMode === true ? 'Update' : 'Create'}
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default PlantModal;
