import apiClient from 'utilities/apiClient';
import { getLoggedInUser } from 'utilities/helpers';

const user = getLoggedInUser();

export const view_byid = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.requester_sap}/${url}`, payload, true);
};

export const view_byid_Approver = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.requester_sap}/${url}`, payload, true);
};

export const approver = (url, payload) => {
  return apiClient.get(
    `${
      user?.user_details?.role === 'approver'
        ? apiClient.Urls.approver_view
        : user?.user_details?.role === 'requester'
        ? apiClient.Urls.requester_view
        : user?.user_details?.role === 'ho'
        ? apiClient.Urls.ho_view
        : apiClient.Urls.issuer_view
    }/${url}`,
    payload,
    true
  );
};

export const hoEdit = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.requester_sap}/${url}`,
    payload,
    true
  );
};
export const hoCancel_sap = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.requester_sap}/${url}`,
    payload,
    true
  );
};

export const delete_requests = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.requester_sap}/${url}`,
    payload,
    true
  );
};

export const reject_sap = (url, payload) => {
  return user?.user_details?.roles[0] === 'approver'
    ? apiClient.put(`${apiClient.Urls.requester_sap}/${url}`, payload, true)
    : apiClient.patch(
        `${apiClient.Urls.requester_sap}/reject/${payload.id}`, //issuer_reject - for Issuer
        payload,
        true
      );
};

export const approve_sap = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.requester_sap}/${url}`,
    payload,
    true
  );
};

export const submit_sap = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.requester_sap}/${url}`, //issuer_reject - for Issuer
    payload,
    true
  );
};

export const generatedById = (payload) => {
  return apiClient.patch(
    `${
      user?.user_details?.role === 'approver'
        ? apiClient.Urls.approver_genratedGP_sap
        : user?.user_details?.role === 'requester'
        ? apiClient.Urls.requester_genratedGP_sap
        : apiClient.Urls.issuer_genratedGP_sap
    }/${payload?.gpid}`,
    true
  );
};

export const issuerGenrate_EditGP_sap = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.issuer_genratedGP_sap}/${payload.gpid}`,
    payload,
    true
  );
};
