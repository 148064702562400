import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  approver,
  reject_sap,
  approve_sap,
  submit_sap,
  generatedById,
  issuerGenrate_EditGP_sap,
  hoEdit,
  hoCancel_sap,
  view_byid,
  view_byid_Approver
} from './api';

const initialState = {
  loading: false,
  getRequestByIdLoading: false,
  hoEditGPLoading: false,
  hoCancelLoading: false,
  issuerGenrateGPLoading: false,
  getRequestById_ApproverLoading: false,
  requester_data: [],
  allRequests: [],
  pagination: [],
  secondpagination: [],
  generatedGpData: [],
  issuerGenrateGP: [],
  vehicleData: [],
  requester_data_Approver: []
};

const actions = {
  GET_REQUEST_BY_ID: 'getRequestById/GET_REQUEST_BY_ID',
  GET_ALL_REQUESTS: 'getAllRequests/GET_ALL_REQUESTS',
  REJECT_REQUEST: 'rejectRequest_sap/REJECT_REQUEST',
  APPROVE_REQUEST: 'approveRequest_sap/APPROVE_REQUEST',
  SUBMIT_REQUEST: 'submitRequest/SUBMIT_REQUEST',
  GET_GENERATED_GP_BY_ID: 'getGeneratedGpById/GET_GENERATED_GP_BY_ID',
  ISSUER_GENRATE_GP_EDIT_GP: 'issuerGenrateGP_EditGP/ISSUER_GENRATE_GP_EDIT_GP',
  HO_EDIT_GP: 'hoEditGP_sap/HO_EDIT_GP',
  CANCEL_GP_HO: 'cancelGP_HO_sap/CANCEL_GP_HO',
  GET_REQUEST_BY_ID_APPROVER:
    'getRequestById_Approver/GET_REQUEST_BY_ID_APPROVER'
};

export const getRequestById = createAsyncThunk(
  actions.GET_REQUEST_BY_ID,
  async (payload) => {
    const response = await view_byid(`gatepass/${payload?.gpid}`, ''); // await approver(`byid`, payload);
    return response;
  }
);
export const getRequestById_Approver = createAsyncThunk(
  actions.GET_REQUEST_BY_ID_APPROVER,
  async (payload) => {
    const response = await view_byid_Approver(
      `requested-gatepass/${payload?.gpid}`,
      ''
    );
    return response;
  }
);

export const getAllRequests = createAsyncThunk(
  actions.GET_ALL_REQUESTS,
  async (payload) => {
    const response = await approver(``, payload);
    return response;
  }
);

export const rejectRequest_sap = createAsyncThunk(
  actions.REJECT_REQUEST,
  async (payload) => {
    const response = await reject_sap(`reject-gatepass/${payload.id}`, payload);
    return response;
  }
);

export const approveRequest_sap = createAsyncThunk(
  actions.APPROVE_REQUEST,
  async (payload) => {
    const response = await approve_sap(
      `approve-gatepass/${payload.id}`,
      payload
    );
    return response;
  }
);

export const submitRequest = createAsyncThunk(
  actions.SUBMIT_REQUEST,
  async (payload) => {
    const response = await submit_sap(`approve/${payload.id}`, payload);
    return response;
  }
);

export const getGeneratedGpById = createAsyncThunk(
  actions.GET_GENERATED_GP_BY_ID,
  async (payload) => {
    console.log('payload_slice', payload);
    const response = await generatedById(payload);
    return response;
  }
);

export const issuerGenrateGP_EditGP = createAsyncThunk(
  actions.ISSUER_GENRATE_GP_EDIT_GP,
  async (payload) => {
    const response = await issuerGenrate_EditGP_sap(payload);
    return response;
  }
);

export const hoEditGP_sap = createAsyncThunk(
  actions.HO_EDIT_GP,
  async (payload) => {
    const response = await hoEdit(
      `change-status-to-generated/${payload.id}`,
      payload
    );
    return response;
  }
);

export const cancelGP_HO_sap = createAsyncThunk(
  actions.CANCEL_GP_HO,
  async (payload) => {
    const response = await hoCancel_sap(`cancel?gpid=${payload.id}`, payload);
    return response;
  }
);

export const viewSAPSlice = createSlice({
  name: 'view_sap',
  initialState,
  reducers: {
    vehicleFormData: (state, action) => {
      state.vehicleData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequestById.pending, (state) => {
        state.getRequestByIdLoading = true;
      })
      .addCase(getRequestById.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getRequestByIdLoading = false;
        state.requester_data = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getRequestById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getRequestByIdLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getRequestById_Approver.pending, (state) => {
        state.getRequestById_ApproverLoading = true;
      })
      .addCase(getRequestById_Approver.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getRequestById_ApproverLoading = false;
        state.requester_data_Approver = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getRequestById_Approver.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getRequestById_ApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllRequests.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllRequests.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequests = data ? data : [];
        state.secondpagination = pagination || null;

        if (!success) {
          //  message.error(msg);
        }
      })
      .addCase(getAllRequests.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(rejectRequest_sap.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(rejectRequest_sap.fulfilled, (state, action) => {
        const { success, msg: msg } = action.payload;
        state.getAllApproverLoading = false;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(rejectRequest_sap.rejected, (state, action) => {
        const { msg: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(approveRequest_sap.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(approveRequest_sap.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(approveRequest_sap.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(submitRequest.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(submitRequest.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(submitRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getGeneratedGpById.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getGeneratedGpById.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.generatedGpData = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getGeneratedGpById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(issuerGenrateGP_EditGP.pending, (state) => {
        state.issuerGenrateGPLoading = true;
      })
      .addCase(issuerGenrateGP_EditGP.fulfilled, (state, action) => {
        // message: msg,
        const { success, data, pagination } = action.payload;
        state.issuerGenrateGPLoading = false;
        state.issuerGenrateGP = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          // message.error(msg);
        }
      })
      .addCase(issuerGenrateGP_EditGP.rejected, (state) => {
        //, action
        // const { message: msg } = action.error;

        state.issuerGenrateGPLoading = false;

        //message.error(msg);
      });

    builder
      .addCase(hoEditGP_sap.pending, (state) => {
        state.hoEditGPLoading = true;
      })
      .addCase(hoEditGP_sap.fulfilled, (state, action) => {
        const { success, msg: msg } = action.payload;
        state.hoEditGPLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(hoEditGP_sap.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.hoEditGPLoading = false;
        message.error(msg);
      });

    builder
      .addCase(cancelGP_HO_sap.pending, (state) => {
        state.hoCancelLoading = true;
      })
      .addCase(cancelGP_HO_sap.fulfilled, (state, action) => {
        const { success, msg: msg } = action.payload;
        state.hoCancelLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(cancelGP_HO_sap.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.hoCancelLoading = false;
        message.error(msg);
      });
  }
});
export const { vehicleFormData } = viewSAPSlice.actions;
export default viewSAPSlice.reducer;
