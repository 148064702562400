import apiClient from 'utilities/apiClient';
import { getLoggedInUser } from 'utilities/helpers';

const user = getLoggedInUser();
export const approver = (url, payload) => {
  return apiClient.get(
    `${
      user?.user_details?.role === 'approver'
        ? apiClient.Urls.reject
        : user?.user_details?.role === 'requester'
        ? apiClient.Urls.requester_reject
        : apiClient.Urls.issuer_reject
    }/${url}`,
    payload,
    true
  );
};

export const delete_requests = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.requester}/${url}`, payload, true);
};

export const reject = (url, payload) => {
  return user?.user_details?.role === 'approver'
    ? apiClient.put(`${apiClient.Urls.reject}/${url}`, payload, true)
    : apiClient.patch(
        `${apiClient.Urls.issuer_reject}/reject/${payload.id}`,
        payload,
        true
      );
};

export const approve = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.reject}/${url}`, payload, true);
};

export const submit = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.issuer_reject}/${url}`,
    payload,
    true
  );
};

export const generatedById = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.issuer_genratedGP}/${url}`,
    payload,
    true
  );
};
// user?.user_details?.role === 'approver'
//   ? apiClient.Urls.approver_genratedGP
//   : user?.user_details?.role === 'requester'
//   ? apiClient.Urls.requester_genratedGP
