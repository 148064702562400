import React from 'react';
import styles from './index.module.less';
import { Modal, Row, Col, Timeline, Button } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';

const ItemHistotyModal = ({
  itemHistotyVisible,
  setItemHistotyVisible,
  itemHistory
}) => {
  const [itemReturns, setItemReturns] = useState([]);
  //console.log('itemHistory>>>>>>>>>>>>>>>>>>>>', itemHistory);
  useEffect(() => {
    const item_Returns = itemHistory?.SapItemReturnLogs?.map((itm) => ({
      comments: itm?.comments,
      createdAt: itm?.createdAt,
      qty_returned: itm?.qty_returned,
      Documents: itm?.SapDocuments
    }));
    setItemReturns(item_Returns);
  }, [itemHistory?.SapItemReturnLogs]);

  //console.log('itemReturns>>>>>>>>>>>>>>>>>', itemReturns);
  const handleFetchDocument = (fileName) => {
    window?.open(
      `${process.env.REACT_APP_API_URL}/fetch-file?fileName=${fileName}`,
      '_blank'
    );
  };
  return (
    <>
      <Modal
        title={
          <>
            <span
              className={`${styles.title_itemHistory}`}
              style={{
                // marginLeft: '8px',
                // marginRight: '4px',
                fontSize: '16px',
                fontWeight: '600'
              }}
            >
              Item History:
            </span>
            <span
              style={{
                marginLeft: '0px',
                marginRight: '2px',
                fontSize: '16px',
                fontWeight: '600'
              }}
            >
              {itemHistory?.item_description}
            </span>
            <span
              style={{
                fontSize: '16px'
              }}
            >
              ({itemHistory?.item_code})
            </span>
          </>
        } //{'Item History : '}
        visible={itemHistotyVisible}
        width={'750px'}
        onCancel={() => setItemHistotyVisible(false)}
        onOk={() => setItemHistotyVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ className: `${styles.ok_button_style}` }}
      >
        <Timeline
          style={{
            fontSize: '13x !important',
            fontFamily: 'SFProText Regular'
          }}
        >
          {itemReturns
            ? itemReturns?.map((val, index) => {
                return (
                  <Timeline.Item key={index} color="#1c4e9d">
                    <>
                      {' '}
                      <Row gutter={8}>
                        {/*  <Col> */}
                        <span>
                          {moment(val?.createdAt).format('DD MMM YYYY')}:
                        </span>
                        {/*  </Col> */}

                        {/*  <Col> */}
                        <span style={{ marginLeft: '1px' }}>
                          {val?.qty_returned} returned
                        </span>
                        {/*  </Col> */}
                        <Col>
                          <ul style={{ marginLeft: '-25px' }}>
                            {val?.Documents.map((item, index) => (
                              <li
                                style={{
                                  //  textAlign: 'left',
                                  display: 'inline',
                                  paddingRight: '20px'
                                }}
                                key={index}
                              >
                                {/*  <a
                                  href={item?.fileUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={`${styles.doc_uploaded}`}
                                >
                                  <span>{item?.originalName}</span>
                                </a> */}
                                <Button
                                  type="link"
                                  onClick={() => {
                                    handleFetchDocument(item?.fileName);
                                  }}
                                >
                                  {item?.originalName}
                                </Button>
                              </li>
                            ))}
                          </ul>
                        </Col>
                      </Row>
                      {/*   <div>
                          <span style={{ fontSize: '12px' }}>
                            {val?.comments}
                          </span>{' '}
                        </div> */}
                      <div>
                        <span style={{ fontSize: '11px', color: '#9E9E9E' }}>
                          {' '}
                          Received {val?.qty_returned}{' '}
                          {itemHistory?.item_description} from Planning Engineer
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: '-4px'
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11px',
                            color: '#9E9E9E',
                            marginRight: '4px'
                          }}
                        >
                          Comments:
                        </span>
                        <span style={{ fontSize: '11px', color: '#9E9E9E' }}>
                          {val?.comments}
                        </span>
                      </div>
                    </>
                  </Timeline.Item>
                );
              })
            : ''}
        </Timeline>
      </Modal>
    </>
  );
};

export default ItemHistotyModal;
