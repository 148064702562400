import React, { useState } from 'react';
import styles from './index.module.less';
import { Card, Row, Badge, Divider, Radio, Space, Tooltip, Empty } from 'antd';
import CustomButton from 'common/CustomButton';
import CustomTable from 'common/Content/CustomTable';
// import { Link } from 'react-router-dom';
// import moment from 'moment';
import SearchBar from '@/common/Content/searchBar';
import { PlusOutlined, EditFilled, SubnodeOutlined } from '@ant-design/icons';
import PlantModal from './modal/PlantModal';
import LocationModal from './modal/LocationModal';
import CompanyModal from './modal/CompanyModal';
import UserModal from './modal/UserModal';
//import { first, get } from 'lodash';
import LocationMapModal from './map/LocationMapModal';
import UserMapModal from './map/UserMapModal';
import LocationMapForUserModal from './map/LocationMapForUserModal';
//import { history } from 'app/history';

const filters = [
  {
    name: 'Plants',
    value: 'Plants'
  },
  {
    name: 'Companies',
    value: 'Companies'
  },
  {
    name: 'Locations',
    value: 'Locations'
  },
  {
    name: 'Users',
    value: 'Users'
  }
];

const AdminListPage = ({
  allPlants,
  pagination,
  page,
  setPage,
  pageSize,
  setPageSize,
  editMode,
  setEditMode,
  selectedRequest,
  setSelectedRequest,
  radiofilter,
  setRadiofilter,
  setTriggerFilter,
  setSearchRequest,
  //setButtonName,
  //buttonName,
  setIsPlantModalOpen,
  isPlantModalOpen,
  setIsLocationModalOpen,
  isLocationModalOpen,
  isCompanyModalOpen,
  setIsCompanyModalOpen,
  isUserModalOpen,
  setIsUserModalOpen,
  getAllPlantsLoading,
  getAllCompaniesLoading,
  getAllUsersLoading,
  getAllLocationsLoading,
  allCompanies,
  allUsers,
  allLocations,
  handleGetRequests
}) => {
  console.log('editMode', editMode);

  const [showMapLocation, setShowMapLocation] = useState(false);
  const [showMapUser, setShowMapUser] = useState(false);
  const [showMapLocationForUser, setShowMapLocationForUser] = useState(false);

  const dataPlants = allPlants?.map((item) => ({
    key: item?.id,
    id: item?.id,
    plant_name: item?.plant_name,
    plant_code: item?.plant_code,
    plant_address: item?.address,
    location_name: item?.SapStorageLocations?.length ? (
      item?.SapStorageLocations ? (
        <span>
          {item?.SapStorageLocations?.map((location) => location?.name).join(
            ',  '
          )}
        </span>
      ) : null
    ) : (
      'NA'
    ),

    gst: item?.gst,
    SapCompanyName: item?.SapCompanyMaster?.company_name,
    SapCompanyId: item?.SapCompanyMaster?.id
  }));

  const dataCompanies = allCompanies?.map((item) => ({
    key: item?.id,
    id: item?.id,
    company_name: item?.company_name,
    company_code: item?.code
  }));

  const dataLocations = allLocations?.map((item) => ({
    key: item?.id,
    id: item?.id,
    location_name: item?.name,
    location_code: item?.code,
    plant_name: item?.SapPlant?.plant_name,
    address: item?.address
  }));

  const dataUsers = allUsers?.map((item) => ({
    key: item?.id,
    id: item?.user?.id,
    first_name: item?.user?.first_name,
    last_name: item?.user?.last_name,
    email: item?.user?.email,
    plant_name: item?.plant?.plant_name,
    location_name: item?.storage_location?.name,
    role_id:
      item?.user?.Role_masters?.length > 0
        ? item?.user?.Role_masters[0]?.id
        : null
  }));
  const columnsPlants = [
    {
      title: 'Plant Name',
      dataIndex: 'plant_name',
      key: 'plant_name',
      //width: 120,
      ellipsis: true,
      //sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.plant_name?.localeCompare(b?.plant_name),
      render: (plant_name) => {
        return (
          <>
            <Tooltip
              title={plant_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={`${styles.cell_style}`}>
                {plant_name === null ? 'NA' : plant_name}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Plant Code',
      dataIndex: 'plant_code',
      key: 'plant_code',
      width: 200,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.plant_code?.localeCompare(b?.plant_code),
      render: (plant_code) => {
        return (
          <>
            <Tooltip
              title={plant_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={`${styles.cell_style}`}>
                {' '}
                {plant_code === null ? 'NA' : plant_code}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Plant Address',
      dataIndex: 'plant_address',
      key: 'plant_address',
      sorter: (a, b) => a?.plant_address?.localeCompare(b?.plant_address),
      ellipsis: true,
      render: (plant_address) => {
        return (
          <>
            <Tooltip
              title={plant_address}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={`${styles.cell_style}`}>
                {' '}
                {plant_address ? plant_address : 'NA'}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Location Name',
      dataIndex: 'location_name',
      key: 'location_name',
      //width: 120,
      ellipsis: true,
      //sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.location_name?.localeCompare(b?.location_name),
      render: (location_name) => {
        return (
          <>
            <Tooltip
              title={location_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={`${styles.cell_style}`}>
                {location_name === null ? 'NA' : location_name}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (record) => (
        <Space size="middle">
          <>
            <Tooltip title={`Edit`}>
              <EditFilled
                className={`${styles.edit_icon}`}
                onClick={() => {
                  setEditMode(true);
                  setSelectedRequest(record);
                  setIsPlantModalOpen(true);
                }}
              />
            </Tooltip>
            {/* <Tooltip title={`Map Location`}>
              <SubnodeOutlined
                className={`${styles.map_icon}`}
                onClick={() => {
                  setShowMapLocation(true);
                  setSelectedRequest(record);
                }}
              />
            </Tooltip> */}
          </>
        </Space>
      )
    }
  ];
  const columnsCompanies = [
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      //width: 120,
      ellipsis: true,
      //sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.company_name?.localeCompare(b?.company_name),
      render: (company_name) => {
        return (
          <>
            <Tooltip
              title={company_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={`${styles.cell_style}`}>
                {company_name === null ? 'NA' : company_name}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Company Code',
      dataIndex: 'company_code',
      key: 'company_code',
      //width: 170,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.company_code?.localeCompare(b?.company_code),
      render: (company_code) => {
        return (
          <>
            <Tooltip
              title={company_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {company_code === null ? 'NA' : company_code}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (record) => (
        <Space size="middle">
          <>
            <Tooltip title={`Edit`}>
              <EditFilled
                className={`${styles.edit_icon}`}
                onClick={() => {
                  setEditMode(true);
                  setIsCompanyModalOpen(true);
                  setSelectedRequest(record);
                }}
              />
            </Tooltip>
          </>
        </Space>
      )
    }
  ];
  const columnsLocations = [
    {
      title: 'Location Name',
      dataIndex: 'location_name',
      key: 'location_name',
      //width: 120,
      ellipsis: true,
      //sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.location_name?.localeCompare(b?.location_name),
      render: (location_name) => {
        return (
          <>
            <Tooltip
              title={location_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={`${styles.cell_style}`}>
                {location_name === null ? 'NA' : location_name}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Location Code',
      dataIndex: 'location_code',
      key: 'location_code',
      width: 200,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.location_code?.localeCompare(b?.location_code),
      render: (location_code) => {
        return (
          <>
            <Tooltip
              title={location_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={`${styles.cell_style}`}>
                {' '}
                {location_code === null ? 'NA' : location_code}
              </span>
            </Tooltip>
          </>
        );
      }
    },

    {
      title: 'Plant Name',
      dataIndex: 'plant_name',
      key: 'plant_name',
      //width: 120,
      ellipsis: true,
      //sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.plant_name?.localeCompare(b?.plant_name),
      render: (plant_name) => {
        return (
          <>
            <Tooltip
              title={plant_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={`${styles.cell_style}`}>
                {plant_name === null ? 'NA' : plant_name}
              </span>
            </Tooltip>
          </>
        );
      }
    },

    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (record) => (
        <Space size="middle">
          <>
            <Tooltip title={`Edit`}>
              <EditFilled
                className={`${styles.edit_icon}`}
                onClick={() => {
                  setEditMode(true);
                  setIsLocationModalOpen(true);
                  setSelectedRequest(record);
                }}
              />
            </Tooltip>
            {/* <Tooltip title={`Map Users`}>
              <SubnodeOutlined
                className={`${styles.map_icon}`}
                onClick={() => {
                  setShowMapUser(true);
                  setSelectedRequest(record);
                }}
              />
            </Tooltip> */}
          </>
        </Space>
      )
    }
  ];
  const columnsUsers = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      //width: 170,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.first_name?.localeCompare(b?.first_name),
      render: (first_name) => {
        return (
          <>
            <Tooltip
              title={first_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={`${styles.cell_style}`}>
                {first_name === null ? 'NA' : first_name}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      //width: 170,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.last_name?.localeCompare(b?.last_name),
      render: (last_name) => {
        return (
          <>
            <Tooltip
              title={last_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={`${styles.cell_style}`}>
                {last_name === null ? 'NA' : last_name}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Location Name',
      dataIndex: 'location_name',
      key: 'location_name',
      //width: 120,
      ellipsis: true,
      //sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.location_name?.localeCompare(b?.location_name),
      render: (location_name) => {
        return (
          <>
            <Tooltip
              title={location_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={`${styles.cell_style}`}>
                {location_name === null ? 'NA' : location_name}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Plant Name',
      dataIndex: 'plant_name',
      key: 'plant_name',
      //width: 120,
      ellipsis: true,
      //sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.plant_name?.localeCompare(b?.plant_name),
      render: (plant_name) => {
        return (
          <>
            <Tooltip
              title={plant_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span className={`${styles.cell_style}`}>
                {plant_name === null ? 'NA' : plant_name}
              </span>
            </Tooltip>
          </>
        );
      }
    },

    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (record) => (
        <Space size="middle">
          <>
            <Tooltip title={`Edit`}>
              <EditFilled
                className={`${styles.edit_icon}`}
                onClick={() => {
                  setEditMode(true);
                  setIsUserModalOpen(true);
                  setSelectedRequest(record);
                }}
              />
            </Tooltip>
            <Tooltip title={`Map Location`}>
              <SubnodeOutlined
                className={`${styles.map_icon}`}
                onClick={() => {
                  setShowMapLocationForUser(true);
                  setSelectedRequest(record);
                }}
              />
            </Tooltip>
          </>
        </Space>
      )
    }
  ];

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          {radiofilter === 'Plants'
            ? `There are no plant to display `
            : radiofilter === 'Companies'
            ? `There are no company to display`
            : radiofilter === 'Locations'
            ? `There are no location to display`
            : `There are no  user to display`}
        </div>
      </span>
    )
  };
  console.log('selectedRequest', selectedRequest);
  return (
    <>
      <div className={`mb-4 ${styles.container}`}>
        <div className={`${styles.page_title}`}>Admin Dashboard</div>
        <div className={`${styles.page_sub_title}`}>
          Your current requests summary and other activities.
        </div>
        <Card className={`${styles.cardStyles}`}>
          <Row
            gutter={16}
            style={{
              marginTop: '-7px',
              marginRight: '0px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <div className={`${styles.Gate_pass_req_title}`}>
                Manage Gate Pass System Data{' '}
                <Badge
                  count={
                    radiofilter === 'Plants'
                      ? `${pagination?.total_records || 0} Plants`
                      : radiofilter === 'Companies'
                      ? `${pagination?.total_records || 0} Companies`
                      : radiofilter === 'Locations'
                      ? `${pagination?.total_records || 0} Locations`
                      : `${pagination?.total_records || 0} Users`
                  }
                  size="small"
                  className={`${styles.badgestyles}`}
                />
              </div>
            </div>
            <Space direction="horizontal" size={10}>
              {/* <sap>
                <CustomButton
                  size="small"
                  className={`${styles.customButton_style_reports}`}
                  type="primary"
                  onClick={() => history.push('/reports-sap-admin')}
                >
                  <span>Admin Reports</span>
                </CustomButton>
              </sap> */}

              <sap>
                <CustomButton
                  size="small"
                  icon={<PlusOutlined />}
                  className={`${styles.customButton_style_secondary}`}
                  type="primary"
                  onClick={() => {
                    if (radiofilter === 'Plants') {
                      setIsPlantModalOpen(true);
                      setEditMode(false);
                    } else if (radiofilter === 'Companies') {
                      setIsCompanyModalOpen(true);
                      setEditMode(false);
                    } else if (radiofilter === 'Locations') {
                      setIsLocationModalOpen(true);
                      setEditMode(false);
                    } else if (radiofilter === 'Users') {
                      setIsUserModalOpen(true);
                      setEditMode(false);
                    }
                  }}
                >
                  <span>{`Create ${
                    radiofilter === 'Plants'
                      ? 'Plant'
                      : radiofilter === 'Companies'
                      ? 'Company'
                      : radiofilter === 'Locations'
                      ? 'Location'
                      : 'User'
                  }`}</span>
                </CustomButton>
              </sap>
            </Space>
          </Row>

          <Divider
            style={{ marginTop: '20px', marginBottom: '15px' }}
          ></Divider>
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Radio.Group
                value={radiofilter}
                size="small"
                className={`${styles.button_import_style}`}
              >
                {filters?.map((item, index) => {
                  return (
                    <Radio.Button
                      value={item.value}
                      key={index}
                      className={`${styles.RadioButton_import_style}`}
                      onChange={() => {
                        window.localStorage.setItem(
                          'set_Radiofilter_Admin',
                          JSON?.stringify(item.value)
                        );
                        setRadiofilter(item.value);
                        setTriggerFilter(true);
                        //setButtonName(item?.name);
                      }}
                    >
                      {item.name}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </div>
            <div>
              <SearchBar
                // onChange={(e) => handleChange(e)}
                onChange={(e) => {
                  setSearchRequest(e.target.value);
                  setTriggerFilter(true);
                }}
                className={` ${styles.searchbar}`}
                inputProps={{
                  placeholder:
                    radiofilter === 'Plants'
                      ? 'Search'
                      : radiofilter === 'Companies'
                      ? 'Search'
                      : radiofilter === 'Locations'
                      ? 'Search'
                      : 'Search'
                }}
              />
            </div>
          </Row>
          <div className={`${styles.table_container}`}>
            <CustomTable
              columns={
                radiofilter === 'Plants'
                  ? columnsPlants
                  : radiofilter === 'Companies'
                  ? columnsCompanies
                  : radiofilter === 'Locations'
                  ? columnsLocations
                  : columnsUsers
              }
              dataSource={
                radiofilter === 'Plants'
                  ? dataPlants
                  : radiofilter === 'Companies'
                  ? dataCompanies
                  : radiofilter === 'Locations'
                  ? dataLocations
                  : dataUsers
              }
              locale={locale}
              loading={
                radiofilter === 'Plants'
                  ? getAllPlantsLoading
                  : radiofilter === 'Companies'
                  ? getAllCompaniesLoading
                  : radiofilter === 'Locations'
                  ? getAllLocationsLoading
                  : getAllUsersLoading
              }
              // rowSelection={rowSelection}
              pagination={{
                current: page,
                pageSize: pageSize,
                showSizeChanger: true,
                size: 'small',
                total: pagination?.total_records || 0,
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                }
              }}
            />
          </div>
          {isPlantModalOpen && (
            <PlantModal
              isPlantModalOpen={isPlantModalOpen}
              setIsPlantModalOpen={setIsPlantModalOpen}
              handleGetRequests={handleGetRequests}
              selectedRequest={selectedRequest}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          )}
          {isLocationModalOpen && (
            <LocationModal
              isLocationModalOpen={isLocationModalOpen}
              setIsLocationModalOpen={setIsLocationModalOpen}
              handleGetRequests={handleGetRequests}
              selectedRequest={selectedRequest}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          )}
          {isCompanyModalOpen && (
            <CompanyModal
              isCompanyModalOpen={isCompanyModalOpen}
              setIsCompanyModalOpen={setIsCompanyModalOpen}
              handleGetRequests={handleGetRequests}
              selectedRequest={selectedRequest}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          )}
          {isUserModalOpen && (
            <UserModal
              isUserModalOpen={isUserModalOpen}
              setIsUserModalOpen={setIsUserModalOpen}
              handleGetRequests={handleGetRequests}
              selectedRequest={selectedRequest}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          )}
          {showMapLocation && (
            <LocationMapModal
              showMapLocation={showMapLocation}
              setShowMapLocation={setShowMapLocation}
              selectedRequest={selectedRequest}
              handleGetRequests={handleGetRequests}
            />
          )}
          {showMapUser && (
            <UserMapModal
              showMapUser={showMapUser}
              setShowMapUser={setShowMapUser}
              selectedRequest={selectedRequest}
              handleGetRequests={handleGetRequests}
            />
          )}
          {showMapLocationForUser && (
            <LocationMapForUserModal
              showMapLocationForUser={showMapLocationForUser}
              setShowMapLocationForUser={setShowMapLocationForUser}
              selectedRequest={selectedRequest}
              handleGetRequests={handleGetRequests}
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default AdminListPage;
