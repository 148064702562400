import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Image, Row, Col, Input, Select } from 'antd';
import styles from './plantModal.module.less';
import FormItem from 'common/FormItem';
import { Form, Space } from 'antd';
import {
  createLocation,
  getAllApprovers,
  getAllIssuers,
  getAllPlants
} from '../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import adminModal_image from 'assets/images/adminModal_image.svg';
import { debounce } from 'lodash';

const { Option } = Select;

const LocationModal = ({
  setIsLocationModalOpen,
  isLocationModalOpen,
  handleGetRequests,
  selectedRequest,
  editMode,
  setEditMode
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [formData1, setFormData1] = useState({});
  const [searchPlant, setSearchPlant] = useState('');
  const [searchApprover, setSearchApprover] = useState('');
  const [searchIssuer, setSearchIssuer] = useState('');

  const { createLocationLoading, allApprovers, allIssuers, allPlants } =
    useSelector((state) => state.admin);

  useEffect(() => {
    if (editMode === true) {
      form.setFieldsValue({
        location_name: selectedRequest?.location_name,
        location_code: selectedRequest?.location_code,
        location_address: selectedRequest?.address
        // plant_id: selectedRequest?.plant_id,
        // approver_id: selectedRequest?.approver_id,
        // issuer_id: selectedRequest?.issuer_id
      });
    }
  }, [selectedRequest]);

  useEffect(() => {
    const payload = { page: 1, per_page: 100, search: searchPlant };
    dispatch(getAllPlants(payload));
  }, [searchPlant]);

  useEffect(() => {
    const payload = { page: 1, per_page: 100, search: searchApprover };
    dispatch(getAllApprovers(payload));
  }, [searchApprover]);

  useEffect(() => {
    const payload = { page: 1, per_page: 100, search: searchIssuer };
    dispatch(getAllIssuers(payload));
  }, [searchIssuer]);

  const debounceFetcherPlant = useMemo(() => {
    const loadOptions = (searchPlant) => {
      setSearchPlant(searchPlant);
    };
    return debounce(loadOptions, 500);
  }, []);

  const debounceFetcherApprover = useMemo(() => {
    const loadOptions = (searchApprover) => {
      setSearchApprover(searchApprover);
    };
    return debounce(loadOptions, 500);
  }, []);

  const debounceFetcherIssuer = useMemo(() => {
    const loadOptions = (searchIssuer) => {
      setSearchIssuer(searchIssuer);
    };
    return debounce(loadOptions, 500);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    setFormData1({
      ...formData1,
      [slug]: value
    });
  };

  const onFinish = () => {
    const payload = {
      id: selectedRequest?.id,
      name:
        editMode === true && formData1.location_name === undefined
          ? selectedRequest?.location_name
          : formData1.location_name,
      code:
        editMode === true && formData1.location_code === undefined
          ? selectedRequest?.location_code
          : formData1.location_code,
      address:
        editMode === true && formData1.location_address === undefined
          ? selectedRequest?.address
          : formData1.location_address,
      plant_id: formData1.plant_id,
      approver_id: formData1.approver_id,
      issuer_id: formData1.issuer_id
    };
    const payloadEdit = {
      id: selectedRequest?.id,
      name:
        editMode === true && formData1.location_name === undefined
          ? selectedRequest?.location_name
          : formData1.location_name,
      code:
        editMode === true && formData1.location_code === undefined
          ? selectedRequest?.location_code
          : formData1.location_code,
      address:
        editMode === true && formData1.location_address === undefined
          ? selectedRequest?.address
          : formData1.location_address
    };
    dispatch(createLocation(editMode === true ? payloadEdit : payload)).then(
      function (response) {
        if (response) {
          setIsLocationModalOpen(false);
          setEditMode(false);
          if (response?.payload?.success) {
            handleGetRequests();
          }
        }
      }
    );
  };

  return (
    <>
      <Modal
        title={
          <span>
            <Image src={adminModal_image} alt="icon" preview={false}></Image>
          </span>
        }
        visible={isLocationModalOpen}
        footer={false}
        className={`${styles.check_circle_icon}`}
        width={'820px'}
        onCancel={() => setIsLocationModalOpen(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                label="Location Name"
                name="location_name"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter location name"
                  onChange={(e) =>
                    handleFormDataOnChange('location_name', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Location Code"
                name="location_code"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter location code"
                  onChange={(e) =>
                    handleFormDataOnChange('location_code', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={editMode !== true ? 12 : 24}>
              <FormItem
                label="Location Address"
                name="location_address"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter location address"
                  onChange={(e) =>
                    handleFormDataOnChange('location_address', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            {editMode !== true && (
              <Col span={12}>
                <FormItem
                  label="Plant"
                  name="plant_id"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.select_styles}`}
                >
                  <Select
                    showSearch
                    placeholder="Select plant"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option?.children
                        ?.toString()
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase());
                    }}
                    onSearch={debounceFetcherPlant}
                    onChange={(value) =>
                      handleFormDataOnChange('plant_id', value)
                    }
                  >
                    {allPlants?.map((item, index) => {
                      return (
                        <Option
                          value={item.id}
                          key={index}
                          className={`${styles.cell_style}`}
                        >
                          {`${item.plant_name} (${item.plant_code})`}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
            )}
          </Row>
          {editMode !== true && (
            <Row gutter={16}>
              <Col span={12}>
                <FormItem
                  label="Approver"
                  name="approver_id"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.select_styles}`}
                >
                  <Select
                    showSearch
                    placeholder="Select approver"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option?.children
                        ?.toString()
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase());
                    }}
                    onSearch={debounceFetcherApprover}
                    onChange={(value) =>
                      handleFormDataOnChange('approver_id', value)
                    }
                  >
                    {allApprovers?.map((item, index) => {
                      return (
                        <Option
                          value={item.id}
                          key={index}
                          className={`${styles.cell_style}`}
                        >
                          {`${item.first_name} (${item.email})`}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Issuer"
                  name="issuer_id"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.select_styles}`}
                >
                  <Select
                    showSearch
                    placeholder="Select issuer"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option?.children
                        ?.toString()
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase());
                    }}
                    onSearch={debounceFetcherIssuer}
                    onChange={(value) =>
                      handleFormDataOnChange('issuer_id', value)
                    }
                  >
                    {allIssuers?.map((item, index) => {
                      return (
                        <Option
                          value={item.id}
                          key={index}
                          className={`${styles.cell_style}`}
                        >
                          {`${item.first_name} (${item.email})`}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
            </Row>
          )}
          <FormItem>
            <Space className={`${styles.button_container}`}>
              <Button
                className={`${styles.import_style_cancel}`}
                size="small"
                onClick={() => setIsLocationModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                loading={createLocationLoading}
                className={`${styles.approve_modal_button}`}
                htmlType="submit"
                size="small"
              >
                {editMode === true ? 'Update' : 'Create'}
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default LocationModal;
