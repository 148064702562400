import React from 'react';
import { message, Modal, Typography } from 'antd';
//import { CheckCircleOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import { Form, Space } from 'antd';
import CustomButton from '@/common/CustomButton';
import { useParams } from 'react-router-dom';
import { approveRequest, submitRequest } from '../../redux/slice';
import { useDispatch } from 'react-redux';
import { history } from '@/app/history';
import { getLoggedInUser } from 'utilities/helpers';

const ApproveModal = ({ approveModal, setApproveModal }) => {
  const params = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const user = getLoggedInUser();

  const onFinish = () => {
    const payload = {
      id: params.id
    };
    {
      user?.user_details?.role === 'approver'
        ? dispatch(approveRequest(payload)).then(function (response) {
            if (response) {
              setApproveModal(false);
              if (response?.payload?.success) {
                message.success(response?.payload?.msg);
                history.push('/gatepass-requests');
              }
            }
          })
        : dispatch(submitRequest(payload)).then(function (response) {
            if (response) {
              setApproveModal(false);
              if (response?.payload?.success) {
                message.success(response?.payload?.msg);
                history.push('/gatepass-requests');
              }
            }
          });
    }
  };

  return (
    <>
      <Modal
        title={'Approve GP'}
        visible={approveModal}
        footer={false}
        className={`${styles.check_circle_icon}`}
        width={'320px'}
        onCancel={() => setApproveModal(false)}
      >
        {/* <h5>Reason for rejection</h5> */}
        <Typography style={{ fontSize: '11px', marginTop: '-6px' }}>
          Are you sure you want to approve this GP? This action can not be
          undone.
        </Typography>
        <br />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <FormItem>
            <Space>
              <CustomButton
                className={`${styles.import_style_cancel}`}
                size="small"
                onClick={() => setApproveModal(false)}
              >
                No
              </CustomButton>
              <CustomButton
                className={`${styles.import_style_approve}`}
                htmlType="submit"
                size="small"
              >
                Approve
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default ApproveModal;
