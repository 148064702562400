import React from 'react';
import { Modal, Row, Timeline, Button } from 'antd';
import styles from './index.module.less';
//import { getLoggedInUser } from 'utilities/helpers';

const UploadedDocumentsModal = ({
  uploadedDocumentsVisible,
  setUploadedDocumentsVisible,
  requestbyid
}) => {
  console.log('requestbyid?.Documents', requestbyid?.Documents);

  //originalName,fileUrl
  const handleFetchDocument = (fileName) => {
    window?.open(
      `${process.env.REACT_APP_API_URL}/fetch-file?fileName=${fileName}`,
      '_blank'
    );
  };

  return (
    <>
      <Modal
        title={'Uploaded Documents : '}
        visible={uploadedDocumentsVisible}
        // className={`${styles.check_circle_icon}`}
        width={'620px'}
        onCancel={() => setUploadedDocumentsVisible(false)}
        onOk={() => setUploadedDocumentsVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ className: `${styles.ok_button_style}` }}
      >
        <Row className="pl-3 ">
          <Timeline
            style={{
              fontSize: '13x !important',
              fontFamily: 'SFProText Regular'
            }}
          >
            {requestbyid?.SapDocuments?.map((item, index) => (
              <Timeline.Item key={index} color="#1c4e9d">
                {/* <a
                  href={item?.fileUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={`${styles.doc_uploaded}`}
                >
                  {item?.originalName}
                </a> */}
                <Button
                  type="link"
                  onClick={() => {
                    handleFetchDocument(item?.fileName);
                  }}
                >
                  {item?.originalName}
                </Button>
              </Timeline.Item>
            ))}
          </Timeline>
        </Row>
        {/*  {requestbyid?.Documents?.map((item, index) => (
          <>
            <div key={index}>{item?.originalName}</div>
          
            <object
              data={item?.originalName}
              type="application/pdf"
              width="50%"
              height="50%"
            >
              <iframe
                src={item?.originalName}
                width="50%"
                height="50%"
               // style="border: none;"
              >
                
              </iframe>
            </object>
            
          </>
         ))}  */}
      </Modal>
    </>
  );
};

export default UploadedDocumentsModal;
{
  /*  <iframe src={item?.filefileUrl} width="100%" height="300" title="PDF-Frame"/> */
}
