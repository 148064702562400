import * as React from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { history } from './app/history';
import { getLoggedInUser } from 'utilities/helpers';
import Login from './features/login';
import Dashboard from './features/dashboard';
import GPRequest from './features/createGP';
import ApproverGatepass from './features/approver_gatepass';
import ViewRequest from './features/viewRequest';
import EditRequest from './features/editGP';
import { MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { useSelector } from 'react-redux';
import Loader from './common/Loader';
import ViewGeneratedGP from './features/viewGeneratedGP';
import EditPartiallyGP from './features/editGP/components/editPartiallyGP';
import PreviewGenratedGP from './features/viewRequest/components/previewGenratedGP';
import EditRequestedGP from './features/editGP/components/edit_requestedGP';
import HoScreen from './features/hoScreen';
import IssuerEditDraft from './features/editGP/components/issuerEditDraft';
import IssuerGatepass from './features/issuer_gatepass/index';
import Issuer_Requster from './features/issuer_gatepass/components/Issuer_Requster';
import ViewRequest_IssuerAsRequester from './features/viewRequest_IssuerAsRequester';
import Reports from './features/reports';

//--------SAP-----------------------------------
import ReqSAPDashboard from './features/SAP/Requesters/dashboard_SAP';
import CreateGP_SAP from './features/SAP/Requesters/createGP_SAP';
import EditGP_SAP from './features/SAP/Requesters/editGP_SAP';
import ViewRequest_SAP from './features/SAP/viewRequest_SAP';
import ApproverDashboardSAP from './features/SAP/Approvers/approver_dashboard_sap';
import IssuerDashboardSAP from './features/SAP/Issuers';
import ReportsSAP from './features/SAP/reportsSAP';
import Issuer_Requster_SAP from './features/SAP/Issuers/components/Issuer_Requster_SAP';
import PreviewGenratedGP_SAP from './features/SAP/viewRequest_SAP/components/previewGenratedGP_SAP';
import EditPartiallyGP_SAP from './features/SAP/Issuers/components/Edit_SAP/editPartiallyGP_SAP';
import ViewRequest_IssuerAsRequester_SAP from './features/SAP/Issuers/components/viewRequest_IssuerAsRequester_SAP';
import DraftGP_SAP from './features/SAP/Requesters/draftGatepass_SAP';
import HoSAP from './features/SAP/HO_SAP/index';
import Admin from './features/SAP/Admin/index';
import ReportsSAPAdmin from './features/SAP/Admin/components/reports/index';

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_URL
    //redirectUri: 'https://gatepass.tataprojects.com/session.html'
    //redirectUri: 'https://gatepass-dev.tataprojects.com/session.html'
    //redirectUri: 'https://gp.lexyslabs.com/session.html'
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 60000,
    loadFrameTimeout: 0,
    asyncPopups: false
  }
};
const pca = new PublicClientApplication(configuration);
pca.addEventCallback((event) => {
  // console.log(event);
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  }
});
//const pca = new PublicClientApplication(configuration);

const roleBasedNavigation = (id) => {
  switch (id) {
    case 'requester':
      return '/dashboard';
    case 'admin':
      return '/gatepass-requests';
    default:
      return '/';
  }
};

const PrivateRoute = ({ children }) => {
  let location = useLocation();

  const { loading } = useSelector((state) => state.login);
  const { loggedIn } = getLoggedInUser();

  if (loading) {
    return <Loader />;
  }

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

const AuthChecker = ({ children }) => {
  let location = useLocation();
  const { loggedIn, user } = getLoggedInUser();
  let goTo = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : roleBasedNavigation(user?.user_details?.role || '');

  if (loggedIn) {
    return <Navigate to={goTo} state={{ from: location }} />;
  }

  return children;
};

function App() {
  const { user } = getLoggedInUser();

  let goTo = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : user?.user_details?.role
    ? roleBasedNavigation(user?.user_details?.role || '')
    : '/login';

  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<Navigate replace to={goTo} />} />
        <Route
          path="login"
          element={
            <AuthChecker>
              <MsalProvider instance={pca}>
                <Login />
              </MsalProvider>
            </AuthChecker>
          }
        />
        <Route
          path="dashboard"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Dashboard />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="create-gp-request"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <GPRequest />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="gatepass-requests"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ApproverGatepass />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="issuer-gatepass"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <IssuerGatepass />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="issuer-requester"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Issuer_Requster />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-requests/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewRequest />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-requests-IssuerAsRequester/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewRequest_IssuerAsRequester />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="view-generatedGP/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewGeneratedGP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="edit-requestedGP/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <EditRequestedGP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="edit-draft-request/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <EditRequest />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="draft-edit-IssuerRequest/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <IssuerEditDraft />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="edit-partially/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <EditPartiallyGP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="preview_GenratedGP"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <PreviewGenratedGP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="ho-requests"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <HoScreen />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="reports"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Reports />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        {/* ------------SAP----------------- */}
        <Route
          path="requesters-sap-dashboard"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ReqSAPDashboard />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="create-sap-gp-request"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CreateGP_SAP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="edit-GP_SAP/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <EditGP_SAP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-requests-sap/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewRequest_SAP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="dashboard-approver-sap"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ApproverDashboardSAP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="dashboard-issuer-sap"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <IssuerDashboardSAP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="issuer-requester-sap"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Issuer_Requster_SAP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="reports-sap"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ReportsSAP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="edit-partially-sap/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <EditPartiallyGP_SAP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-requests-IssuerAsRequester-sap/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewRequest_IssuerAsRequester_SAP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="preview-genratedGP-sap"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <PreviewGenratedGP_SAP />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="draft-gatepass-SAP/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <DraftGP_SAP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="ho-requests_sap"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <HoSAP />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="admin-sap"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Admin />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="reports-sap-admin"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ReportsSAPAdmin />
              </MsalProvider>
            </PrivateRoute>
          }
        />
      </Routes>
    </HistoryRouter>
  );
}

export default App;
