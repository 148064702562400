import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { approver, issuer } from './api';

const initialState = {
  loading: false,
  getAllApproverLoading: false,
  allPendingRequests: [],
  allRequests: [],
  allRequestsIssuer: [],
  pagination: [],
  secondpagination: [],
  url: ''
};
const actions = {
  GET_ALL_PENDING_REQUESTS: 'getAllPendingRequests/GET_ALL_PENDING_REQUESTS',
  GET_ALL_REQUESTS: 'getAllRequests/GET_ALL_REQUESTS',
  GET_ALL_ISSUER_PENDING_REQUESTS:
    'getAllIssuerPendingRequests/GET_ALL_ISSUER_PENDING_REQUESTS',
  //GET_ALL_ISSUER_REQUESTS: 'getAllIssuerRequests/GET_ALL_ISSUER_REQUESTS',
  PRINT_REQUESTS: 'printRequest/PRINT_REQUESTS',
  SEARCH_TICKET: 'searchvalues/SEARCH_TICKET',
  SEARCH_TICKET_ISSUER: 'searchvaluesIssuer/SEARCH_TICKET_ISSUER'
};

export const getAllPendingRequests = createAsyncThunk(
  actions.GET_ALL_PENDING_REQUESTS,
  async (payload) => {
    const response = await approver(``, payload);
    return response;
  }
);

// export const getAllIssuerPendingRequests = createAsyncThunk(
//   actions.GET_ALL_ISSUER_PENDING_REQUESTS,
//   async (payload) => {
//     const response = await issuer(`gatepasses`, payload);
//     return response;
//   }
// );

export const getAllRequests = createAsyncThunk(
  actions.GET_ALL_REQUESTS,
  async (payload) => {
    const response = await approver(`requested-gatepasses`, payload);
    return response;
  }
);

// export const printRequest = createAsyncThunk(
//   actions.PRINT_REQUESTS,
//   async (payload) => {
//     const response = await print(`print/${payload.gpid}`, payload);
//     return response;
//   }
// );

export const searchvalues = createAsyncThunk(
  actions.SEARCH_TICKET,
  async (payload) => {
    // console.log(("SEARCHPAYLOAD",payload?.ticket))
    const response = await approver(
      `requested-gatepasses?status=${payload?.status}&search=${payload?.ticket}&page=${payload?.page}`
    );
    return response;
  }
);
//`gatepass?status=${payload?.status}&search=${payload?.ticket}&page=${payload?.page}`
export const searchvaluesIssuer = createAsyncThunk(
  actions.SEARCH_TICKET_ISSUER,
  async (payload) => {
    // console.log(("SEARCHPAYLOAD",payload?.ticket))
    const response = await issuer(
      `?status=${payload?.status}&search=${payload?.ticket}&page=${payload?.page}`
    );
    return response;
  }
);
export const approverSlice = createSlice({
  name: 'approver',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPendingRequests.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllPendingRequests.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allPendingRequests = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllPendingRequests.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllRequests.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllRequests.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequests = data ? data : [];
        state.secondpagination = pagination || null;
      })
      .addCase(getAllRequests.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    // builder
    //   .addCase(getAllIssuerPendingRequests.pending, (state) => {
    //     state.getAllApproverLoading = true;
    //   })
    //   .addCase(getAllIssuerPendingRequests.fulfilled, (state, action) => {
    //     const { data, pagination } = action.payload;
    //     state.getAllApproverLoading = false;
    //     state.allPendingRequests = data ? data : [];
    //     state.pagination = pagination || null;
    //   })
    //   .addCase(getAllIssuerPendingRequests.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.getAllApproverLoading = false;

    //     message.error(msg);
    //   });

    // builder
    //   .addCase(printRequest.pending, (state) => {
    //     state.getAllApproverLoading = true;
    //   })
    //   .addCase(printRequest.fulfilled, (state, action) => {
    //     const { success, url: url, msg: msg } = action.payload;
    //     state.url = url ? url : '';
    //     state.getAllApproverLoading = false;
    //     if (success) {
    //       // message.success(msg);
    //     } else {
    //       message.error(msg);
    //     }
    //   })
    //   .addCase(printRequest.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.getAllApproverLoading = false;

    //     message.error(msg);
    //   });
    builder
      .addCase(searchvalues.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(searchvalues.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequests = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          // message.error(msg);
        }
      })
      .addCase(searchvalues.rejected, (state) => {
        // const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        // message.error(msg);
      });
    builder
      .addCase(searchvaluesIssuer.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(searchvaluesIssuer.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequests = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          //message.error(msg);
        }
      })
      .addCase(searchvaluesIssuer.rejected, (state) => {
        // const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        // message.error(msg);
      });
  }
});
export default approverSlice.reducer;
