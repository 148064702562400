import CustomLayout from '@/common/Layout';
import ApproverList from './components/approverList';

const ApproverDashboardSAP = () => {
  return (
    <>
      <CustomLayout sider="true">
        <ApproverList />
      </CustomLayout>
    </>
  );
};

export default ApproverDashboardSAP;
