import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { approver, issuer, print, hoRole_sap } from './api';

const initialState = {
  loading: false,
  getAllApproverLoading: false,
  getAllHoLoading: false,
  allPendingRequests: [],
  allRequests: [],
  pagination: [],
  secondpagination: [],
  url: ''
};
const actions = {
  GET_ALL_PENDING_REQUESTS: 'getAllPendingRequests/GET_ALL_PENDING_REQUESTS',
  GET_ALL_REQUESTS: 'getAllRequests_sap/GET_ALL_REQUESTS',
  GET_ALL_ISSUER_PENDING_REQUESTS:
    'getAllIssuerPendingRequests/GET_ALL_ISSUER_PENDING_REQUESTS',
  GET_ALL_ISSUER_REQUESTS: 'getAllIssuerRequests/GET_ALL_ISSUER_REQUESTS',
  PRINT_REQUESTS: 'printRequest_sap/PRINT_REQUESTS',
  SEARCH_TICKET: 'searchvalues/SEARCH_TICKET',
  SEARCH_TICKET_ISSUER: 'searchvaluesIssuer/SEARCH_TICKET_ISSUER'
};

export const getAllPendingRequests = createAsyncThunk(
  actions.GET_ALL_PENDING_REQUESTS,
  async (payload) => {
    const response = await approver(``, payload);
    return response;
  }
);

export const getAllIssuerPendingRequests = createAsyncThunk(
  actions.GET_ALL_ISSUER_PENDING_REQUESTS,
  async (payload) => {
    const response = await issuer(``, payload);
    return response;
  }
);

export const getAllRequests_sap = createAsyncThunk(
  actions.GET_ALL_REQUESTS,
  async (payload) => {
    const response = await hoRole_sap(``, payload);
    return response;
  }
);

export const getAllIssuerRequests = createAsyncThunk(
  actions.GET_ALL_ISSUER_REQUESTS,
  async (payload) => {
    const response = await issuer(``, payload);
    return response;
  }
);

export const printRequest_sap = createAsyncThunk(
  actions.PRINT_REQUESTS,
  async (payload) => {
    const response = await print(`print/${payload.gpid}`, payload);
    return response;
  }
);

export const searchvalues = createAsyncThunk(
  actions.SEARCH_TICKET,
  async (payload) => {
    // console.log(("SEARCHPAYLOAD",payload?.ticket))
    const response = await hoRole_sap(
      `?status=${payload?.status}&search=${payload?.ticket}&page=${payload?.page}`
    );
    return response;
  }
);
//`gatepass?status=${payload?.status}&search=${payload?.ticket}&page=${payload?.page}`
export const searchvaluesIssuer = createAsyncThunk(
  actions.SEARCH_TICKET_ISSUER,
  async (payload) => {
    // console.log(("SEARCHPAYLOAD",payload?.ticket))
    const response = await issuer(
      `?status=${payload?.status}&search=${payload?.ticket}&page=${payload?.page}`
    );
    return response;
  }
);
export const hoScreenSapSlice = createSlice({
  name: 'hoScreen_sap',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPendingRequests.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllPendingRequests.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allPendingRequests = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllPendingRequests.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllRequests_sap.pending, (state) => {
        state.getAllHoLoading = true;
      })
      .addCase(getAllRequests_sap.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllHoLoading = false;
        state.allRequests = data ? data : [];
        state.secondpagination = pagination || null;
      })
      .addCase(getAllRequests_sap.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllHoLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllIssuerPendingRequests.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllIssuerPendingRequests.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allPendingRequests = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllIssuerPendingRequests.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllIssuerRequests.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllIssuerRequests.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequests = data ? data : [];
        state.secondpagination = pagination || null;
      })
      .addCase(getAllIssuerRequests.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(printRequest_sap.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(printRequest_sap.fulfilled, (state, action) => {
        const { success, url: url, msg: msg } = action.payload;
        state.url = url ? url : '';
        state.getAllApproverLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(printRequest_sap.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(searchvalues.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(searchvalues.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequests = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          // message.error(msg);
        }
      })
      .addCase(searchvalues.rejected, (state) => {
        // const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        // message.error(msg);
      });
    builder
      .addCase(searchvaluesIssuer.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(searchvaluesIssuer.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequests = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          //message.error(msg);
        }
      })
      .addCase(searchvaluesIssuer.rejected, (state) => {
        // const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        // message.error(msg);
      });
  }
});
export default hoScreenSapSlice.reducer;
