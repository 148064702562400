import React, { useState, useEffect } from 'react';
import { Modal, Tooltip, Space } from 'antd';
//import { getLoggedInUser } from 'utilities/helpers';
import CustomTableItem from 'common/Content/CustomTableItem';
import ItemHistotyModal from '../../../editGP/components/itemHistotyModal';
import { useDispatch, useSelector } from 'react-redux';
import { get_item_history } from '../../../editGP/redux/slice';

const ItemsModal = ({
  itemsModal,
  setItemsModal,
  requester_data,
  gatepassItems,
  itemsPagination,
  page,
  setPage,
  pageSize,
  setPageSize
}) => {
  // const user = getLoggedInUser();
  const { itemHistory } = useSelector((state) => state.edit);
  const dispatch = useDispatch();
  const [itemHistotyVisible, setItemHistotyVisible] = useState(false);
  const [itemId, setItemId] = useState();

  console.log('requester_data_ItemModal', requester_data);
  console.log('Category', requester_data?.Category?.category);
  useEffect(() => {
    if (itemId) {
      dispatch(
        get_item_history({
          gpid: requester_data?.id,
          item_id: itemId
        })
      );
    }
  }, [requester_data, gatepassItems, itemId]);

  const columns = [
    {
      title: 'Item Code',
      dataIndex: 'item_code',
      key: 'item_code',
      sorter: (a, b) => Number(a.item_code) - Number(b.item_code),
      render: (item_code) => {
        return (
          <>
            <Tooltip
              title={item_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {item_code}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Item Description',
      dataIndex: 'item_description',
      key: 'item_description',
      sorter: (a, b) => a?.item_description?.localeCompare(b?.item_description),
      render: (item_description, record) => {
        return (
          <>
            {' '}
            <Tooltip
              title={item_description}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {requester_data?.Category?.category ===
              'Returnable Gate Pass (RGP)' ? (
                record.status === 'Printed' ||
                record.status === 'Closed' ||
                record.status === 'Partially received' ? (
                  <a
                    onClick={() => {
                      setItemHistotyVisible(true);
                      setItemId(record.id);
                    }}
                  >
                    {item_description}
                  </a>
                ) : (
                  <span>{item_description}</span>
                )
              ) : (
                <span>{item_description}</span>
              )}
              {/* {record.status === 'Printed' ? (
                <a
                  onClick={() => {
                    setItemHistotyVisible(true);
                    setItemId(record.id);
                  }}
                >
                  {item_description}
                </a>
              ) : record.status === 'Closed' ? (
                <a
                  onClick={() => {
                    setItemHistotyVisible(true);
                    setItemId(record.id);
                  }}
                >
                  {item_description}
                </a>
              ) : record.status === 'Partially received' ? (
                <a
                  onClick={() => {
                    setItemHistotyVisible(true);
                    setItemId(record.id);
                  }}
                >
                  {item_description}
                </a>
              ) : record.status === 'Generated' ? (
                { item_description }
              ) : record.status === 'Approved' ? (
                { item_description }
              ) : record.status === 'Requested' ? (
                { item_description }
              ) : (
                ''
              )} */}
            </Tooltip>
          </>
        );
      }
    },

    {
      title: 'UOM',
      dataIndex: 'uom',
      key: 'uom',
      sorter: (a, b) => a?.uom?.localeCompare(b?.uom),
      render: (uom) => {
        return (
          <>
            <Tooltip
              title={uom}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {uom}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => Number(a.quantity) - Number(b.quantity),
      render: (quantity) => {
        return (
          <>
            <Tooltip
              title={quantity}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {quantity}
            </Tooltip>
          </>
        );
      }
    },
    /*  {
      title: 'Quantity Returned',
      dataIndex: 'qty_returned',
      key: 'qty_returned',
      render: (qty_returned) => {
        return (
          <>
            <Tooltip
              title={qty_returned}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {qty_returned}
            </Tooltip>
          </>
        );
      }
    }, */

    {
      title: 'Item Remarks',
      dataIndex: 'item_remarks',
      key: 'item_remarks',
      render: (item_remarks) => {
        return (
          <>
            <Tooltip
              title={item_remarks}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {item_remarks}
            </Tooltip>
          </>
        );
      }
    }
  ];
  const columnsERP = [
    {
      title: 'Item Code',
      dataIndex: 'item_code',
      key: 'item_code',
      sorter: (a, b) => Number(a.item_code) - Number(b.item_code),
      render: (item_code) => {
        return (
          <>
            <Tooltip
              title={item_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {item_code}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Item Description',
      dataIndex: 'item_description',
      key: 'item_description',
      sorter: (a, b) => a?.item_description?.localeCompare(b?.item_description),
      render: (item_description, record) => {
        return (
          <>
            <Tooltip
              title={item_description}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {record.status === 'Closed' ? (
                <a
                  onClick={() => {
                    setItemHistotyVisible(true);
                    setItemId(record.id);
                  }}
                >
                  {item_description}
                </a>
              ) : (
                <span>{item_description}</span>
              )}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'UOM',
      dataIndex: 'uom',
      key: 'uom',
      sorter: (a, b) => a?.uom?.localeCompare(b?.uom),
      render: (uom) => {
        return (
          <>
            <Tooltip
              title={uom}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {uom}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => Number(a.quantity) - Number(b.quantity),
      render: (quantity) => {
        return (
          <>
            <Tooltip
              title={quantity}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {quantity}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Item Remarks',
      dataIndex: 'item_remarks',
      key: 'item_remarks',
      render: (item_remarks) => {
        return (
          <>
            <Tooltip
              title={item_remarks}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {item_remarks}
            </Tooltip>
          </>
        );
      }
    }
  ];
  return (
    <>
      <Modal
        title={
          <Space direction="vertical" size={7}>
            <div style={{ fontSize: '20px' }}>Items Details</div>{' '}
            <Space direction="horizontal" size={7}>
              <span>Total Value:</span>
              <span>{requester_data?.all_items_value}</span>
            </Space>
          </Space>
        }
        visible={itemsModal}
        // className={`${styles.check_circle_icon}`}
        width={'90%'}
        onCancel={() => setItemsModal(false)}
        onOk={() => setItemsModal(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <CustomTableItem
          columns={
            requester_data?.Method_type?.method_type === 'Direct Entry'
              ? columns
              : columnsERP
          }
          dataSource={gatepassItems ? gatepassItems : ''}
          pagination={{
            current: page,
            pageSize: pageSize,
            //showSizeChanger: true,
            size: 'small',
            total: itemsPagination?.total_records || 0,
            onChange: (page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }
          }}
        />
        {itemHistotyVisible ? (
          <ItemHistotyModal
            itemHistotyVisible={itemHistotyVisible}
            setItemHistotyVisible={setItemHistotyVisible}
            itemHistory={itemHistory}
          />
        ) : (
          ''
        )}
      </Modal>
    </>
  );
};

export default ItemsModal;
