import React from 'react';
import { Row, Col } from 'antd';
import styles from './index.module.less';
import loginImage from 'features/login/images/tata_logo.svg';
import infoImage from 'features/login/images/Info.png';

const AuthWrapper = ({ children }) => {
  return (
    <Row className={styles.container}>
      <Col span={13}>
        <div
          className={styles.left_outer_container}
          style={{
            backgroundSize: 'cover',
            //height: '100vh',
            color: '#f5f5f5'
          }}
        >
          <div className={`${styles.tata_logo_div}`}>
            <img
              src={loginImage}
              // style={{ height: '40px' }}
              className={`${styles.tata_logo}`}
            />
          </div>
          <div>
            <img
              className={`${styles.vehicle_image}`}
              vehicle_image
              src={infoImage}
              // style={{ height: '350px', marginTop: '13%', marginLeft: '72px' }}
            />
          </div>
        </div>
      </Col>
      <Col span={11}>{children}</Col>
    </Row>
  );
};

export default AuthWrapper;
