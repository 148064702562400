import apiClient from 'utilities/apiClient';

export const approver = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.approver_sap}/${url}`, payload, true);
};

export const issuer = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.issuer_sap}/${url}`, payload, true);
};

export const delete_requests = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.requester_sap}/${url}`,
    payload,
    true
  );
};

export const print = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.issuer_print}/${url}`,
    payload,
    true
  );
};

/* export const approverSearch = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.approverSearch}`, payload, true);
}; */
