import React, { useState, useMemo, useEffect } from 'react';
import { Modal, Button, Image, Row, Col, Select, Input } from 'antd';
//import { CheckCircleOutlined } from '@ant-design/icons';
import styles from './plantModal.module.less';
import FormItem from 'common/FormItem';
import { Form, Space } from 'antd';
import { createUser, getRoles } from '../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import adminModal_image from 'assets/images/adminModal_image.svg';
import { debounce } from 'lodash';

const { Option } = Select;

const UserModal = ({
  isUserModalOpen,
  setIsUserModalOpen,
  handleGetRequests,
  selectedRequest,
  editMode,
  setEditMode
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [formData1, setFormData1] = useState({});
  const [projectSearch, setProjectSearch] = useState('');

  useEffect(() => {
    const payload = { page: 1, per_page: 100, search: projectSearch };
    //if (projectSearch) payload['search'] = projectSearch;
    dispatch(getRoles(payload));
  }, [projectSearch]);

  const { createUserLoading, allRoles } = useSelector((state) => state.admin);

  useEffect(() => {
    if (editMode === true) {
      form.setFieldsValue({
        first_name: selectedRequest?.first_name,
        last_name: selectedRequest?.last_name,
        email: selectedRequest?.email
        //role: selectedRequest?.role_id
      });
    }
  }, [selectedRequest]);

  const debounceFetcherProject = useMemo(() => {
    const loadOptions = (projectSearch) => {
      setProjectSearch(projectSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    setFormData1({
      ...formData1,
      [slug]: value
    });
  };

  const onFinish = () => {
    const payload = {
      id: selectedRequest?.id,
      first_name:
        editMode === true && formData1.first_name === undefined
          ? selectedRequest?.first_name
          : formData1.first_name,
      last_name:
        editMode === true && formData1.last_name === undefined
          ? selectedRequest?.last_name
          : formData1.last_name,
      email:
        editMode === true && formData1.email === undefined
          ? selectedRequest?.email
          : formData1.email,
      role_id: formData1.role
    };
    const payloadEdit = {
      id: selectedRequest?.id,
      first_name:
        editMode === true && formData1.first_name === undefined
          ? selectedRequest?.first_name
          : formData1.first_name,
      last_name:
        editMode === true && formData1.last_name === undefined
          ? selectedRequest?.last_name
          : formData1.last_name,
      email:
        editMode === true && formData1.email === undefined
          ? selectedRequest?.email
          : formData1.email
    };
    dispatch(createUser(editMode === true ? payloadEdit : payload)).then(
      function (response) {
        if (response) {
          setIsUserModalOpen(false);
          setEditMode(false);
          if (response?.payload?.success) {
            handleGetRequests();
          }
        }
      }
    );
  };

  return (
    <>
      <Modal
        title={
          <span>
            <Image src={adminModal_image} alt="icon" preview={false}></Image>
          </span>
        }
        visible={isUserModalOpen}
        footer={false}
        className={`${styles.check_circle_icon}`}
        width={'820px'}
        onCancel={() => setIsUserModalOpen(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                label="First Name"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter first name"
                  onChange={(e) =>
                    handleFormDataOnChange('first_name', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter last name"
                  onChange={(e) =>
                    handleFormDataOnChange('last_name', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={editMode !== true ? 12 : 24}>
              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  },
                  {
                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Please enter a valid email address!'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter email"
                  onChange={(e) =>
                    handleFormDataOnChange('email', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            {editMode !== true && (
              <Col span={12}>
                <FormItem
                  label="Role"
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.select_styles}`}
                  // onChange={(e) => onChangeProject(e.target.value)}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option?.children
                        ?.toString()
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase());
                    }}
                    onSearch={debounceFetcherProject}
                    onChange={(value) => handleFormDataOnChange('role', value)}
                    placeholder="Select role"
                  >
                    {allRoles?.map((item, index) => {
                      return (
                        <Option
                          value={item.id}
                          key={index}
                          className={`${styles.cell_style}`}
                        >
                          {item.role_name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
            )}
          </Row>

          <FormItem>
            <Space className={`${styles.button_container}`}>
              <Button
                className={`${styles.import_style_cancel}`}
                size="small"
                onClick={() => setIsUserModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                loading={createUserLoading}
                className={`${styles.approve_modal_button}`}
                htmlType="submit"
                size="small"
              >
                {editMode === true ? 'Update' : 'Create'}
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default UserModal;
