import React from 'react';
import CustomLayout from '@/common/Layout';
import AdminPage from './components';

const Admin = () => {
  return (
    <CustomLayout sider="true">
      <AdminPage />
    </CustomLayout>
  );
};

export default Admin;
