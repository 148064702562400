import React, { useState } from 'react';
import styles from './index.module.less';
import { Col, Row, Form, Typography, Divider, Button, Spin } from 'antd';
import FormItem from 'common/FormItem';
import ItemsModal from '../ItemsModal';
import moment from 'moment';
import { ArrowLeftOutlined } from '@ant-design/icons';

const Viewdetails_IssuerAsRequester = ({
  requester_data,
  gatepassItems,
  getRequestByIdLoading,
  itemsPagination,
  page,
  setPage,
  pageSize,
  setPageSize
}) => {
  // const [requester_data, setRequester_data] = useState();
  // useEffect(() => {
  //   setRequester_data(requester_data1);
  // }, [requester_data1]);

  const onFinish = (values) => {
    console.log('Values', values);
  };

  const [form] = Form.useForm();
  const [itemsModal, setItemsModal] = useState(false);

  const handleFetchDocument = (fileName) => {
    window?.open(
      `${process.env.REACT_APP_API_URL}/fetch-file?fileName=${fileName}`,
      '_blank'
    );
  };

  return (
    <>
      <Spin spinning={getRequestByIdLoading}>
        <div className={`mb-4 ${styles.container}`}>
          <Row gutter={16} style={{ marginTop: '-9px' }}>
            <Col span={21}>
              <div className={`${styles.page_title1}`}>
                <span style={{ fontSize: '18px' }}>Ticket no:</span>

                <span style={{ fontSize: '18px', marginLeft: '5px' }}>
                  {requester_data ? requester_data?.ticket : 'NA'}
                </span>
                <span style={{ fontSize: '18px', marginLeft: '20px' }}>
                  Gate Pass no:
                </span>
                <span style={{ fontSize: '18px', marginLeft: '5px' }}>
                  {requester_data ? requester_data?.gp_number : 'NA'}
                </span>
              </div>
              <div className={`${styles.page_sub_title}`}>
                Please review it and take action
              </div>
            </Col>
            <Col span={2} style={{ marginLeft: '20px' }}>
              <a href={'/issuer-requester'}>
                <Button className={`${styles.back_button}`}>
                  <ArrowLeftOutlined className={`${styles.back_icon}`} />
                  Back
                </Button>
              </a>
            </Col>
          </Row>
          <br />
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className={`${styles.formstyles}`}
          >
            <Row gutter={16}>
              <Col span={6}>
                <FormItem label="Project" className={`${styles.select_styles}`}>
                  <Typography>
                    {requester_data?.project_master === null
                      ? ''
                      : `${requester_data?.project_master?.project_name}(${requester_data?.project_master?.project_code}) `}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="Project location"
                  className={`${styles.select_styles}`}
                >
                  <Typography>
                    {requester_data?.project_location_master === null
                      ? ''
                      : requester_data?.project_location_master?.name}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Type" className={`${styles.select_styles}`}>
                  <Typography>
                    {requester_data?.type_id === null
                      ? ''
                      : requester_data?.Type?.type}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="Category"
                  className={`${styles.select_styles}`}
                >
                  <Typography>
                    {requester_data?.category_id === null
                      ? ''
                      : requester_data?.Category?.category}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            {requester_data?.Method_type?.method_type === 'ERP' ? (
              <>
                <Divider
                  style={{
                    margin: 0,
                    marginTop: '10px',
                    marginBottom: '25px'
                  }}
                />
                <Row gutter={16}>
                  <Col span={6}>
                    <FormItem
                      label="Reference documents"
                      className={`${styles.select_styles}`}
                    >
                      <Typography>
                        {requester_data
                          ? requester_data?.Document_master?.document_name
                          : 'NA'}
                      </Typography>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Reference number"
                      className={`${styles.select_styles}`}
                    >
                      <Typography>
                        {requester_data?.document_number !== null
                          ? requester_data?.document_number
                          : 'NA'}
                      </Typography>
                    </FormItem>
                  </Col>
                </Row>
              </>
            ) : (
              ''
            )}
            <Divider
              style={{
                // margin: 0,
                marginTop: '10px',
                marginBottom: '15px'
              }}
            />
            <Row gutter={16}>
              <Col span={6}>
                <FormItem label="Method" className={`${styles.select_styles}`}>
                  <Typography>
                    {requester_data?.methodType_id === null
                      ? ''
                      : requester_data?.Method_type?.method_type}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label={
                    requester_data?.Document_master?.id === 3
                      ? 'Project name'
                      : 'Vendor name'
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography>
                    {requester_data
                      ? requester_data?.Vendor_Master?.vendorName
                      : 'NA'}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label={
                    requester_data?.Document_master?.id === 3
                      ? 'Project location'
                      : 'Vendor location'
                  }
                  className={`${styles.select_styles}`}
                >
                  <Typography>
                    {requester_data ? (
                      <>
                        <span>
                          {requester_data?.vendor_location_name !== null
                            ? requester_data?.vendor_location_name
                            : 'NA'}
                          {/* //Vendor_Site_Master?.vendorSite */}
                        </span>{' '}
                      </>
                    ) : (
                      'NA'
                    )}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Address" className={`${styles.select_styles}`}>
                  <Typography className={`${styles.addr_typo}`}>
                    {requester_data?.vendor_location_address !== null
                      ? requester_data?.vendor_location_address
                      : 'NA'}
                    {/* Vendor_Site_Master?.addressLine1 */}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Divider
              style={{
                //margin: 0,
                marginTop: '10px',
                marginBottom: '15px'
              }}
            />
            <Row gutter={16}>
              <Col span={6}>
                <FormItem
                  label="Generated date"
                  className={`${styles.select_styles}`}
                >
                  <Typography>
                    {requester_data?.date_of_generated === null
                      ? ''
                      : moment(requester_data?.date_of_generated).format(
                          'DD MMM YYYY'
                        )}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="Material belongs to"
                  className={`${styles.select_styles}`}
                >
                  <Typography>
                    {requester_data
                      ? requester_data?.MaterialBelong?.material_name
                      : 'NA'}
                  </Typography>
                </FormItem>
              </Col>
              {requester_data?.Category?.category ===
              'Returnable Gate Pass (RGP)' ? (
                <>
                  <Col span={6}>
                    <FormItem
                      label="Expected date of return"
                      className={`${styles.select_styles}`}
                    >
                      <Typography>
                        {requester_data
                          ? requester_data?.expected_date_return === null
                            ? ' '
                            : moment(
                                requester_data?.expected_date_return
                              ).format('DD MMM YYYY')
                          : 'NA'}
                      </Typography>
                    </FormItem>
                  </Col>

                  <Col span={6}>
                    <FormItem
                      label="Actual return date"
                      className={`${styles.select_styles}`}
                    >
                      <Typography>
                        {requester_data
                          ? requester_data?.actual_return_date === null
                            ? ''
                            : moment(requester_data?.actual_return_date).format(
                                'DD MMM YYYY'
                              )
                          : 'NA'}
                      </Typography>
                    </FormItem>
                  </Col>
                </>
              ) : (
                ''
              )}
            </Row>
            <Divider
              style={{
                //margin: 0,
                marginTop: '10px',
                marginBottom: '15px'
              }}
            />
            <Row gutter={16}>
              <Col span={6}>
                <FormItem
                  label="Vehicle number"
                  className={`${styles.select_styles}`}
                >
                  <Typography>
                    {requester_data ? requester_data?.vehicle_number : 'NA'}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="LR number"
                  className={`${styles.select_styles}`}
                >
                  <Typography>
                    {requester_data ? requester_data?.lr_number : 'NA'}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="LR Date" className={`${styles.select_styles}`}>
                  <Typography>
                    {requester_data
                      ? requester_data?.lr_date === null
                        ? ''
                        : moment(requester_data?.lr_date).format('DD MMM YYYY')
                      : 'NA'}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="Name of the carrier person"
                  className={`${styles.select_styles}`}
                >
                  <Typography>
                    {requester_data
                      ? requester_data?.carrier_person_name
                      : 'NA'}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Divider
              style={{
                // margin: 0,
                marginTop: '10px',
                marginBottom: '15px'
              }}
            />
            <Row gutter={16}>
              <Col span={6}>
                <FormItem
                  label="Approver"
                  className={`${styles.select_styles}`}
                >
                  <Typography>
                    {requester_data?.Approvers?.length > 0
                      ? requester_data?.Approvers[0]?.User?.email
                      : 'NA'}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="Issuer" className={`${styles.select_styles}`}>
                  <Typography>
                    {requester_data?.Approvers?.length > 0
                      ? requester_data?.Approvers[1]?.User?.email
                      : 'NA'}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="GST Number"
                  className={`${styles.select_styles}`}
                >
                  <Typography>
                    {requester_data?.project_location_master === null
                      ? ''
                      : requester_data?.project_location_master?.gst_number}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Divider
              style={{
                margin: 0,
                marginTop: '10px',
                marginBottom: '10px'
              }}
            />

            <a
              className={`${styles.itemsDetails}`}
              // htmlType="submit"
              size="small"
              onClick={() => setItemsModal(true)}
            >
              Item Details
            </a>
            <br />

            <Divider
              style={{
                margin: 0,
                marginBottom: '15px',
                marginTop: '15px'
              }}
            />
            <Row gutter={16}>
              <Col span={6}>
                <FormItem
                  label="Reason for sending"
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.addr_typo}`}>
                    {requester_data
                      ? requester_data?.Reason?.reason_name
                      : 'NA'}
                  </Typography>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="Detailed reason"
                  className={`${styles.select_styles}`}
                >
                  <Typography className={`${styles.addr_typo}`}>
                    {requester_data ? requester_data?.detailed_reason : 'NA'}
                  </Typography>
                </FormItem>
              </Col>
              {requester_data?.status === 'Rejected' ? (
                <Col span={6}>
                  <FormItem
                    label="Reason for rejection"
                    className={`${styles.select_styles}`}
                  >
                    <Typography className={`${styles.addr_typo}`}>
                      {requester_data
                        ? requester_data?.reason_for_rejection
                        : 'NA'}
                    </Typography>
                  </FormItem>
                </Col>
              ) : (
                ''
              )}
              <Col span={6}>
                <FormItem label="Remarks" className={`${styles.select_styles}`}>
                  <Typography className={`${styles.addr_typo}`}>
                    {requester_data ? requester_data?.remarks : 'NA'}
                  </Typography>
                </FormItem>
              </Col>
            </Row>
            <Divider
              style={{
                margin: 0,
                marginTop: '10px',
                marginBottom: '10px'
              }}
            />
            {/* {requester_data?.Documents?.length > 0 ? ( */}
            <div
              style={{
                fontWeight: 550,
                color: 'rgba(0, 0, 0, 0.85)',
                fontSize: '13px',
                marginBottom: '20px',
                flexDirection: 'row'
              }}
            >
              <Row>
                <Col>
                  <span style={{ fontWeight: '500' }}>
                    {' '}
                    Uploaded documents:
                  </span>
                </Col>
                <Col>
                  <ul style={{ marginLeft: '-20px' }}>
                    {requester_data?.Documents?.map((item, index) => (
                      <li
                        className={`${styles.li_style}`}
                        style={{ marginRight: '20px' }}
                        key={index}
                      >
                        {/* <a
                          href={item?.fileUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item?.originalName}
                        </a> */}
                        <Button
                          type="link"
                          onClick={() => {
                            handleFetchDocument(item?.fileName);
                          }}
                        >
                          {item?.originalName}
                        </Button>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </div>

            <Divider
              style={{
                margin: 0,
                marginTop: '250px !important',
                marginBottom: '25px'
              }}
            />
          </Form>

          {itemsModal ? (
            <ItemsModal
              itemsModal={itemsModal}
              setItemsModal={setItemsModal}
              requester_data={requester_data}
              gatepassItems={gatepassItems}
              itemsPagination={itemsPagination}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          ) : (
            ''
          )}
        </div>
      </Spin>
    </>
  );
};

export default Viewdetails_IssuerAsRequester;
