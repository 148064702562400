import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { requests, delete_requests, delete_draft } from './api';

const initialState = {
  loading: false,
  getAllApproverLoading: false,
  allRequests: [],
  pagination: []
};

const actions = {
  GET_ALL_REQUESTS: 'getAllRequests/GET_ALL_REQUESTS',
  DELETE_REQUEST: 'deleteRequest_sap/DELETE_REQUEST',
  SEARCH_TICKET: 'searchvalues/SEARCH_TICKET',
  DELETE_DRAFT: 'deleteDraft_sap/DELETE_DRAFT'
};

export const getAllRequests = createAsyncThunk(
  actions.GET_ALL_REQUESTS,
  async (payload) => {
    const response = await requests(`gatepasses`, payload);
    return response;
  }
);
export const deleteRequest_sap = createAsyncThunk(
  actions.DELETE_REQUEST,
  async (payload) => {
    const response = await delete_requests(
      `delete-gatepass?gpid=${payload.gpid}`,
      payload
    );
    return response;
  }
);
///api-v1/draft?draft_id=713
export const deleteDraft_sap = createAsyncThunk(
  actions.DELETE_DRAFT,
  async (payload) => {
    const response = await delete_draft(
      `draft?draft_id=${payload.gpid}`,
      payload
    );
    return response;
  }
);

export const searchvalues = createAsyncThunk(
  actions.SEARCH_TICKET,
  async (payload) => {
    const response = await requests(
      `gatepasses?status=${payload?.status}&search=${payload?.ticket}&page=${payload?.page}`
    );
    return response;
  }
);

export const dashboardSAPSlice = createSlice({
  name: 'dashboard_sap',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllRequests.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllRequests.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequests = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllRequests.rejected, (state) => {
        state.getAllApproverLoading = false;
      });
    builder
      .addCase(deleteRequest_sap.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(deleteRequest_sap.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          message.success('record deleted successfully');
        }
      })
      .addCase(deleteRequest_sap.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(deleteDraft_sap.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(deleteDraft_sap.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          message.success('record deleted successfully');
        }
      })
      .addCase(deleteDraft_sap.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(searchvalues.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(searchvalues.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequests = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          // message.error(msg);
        }
      })
      .addCase(searchvalues.rejected, (state) => {
        // const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        // message.error(msg);
      });
  }
});
export default dashboardSAPSlice.reducer;
