import React, { useState, useEffect } from 'react';
import CustomLayout from '@/common/Layout';
import ViewDetails_SAP from './components/viewdetails_SAP';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestById } from './redux/slice';
import { useParams } from 'react-router-dom';
import { getGatepassItems } from '../Requesters/editGP_SAP/redux/slice';

const ViewRequest_SAP = () => {
  const { requester_data, getRequestByIdLoading } = useSelector(
    (state) => state.view_sap
  );
  const { gatepassItems, itemsPagination } = useSelector(
    (state) => state.edit_sap
  );

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  //console.log('gatepassItems>>>>>>>>>>', gatepassItems);

  //console.log('requester_data', requester_data);

  const params = useParams();
  useEffect(() => {
    handleGetRequestById();
  }, []);
  const handleGetRequestById = () => {
    const payload = { gpid: params?.id };
    dispatch(getRequestById(payload));
  };

  useEffect(() => {
    dispatch(
      getGatepassItems({
        gpid: parseInt(params.id),
        page: page,
        per_page: pageSize,
        all: 1
        // search: itemSearchValue === undefined ? '' : itemSearchValue
      })
    );
  }, [page]);

  return (
    <>
      <CustomLayout sider="true">
        <ViewDetails_SAP
          requester_data1={requester_data}
          // requester_data_Approver1={requester_data_Approver}
          gatepassItems={gatepassItems}
          getRequestByIdLoading={getRequestByIdLoading}
          itemsPagination={itemsPagination}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </CustomLayout>
    </>
  );
};

export default ViewRequest_SAP;
