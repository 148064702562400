import CustomLayout from '@/common/Layout';
import IssuerGatepassList from './components/issuerGatepassList';

const IssuerGatepass = () => {
  return (
    <>
      <CustomLayout sider="true">
        <IssuerGatepassList />
      </CustomLayout>
    </>
  );
};

export default IssuerGatepass;
