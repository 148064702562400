import React, { useCallback } from 'react';
import styles from './index.module.less';
import {
  Card,
  Row,
  Badge,
  Divider,
  Radio,
  Space,
  Popconfirm,
  Tooltip,
  Empty
} from 'antd';
//import CustomButton from 'common/CustomButton';
import CustomTable from 'common/Content/CustomTable';
//import { Link } from 'react-router-dom';
import moment from 'moment';
import SearchBar from '@/common/Content/searchBar';
import {
  //PlusOutlined,
  DeleteFilled,
  EditFilled
} from '@ant-design/icons';

const filters = [
  {
    name: 'All',
    value: ''
  },
  /* {
    name: 'Draft',
    value: 'Draft'
  }, */
  {
    name: 'Requested',
    value: 'Requested'
  },
  {
    name: 'Approved',
    value: 'Approved'
  },
  {
    name: 'Rejected',
    value: 'Rejected'
  },
  {
    name: 'Generated',
    value: 'Generated'
  },
  {
    name: 'Printed',
    value: 'Printed'
  },

  {
    name: 'Partially Received',
    value: 'Partially received'
  },

  {
    name: 'Closed',
    value: 'Closed'
  },
  {
    name: 'Cancelled',
    value: 'Cancelled'
  }
];

const List = ({
  allRequests,
  pagination,
  loading,
  page,
  setPage,
  pageSize,
  setPageSize,
  setEditMode,
  setSelectedRequest,
  handleDeleteRequest,
  handleDeleteDraft,
  radiofilter,
  setRadiofilter,
  showDisablePopup,
  setShowDisablePopup,
  //handleChange,
  setTriggerFilter,
  setSearchRequest
  // setShowDateranga
  //showDateranga
}) => {
  //console.log('allRequests', allRequests);
  // const [selected, setSelected] = useState([]);
  // const [selectedRowKeys, setSelectedRowKeys] = useState('');
  const data1 = allRequests?.map((item) => ({
    key: item?.id,
    id: item?.id,
    ticket: item?.ticket,
    Method_type: item?.Method_type?.method_type,
    Location: item?.Location?.location_name,
    Type: item?.Type?.type,
    Category: item?.Category?.category,
    date: item?.date,
    status: item?.status,
    approver_status: item?.approver_status,
    issuer_status: item?.issuer_status,
    gp_number: item?.gp_number,
    Project_location: item?.project_location_master?.name,
    date_of_generated: item?.date_of_generated,
    updatedAt: item?.updatedAt
  }));
  /*  */
  // console.log('data1....', data1);
  const columns = [
    {
      title: 'Request #',
      dataIndex: 'ticket',
      key: 'ticket',
      width: 120,
      ellipsis: true,
      //sorter: (a, b) => a.ticket - b.ticket,
      sorter: (a, b) => a?.ticket?.localeCompare(b?.ticket),
      render: (ticket, record) => {
        return (
          <>
            <Tooltip
              title={ticket}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a href={`view-requests/${record.id}`}>
                {ticket === null ? 'Not generated' : ticket}
              </a>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Gate Pass #',
      dataIndex: 'gp_number',
      key: 'gp_number',
      width: 170,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.gp_number?.localeCompare(b?.gp_number),
      render: (gp_number) => {
        return (
          <>
            <Tooltip
              title={gp_number}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {gp_number === null ? 'Not generated' : gp_number}
            </Tooltip>
          </>
        );
      }
    },

    {
      title: 'Location',
      dataIndex: 'Project_location',
      key: 'Project_location',
      sorter: (a, b) => a?.Project_location?.localeCompare(b?.Project_location),
      ellipsis: true,
      render: (Project_location) => {
        return (
          <>
            <Tooltip
              title={Project_location}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {Project_location}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      sorter: (a, b) => a?.Type?.localeCompare(b?.Type),
      render: (Type) => {
        return (
          <>
            <Tooltip
              title={Type}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {Type}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Category',
      dataIndex: 'Category',
      key: 'Category',
      ellipsis: true,
      sorter: (a, b) => a?.Category?.localeCompare(b?.Category),
      render: (Category) => {
        if (Category === 'Returnable Gate Pass (RGP)') {
          return (
            <>
              <Tooltip
                title={Category}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                RGP {/* {Category} */}
              </Tooltip>
            </>
          );
        } else if (Category === 'Non-returnable Gate Pass (NRGP)') {
          return (
            <>
              <Tooltip
                title={Category}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                NRGP {/* {Category} */}
              </Tooltip>
            </>
          );
        }
      }
    },
    {
      title: 'Date',
      dataIndex: 'date_of_generated',
      key: 'date_of_generated',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`date_of_generated`]).unix() -
        moment(b[`date_of_generated`]).unix(),
      render: (date_of_generated) => (
        <>
          <Tooltip
            title={
              date_of_generated === null
                ? 'Not generated'
                : moment(date_of_generated).format('DD MMM YYYY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {date_of_generated === null
              ? 'Not generated'
              : moment(date_of_generated).format('DD MMM YYYY')}
          </Tooltip>
        </>
      )
    },
    {
      title: (
        <Tooltip
          title={'Cancelled Date'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Cancelled Date
        </Tooltip>
      ),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`updatedAt`]).unix() - moment(b[`updatedAt`]).unix(),
      render: (updatedAt, record) => (
        <>
          {record?.status === 'Cancelled' ? (
            <Tooltip
              title={
                updatedAt === null
                  ? 'NA'
                  : moment(updatedAt).format('DD MMM YYYY')
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {updatedAt === null
                ? 'NA'
                : moment(updatedAt).format('DD MMM YYYY')}
            </Tooltip>
          ) : (
            'NA'
          )}
        </>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '170px',
      ellipsis: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (text, record) => (
        <Badge
          className={
            record?.status === 'Rejected'
              ? `${styles.badgeRejStyles}`
              : record.status === 'Cancelled'
              ? `${styles.badgeCanStyles}`
              : record.status === 'Requested'
              ? `${styles.badgeReqStyles}`
              : record.status === 'Generated'
              ? `${styles.badgeGpGenStyles}`
              : record.status === 'Partially received'
              ? `${styles.badgePartRecivStyles}`
              : record.status === 'Printed'
              ? `${styles.badgeGpPriStyles}`
              : record.status === 'Closed'
              ? `${styles.badgeCloseStyles}`
              : `${styles.badgeStyles}`
          }
          color={
            record?.status === 'Rejected' || record?.status === 'Rejected'
              ? 'red'
              : record?.status === 'Requested'
              ? 'blue'
              : record?.status === 'Cancelled'
              ? '#820b03'
              : record?.status === 'Generated'
              ? ' #059720'
              : record?.status === 'Partially received'
              ? '#C11574'
              : record?.status === 'Printed'
              ? '#344054'
              : record?.status === 'Closed'
              ? '#18068b'
              : 'green'
          }
          text={
            record?.status === 'Rejected'
              ? record?.status?.charAt(0).toUpperCase() +
                record?.status?.slice(1)
              : record?.status?.charAt(0).toUpperCase() +
                record?.status?.slice(1)
          }
        />
      )
    },

    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (record) => (
        <Space size="middle">
          {record.status === 'Requested' || record.status === 'Rejected' ? (
            <>
              <Popconfirm
                title="Are you sure do you want to cancel this request?"
                visible={visibility(record)}
                onConfirm={() => {
                  handleDeleteRequest({
                    id: record.id
                  });
                }}
                onCancel={() => {
                  setShowDisablePopup({});
                }}
                okText="Cancel"
                cancelText="No"
                okButtonProps={{ type: 'danger' }}
              >
                <DeleteFilled
                  className={`${styles.delete_icon}`}
                  onClick={() => {
                    setShowDisablePopup({ [record.id]: true });
                  }}
                />
                {/*  <Button className={`${styles.delete_icon}`}>
                  {' '}
                  <Image
                    className="cursor-pointer"
                    alt="users_delete"
                    src={deleteIcon}
                    preview={false}
                    onClick={() => {
                      setShowDisablePopup({ [record.id]: true });
                    }}
                  />
                </Button> */}
              </Popconfirm>
              <a href={`edit-requestedGP/${record.id}`}>
                <EditFilled
                  className={`${styles.edit_icon}`}
                  onClick={() => {
                    setEditMode(true);
                    setSelectedRequest(record);
                  }}
                />
                {/* <Button className={`${styles.edit_icon}`}>
                  {' '}
                  <Image
                    alt="request_edit"
                    src={editIcon}
                    preview={false}
                    className="cursor-pointer"
                    onClick={() => {
                      setEditMode(true);
                      setSelectedRequest(record);
                    }}
                  />
                </Button> */}
              </a>
            </>
          ) : record.status === 'Draft' ? (
            <>
              <Popconfirm
                title="Are you sure do you want to delete this request?"
                visible={visibility(record)}
                onConfirm={() => {
                  handleDeleteDraft({
                    id: record.id
                  });
                }}
                onCancel={() => {
                  setShowDisablePopup({});
                }}
                okText="Delete"
                cancelText="No"
                okButtonProps={{ type: 'danger' }}
              >
                <DeleteFilled
                  className={`${styles.delete_icon}`}
                  onClick={() => {
                    setShowDisablePopup({ [record.id]: true });
                  }}
                />
                {/* <Button className={`${styles.delete_icon}`}>
                  {' '}
                  <Image
                    className="cursor-pointer"
                    alt="users_delete"
                    src={deleteIcon}
                    preview={false}
                    onClick={() => {
                      setShowDisablePopup({ [record.id]: true });
                    }}
                  />
                </Button> */}
              </Popconfirm>
              <a href={`edit-draft-request/${record.id}`}>
                <EditFilled
                  className={`${styles.edit_icon}`}
                  onClick={() => {
                    setEditMode(true);
                    setSelectedRequest(record);
                  }}
                />
                {/* <Button className={`${styles.edit_icon}`}>
                  {' '}
                  <Image
                    alt="request_edit"
                    src={editIcon}
                    preview={false}
                    className="cursor-pointer"
                    onClick={() => {
                      setEditMode(true);
                      setSelectedRequest(record);
                    }}
                  />
                </Button> */}
              </a>
            </>
          ) : (
            ''
          )}
        </Space>
      )
    }
  ];

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {/*  <Image alt="users_empty_state" src={emptyState} preview={false} /> */}
        <div className={styles.empty_state_text}>
          There are no gate pass requests to display
        </div>
      </span>
    )
  };

  /*  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log('selectedRowKeys>>>', selectedRowKeys);
      // setSelected(selectedRows.map(row => row.key))
      console.log('selectedRows>>>', selectedRows);
      setSelected(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },

    getCheckboxProps: (record) => ({
      disabled: selected.find((item) => item.key !== record.key),
      name: record.key
      // name: record?.id
    })
  }; */

  return (
    <>
      <div className={`mb-4 ${styles.container}`}>
        {/*  <div className={`${styles.carousel_banner}`}>
          <Carousel
            dots={false}
            //className="welcome-carousel"
            autoplay
            autoplaySpeed={3000}
            className={` ${styles.contentStyle}`}
          >
            <div>
              <h3 className={` ${styles.contentStyle2}`}>
                <div
                  style={{
                    color: '#fff',
                    fontSize: '28px',
                    marginTop: '20px',
                    marginLeft: '30px'
                  }}
                >
                  Dashboard
                  <div style={{ color: '#fff', fontSize: '18px' }}>
                    View gate pass requests and their current status
                  </div>
                </div>
              </h3>
            </div>
            <div>
              <h3 className={` ${styles.contentStyle1}`}>
                <div
                  style={{
                    color: '#fff',
                    fontSize: '28px',
                    marginTop: '20px',
                    marginLeft: '30px'
                  }}
                >
                  Gate pass requests{' '}
                  <Badge
                    count={`${pagination?.total_records || 0} requests`}
                    size="small"
                    className={`${styles.badgestyles}`}
                  />
                  <div style={{ color: '#fff', fontSize: '18px' }}>
                    Keep track of your recent gate pass requests
                  </div>
                  <div style={{ marginTop: '12%' }}>
                    <Link to="/create-gp-request">
                      <CustomButton
                        size="small"
                        icon={<PlusOutlined />}
                        className={`${styles.customButton_style_secondary}`}
                        type="primary"
                      >
                        <span>Request new gate pass</span>
                      </CustomButton>
                    </Link>
                  </div>
                </div>
              </h3>
            </div>
          </Carousel>
        </div> */}
        <div className={`${styles.page_title}`}>Dashboard</div>
        <div className={`${styles.page_sub_title}`}>
          View gate pass requests and their current status
        </div>
        <Card className={`${styles.cardStyles}`}>
          <Row
            gutter={16}
            style={{
              marginTop: '-7px',
              marginRight: '0px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <div className={`${styles.Gate_pass_req_title}`}>
                Gate pass requests{' '}
                <Badge
                  count={`${pagination?.total_records || 0} requests`}
                  size="small"
                  className={`${styles.badgestyles}`}
                />
              </div>
              <div className={`${styles.Gate_pass_req_sub_title}`}>
                Keep track of your recent gate pass requests
              </div>
            </div>
            <div>
              {/* <Link to="/create-gp-request">
                <CustomButton
                  size="small"
                  icon={<PlusOutlined />}
                  className={`${styles.customButton_style_secondary}`}
                  type="primary"
                >
                  <span>Request New Gate Pass</span>
                </CustomButton>
              </Link> */}
            </div>
          </Row>

          <Divider
            style={{ marginTop: '20px', marginBottom: '15px' }}
          ></Divider>
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Radio.Group
                value={radiofilter}
                size="small"
                className={`${styles.button_import_style}`}
              >
                {filters.map((item, index) => {
                  return (
                    <Radio.Button
                      value={item.value}
                      key={index}
                      className={`${styles.RadioButton_import_style}`}
                      onChange={() => {
                        window.localStorage.setItem(
                          'set_Radiofilter',
                          JSON?.stringify(item.value)
                        );
                        setRadiofilter(item.value);
                        setTriggerFilter(true);
                      }}
                    >
                      {item.name}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </div>
            <div>
              <SearchBar
                // onChange={(e) => handleChange(e)}
                onChange={(e) => {
                  setSearchRequest(e.target.value);
                  setTriggerFilter(true);
                }}
                className={` ${styles.searchbar}`}
                inputProps={{
                  placeholder: 'Search by gate pass request#'
                }}
              />
            </div>
          </Row>
          <div className={`${styles.table_container}`}>
            <CustomTable
              columns={columns}
              dataSource={data1}
              locale={locale}
              loading={loading}
              /*  rowSelection={rowSelection} */
              pagination={{
                current: page,
                pageSize: pageSize,
                showSizeChanger: true,
                size: 'small',
                total: pagination?.total_records || 0,
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                }
              }}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default List;
/* {date_of_generated === null
            ? 'Not generated'
            : record?.status === 'Generated'
            ? moment(date_of_generated).format('DD MMM YYYY')
            : record.status === 'Printed'
            ? moment(date_of_generated).format('DD MMM YYYY')
            : record.status === 'Partially received'
            ? moment(date_of_generated).format('DD MMM YYYY')
            : record.status === 'Cancelled'
            ? moment(date_of_generated).format('DD MMM YYYY')
            : record.status === 'Closed'
            ? moment(date_of_generated).format('DD MMM YYYY')
            : 'Not generated'} */
