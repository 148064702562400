import React from 'react'; //, { useCallback }
import styles from './index.module.less';
//import emptyState from 'assets/images/empty_state.svg';
import {
  Card,
  Row,
  Badge,
  Radio,
  // Space,
  //Popconfirm,
  //Image,
  Tooltip,
  Empty
} from 'antd';
import CustomTable from 'common/Content/CustomTable';
import moment from 'moment';
import SearchBar from '@/common/Content/searchBar';
//import printIcon from 'assets/images/print.svg';
//import { PrinterOutlined } from '@ant-design/icons';

const filtersHo = [
  {
    name: 'All',
    value: ''
  },
  {
    name: 'Requested',
    value: 'Requested'
  },
  {
    name: 'Approved',
    value: 'Approved'
  },
  {
    name: 'Rejected',
    value: 'Rejected'
  },
  {
    name: 'Generated',
    value: 'Generated'
  },

  {
    name: 'Printed',
    value: 'Printed'
  },

  {
    name: 'Partially Received',
    value: 'Partially received'
  },
  {
    name: 'Closed',
    value: 'Closed'
  },
  {
    name: 'Cancelled',
    value: 'Cancelled'
  }
];
const HoScreenPage = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  allRequests,
  setRadiofilter,
  secondpagination,
  radiofilter,
  //user,
  //handleChange,
  //showDisablePopup,
  // setShowDisablePopup,
  // handlePrintRequest,
  getAllHoLoading,
  setTriggerFilter,
  setSearchRequest
}) => {
  console.log('allRequests', allRequests);
  // const visibility = useCallback((record) => {
  //   return showDisablePopup[record.id];
  // }, showDisablePopup);
  const data1 = allRequests?.map((item) => ({
    key: item?.id,
    id: item?.id,
    ticket: item?.ticket,
    Method_type: item?.Method_type?.method_type,
    Location: item?.Location?.location_name,
    Type: item?.Type?.type,
    Category: item?.Category?.category,
    date: item?.date,
    status: item?.status,
    approver_status: item?.approver_status,
    issuer_status: item?.issuer_status,
    gp_number: item?.gp_number,
    Project_location: item?.project_location_master?.name,
    date_of_generated: item?.date_of_generated,
    updatedAt: item?.updatedAt
  }));
  const hoColumns = [
    {
      title: 'Request #',
      dataIndex: 'ticket',
      key: 'ticket',
      width: '120px',
      ellipsis: true,
      sorter: (a, b) => a?.ticket?.localeCompare(b?.ticket),
      render: (ticket, record) => {
        return (
          <>
            <Tooltip
              title={ticket}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {<a href={`view-requests/${record.id}`}>{ticket}</a>}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Gate Pass #',
      dataIndex: 'gp_number',
      key: 'gp_number',
      width: 180,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.gp_number?.localeCompare(b?.gp_number),
      render: (gp_number) => {
        return (
          <>
            <Tooltip
              title={gp_number}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {gp_number === null ? 'Not generated' : gp_number}
            </Tooltip>
          </>
        );
      }
    },

    {
      title: 'Location',
      dataIndex: 'Project_location',
      key: 'Project_location',
      ellipsis: true,
      sorter: (a, b) => a?.Project_location?.localeCompare(b?.Project_location),
      render: (Project_location) => {
        return (
          <>
            <Tooltip
              title={Project_location}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {Project_location}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      ellipsis: true,
      sorter: (a, b) => a?.Type?.localeCompare(b?.Type),
      render: (Type) => {
        return (
          <>
            <Tooltip
              title={Type}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {Type}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Category',
      dataIndex: 'Category',
      key: 'Category',
      ellipsis: true,
      sorter: (a, b) => a?.Category?.localeCompare(b?.Category),
      render: (Category) => {
        if (Category === 'Returnable Gate Pass (RGP)') {
          return (
            <>
              <Tooltip
                title={Category}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                RGP {/* {Category} */}
              </Tooltip>
            </>
          );
        } else if (Category === 'Non-returnable Gate Pass (NRGP)') {
          return (
            <>
              <Tooltip
                title={Category}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                NRGP {/* {Category} */}
              </Tooltip>
            </>
          );
        }
      }
    },

    {
      title: 'Date',
      dataIndex: 'date_of_generated',
      key: 'date_of_generated',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`date_of_generated`]).unix() -
        moment(b[`date_of_generated`]).unix(),
      render: (date_of_generated, record) => (
        <>
          {record?.status === 'Generated' ||
          record.status === 'Printed' ||
          record.status === 'Partially received' ||
          record.status === 'Cancelled' ||
          record.status === 'Closed' ? (
            <Tooltip
              title={
                date_of_generated === null
                  ? 'Not generated'
                  : moment(date_of_generated).format('DD MMM YYYY')
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {date_of_generated === null
                ? 'Not generated'
                : moment(date_of_generated).format('DD MMM YYYY')}
            </Tooltip>
          ) : (
            'Not generated'
          )}
        </>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '180px',
      ellipsis: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (text, record) => (
        <Badge
          className={
            record?.status === 'Rejected'
              ? `${styles.badgeRejStyles}`
              : record.status === 'Cancelled'
              ? `${styles.badgeCanStyles}`
              : record.status === 'Requested'
              ? `${styles.badgeReqStyles}`
              : record.status === 'Generated'
              ? `${styles.badgeGpGenStyles}`
              : record.status === 'Partially received'
              ? `${styles.badgePartRecivStyles}`
              : record.status === 'Printed'
              ? `${styles.badgeGpPriStyles}`
              : record.status === 'Closed'
              ? `${styles.badgeCloseStyles}`
              : `${styles.badgeStyles}`
          }
          color={
            record?.status === 'Rejected' || record?.status === 'Rejected'
              ? 'red'
              : record?.status === 'Cancelled'
              ? '#820b03'
              : record?.status === 'Requested'
              ? 'blue'
              : record?.status === 'Generated'
              ? ' #059720'
              : record?.status === 'Partially received'
              ? '#C11574'
              : record?.status === 'Printed'
              ? '#344054'
              : record?.status === 'Closed'
              ? '#18068b'
              : 'green'
          }
          text={
            record?.status === 'Rejected'
              ? record?.status?.charAt(0).toUpperCase() +
                record?.status?.slice(1)
              : record?.status?.charAt(0).toUpperCase() +
                record?.status?.slice(1)
          }
        />
      )
    },
    {
      title: (
        <Tooltip
          title={'Cancelled Date'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Cancelled Date
        </Tooltip>
      ),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`updatedAt`]).unix() - moment(b[`updatedAt`]).unix(),
      render: (updatedAt, record) => (
        <>
          {record?.status === 'Cancelled' ? (
            <Tooltip
              title={
                updatedAt === null
                  ? 'NA'
                  : moment(updatedAt).format('DD MMM YYYY')
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {updatedAt === null
                ? 'NA'
                : moment(updatedAt).format('DD MMM YYYY')}
            </Tooltip>
          ) : (
            'NA'
          )}
        </>
      )
    }
    /*  {
      title: 'Action',
      key: 'action',
      width: 80,
      //disabled: true,
      render: (record) => (
        <Space size="middle">
          <>
            {record?.status === 'Generated' ? (
              <PrinterOutlined disabled className={`${styles.icon_color}`} />
            ) : (
              //   <Popconfirm
              //   disabled
              //   title="Are you sure you want to print this gate pass?"
              //   visible={visibility(record)}
              //   onConfirm={() => {
              //     handlePrintRequest({
              //       id: record?.id
              //     });
              //   }}
              //   onCancel={() => {
              //     setShowDisablePopup({ [record?.id]: false });
              //   }}
              //   okText="Print"
              //   cancelText="No"
              //   okButtonProps={{ type: 'danger' }}
              // >
                
              //   <Image

              //     className={`${styles.icon_color}`}
              //     alt="users_delete"
              //     src={printIcon}
              //     preview={false}
              //     // onClick={() => {}}
              //   />
              // </Popconfirm> 
              ''
            )}
          </>
        </Space>
      )
    } */
  ];

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no gate pass requests to display
        </div>
      </span>
    )
  };
  return (
    <>
      <Card className={`${styles.cardStyles_approver}`}>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Radio.Group
              value={radiofilter}
              size="small"
              className={`${styles.button_import_style_issuer}`}
            >
              {filtersHo.map((item, index) => {
                return (
                  <Radio.Button
                    value={item.value}
                    key={index}
                    className={`${styles.RadioButton_import_style}`}
                    onChange={() => {
                      window.localStorage.setItem(
                        'set_Radiofilter',
                        JSON?.stringify(item.value)
                      );
                      setRadiofilter(item.value);
                      setTriggerFilter(true);
                    }}
                  >
                    {item.name}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </div>
          <div>
            <SearchBar
              //onChange={(e) => handleChange(e)}
              onChange={(e) => {
                setSearchRequest(e.target.value);
                setTriggerFilter(true);
              }}
              className={`w-100 ${styles.searchbar}`}
              justify="start"
              align="start"
              inputProps={{
                placeholder: 'Search by gate pass request#'
              }}
            />
          </div>
        </Row>

        <div className={`${styles.table_container}`}>
          <CustomTable
            locale={locale}
            loading={getAllHoLoading}
            columns={hoColumns}
            dataSource={data1}
            pagination={{
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              size: 'small',
              total: secondpagination?.total_records || 0,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default HoScreenPage;
