import apiClient from 'utilities/apiClient';

export const requester_sap = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.requester_sap}/${url}`, payload, true);
};
export const get_gatepass_items_sap = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.gatepass_items_sap}${url}`,
    payload,
    true
  );
};

export const edit_request = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.issuer_reject}/${url}`,
    payload,
    true
  );
};

export const requests = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.requester}/${url}`, payload, true);
};

export const create_request = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.requester}/${url}`,
    payload,
    true,
    'file'
  );
};
export const partiallyEdit_request = (payload) => {
  return apiClient.post(
    `${apiClient.Urls?.mark_received_items}`,
    payload,
    true,
    'file'
  );
};

export const partiallyEdit_expected_date = (payload) => {
  return apiClient.post(
    `${apiClient.Urls?.partiallyEdit_expected_date}`,
    payload,
    true,
    'file'
  );
};

export const create_draft = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.requester_sap}/${url}`,
    payload,
    true,
    'file'
  );
};
export const create_draft_items = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.requester_create_items}/${url}`,
    payload,
    true,
    'file'
  );
};

export const gp_history = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.gp_history}/${url}`, payload, true);
};

export const item_history_sap = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.item_history_sap}/${url}`,
    payload,
    true
  );
};

export const deleteUploadedDocs = (url, payload) => {
  return apiClient.delete(
    `${apiClient.Urls.requester_sap}/${url}`,
    payload,
    true
  );
};
