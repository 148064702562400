import CustomLayout from '@/common/Layout';
import IssuerDashboardList from './components/IssuerDashboardList';

const IssuerDashboardSAP = () => {
  return (
    <>
      <CustomLayout sider="true">
        <IssuerDashboardList />
      </CustomLayout>
    </>
  );
};

export default IssuerDashboardSAP;
