import React, { useState, useEffect } from 'react';
import { Modal, Input, Row, Col, Button, Upload, Form, Space } from 'antd';
import {
  CheckCircleOutlined,
  UploadOutlined
  //DeleteOutlined
} from '@ant-design/icons';
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import CustomButton from '@/common/CustomButton';
import ItemHistotyModal from '../itemHistotyModal';
//import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { partially_Edit_request, get_item_history } from '../../redux/slice';
//import { history } from '@/app/history';
//import { numIntStyleFormat } from 'utilities/helpers';

const { TextArea } = Input;
const PartiallyReturnItemModal = ({
  returnItemModal,
  setReturnItemModal,
  requestbyid,
  gatepassItems,
  itemID,
  handleGetGatepassItems,
  partiallyReturnLoading
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [doc, setDoc] = useState([]);
  const [itemHistotyVisible, setItemHistotyVisible] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [integerValue, setIntegerValue] = useState(0);

  const { itemHistory } = useSelector((state) => state.edit);

  useEffect(() => {
    dispatch(
      get_item_history({
        gpid: requestbyid?.id,
        item_id: itemID
      })
    );
  }, [requestbyid, gatepassItems]);

  const itemDetails = gatepassItems.find((item) => item.id === itemID);
  useEffect(() => {
    form.setFieldsValue({
      item_description: itemDetails?.item_description,
      quantity: itemDetails?.quantity,
      qty_returned: itemDetails?.qty_returned,
      item_remarks: itemDetails?.item_remarks
    });
  }, [gatepassItems]);

  const normFile = (e) => {
    setDoc(e?.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = (values) => {
    let formdata = new FormData();
    console.log('values?.item_remarks', values?.item_remarks);
    let items = {};
    items['id'] = itemID;
    items['qty_received'] = values?.qty_received;
    items['remarks'] = values?.item_remarks;

    let newItems = [items];

    formdata.append(
      'gp_id',
      requestbyid?.id === undefined ? '' : requestbyid?.id
    );

    formdata.append('items', JSON.stringify(newItems));

    for (let i in doc) {
      formdata.append('files', doc[i]?.originFileObj);
    }

    dispatch(partially_Edit_request(formdata)).then(function (response) {
      if (response) {
        setReturnItemModal(false);
        if (response?.payload?.success) {
          handleGetGatepassItems();
          // message.success(response?.payload?.msg);
        }
      }
    });
  };
  /*  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  }; */
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    // Convert the string to an integer and restrict to 2 decimal places
    const parsedValue = parseFloat(value);
    const roundedValue = isNaN(parsedValue)
      ? 0
      : parseFloat(parsedValue.toFixed(3));
    setIntegerValue(roundedValue);
  };
  return (
    <>
      <Modal
        title={
          <>
            <span>
              <CheckCircleOutlined
                style={{
                  color: '#2364ad',
                  fontSize: '20px'
                }}
              />
            </span>
            <span
              style={{
                marginLeft: '8px',
                marginRight: '4px',
                fontSize: '16px',
                fontWeight: '600'
              }}
            >
              {itemDetails?.item_description}
            </span>
            <span
              style={{
                fontSize: '16px'
              }}
            >
              ({itemDetails?.item_code})
            </span>
          </>
        }
        visible={returnItemModal}
        footer={false}
        className={`${styles.check_circle_icon}`}
        width={'980px'}
        onCancel={() => setReturnItemModal(false)}
        //bodyStyle={{ paddingTop: '-20px !important'}}
      >
        <Row
          style={{
            marginLeft: '88%'
          }}
        >
          {' '}
          <Button
            onClick={() => setItemHistotyVisible(true)}
            className={`${styles.item_history_button}`}
          >
            Item History
          </Button>
        </Row>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <div className={`${styles.grey_holder}  w-100 mt-0 mb-1`}>
            <Row
              gutter={16}
              style={{
                marginLeft: '8px',
                marginRight: '8px',
                marginBottom: '-18px'
                // marginTop: '8px'
              }}
            >
              {/*  <Col span={4}>
                <FormItem
                  label="Item code"
                  name={[name, 'item_code']}
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles} ${styles.padding_styles}`}
                >
                  <Input
                    // defaultValue={item?.item_name}
                    disabled
                    allowClear
                    placeholder="Please enter item code"
                  />
                </FormItem>
              </Col> */}
              <Col span={8}>
                <FormItem
                  label="Total Quantity"
                  //  {...restField}
                  name="quantity"
                  // initialValue={item?.quantity}
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles} ${styles.padding_styles}`}
                >
                  <Input disabled allowClear placeholder="Total Quantity" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="Returned Quantity"
                  name="qty_returned"
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.input_styles} ${styles.padding_styles}`}
                >
                  <Input disabled allowClear placeholder="Returned qty" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label="Received Quantity"
                  name="qty_received"
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    },
                    {
                      validator: async (_, value) => {
                        console.log('Input_value', value);

                        // const parsedInputValue = parseFloat(value);
                        // const roundedInputValue = isNaN(parsedInputValue)
                        //   ? 0
                        //   : parseFloat(parsedInputValue?.toFixed(2));
                        // console.log('roundedInputValue', roundedInputValue);

                        const qty =
                          itemDetails?.quantity - itemDetails?.qty_returned;

                        const parsedValue = parseFloat(qty);
                        const roundedValue = isNaN(parsedValue)
                          ? 0
                          : parseFloat(parsedValue).toFixed(3);

                        // console.log('qty', qty);
                        console.log('roundedValue', roundedValue);
                        // console.log('integerValue', integerValue);

                        if (
                          roundedValue >= integerValue
                          // parseFloat(numIntStyleFormat(qty)) >= value
                          //  &&
                          // value === value.replace(/[^0-9]/g, '')
                        ) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            `Received Quantity is less-than or equal to ${roundedValue}`
                          );
                        }
                      }
                    }
                  ]}
                  className={`${styles.input_styles} ${styles.padding_styles}`}
                >
                  <Input
                    type="number"
                    step={0.001}
                    min={0}
                    onKeyPress={preventMinus}
                    disabled={
                      itemDetails?.quantity === itemDetails?.qty_returned
                    }
                    allowClear
                    placeholder="Please enter quantity for Return"
                    onChange={handleInputChange}
                    value={inputValue}
                  />
                </FormItem>
              </Col>
              {/* <Col span={6}>
                <FormItem
                  label="Item description"
                  // {...restField}
                  name="item_description"
                  // initialValue={ item?.item_description}
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.textarea_styles} ${styles.padding_styles}`}
                >
                  <TextArea
                    // defaultValue={item?.item_description}
                    disabled
                    allowClear
                    placeholder="Enter item description"
                    size="small"
                  />
                </FormItem>
              </Col> */}
            </Row>
            <Row
              gutter={16}
              style={{
                marginLeft: '8px',
                marginRight: '8px',
                marginBottom: '-8px',
                marginTop: '8px'
              }}
            >
              <Col span={16}>
                <FormItem
                  label="Item Remarks"
                  name="item_remarks"
                  // initialValue={ item?.item_description}
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                  className={`${styles.textarea_styles} ${styles.padding_styles}`}
                >
                  <TextArea
                    // defaultValue={item?.item_description}
                    // disabled
                    allowClear
                    placeholder="Enter item remarks"
                    size="small"
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  // name={[name, 'upload']}
                  name="files"
                  label="Upload document"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  className={`${styles.input_styles} ${styles.padding_styles}`}
                >
                  <Upload
                    beforeUpload={() => false}
                    customRequest={({ onChange }) =>
                      setTimeout(() => {
                        onChange('ok', null);
                      }, 0)
                    }
                    //name="logo"
                    action="false"
                  >
                    <Button
                      disabled={
                        itemDetails?.quantity === itemDetails?.qty_returned
                      }
                      icon={<UploadOutlined />}
                      className={styles.custom_file_upload2}
                    >
                      Upload
                    </Button>
                  </Upload>
                </FormItem>
              </Col>
            </Row>
          </div>
          <FormItem>
            <div style={{ float: 'right' }}>
              <Space>
                <CustomButton
                  className={`${styles.cancel_button}`}
                  size="small"
                  onClick={() => setReturnItemModal(false)}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  disabled={itemDetails?.quantity === itemDetails?.qty_returned}
                  loading={partiallyReturnLoading}
                  className={`${styles.return_button}`}
                  htmlType="submit"
                  size="small"
                >
                  Return
                </CustomButton>
              </Space>
            </div>
          </FormItem>
        </Form>
        {itemHistotyVisible ? (
          <ItemHistotyModal
            itemHistotyVisible={itemHistotyVisible}
            setItemHistotyVisible={setItemHistotyVisible}
            itemHistory={itemHistory}
          />
        ) : (
          ''
        )}
      </Modal>
    </>
  );
};

export default PartiallyReturnItemModal;
