import React from 'react';
import { Modal, Row, Timeline } from 'antd';
import moment from 'moment';
import styles from './index.module.less';

const RemarksHistotyModal = ({
  remarksHistotyVisible,
  setRemarksHistotyVisible,
  gpHistory
}) => {
  console.log('gpHistory', gpHistory);

  return (
    <>
      <Modal
        title={'Extension History : '}
        visible={remarksHistotyVisible}
        width={'620px'}
        onCancel={() => setRemarksHistotyVisible(false)}
        onOk={() => setRemarksHistotyVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ className: `${styles.ok_button_style}` }}
      >
        <Row className="pl-3 ">
          <Timeline
            style={{
              fontSize: '13x !important',
              fontFamily: 'SFProText Regular'
            }}
          >
            {gpHistory?.map((item, index) => (
              <Timeline.Item key={index} color="#1c4e9d">
                <div>
                  <span style={{ fontSize: '12px', fontWeight: 500 }}>
                    Updated on
                  </span>{' '}
                  <span style={{ marginRight: '4px' }}>
                    {moment(item?.updatedAt).format('ll')}.
                  </span>
                  <span>{item?.action_taken}</span>{' '}
                </div>
                {/* <div>
                  <span style={{ fontSize: '12px', fontWeight: 500 }}>
                    Extended date:
                  </span>{' '}
                  <span style={{ fontSize: '12px' }}>{item?.action_taken}</span>{' '}
                </div> */}
                <div>
                  {/*  <span style={{ fontSize: '12px', fontWeight: 500 }}>
                    Remarks:
                  </span>{' '} */}
                  <span style={{ fontSize: '11px' }}>{item?.comments}</span>{' '}
                </div>
                {/* <a href={item?.fileUrl} target="_blank" rel="noreferrer">
                  {item?.originalName}
                </a> */}
              </Timeline.Item>
            ))}
          </Timeline>
        </Row>
      </Modal>
    </>
  );
};

export default RemarksHistotyModal;
