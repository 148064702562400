import React from 'react';
import { message, Modal, Typography, Button } from 'antd';
//import { CheckCircleOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import { Form, Space } from 'antd';
//import CustomButton from '@/common/CustomButton';
import { useParams } from 'react-router-dom';
import { approveRequest, submitRequest } from '../../redux/slice';
import { useDispatch } from 'react-redux';
import { history } from '@/app/history';
import { getLoggedInUser } from 'utilities/helpers';

const ApproveModal = ({ approveModal, setApproveModal }) => {
  const params = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const user = getLoggedInUser();

  const onFinish = () => {
    const payload = {
      id: params.id
    };
    {
      user?.user_details?.roles[0] === 'approver'
        ? dispatch(approveRequest(payload)).then(function (response) {
            if (response) {
              setApproveModal(false);
              if (response?.payload?.success) {
                message.success(response?.payload?.msg);
                history.push('/gatepass-requests');
              }
            }
          })
        : dispatch(submitRequest(payload)).then(function (response) {
            if (response) {
              setApproveModal(false);
              if (response?.payload?.success) {
                message.success(response?.payload?.msg);
                history.push('/gatepass-requests');
              }
            }
          });
    }
  };

  return (
    <>
      <Modal
        title={<span>Please confirm</span>}
        visible={approveModal}
        footer={false}
        className={`${styles.check_circle_icon}`}
        width={'420px'}
        onCancel={() => setApproveModal(false)}
      >
        {/* <h5>Reason for rejection</h5> */}
        <Typography
          style={{
            fontSize: '12px',
            marginTop: '-6px',
            fontFamily: 'SFProText Regular'
          }}
        >
          Are you sure you want to approve this Gate Pass? This action cannot be
          undone.
        </Typography>
        <br />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <FormItem>
            <Space>
              <Button
                className={`${styles.approve_modal_button}`}
                htmlType="submit"
                size="small"
              >
                Approve
              </Button>
              <Button
                className={`${styles.import_style_cancel}`}
                size="small"
                onClick={() => setApproveModal(false)}
              >
                No
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default ApproveModal;
