import React, { useState, useEffect } from 'react';
import ApproverListPage from '../approverListPage';
import styles from './index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllRequests
  //searchvalues
  // searchvaluesIssuer
} from '../../redux/slice';
import {
  deleteRequest_sap,
  deleteDraft_sap
} from '../../../../Requesters/dashboard_SAP/redux/slice';
import { getLoggedInUser } from 'utilities/helpers';
//import { useReactToPrint } from 'react-to-print';
//import print from 'print-js'; //yarn add print-js
//import printJS from 'print-js'; //yarn add print-js
import { Badge, Row } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import CustomButton from 'common/CustomButton';

const ApproverList = () => {
  const {
    allRequests,
    secondpagination,
    getAllApproverLoading,
    url
    //allRequestsIssuer
  } = useSelector((state) => state.approver_sap);

  const [page, setPage] = useState(1);
  //const [secondpage, setSecondPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter')) === null
      ? ''
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter'))
  );
  //const [searchvalues, setSearchvalues] = useState('');
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [searchRequest, setSearchRequest] = useState('');

  const user = getLoggedInUser();
  // console.log('url>>>>', url);

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllRequests();
  }, [page, pageSize]);

  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        handleGetAllRequests();
      } else {
        setPage(1);
      }
    }
  }, [radiofilter, searchRequest]);

  const handleGetAllRequests = () => {
    let payload = {
      page: page,
      per_page: pageSize,
      status: radiofilter,
      search: searchRequest
    };

    dispatch(getAllRequests(payload));
  };

  /*  const handleChange = (e) => {
    const value = e.target.value;
    setSearchvalues(value);
  }; */
  /*  const handleChange = (e) => {
    const value = {
      ticket: e.target.value,
      page: page,
      status: radiofilter
    };
    dispatch(searchvalues(value));
  }; */

  const handleDeleteRequest = (payload) => {
    const id = { gpid: payload.id };
    dispatch(deleteRequest_sap(id)).then(function (response) {
      if (response) {
        handleGetAllRequests();
      }
    });
  };

  const handleDeleteDraft = (payload) => {
    const id = { gpid: payload.id };
    dispatch(deleteDraft_sap(id)).then(function (response) {
      if (response) {
        handleGetAllRequests();
      }
    });
  };

  /* const handlePrintRequest = (payload) => {
    const id = { gpid: payload.id };
    dispatch(printRequest(id)).then(function (response) {
      if (response) {
        console.log('printJS..', response?.payload?.url);
        printJS({
          printable: response?.payload?.url,
          type: 'pdf',
          onError: function (error) {
            alert('Error found => ' + error.message);
          }
        });
        // handlePrint();
        handleGetAllRequests();
      }
    });
  }; */

  return (
    <>
      <div className={`mb-4 ${styles.container}`}>
        {user?.user_details?.roles[0] === 'approver' ? (
          <>
            <div className={`${styles.page_title}`}>
              {' '}
              Gate pass requests{' '}
              <Badge
                count={`${secondpagination?.total_records || 0} requests`}
                size="small"
                className={`${styles.badgestyles}`}
              />
            </div>

            <div className={`${styles.page_sub_title}`}>
              Review and approve on incoming gate pass requests
            </div>
          </>
        ) : (
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <div className={`${styles.page_title}`}>
                {' '}
                Gate pass requests{' '}
                <Badge
                  count={`${secondpagination?.total_records || 0} requests`}
                  size="small"
                  className={`${styles.badgestyles}`}
                />
              </div>
              <div className={`${styles.page_sub_title}`}>
                Review gate pass requests, generate gate passes and print them
              </div>
            </div>
            <div>
              <Link to="/create-gp-request">
                <CustomButton
                  size="small"
                  icon={<PlusOutlined />}
                  className={`${styles.customButton_style_secondary}`}
                  type="primary"
                >
                  <span>Request New Gate Pass</span>
                </CustomButton>
              </Link>
            </div>
          </Row>
        )}

        <ApproverListPage
          setRadiofilter={setRadiofilter}
          allRequests={allRequests}
          // allRequestsIssuer={allRequestsIssuer}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          secondpagination={secondpagination}
          radiofilter={radiofilter}
          user={user}
          //handleChange={handleChange}
          //handlePrintRequest={handlePrintRequest}
          showDisablePopup={showDisablePopup}
          setShowDisablePopup={setShowDisablePopup}
          url={url}
          loading={getAllApproverLoading}
          setTriggerFilter={setTriggerFilter}
          setSelectedRequest={setSelectedRequest}
          selectedRequest={selectedRequest}
          handleDeleteRequest={handleDeleteRequest}
          handleDeleteDraft={handleDeleteDraft}
          setSearchRequest={setSearchRequest}
        />
      </div>
    </>
  );
};

export default ApproverList;
