import React, { useState, useEffect } from 'react';
import CustomLayout from '@/common/Layout';
import Viewdetails_IssuerAsRequester from './components/viewdetails_IssuerAsRequester';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestById } from '../../../viewRequest_SAP/redux/slice'; //'../viewRequest/redux/slice';
import { useParams } from 'react-router-dom';
import { getGatepassItems } from '../Edit_SAP/redux/slice';
('../editGP/redux/slice');

const ViewRequest_IssuerAsRequester_SAP = () => {
  const { requester_data, getRequestByIdLoading } = useSelector(
    (state) => state.view_sap
  );
  const { gatepassItems, itemsPagination } = useSelector(
    (state) => state.edit_sap
  );

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const params = useParams();
  useEffect(() => {
    handleGetRequestById();
  }, []);
  const handleGetRequestById = () => {
    const payload = { gpid: params.id };
    dispatch(getRequestById(payload));
  };

  useEffect(() => {
    dispatch(
      getGatepassItems({
        gpid: parseInt(params.id),
        page: page,
        per_page: pageSize
      })
    );
  }, [page]);

  return (
    <>
      <CustomLayout sider="true">
        <Viewdetails_IssuerAsRequester
          requester_data={requester_data}
          gatepassItems={gatepassItems}
          getRequestByIdLoading={getRequestByIdLoading}
          itemsPagination={itemsPagination}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </CustomLayout>
    </>
  );
};

export default ViewRequest_IssuerAsRequester_SAP;
