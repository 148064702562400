import React, { useState, useEffect } from 'react';
import IssuerDashboardListPage from '../IssuerDashboardListPage';
import styles from './index.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { getAllIssuerRequests, printRequest } from '../../redux/slice';
import {
  deleteRequest_sap,
  deleteDraft_sap
} from '../../../Requesters/dashboard_SAP/redux/slice';
import { getLoggedInUser } from 'utilities/helpers';

import printJS from 'print-js'; //yarn add print-js
import { Badge } from 'antd';

const IssuerDashboardList = () => {
  const {
    allRequests,
    secondpagination,
    getAllApproverLoading,
    url,
    allRequestsIssuer
  } = useSelector((state) => state.issuer_sap);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter')) === null
      ? ''
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter'))
  );
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [searchRequest, setSearchRequest] = useState('');

  const user = getLoggedInUser();

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllRequests();
  }, [page, pageSize]);

  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        handleGetAllRequests();
      } else {
        setPage(1);
      }
    }
  }, [radiofilter, searchRequest]);

  const handleGetAllRequests = () => {
    let payload = {
      page: page,
      per_page: pageSize,
      status: radiofilter,
      search: searchRequest
    };

    dispatch(getAllIssuerRequests(payload));
  };

  const handleDeleteRequest = (payload) => {
    const id = { gpid: payload.id };
    dispatch(deleteRequest_sap(id)).then(function (response) {
      if (response) {
        handleGetAllRequests();
      }
    });
  };

  const handleDeleteDraft = (payload) => {
    const id = { gpid: payload.id };
    dispatch(deleteDraft_sap(id)).then(function (response) {
      if (response) {
        handleGetAllRequests();
      }
    });
  };

  const handlePrintRequest = (payload) => {
    const id = { gpid: payload.id };
    dispatch(printRequest(id)).then(function (response) {
      if (response) {
        console.log('printJS..', response?.payload?.url);
        printJS({
          printable: response?.payload?.url,
          type: 'pdf',
          onError: function (error) {
            alert('Error found => ' + error.message);
          }
        });
        handleGetAllRequests();
      }
    });
  };
  return (
    <>
      <div className={`mb-4 ${styles.container}`}>
        <>
          <div className={`${styles.page_title}`}>
            {' '}
            Gate pass requests{' '}
            <Badge
              count={`${secondpagination?.total_records || 0} requests`}
              size="small"
              className={`${styles.badgestyles}`}
            />
          </div>

          <div className={`${styles.page_sub_title}`}>
            Review and approve on incoming gate pass requests
          </div>
        </>

        <IssuerDashboardListPage
          setRadiofilter={setRadiofilter}
          allRequests={allRequests}
          allRequestsIssuer={allRequestsIssuer}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          secondpagination={secondpagination}
          radiofilter={radiofilter}
          user={user}
          handlePrintRequest={handlePrintRequest}
          showDisablePopup={showDisablePopup}
          setShowDisablePopup={setShowDisablePopup}
          url={url}
          loading={getAllApproverLoading}
          setTriggerFilter={setTriggerFilter}
          setSelectedRequest={setSelectedRequest}
          selectedRequest={selectedRequest}
          handleDeleteRequest={handleDeleteRequest}
          handleDeleteDraft={handleDeleteDraft}
          setSearchRequest={setSearchRequest}
        />
      </div>
    </>
  );
};

export default IssuerDashboardList;
