import React, { useState, useEffect } from 'react';
import AdminListPage from './AdminListPage';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllPlants,
  getAllCompanies,
  getAllUsers,
  getAllLocations
} from '../redux/slice';

const AdminPage = () => {
  window.localStorage.setItem('set_Radiofilter', JSON?.stringify(''));

  const {
    allPlants,
    pagination,
    getAllPlantsLoading,
    getAllCompaniesLoading,
    allCompanies,
    getAllUsersLoading,
    allUsers,
    getAllLocationsLoading,
    allLocations
  } = useSelector((state) => state.admin);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter_Admin')) === null
      ? 'Plants'
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter_Admin'))
  );
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [showDateranga, setShowDateranga] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [searchRequest, setSearchRequest] = useState('');
  //const [buttonName, setButtonName] = useState('Plants');
  const [isPlantModalOpen, setIsPlantModalOpen] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  // console.log('radiofilter>>>', radiofilter);

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetRequests();
  }, [page, pageSize]);

  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        handleGetRequests();
      } else {
        setPage(1);
      }
    }
  }, [radiofilter, searchRequest]);

  const handleGetRequests = () => {
    let payload = {
      page,
      per_page: pageSize,
      search: searchRequest
    };
    if (radiofilter === 'Plants') {
      dispatch(getAllPlants(payload));
    } else if (radiofilter === 'Companies') {
      dispatch(getAllCompanies(payload));
    } else if (radiofilter === 'Users') {
      dispatch(getAllUsers(payload));
    } else if (radiofilter === 'Locations') {
      dispatch(getAllLocations(payload));
    }
  };

  return (
    <>
      <AdminListPage
        pagination={pagination}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setSelectedRequest={setSelectedRequest}
        selectedRequest={selectedRequest}
        radiofilter={radiofilter}
        setRadiofilter={setRadiofilter}
        showDisablePopup={showDisablePopup}
        setShowDisablePopup={setShowDisablePopup}
        // handleChange={handleChange}
        setShowDateranga={setShowDateranga}
        showDateranga={showDateranga}
        setTriggerFilter={setTriggerFilter}
        setSearchRequest={setSearchRequest}
        // buttonName={buttonName}
        // setButtonName={setButtonName}
        setIsPlantModalOpen={setIsPlantModalOpen}
        isPlantModalOpen={isPlantModalOpen}
        setIsLocationModalOpen={setIsLocationModalOpen}
        isLocationModalOpen={isLocationModalOpen}
        isCompanyModalOpen={isCompanyModalOpen}
        setIsCompanyModalOpen={setIsCompanyModalOpen}
        isUserModalOpen={isUserModalOpen}
        setIsUserModalOpen={setIsUserModalOpen}
        getAllPlantsLoading={getAllPlantsLoading}
        allPlants={allPlants}
        allCompanies={allCompanies}
        getAllCompaniesLoading={getAllCompaniesLoading}
        allUsers={allUsers}
        getAllUsersLoading={getAllUsersLoading}
        allLocations={allLocations}
        getAllLocationsLoading={getAllLocationsLoading}
        handleGetRequests={handleGetRequests}
        setEditMode={setEditMode}
        editMode={editMode}
      />
    </>
  );
};

export default AdminPage;
