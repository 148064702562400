import React from 'react';
import { message, Modal, Typography } from 'antd';
//import { StopOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import { Form, Input, Space } from 'antd';
import CustomButton from '@/common/CustomButton';
import { useParams } from 'react-router-dom';
import { rejectRequest } from '../../redux/slice';
import { useDispatch } from 'react-redux';
import { history } from '@/app/history';

const { TextArea } = Input;

const RejectModal = ({ rejectModal, setRejectModal }) => {
  const [form] = Form.useForm();
  const params = useParams();

  const dispatch = useDispatch();

  const onFinish = (values) => {
    const payload = {
      id: params.id,
      values: values
    };
    dispatch(rejectRequest(payload)).then(function (response) {
      if (response) {
        console.log(response);
        setRejectModal(false);
        if (response?.payload?.success) {
          message.success(response?.payload?.msg);
          history.push('/gatepass-requests');
        }
      }
    });
  };

  return (
    <>
      <Modal
        title={'Reject GP'}
        visible={rejectModal}
        footer={false}
        className={`${styles.not_allowed_icon}`}
        width={'320px'}
        onCancel={() => setRejectModal(false)}
      >
        <h5>Reason for rejection</h5>
        <Typography style={{ fontSize: '11px', marginTop: '-6px' }}>
          Are you sure you want to reject this GP? This action can not be
          undone.
        </Typography>
        <br />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <FormItem
            label="Enter the reason"
            name="reason"
            rules={[
              {
                required: true,
                message: 'Required field'
              }
            ]}
            className={`${styles.select_styles}`}
          >
            <TextArea allowClear placeholder="Enter reason" />
          </FormItem>
          <FormItem>
            <Space>
              <CustomButton
                className={`${styles.import_style_cancel}`}
                size="small"
                onClick={() => setRejectModal(false)}
              >
                No
              </CustomButton>
              <CustomButton
                className={`${styles.import_style_reject}`}
                htmlType="submit"
                size="small"
              >
                Reject
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default RejectModal;
