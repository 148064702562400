import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  requests_sap,
  //requests,
  create_request,
  create_draft,
  create_draft_items,
  erp_Document
} from './api';

const initialState = {
  loading: false,
  getAllPlantsLoading: false,
  plants: [],
  plantLocations: [],
  methods_sap: [],

  //--------------------------------
  createDraftLoading: false,
  getERP_DocumentLoading: false,
  project: [],
  projectLocations: [],
  types: [],
  categories: [],
  methods: [],
  referencedocs: [],
  docs: [],
  materials: [],
  pagination: [],
  reasons: [],
  approvers: [],
  items: [],
  vendors: [],
  vendorLocation: [],
  issuers: [],
  erp_DocumentList: [],
  isTheDocumentExist: '',
  vendorLocationAddressList: [],
  plants_forSAP: []
};

const actions = {
  GET_ALL_PLANTS: 'getAllPlants/GET_ALL_PLANTS',
  GET_ALL_PLANT_LOCATIONS: 'getAllPlantLocations/GET_ALL_PLANT_LOCATIONS',
  GET_ALL_METHODS_SAP: 'getAllmethodsSAP/GET_ALL_METHODS_SAP',

  GET_ALL_PROEJCT_LOCATIONS: 'getAllProjectLocations/GET_ALL_PROEJCT_LOCATIONS',
  GET_ALL_PROEJCT: 'getAllProject/GET_ALL_PROEJCT',
  GET_ALL_METHODS: 'getAllmethods/GET_ALL_METHODS',

  GET_ALL_TYPES: 'getAlltypes/GET_ALL_TYPES',
  GET_ALL_CATEGORIES: 'getAllcategories/GET_ALL_CATEGORIES',
  GET_ALL_REFERENCE_DOCS: 'getAllreferenceDocs/GET_ALL_REFERENCE_DOCS',
  GET_ALL_MATERIALS: 'getAllmaterils/GET_ALL_MATERIALS',
  CREATE_REQUEST: 'createRequest/CREATE_REQUEST',
  GET_ALL_REASONS: 'getAllreasontosend/GET_ALL_REASONS',
  GET_ALL_APPROVERS: 'getAllapprovers/GET_ALL_APPROVERS',
  // GET_ALL_ITEMS: 'getAllItems/GET_ALL_ITEMS',
  GET_ALL_VENDORS: 'getAllVendors/GET_ALL_VENDORS',
  GET_ALL_VENDOR_LOCATION: 'getAllVendorLocation/GET_ALL_VENDOR_LOCATION',
  GET_ALL_ISSUERS: 'getAllissuers/GET_ALL_ISSUERS',
  CREATE_DRAFT_UPLOAD: 'createDraftUpload/CREATE_DRAFT_UPLOAD',
  CREATE_DRAFT_ITEMS: 'createDraftItems/CREATE_DRAFT_ITEMS',
  GET_ERP_DOCUMENT: 'getERP_Document/GET_ERP_DOCUMENT',
  GET_CHECK_DOCUMENT_NUMBER:
    'get_Check_DocumentNumber/GET_CHECK_DOCUMENT_NUMBER',
  GET_VENDOR_LOCATION_ADDRESSES:
    'vendorLocationAddresses/GET_VENDOR_LOCATION_ADDRESSES',
  GET_ALL_PLANTS_SAP: 'getAllPlants_forSAP/GET_ALL_PLANTS_SAP'
};
//'https://gp-api.lexyslabs.com/api-v2/vendor-location-addresses?vendorSiteId=58663&page=1&per_page=10'
export const vendorLocationAddresses = createAsyncThunk(
  actions.GET_VENDOR_LOCATION_ADDRESSES,
  async (payload) => {
    const response = await requests_sap(`vendor-location-addresses`, payload);
    return response;
  }
);

export const getAllPlants = createAsyncThunk(
  actions.GET_ALL_PLANTS,
  async (payload) => {
    const response = await requests_sap(`plants`, payload);
    return response;
  }
);
export const getAllPlants_forSAP = createAsyncThunk(
  actions.GET_ALL_PLANTS_SAP,
  async (payload) => {
    const response = await requests_sap(`plants`, payload);
    return response;
  }
);
export const getAllPlantLocations = createAsyncThunk(
  actions.GET_ALL_PLANT_LOCATIONS,
  async (payload) => {
    const response = await requests_sap(`storage-locations`, payload);
    return response;
  }
);
export const getAllmethodsSAP = createAsyncThunk(
  actions.GET_ALL_METHODS_SAP,
  async (payload) => {
    const response = await requests_sap(`method-type`, payload);
    return response;
  }
);
//'https://gatepass-api.tataprojects.com/api-v2/check-document-number-existence?SapDocumentMasterId=1&document_number=12345&SapStorageLocationId=5'
export const get_Check_DocumentNumber = createAsyncThunk(
  actions.GET_CHECK_DOCUMENT_NUMBER,
  async (payload) => {
    const response = await requests_sap(
      `check-document-number-existence`,
      payload
    );
    return response;
  }
);

export const getAllProject = createAsyncThunk(
  actions.GET_ALL_PROEJCT,
  async (payload) => {
    const response = await requests_sap(`project`, payload);
    return response;
  }
);
export const getAllProjectLocations = createAsyncThunk(
  actions.GET_ALL_PROEJCT_LOCATIONS,
  async (payload) => {
    const response = await requests_sap(`project-locations`, payload);
    return response;
  }
);

export const getAlltypes = createAsyncThunk(
  actions.GET_ALL_TYPES,
  async (payload) => {
    const response = await requests_sap(`type`, payload);
    return response;
  }
);
export const getAllcategories = createAsyncThunk(
  actions.GET_ALL_CATEGORIES,
  async (payload) => {
    const response = await requests_sap(`category`, payload);
    return response;
  }
);
export const getAllreferenceDocs = createAsyncThunk(
  actions.GET_ALL_REFERENCE_DOCS,
  async (payload) => {
    const response = await requests_sap(`document`, payload);
    return response;
  }
);
export const getAllmethods = createAsyncThunk(
  actions.GET_ALL_METHODS,
  async (payload) => {
    const response = await requests_sap(`method-type`, payload);
    return response;
  }
);
export const getAllmaterils = createAsyncThunk(
  actions.GET_ALL_MATERIALS,
  async (payload) => {
    const response = await requests_sap(`material-belong`, payload);
    return response;
  }
);

export const getAllreasontosend = createAsyncThunk(
  actions.GET_ALL_REASONS,
  async (payload) => {
    const response = await requests_sap(`reason-to-send`, payload);
    return response;
  }
);
// export const getAllItems = createAsyncThunk(
//   actions.GET_ALL_ITEMS,
//   async (payload) => {
//     const response = await requests_sap(`item`, payload);
//     return response;
//   }
// );
export const getAllapprovers = createAsyncThunk(
  actions.GET_ALL_APPROVERS,
  async (payload) => {
    const response = await requests_sap(`approvers`, payload);
    return response;
  }
);
export const getAllissuers = createAsyncThunk(
  actions.GET_ALL_ISSUERS,
  async (payload) => {
    const response = await requests_sap(`issuers`, payload);
    return response;
  }
);

export const createRequest = createAsyncThunk(
  actions.CREATE_REQUEST,
  async (payload) => {
    const response = await create_request(`create/gatepass`, payload);
    return response;
  }
);

export const createDraftUpload = createAsyncThunk(
  actions.CREATE_DRAFT_UPLOAD,
  async (payload) => {
    const response = await create_draft(
      `create-or-update-draft?is_submitting=${payload?.get('is_submitting')}`,
      payload
    );
    return response;
  }
);

export const createDraftItems = createAsyncThunk(
  actions.CREATE_DRAFT_ITEMS,
  async (payload) => {
    const response = await create_draft_items(
      `draft-items?gpid=${payload?.gpid}&is_submitting=${payload?.is_submitting}`,
      payload
    );
    return response;
  }
);

export const getAllVendors = createAsyncThunk(
  actions.GET_ALL_VENDORS,
  async (payload) => {
    const response = await requests_sap(`vendor`, payload);
    return response;
  }
);

export const getAllVendorLocation = createAsyncThunk(
  actions.GET_ALL_VENDOR_LOCATION,
  async (payload) => {
    const response = await requests_sap(`vendor-locations`, payload);
    return response;
  }
);
////erp_Document/VendorData
export const getERP_Document = createAsyncThunk(
  actions.GET_ERP_DOCUMENT,
  async (payload) => {
    const response = await erp_Document(`Entities-Headers`, payload);
    return response;
  }
);
export const request_sapSlice = createSlice({
  name: 'requests_sap',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(vendorLocationAddresses.pending, (state) => {
        state.vendorLocationAddressesLoading = true;
      })
      .addCase(vendorLocationAddresses.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.vendorLocationAddressesLoading = false;
        state.vendorLocationAddressList = data ? data : [];
        state.pagination = pagination || null;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(vendorLocationAddresses.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.vendorLocationAddressesLoading = false;
        message.error(msg);
      });
    builder
      .addCase(getAllPlants.pending, (state) => {
        state.getAllPlantsLoading = true;
      })
      .addCase(getAllPlants.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getAllPlantsLoading = false;
        state.plants = data ? data : [];
        state.pagination = pagination || null;
        if (!success) {
          message.error(msg);
        }
      });
    builder
      .addCase(getAllPlants_forSAP.pending, (state) => {
        state.getAllPlantsLoading = true;
      })
      .addCase(getAllPlants_forSAP.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllPlantsLoading = false;
        state.plants_forSAP = data ? data : [];
        state.pagination = pagination || null;
        if (!success) {
          //message.error(msg);
        }
      })
      .addCase(getAllPlants_forSAP.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getAllPlantsLoading = false;
        message.error(msg);
      });

    builder
      .addCase(getAllPlantLocations.pending, (state) => {
        state.getAllPlantsLoading = true;
      })
      .addCase(getAllPlantLocations.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllPlantsLoading = false;
        state.plantLocations = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllPlantLocations.rejected, (state) => {
        state.getAllPlantsLoading = false;
      });
    builder
      .addCase(getAllmethodsSAP.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllmethodsSAP.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.methods_sap = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllmethodsSAP.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    //---------------------------------------------------------------------------------------------------
    builder
      .addCase(getAllProject.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllProject.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.project = data ? data : [];
        state.pagination = pagination || null;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllProject.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getAllApproverLoading = false;
        message.error(msg);
      });

    builder
      .addCase(getAllProjectLocations.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllProjectLocations.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        //message: msg,
        state.getAllApproverLoading = false;
        state.projectLocations = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          // message.error(msg);
        }
      })
      .addCase(getAllProjectLocations.rejected, (state) => {
        //const { message: msg } = action.error;  , action

        state.getAllApproverLoading = false;

        //message.error(msg);
      });

    builder
      .addCase(getAlltypes.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAlltypes.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.types = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAlltypes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllcategories.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllcategories.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.categories = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllcategories.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllmethods.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllmethods.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.methods = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllmethods.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllreferenceDocs.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllreferenceDocs.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.docs = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllreferenceDocs.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllmaterils.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllmaterils.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.materials = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllmaterils.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllreasontosend.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllreasontosend.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.reasons = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllreasontosend.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    // builder
    //   .addCase(getAllItems.pending, (state) => {
    //     state.getAllItemsLoading = true;
    //   })
    //   .addCase(getAllItems.fulfilled, (state, action) => {
    //     const { data, pagination } = action.payload;
    //     state.getAllItemsLoading = false;
    //     state.items = data ? data : [];
    //     state.pagination = pagination || null;
    //   })
    //   .addCase(getAllItems.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.getAllItemsLoading = false;

    //     message.error(msg);
    //   });
    builder
      .addCase(getAllapprovers.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllapprovers.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.approvers = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllapprovers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllissuers.pending, (state) => {
        state.getAllIssuerLoading = true;
      })
      .addCase(getAllissuers.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.issuers = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllissuers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createRequest.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(createRequest.fulfilled, (state, action) => {
        console.log('createGP_Action', action.payload);
        const { success } = action.payload;
        state.getAllApproverLoading = false;
        if (success) {
          message.success('Gate Pass generated successfully');
        }
      })
      .addCase(createRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createDraftUpload.pending, (state) => {
        state.createDraftLoading = true;
      })
      .addCase(createDraftUpload.fulfilled, (state, action) => {
        // console.log('createGP_Action', action.payload);
        const { success } = action.payload;
        state.createDraftLoading = false;
        if (success) {
          // message.success('Gate Pass generated successfully');
        }
      })
      .addCase(createDraftUpload.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createDraftLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createDraftItems.pending, (state) => {
        state.createDraftLoading = true;
      })
      .addCase(createDraftItems.fulfilled, (state, action) => {
        // console.log('createGP_Action', action.payload);
        const { success, msg } = action.payload;
        state.createDraftLoading = false;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createDraftItems.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createDraftLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllVendors.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllVendors.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.vendors = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllVendors.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllVendorLocation.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllVendorLocation.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload; //message: msg,
        state.getAllApproverLoading = false;
        state.vendorLocation = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          // message.error(msg);
        }
      })
      .addCase(getAllVendorLocation.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getERP_Document.pending, (state) => {
        state.getERP_DocumentLoading = true;
      })
      .addCase(getERP_Document.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload; //message: msg,
        state.getERP_DocumentLoading = false;
        state.erp_DocumentList = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          // message.error(msg);
        }
      })
      .addCase(getERP_Document.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getERP_DocumentLoading = false;

        message.error(msg);
      });
    //----------------
    builder
      .addCase(get_Check_DocumentNumber.pending, (state) => {
        state.get_Check_DocumentNumberLoading = true;
      })
      .addCase(get_Check_DocumentNumber.fulfilled, (state, action) => {
        const { success, isTheDocumentExist } = action.payload; //message: msg,
        state.get_Check_DocumentNumberLoading = false;
        state.isTheDocumentExist = isTheDocumentExist;
        // state.erp_DocumentList = data ? data : [];
        // state.pagination = pagination || null;

        if (!success) {
          // message.error(msg);
        }
      })
      .addCase(get_Check_DocumentNumber.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.get_Check_DocumentNumberLoading = false;

        message.error(msg);
      });
  }
});
export default request_sapSlice.reducer;
