import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  requester_sap,
  get_gatepass_items_sap,
  item_history_sap,
  //issuer,
  edit_request,
  requests,
  create_request,
  //partiallyEdit_request,
  partiallyEdit_expected_date,
  //get_gatepass_items,
  create_draft,
  create_draft_items,
  gp_history,
  deleteUploadedDocs
} from './api';

const initialState = {
  loading: false,
  getrequestByidLoading: false,
  partiallyDataLoading: false,
  getGatepassItemsLoading: false,
  createDraftLoading: false,
  gpHistoryLoading: false,
  partiallyReturnLoading: false,
  itemHistoryLoading: false,
  deleteDocsLoading: false,
  requestbyid: [],
  projectLocations: [],
  types: [],
  categories: [],
  methods: [],
  referencedocs: [],
  docs: [],
  materials: [],
  pagination: [],
  reasons: [],
  approvers: [],
  partiallyEdit_request: [],
  partially_expected_date: [],
  gatepassItems: [],
  itemsPagination: '',
  gpHistory: [],
  itemHistory: []
};

const actions = {
  GET_ALL_REASONS: 'getAllreasontosend/GET_ALL_REASONS',
  GET_REQUEST_BY_ID: 'getrequestByid/GET_REQUEST_BY_ID',
  GET_ALL_PROEJCT_LOCATIONS: 'getAllProjectLocations/GET_ALL_PROEJCT_LOCATIONS',
  GET_ALL_TYPES: 'getAlltypes/GET_ALL_TYPES',
  GET_ALL_CATEGORIES: 'getAllcategories/GET_ALL_CATEGORIES',
  GET_ALL_METHODS: 'getAllmethods/GET_ALL_METHODS',
  GET_ALL_REFERENCE_DOCS: 'getAllreferenceDocs/GET_ALL_REFERENCE_DOCS',
  GET_ALL_MATERIALS: 'getAllmaterils/GET_ALL_MATERIALS',
  EDIT_REQUEST: 'editRequest/EDIT_REQUEST',
  GET_ALL_APPROVERS: 'getAllapprovers/GET_ALL_APPROVERS',
  GET_REQUESTER_BY_ID: 'getrequesterdata/GET_REQUESTER_BY_ID',
  CREATE_REQUEST: 'createRequest/CREATE_REQUEST',
  //PARTIALLY_EDIT_REQUEST: 'partially_Edit_request/PARTIALLY_EDIT_REQUEST',
  PARTIALLY_DATA_EXPECTED_DATE:
    'partiallyData_expected_date/PARTIALLY_DATA_EXPECTED_DATE',
  GET_GATEPASS_ITEMS: 'getGatepassItems/GET_GATEPASS_ITEMS',
  CREATE_DRAFT_UPLOAD: 'createDraftUpload_sap/CREATE_DRAFT_UPLOAD',
  CREATE_DRAFT_ITEMS: 'createDraftItems/CREATE_DRAFT_ITEMS',
  GET_GP_HISTORY: 'get_gp_history/GET_GP_HISTORY',
  GET_ITEM_HISTORY: 'get_item_history/GET_ITEM_HISTORY',
  DELETE_UPLOADED_DOCS_SAP: 'delete_uploaded_docs_sap/DELETE_UPLOADED_DOCS_SAP'
};

export const getrequestByid = createAsyncThunk(
  actions.GET_REQUEST_BY_ID,
  async (payload) => {
    const response = await requester_sap(`gatepass/${payload}`, '');
    return response;
  }
);

export const getrequesterdata = createAsyncThunk(
  actions.GET_REQUESTER_BY_ID,
  async (payload) => {
    const response = await requester_sap(`gatepass/${payload}`, '');
    return response;
  }
);
export const getGatepassItems = createAsyncThunk(
  actions.GET_GATEPASS_ITEMS,
  async (payload) => {
    const response = await get_gatepass_items_sap(
      `?gpid=${payload?.gpid}&search=${
        payload?.search === undefined ? '' : payload?.search
      }&page=${payload?.page}&per_page=${payload?.per_page}&all=${
        payload?.all
      }`,
      ''
    ); //&all=${payload?.all}
    return response;
  }
);

export const editRequest = createAsyncThunk(
  actions.EDIT_REQUEST,
  async (payload) => {
    const response = await edit_request(`edit?gpid=${payload.id}`, payload);
    return response;
  }
);

// export const partially_Edit_request = createAsyncThunk(
//   actions.PARTIALLY_EDIT_REQUEST,
//   async (payload) => {
//     const response = await partiallyEdit_request(payload);
//     return response;
//   }
// );

export const partiallyData_expected_date = createAsyncThunk(
  actions.PARTIALLY_DATA_EXPECTED_DATE,
  async (payload) => {
    const response = await partiallyEdit_expected_date(payload);
    return response;
  }
);

export const getAllProjectLocations = createAsyncThunk(
  actions.GET_ALL_PROEJCT_LOCATIONS,
  async (payload) => {
    const response = await requests(`project-locations`, payload);
    return response;
  }
);

export const getAlltypes = createAsyncThunk(
  actions.GET_ALL_TYPES,
  async (payload) => {
    const response = await requests(`type`, payload);
    return response;
  }
);
export const getAllcategories = createAsyncThunk(
  actions.GET_ALL_CATEGORIES,
  async (payload) => {
    const response = await requests(`category`, payload);
    return response;
  }
);
export const getAllreferenceDocs = createAsyncThunk(
  actions.GET_ALL_REFERENCE_DOCS,
  async (payload) => {
    const response = await requests(`document`, payload);
    return response;
  }
);
export const getAllmethods = createAsyncThunk(
  actions.GET_ALL_METHODS,
  async (payload) => {
    const response = await requests(`method-type`, payload);
    return response;
  }
);
export const getAllmaterils = createAsyncThunk(
  actions.GET_ALL_MATERIALS,
  async (payload) => {
    const response = await requests(`material-belong`, payload);
    return response;
  }
);

export const getAllreasontosend = createAsyncThunk(
  actions.GET_ALL_REASONS,
  async (payload) => {
    const response = await requests(`reason-to-send`, payload);
    return response;
  }
);

export const getAllapprovers = createAsyncThunk(
  actions.GET_ALL_APPROVERS,
  async (payload) => {
    const response = await requests(`approvers`, payload);
    return response;
  }
);

export const createRequest = createAsyncThunk(
  actions.CREATE_REQUEST,
  async (payload) => {
    const response = await create_request(
      `create/gatepass?id=${payload.get('id')}`,
      payload
    );
    return response;
  }
);

export const createDraftUpload_sap = createAsyncThunk(
  actions.CREATE_DRAFT_UPLOAD,
  async (payload) => {
    const response = await create_draft(
      `create-or-update-draft?gpid=${payload?.get(
        'gpid'
      )}&is_submitting=${payload?.get('is_submitting')}`,
      payload
    );
    return response;
  }
);
export const createDraftItems = createAsyncThunk(
  actions.CREATE_DRAFT_ITEMS,
  async (payload) => {
    const response = await create_draft_items(
      `draft-items?gpid=${payload?.gpid}&is_submitting=${payload?.is_submitting}`,
      payload
    );
    return response;
  }
);

export const get_gp_history = createAsyncThunk(
  actions.GET_GP_HISTORY,
  async (payload) => {
    const response = await gp_history(
      `${payload.gpid}?action=${payload.action}`
    );
    return response;
  }
);

export const get_item_history = createAsyncThunk(
  actions.GET_ITEM_HISTORY,
  async (payload) => {
    const response = await item_history_sap(
      `${payload.gpid}?item_id=${payload.item_id}`
    );
    return response;
  }
);

export const delete_uploaded_docs_sap = createAsyncThunk(
  actions.DELETE_UPLOADED_DOCS_SAP,
  async (payload) => {
    const response = await deleteUploadedDocs(
      `draft-docs?gpid=${payload?.gpid}`,
      payload
    );
    return response;
  }
);

export const editSAPSlice = createSlice({
  name: 'edit_sap',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getrequestByid.pending, (state) => {
        state.getrequestByidLoading = true;
      })
      .addCase(getrequestByid.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getrequestByidLoading = false;
        state.requestbyid = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          // message.error(msg);
        }
      })
      .addCase(getrequestByid.rejected, (state) => {
        //const { message: msg } = action.error;

        state.getrequestByidLoading = false;

        // message.error(msg);
      });

    builder
      .addCase(getrequesterdata.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getrequesterdata.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.requestbyid = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          //  message.error(msg);
        }
      })
      .addCase(getrequesterdata.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(editRequest.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(editRequest.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;
        if (success) {
          message.success(msg);
        }
      })
      .addCase(editRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    // builder
    //   .addCase(partially_Edit_request.pending, (state) => {
    //     state.partiallyReturnLoading = true;
    //   })
    //   .addCase(partially_Edit_request.fulfilled, (state, action) => {
    //     const { success } = action.payload;

    //     state.partiallyReturnLoading = false;
    //     if (success) {
    //       // message.success(msg);
    //     }
    //   })
    //   .addCase(partially_Edit_request.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.partiallyReturnLoading = false;

    //     message.error(msg);
    //   });

    builder
      .addCase(partiallyData_expected_date.pending, (state) => {
        state.partiallyDataLoading = true;
      })
      .addCase(partiallyData_expected_date.fulfilled, (state) => {
        //, action
        // const { success, message: msg } = action.payload;
        state.partiallyDataLoading = false;
        // if (success) {
        //  // message.success(msg);
        // }
      })
      .addCase(partiallyData_expected_date.rejected, (state) => {
        //, action
        //const { message: msg } = action.error;

        state.partiallyDataLoading = false;

        // message.error(msg);
      });

    builder
      .addCase(getAllProjectLocations.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllProjectLocations.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        //message: msg,
        state.getAllApproverLoading = false;
        state.projectLocations = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          // message.error(msg);
        }
      })
      .addCase(getAllProjectLocations.rejected, (state) => {
        //const { message: msg } = action.error;
        //, action
        state.getAllApproverLoading = false;

        // message.error(msg);
      });

    builder
      .addCase(getAlltypes.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAlltypes.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.types = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAlltypes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllcategories.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllcategories.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.categories = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllcategories.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllmethods.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllmethods.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.methods = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllmethods.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllreferenceDocs.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllreferenceDocs.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.docs = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllreferenceDocs.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllmaterils.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllmaterils.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.materials = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllmaterils.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllreasontosend.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllreasontosend.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.reasons = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllreasontosend.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllapprovers.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllapprovers.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.approvers = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllapprovers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createRequest.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(createRequest.fulfilled, (state, action) => {
        const { success } = action.payload;
        state.getAllApproverLoading = false;
        if (success) {
          // message.success(msg);
          // history.push('/dashboard');
        }
      })
      .addCase(createRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getGatepassItems.pending, (state) => {
        state.getGatepassItemsLoading = true;
      })
      .addCase(getGatepassItems.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getGatepassItemsLoading = false;
        state.gatepassItems = data ? data : [];
        state.itemsPagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getGatepassItems.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getGatepassItemsLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createDraftUpload_sap.pending, (state) => {
        state.createDraftLoading = true;
      })
      .addCase(createDraftUpload_sap.fulfilled, (state, action) => {
        const { success } = action.payload;
        state.createDraftLoading = false;
        if (success) {
          // message.success('Gate Pass generated successfully');
        }
      })
      .addCase(createDraftUpload_sap.rejected, (state) => {
        //, action
        // const { message: msg } = action.error;

        state.createDraftLoading = false;

        // message.error(msg);
      });
    builder
      .addCase(createDraftItems.pending, (state) => {
        state.createDraftLoading = true;
      })
      .addCase(createDraftItems.fulfilled, (state, action) => {
        console.log('createGP_Action', action.payload);
        const { success } = action.payload;
        state.createDraftLoading = false;
        if (success) {
          // message.success('Gate Pass generated successfully');
        }
      })
      .addCase(createDraftItems.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createDraftLoading = false;

        message.error(msg);
      });
    builder
      .addCase(get_gp_history.pending, (state) => {
        state.gpHistoryLoading = true;
      })
      .addCase(get_gp_history.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.gpHistory = data ? data : [];
        state.gpHistoryLoading = false;
      })
      .addCase(get_gp_history.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.gpHistoryLoading = false;
        message.error(msg);
      });

    builder
      .addCase(get_item_history.pending, (state) => {
        state.itemHistoryLoading = true;
      })
      .addCase(get_item_history.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.itemHistory = data ? data : [];
        state.itemHistoryLoading = false;
      })
      .addCase(get_item_history.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.itemHistoryLoading = false;
        message.error(msg);
      });

    builder
      .addCase(delete_uploaded_docs_sap.pending, (state) => {
        state.deleteDocsLoading = true;
      })
      .addCase(delete_uploaded_docs_sap.fulfilled, (state, action) => {
        const { success, msg: msg } = action.payload;
        state.deleteDocsLoading = false;
        if (success) {
          message.success(msg);
        }
      })
      .addCase(delete_uploaded_docs_sap.rejected, (state, action) => {
        const { msg: msg } = action.error;
        state.deleteDocsLoading = false;
        message.error(msg);
      });
  }
});
export default editSAPSlice.reducer;
