import React from 'react';
import styles from './index.module.less';
import { Card, Row, Badge, Radio, Tooltip, Empty } from 'antd';
import CustomTable from 'common/Content/CustomTable';
import moment from 'moment';
import SearchBar from '@/common/Content/searchBar';

const filters = [
  {
    name: 'All',
    value: ''
  },
  {
    name: 'Requested',
    value: 'Requested'
  },
  /* {
    name: 'Pending',
    value: 'Pending'
  }, */
  {
    name: 'Approved',
    value: 'Approved'
  },
  {
    name: 'Rejected',
    value: 'Rejected'
  },
  {
    name: 'Generated',
    value: 'Generated'
  },

  {
    name: 'Printed',
    value: 'Printed'
  },
  {
    name: 'Partially Received',
    value: 'Partially received'
  },
  {
    name: 'Closed',
    value: 'Closed'
  }
];

const ApproverListPage = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  allRequests,
  setRadiofilter,
  secondpagination,
  radiofilter,
  user,
  //handleChange,
  loading,
  setTriggerFilter,
  setSearchRequest
}) => {
  //console.log('allRequests', allRequests);
  //console.log('radiofilter', radiofilter);
  const tableData = allRequests?.map((item) => ({
    key: item?.id,
    id: item?.id,
    ticket: item?.ticket,
    Method_type: item?.Method_type?.method_type,
    Location: item?.Location?.location_name,
    Type: item?.Type?.type,
    Category: item?.Category?.category,
    date: item?.date,
    status: item?.status,
    approver_status: item?.approver_status,
    issuer_status: item?.issuer_status,
    gp_number: item?.gp_number,
    Project_location: item?.project_location_master?.name,
    date_of_generated: item?.date_of_generated,
    updatedAt: item?.updatedAt,
    requester_name: `${item?.requester?.first_name} (${item?.requester?.email})`
  }));
  console.log('tableData########', tableData);
  // const visibility = useCallback((record) => {
  //   return showDisablePopup[record.id];
  // }, showDisablePopup);

  const columns = [
    {
      title: (
        <Tooltip
          title={'Request #'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Request #
        </Tooltip>
      ),
      dataIndex: 'ticket',
      key: 'ticket',
      width: '100px',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => a?.ticket?.localeCompare(b?.ticket),
      render: (ticket, record) => {
        return (
          <>
            <Tooltip
              title={ticket}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {/*  {record.status === 'Requested' ? ( */}
              <a href={`view-requests/${record.id}`}>{ticket}</a>
              {/*  ) : (
                <span>{ticket}</span>
              )} */}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Requester Name'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Requester Name
        </Tooltip>
      ),
      dataIndex: 'requester_name',
      key: 'requester_name',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => a?.requester_name?.localeCompare(b?.requester_name),
      render: (requester_name) => {
        return (
          <>
            <Tooltip
              title={requester_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {requester_name ? requester_name : 'NA'}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Gate Pass #'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Gate Pass #
        </Tooltip>
      ),
      dataIndex: 'gp_number',
      key: 'gp_number',
      // width: 180,
      showSorterTooltip: false,
      sorter: (a, b) => a?.gp_number?.localeCompare(b?.gp_number),
      ellipsis: {
        showTitle: false
      },
      render: (gp_number) => {
        return (
          <>
            <Tooltip
              title={gp_number}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {gp_number === null ? 'Not generated' : gp_number}
            </Tooltip>
          </>
        );
      }
    },

    {
      title: (
        <Tooltip
          title={'Location'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Location
        </Tooltip>
      ),
      dataIndex: 'Project_location',
      key: 'Project_location',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => a?.Project_location?.localeCompare(b?.Project_location),
      render: (Project_location) => {
        return (
          <>
            <Tooltip
              title={Project_location}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {Project_location}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Type'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Type
        </Tooltip>
      ),
      dataIndex: 'Type',
      key: 'Type',
      width: 80,
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => a?.Type?.localeCompare(b?.Type),
      render: (Type) => {
        return (
          <>
            <Tooltip
              title={Type}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {Type}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title={'Category'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Category
        </Tooltip>
      ),
      dataIndex: 'Category',
      key: 'Category',
      width: 70,
      ellipsis: true,
      showSorterTooltip: false,
      filterDropdownVisible: false,
      sorter: (a, b) => a?.Category?.localeCompare(b?.Category),
      render: (Category) => {
        if (Category === 'Returnable Gate Pass (RGP)') {
          return (
            <>
              <Tooltip
                title={Category}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                RGP {/* {Category} */}
              </Tooltip>
            </>
          );
        } else if (Category === 'Non-returnable Gate Pass (NRGP)') {
          return (
            <>
              <Tooltip
                title={Category}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                NRGP {/* {Category} */}
              </Tooltip>
            </>
          );
        }
      }
    },

    {
      title: (
        <Tooltip
          title={'Date'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Date
        </Tooltip>
      ),
      dataIndex: 'date_of_generated',
      key: 'date_of_generated',
      ellipsis: true,
      width: 120,
      showSorterTooltip: false,
      sorter: (a, b) =>
        moment(a[`date_of_generated`]).unix() -
        moment(b[`date_of_generated`]).unix(),
      render: (date_of_generated) => (
        <>
          <Tooltip
            title={
              date_of_generated === null
                ? 'Not generated'
                : moment(date_of_generated).format('DD MMM YYYY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {date_of_generated === null
              ? 'Not generated'
              : moment(date_of_generated).format('DD MMM YYYY')}
          </Tooltip>
        </>
      )
    },
    {
      title: (
        <Tooltip
          title={'Status'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Status
        </Tooltip>
      ),
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      showSorterTooltip: false,
      //width: '160px',
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (text, record) => (
        <Badge
          className={
            record?.status === 'Rejected'
              ? `${styles.badgeRejStyles}`
              : record.status === 'Cancelled'
              ? `${styles.badgeCanStyles}`
              : record.status === 'Requested'
              ? `${styles.badgeReqStyles}`
              : record.status === 'Generated'
              ? `${styles.badgeGpGenStyles}`
              : record.status === 'Partially received'
              ? `${styles.badgePartRecivStyles}`
              : record.status === 'Printed'
              ? `${styles.badgeGpPriStyles}`
              : record.status === 'Closed'
              ? `${styles.badgeCloseStyles}`
              : record.status === 'Pending'
              ? `${styles.badgeRequestedStyles}`
              : `${styles.badgeStyles}`
          }
          color={
            record?.status === 'Rejected'
              ? 'red'
              : record?.status === 'Cancelled'
              ? '#820b03'
              : record?.status === 'Pending'
              ? 'blue'
              : record?.status === 'Generated'
              ? ' #059720'
              : record?.status === 'Partially received'
              ? '#C11574'
              : record?.status === 'Printed'
              ? '#344054'
              : record?.status === 'Closed'
              ? '#18068b'
              : record?.status === 'Requested'
              ? '#046f89'
              : 'green'
          }
          text={
            record?.status === 'Pending'
              ? 'Pending' //'Pending'#046f89
              : record?.status?.charAt(0).toUpperCase() +
                record?.status?.slice(1)
          }
        />
      )
    }
  ];

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {/*  <Image alt="users_empty_state" src={emptyState} preview={false} /> */}
        <div className={styles.empty_state_text}>
          There are no gate pass requests to display
        </div>
      </span>
    )
  };
  return (
    <>
      <Card className={`${styles.cardStyles_approver}`}>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Radio.Group
              value={radiofilter}
              size="small"
              className={
                user?.user_details?.roles[0] === 'approver'
                  ? `${styles.button_import_style}`
                  : `${styles.button_import_style_issuer}`
              }
            >
              {filters.map((item, index) => {
                return (
                  <Radio.Button
                    value={item.value}
                    key={index}
                    defaultValue="All"
                    className={`${styles.RadioButton_import_style}`}
                    onChange={() => {
                      window.localStorage.setItem(
                        'set_Radiofilter',
                        JSON?.stringify(item.value)
                      );
                      setRadiofilter(item.value);
                      setTriggerFilter(true);
                    }}
                  >
                    {item.name}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </div>
          <div>
            <SearchBar
              // onChange={(e) => handleChange(e)}
              onChange={(e) => {
                setSearchRequest(e.target.value);
                setTriggerFilter(true);
              }}
              className={`w-100 ${styles.searchbar}`}
              justify="start"
              align="start"
              inputProps={{
                placeholder: 'Search by gate pass request#'
              }}
            />
          </div>
        </Row>

        <div className={`${styles.table_container}`}>
          <CustomTable
            locale={locale}
            loading={loading}
            dataSource={tableData}
            columns={columns}
            pagination={{
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              size: 'small',
              total: secondpagination?.total_records || 0,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default ApproverListPage;
