import React from 'react';
import { Modal } from 'antd';
//import { getLoggedInUser } from 'utilities/helpers';
//import { useDispatch, useSelector } from 'react-redux';

const VendorERP_Modal = ({ vendorERPModal, setVendorERPModal, SAPdata }) => {
  // const user = getLoggedInUser();
  // const { itemHistory } = useSelector((state) => state.edit);
  // const dispatch = useDispatch();
  // const [itemHistotyVisible, setItemHistotyVisible] = useState(false);
  // const [itemId, setItemId] = useState();

  return (
    <>
      <Modal
        title={'Reason for failure'}
        visible={vendorERPModal}
        width={'620px'}
        onCancel={() => setVendorERPModal(false)}
        onOk={
          () => window.location.reload()
          //setVendorERPModal(false)
        }
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div style={{ fontWeight: '600', fontSize: '14px' }}>
          {SAPdata?.map((item, index) => (
            <ul key={index}>
              <li>
                <div style={{ fontWeight: '600', fontSize: '14px' }}>
                  <sapn style={{ color: 'red', marginRight: '5px' }}>
                    SAP/TCS Error:
                  </sapn>
                  <span>{item}</span>
                </div>
              </li>
            </ul>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default VendorERP_Modal;
