import styles from './index.module.less';
import { Col, Row, Select, Input } from 'antd';
import FormItem from 'common/FormItem';
const { Option } = Select;
//const { TextArea } = Input;

export default function Vendor_IRISO_MRR({
  debounceFetcher,
  handleFormDataOnChange,
  vendors,
  formData1,
  documentID,
  erpParseItems,
  vendorAddressValue,
  vendorLocation,
  vendorERPShow
}) {
  //const vendorIRISO = [{ vendorName: erpParseItems?.vendor_code, id: 1 }];
  return (
    <>
      {(formData1?.methodType_id === 1 && documentID === 2) ||
      (formData1?.methodType_id === 1 && documentID === 3) ? (
        /* (formData1?.methodType_id === 1 &&
        documentID === 1 &&
        erpParseItems?.type_of_issue !== 'FREE') ||
      (formData1?.methodType_id === 1 &&
        documentID === 1 &&
        erpParseItems?.type_of_issue !== 'CHARGEABLE') */
        <Row gutter={16}>
          <Col span={8}>
            {documentID === 3 || documentID === 2 ? (
              <FormItem
                label={
                  documentID === 3
                    ? 'Project name and code'
                    : 'Vendor name and code'
                }
                // name="vendor_id"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={
                  documentID === 3 || documentID === 2
                    ? `${styles.input_styles}`
                    : `${styles.select_styles}`
                }
              >
                <Input
                  disabled
                  value={
                    erpParseItems?.vendor_name === undefined
                      ? ''
                      : `${erpParseItems?.vendor_name} (${erpParseItems?.vendor_code})`
                  }
                  allowClear
                  placeholder={'Project name and code'}
                  onChange={(value) =>
                    handleFormDataOnChange('vendor_id', value)
                  }
                />
              </FormItem>
            ) : (
              /*  <FormItem
                label={
                  documentID === 3
                    ? 'Project name and code'
                    : 'Vendor name and code'
                }
                name="vendor_id"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={
                  documentID === 3
                    ? `${styles.input_styles}`
                    : `${styles.select_styles}`
                }
              >
                <Select
                  //showSearch
                  allowClear={true}
                  optionFilterProp="children"
                  placeholder={
                    documentID === 3
                      ? 'Select Project Name'
                      : 'Select Vendor Name'
                  }
                  onSearch={debounceFetcher}
                  onChange={(value) =>
                    handleFormDataOnChange('vendor_id', value)
                  }
                  filterOption={(input, option) => {
                    return (
                      option?.key
                        ?.toString()
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0 ||
                      option?.title
                        ?.toString()
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    );
                  }}
                >
                  {vendors?.map((item, index) => (
                    <Option
                      title={item.vendorName}
                      key={index}
                      value={item?.id}
                    >
                      <span
                        style={{
                          fontSize: '12px',
                          textTransform: 'uppercase'
                        }}
                      >
                        <span style={{ marginRight: '4px' }}>
                          {item?.vendorName}
                        </span>
                      </span>
                    </Option>
                  ))}
                </Select>
              </FormItem> */
              ''
            )}
          </Col>
          <Col span={8}>
            <FormItem
              label="Location"
              // name="vendor_location"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
              className={`${styles.input_styles}`}
            >
              <Input
                disabled
                value={erpParseItems?.vendor_location}
                allowClear
                placeholder="Enter Location"
                onChange={(value) =>
                  handleFormDataOnChange('vendor_location', value)
                }
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label="Address"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
              className={`${styles.input_styles}`}
            >
              <Input
                disabled
                //value={vendorAddressValue}
                value={erpParseItems?.vendor_address}
                allowClear
                placeholder="Enter Address"
                onChange={(e) =>
                  handleFormDataOnChange('vendor_address', e.target.value)
                }
              />
            </FormItem>
          </Col>
        </Row>
      ) : formData1?.methodType_id === 1 &&
        documentID === 1 &&
        erpParseItems?.type_of_issue === 'CHARGEABLE' ? (
        <Row gutter={16}>
          <Col span={8}>
            <FormItem
              label={'Vendor name and code'}
              // name="vendor_id"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
              className={`${styles.input_styles}`}
            >
              <Input
                disabled
                value={
                  erpParseItems?.vendor_name === undefined ||
                  erpParseItems?.vendor_name === null
                    ? ''
                    : `${erpParseItems?.vendor_name} (${erpParseItems?.vendor_code})`
                }
                allowClear
                placeholder={'Project name and code'}
                onChange={(value) => handleFormDataOnChange('vendor_id', value)}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label="Location"
              // name="vendor_location"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
              /* className={`${styles.select_styles}`} */
              className={`${styles.input_styles}`}
            >
              <Input
                disabled
                value={
                  erpParseItems?.vendor_location === undefined ||
                  erpParseItems?.vendor_location === null
                    ? ''
                    : erpParseItems?.vendor_location
                }
                allowClear
                placeholder="Enter Location"
                onChange={(value) =>
                  handleFormDataOnChange('vendor_location', value)
                }
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label="Address"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
              className={`${styles.input_styles}`}
            >
              <Input
                disabled
                //value={vendorAddressValue}
                value={
                  erpParseItems?.vendor_address === undefined ||
                  erpParseItems?.vendor_address === null
                    ? ''
                    : erpParseItems?.vendor_address
                }
                allowClear
                placeholder="Enter Address"
                onChange={(e) =>
                  handleFormDataOnChange('vendor_address', e.target.value)
                }
              />
            </FormItem>
          </Col>
        </Row>
      ) : (
        <Row gutter={16}>
          <Col span={8}>
            <FormItem
              label={'Vendor name and code'}
              name="vendor_id"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
              className={`${styles.select_styles}`}
            >
              <Select
                // showSearch
                disabled={vendorERPShow === false}
                allowClear={true}
                optionFilterProp="children"
                placeholder="Select Vendor Name"
                onSearch={debounceFetcher}
                onChange={(value) => handleFormDataOnChange('vendor_id', value)}
                filterOption={(input, option) => {
                  return (
                    option?.key
                      ?.toString()
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0 ||
                    option?.title
                      ?.toString()
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  );
                }}
              >
                {vendors?.map((item, index) => (
                  <Option title={item.vendorName} key={index} value={item?.id}>
                    <span
                      style={{
                        fontSize: '12px',
                        textTransform: 'uppercase'
                      }}
                    >
                      <span style={{ marginRight: '4px' }}>
                        {item?.vendorName}
                      </span>
                    </span>
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label="Location"
              name="vendor_location"
              rules={[
                formData1?.vendor_id === 'other'
                  ? {
                      required: false,
                      message: 'Required field'
                    }
                  : {
                      required: true,
                      message: 'Required field'
                    }
              ]}
              className={`${styles.select_styles}`}
            >
              <Select
                disabled={vendorERPShow === false}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option?.children
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase());
                }}
                onChange={(value) =>
                  handleFormDataOnChange('vendor_location', value)
                }
                placeholder="Select Location"
              >
                {vendorLocation.map((item, index) => {
                  return (
                    <Option value={item.id} key={index}>
                      <span
                        style={{
                          fontSize: '12px',
                          textTransform: 'uppercase'
                        }}
                      >
                        {' '}
                        {item.vendorSite}, {item.city}, {item.state}
                      </span>
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label="Address"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
              className={`${styles.input_styles}`}
            >
              <Input
                disabled
                //value={vendorAddress[0]?.addressLine1}
                value={vendorAddressValue}
                allowClear
                placeholder="Enter Address"
                onChange={(e) =>
                  handleFormDataOnChange('vendor_address', e.target.value)
                }
              />
            </FormItem>
          </Col>
        </Row>
      )}
    </>
  );
}
