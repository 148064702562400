import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { approver, reject, approve, submit, generatedById } from './api';

const initialState = {
  loading: false,
  requester_data: [],
  allRequests: [],
  pagination: [],
  secondpagination: [],
  generatedGpData: []
};

const actions = {
  GET_REQUEST_BY_ID: 'getRequestById/GET_REQUEST_BY_ID',
  GET_ALL_REQUESTS: 'getAllRequests/GET_ALL_REQUESTS',
  REJECT_REQUEST: 'rejectRequest/REJECT_REQUEST',
  APPROVE_REQUEST: 'approveRequest/APPROVE_REQUEST',
  SUBMIT_REQUEST: 'submitRequest/SUBMIT_REQUEST',
  GET_GENERATED_GP_BY_ID: 'getGeneratedGpById/GET_GENERATED_GP_BY_ID'
};

export const getRequestById = createAsyncThunk(
  actions.GET_REQUEST_BY_ID,
  async (payload) => {
    const response = await approver(`byid`, payload);
    return response;
  }
);

export const getAllRequests = createAsyncThunk(
  actions.GET_ALL_REQUESTS,
  async (payload) => {
    const response = await approver(``, payload);
    return response;
  }
);

export const rejectRequest = createAsyncThunk(
  actions.REJECT_REQUEST,
  async (payload) => {
    const response = await reject(`reject/gatepass/${payload.id}`, payload);
    return response;
  }
);

export const approveRequest = createAsyncThunk(
  actions.APPROVE_REQUEST,
  async (payload) => {
    const response = await approve(`approve/gatepass/${payload.id}`, payload);
    return response;
  }
);

export const submitRequest = createAsyncThunk(
  actions.SUBMIT_REQUEST,
  async (payload) => {
    const response = await submit(`approve/${payload.id}`, payload);
    return response;
  }
);

export const getGeneratedGpById = createAsyncThunk(
  actions.GET_GENERATED_GP_BY_ID,
  async (payload) => {
    console.log('payload_slice', payload);
    const response = await generatedById(`${payload.gpid}`, payload);
    return response;
  }
);

export const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRequestById.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getRequestById.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.requester_data = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getRequestById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllRequests.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllRequests.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.allRequests = data ? data : [];
        state.secondpagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllRequests.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(rejectRequest.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(rejectRequest.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(rejectRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(approveRequest.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(approveRequest.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(approveRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(submitRequest.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(submitRequest.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.getAllApproverLoading = false;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(submitRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getGeneratedGpById.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getGeneratedGpById.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.getAllApproverLoading = false;
        state.generatedGpData = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getGeneratedGpById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
  }
});
export default viewSlice.reducer;
