import apiClient from 'utilities/apiClient';

export const issuer_sap = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.issuer_sap}/${url}`, payload, true);
};
export const edit_request_sap = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.issuer_sap}/${url}`, payload, true);
};

export const requests_sap = (url, payload) => {
  return apiClient.get(`${apiClient.Urls._sap}/${url}`, payload, true);
};

export const create_request_sap = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.requester_sap}/${url}`,
    payload,
    true,
    'file'
  );
};
export const partiallyEdit_request_sap = (payload) => {
  return apiClient.post(
    `${apiClient.Urls?.mark_received_items_sap}`,
    payload,
    true,
    'file'
  );
};

export const partiallyEdit_expected_date_sap = (payload) => {
  return apiClient.post(
    `${apiClient.Urls?.partiallyEdit_expected_date_sap}`,
    payload,
    true,
    'file'
  );
};
export const get_gatepass_items_sap = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.gatepass_items_sap}${url}`,
    payload,
    true
  );
};

export const create_draft_sap = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.issuer_sap}/${url}`,
    payload,
    true,
    'file'
  );
};
export const create_draft_items_sap = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.requester_create_items}/${url}`,
    payload,
    true,
    'file'
  );
};

export const gp_history_sap = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.gp_history_sap}/${url}`,
    payload,
    true
  );
};

export const item_history_sap = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.item_history_sap}/${url}`,
    payload,
    true
  );
};

export const deleteUploadedDocs_sap = (url, payload) => {
  return apiClient.delete(`${apiClient.Urls.issuer_sap}/${url}`, payload, true);
};
