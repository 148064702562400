import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Image, Row, Col, Select, Input } from 'antd';
//import { CheckCircleOutlined } from '@ant-design/icons';
import styles from './plantModal.module.less';
import FormItem from 'common/FormItem';
import { Form, Space } from 'antd';
import {
  postMapStorageLocation,
  getUnmappedStorageLocation
} from '../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
// import { history } from '@/app/history';
//import { getLoggedInUser } from 'utilities/helpers';
import adminModal_image from 'assets/images/adminModal_image.svg';
import { debounce } from 'lodash';

const { Option } = Select;
//const { TextArea } = Input;

const LocationMapModal = ({
  showMapLocation,
  setShowMapLocation,
  selectedRequest,
  handleGetRequests
}) => {
  //const params = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  //const user = getLoggedInUser();

  const [formData1, setFormData1] = useState({});
  const [projectSearch, setProjectSearch] = useState('');

  const { postMapStorageLocationLoading, allUnmappedStorageLocation } =
    useSelector((state) => state.admin);

  useEffect(() => {
    const payload = {
      page: 1,
      per_page: 100,
      search: projectSearch
    };
    // if (projectSearch) payload['search'] = projectSearch;
    dispatch(getUnmappedStorageLocation(payload));
  }, [projectSearch]);

  const debounceFetcherProject = useMemo(() => {
    const loadOptions = (projectSearch) => {
      setProjectSearch(projectSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    // if (slug === 'location_id') {
    //   setGstNumber(gstNumberVal[0]?.gst_number);
    //   form.setFieldsValue({
    //     vendor_location: undefined
    //   });
    // }

    setFormData1({
      ...formData1,
      [slug]: value
    });
  };

  const onFinish = () => {
    const payload = {
      storageLocationId: formData1?.location_name,
      plantId: selectedRequest?.id
    };
    dispatch(postMapStorageLocation(payload)).then(function (response) {
      if (response) {
        setShowMapLocation(false);
        if (response?.payload?.success) {
          handleGetRequests();
        }
      }
    });
  };

  return (
    <>
      <Modal
        title={
          <span>
            <Image src={adminModal_image} alt="icon" preview={false}></Image>
          </span>
        }
        visible={showMapLocation}
        footer={false}
        className={`${styles.check_circle_icon}`}
        width={'820px'}
        onCancel={() => setShowMapLocation(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Location Name"
                name="location_name"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onSearch={debounceFetcherProject}
                  onChange={(value) =>
                    handleFormDataOnChange('location_name', value)
                  }
                  placeholder="Select location name"
                >
                  {allUnmappedStorageLocation?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        <span>
                          <span className={`${styles.cell_style}`}>
                            {' '}
                            {item.name}
                          </span>{' '}
                          <span> ({item.code})</span>
                        </span>
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Plant Name"
                // name="plant_name"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  disabled
                  value={`${selectedRequest?.plant_name} (${selectedRequest?.plant_code})`}
                  placeholder="Enter plant name"
                  onChange={(e) =>
                    handleFormDataOnChange('plant_name', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <FormItem>
            <Space className={`${styles.button_container}`}>
              <Button
                className={`${styles.import_style_cancel}`}
                size="small"
                onClick={() => setShowMapLocation(false)}
              >
                Cancel
              </Button>
              <Button
                loading={postMapStorageLocationLoading}
                className={`${styles.approve_modal_button}`}
                htmlType="submit"
                size="small"
              >
                Map
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default LocationMapModal;
