import CustomLayout from '@/common/Layout';
import DraftGPRequest from './components/draftGatepassRequest';
import { getLoggedInUser } from 'utilities/helpers';
import EditGPByIssuer from './components/editGPByIssuer';
//import EditRequestedGP from './components/edit_requestedGP';

const EditRequest = () => {
  const user = getLoggedInUser();
  // console.log('user>>>>', user?.user_details?.role);
  return (
    <>
      <CustomLayout sider="true">
        {user?.user_details?.roles[0] === 'requester' ? (
          <DraftGPRequest />
        ) : user?.user_details?.roles[0] === 'issuer' ? (
          <EditGPByIssuer />
        ) : (
          ''
        )}
      </CustomLayout>
    </>
  );
};

export default EditRequest;
