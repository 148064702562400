import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Image, Row, Col, Select, Input } from 'antd';
import styles from './plantModal.module.less';
import FormItem from 'common/FormItem';
import { Form, Space } from 'antd';
import { postMapUser, getUserList } from '../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import adminModal_image from 'assets/images/adminModal_image.svg';
import { debounce } from 'lodash';

const { Option } = Select;

const UserMapModal = ({
  showMapUser,
  setShowMapUser,
  selectedRequest,
  handleGetRequests
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [formData1, setFormData1] = useState({});
  const [userSearch, setUserSearch] = useState('');

  const { postMapUserLoading, userList } = useSelector((state) => state.admin);

  useEffect(() => {
    const payload = {
      page: 1,
      per_page: 100,
      search: userSearch
    };
    //if (userSearch) payload['search'] = userSearch;
    dispatch(getUserList(payload));
  }, [userSearch]);

  const debounceFetcherUser = useMemo(() => {
    const loadOptions = (userSearch) => {
      setUserSearch(userSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);
    setFormData1({
      ...formData1,
      [slug]: value
    });
  };

  const onFinish = () => {
    const payload = {
      storageLocationId: selectedRequest?.id,
      userId: formData1?.user_name
    };
    dispatch(postMapUser(payload)).then(function (response) {
      if (response) {
        setShowMapUser(false);
        if (response?.payload?.success) {
          handleGetRequests();
        }
      }
    });
  };

  return (
    <>
      <Modal
        title={
          <span>
            <Image src={adminModal_image} alt="icon" preview={false}></Image>
          </span>
        }
        visible={showMapUser}
        footer={false}
        className={`${styles.check_circle_icon}`}
        width={'820px'}
        onCancel={() => setShowMapUser(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Requester Name "
                name="user_name"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onSearch={debounceFetcherUser}
                  onChange={(value) =>
                    handleFormDataOnChange('user_name', value)
                  }
                  placeholder="Select user name"
                >
                  {userList?.map((item, index) => {
                    return (
                      <Option value={item.id} key={index}>
                        <span>
                          <span className={`${styles.cell_style}`}>
                            {' '}
                            {`${item?.first_name}`}
                          </span>
                          <span> {` (${item?.email})`}</span>
                        </span>
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Location Name"
                // name="plant_name"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  disabled
                  value={`${selectedRequest?.location_name} (${selectedRequest?.location_code})`}
                  placeholder="Enter location name"
                  onChange={(e) =>
                    handleFormDataOnChange('location_name', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <FormItem>
            <Space className={`${styles.button_container}`}>
              <Button
                className={`${styles.import_style_cancel}`}
                size="small"
                onClick={() => setShowMapUser(false)}
              >
                Cancel
              </Button>
              <Button
                loading={postMapUserLoading}
                className={`${styles.approve_modal_button}`}
                htmlType="submit"
                size="small"
              >
                Map
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default UserMapModal;
