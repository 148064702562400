import React from 'react';
import { Modal, Typography } from 'antd'; // message,
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import { Form, Space } from 'antd';
import CustomButton from '@/common/CustomButton';
import { useParams } from 'react-router-dom';
import { hoEditGP } from '../../redux/slice';
import { useDispatch } from 'react-redux';
import { history } from '@/app/history';
//import { getLoggedInUser } from 'utilities/helpers';

const HoModal = ({ hoModal, setHoModal, requester_data }) => {
  const params = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  //const user = getLoggedInUser();

  const onFinish = () => {
    const payload = {
      id: params.id
    };
    dispatch(hoEditGP(payload)).then(function (response) {
      if (response) {
        setHoModal(false);
        if (response?.payload?.success) {
          // message.success(response?.payload?.msg);
          history.push('/ho-requests');
        }
      }
    });
    /*  {
      user?.user_details?.role === 'approver'
        ? dispatch(hoEditGP (payload)).then(function (response) {
            if (response) {
              setHoModal(false);
              if (response?.payload?.success) {
                message.success(response?.payload?.msg);
                history.push('/gatepass-requests');
              }
            }
          })
        : dispatch(submitRequest(payload)).then(function (response) {
            if (response) {
              setHoModal(false);
              if (response?.payload?.success) {
                message.success(response?.payload?.msg);
                history.push('/gatepass-requests');
              }
            }
          });
    } */
  };

  return (
    <>
      <Modal
        title={<span>#{requester_data ? requester_data?.ticket : 'NA'}</span>}
        visible={hoModal}
        footer={false}
        className={`${styles.check_circle_icon}`}
        width={'520px'}
        onCancel={() => setHoModal(false)}
      >
        {/* <h5>Reason for rejection</h5> */}
        <Typography
          style={{
            fontSize: '12px',
            marginTop: '6px',
            marginBottom: '10px',
            fontFamily: 'SFProText Regular'
          }}
        >
          Are you sure you want to update the status as Gate Pass Generated?
          This action cannot be undone.
        </Typography>
        <br />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <FormItem>
            <Space
              style={{
                display: 'flex',
                justifyContent: 'center',
                justifyItems: ' center'
              }}
            >
              <CustomButton
                className={`${styles.import_style_success_cancel}`}
                size="small"
                onClick={() => setHoModal(false)}
              >
                No
              </CustomButton>
              <CustomButton
                className={`${styles.import_style_success_ho}`}
                htmlType="submit"
                size="small"
              >
                Change to Gate Pass Generated
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default HoModal;
