import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { adminGet, adminPost } from './api';

const initialState = {
  loading: false,
  //getAllApproverLoading: false,
  pagination: [],
  getAllPlantsLoading: false,
  allPlants: [],
  getAllCompaniesLoading: false,
  allCompanies: [],
  getAllUsersLoading: false,
  allUsers: [],
  getAllLocationsLoading: false,
  allLocations: [],
  createPlantLoading: false,
  createLocationLoading: false,
  createCompanyLoading: false,
  createUserLoading: false,
  getRolesLoading: false,
  allRoles: [],
  getUnmappedStorageLocationLoading: false,
  allUnmappedStorageLocation: [],
  postMapStorageLocationLoading: false,
  postMapUserLoading: false,
  getUseListLoading: false,
  userList: [],
  getAllApproversLoading: false,
  allApprovers: [],
  getAllIssuersLoading: false,
  allIssuers: []
};

const actions = {
  //SEARCH_TICKET: 'searchvalues/SEARCH_TICKET',
  GET_ALL_PLANTS: 'getAllPlants/GET_ALL_PLANTS',
  GET_ALL_COMPANIES: 'getAllCompanies/GET_ALL_COMPANIES',
  GET_ALL_USERS: 'getAllUsers/GET_ALL_USERS',
  GET_ALL_LOCATIONS: 'getAllLocations/GET_ALL_LOCATIONs',
  POST_CREATE_PLANTS: 'createPlant/POST_CREATE_PLANTS',
  POST_CREATE_LOCATIONS: 'createLocation/POST_CREATE_LOCATIONS',
  POST_CREATE_COMPANIES: 'createCompany/POST_CREATE_COMPANIES',
  POST_CREATE_USERS: 'createUser/POST_CREATE_USERS',
  GET_ROLES: 'getRoles/GET_ROLES',
  GET_UNMAPPED_STORAGE_LOCATION:
    'getGetUnmappedStorageLocation/GET_UNMAPPED_STORAGE_LOCATION',
  POST_MAP_STORAGE_LOCATION: 'postMapStorageLocation/POST_MAP_STORAGE_LOCATION',
  POST_MAP_USER: 'postMapUser/POST_MAP_USER',
  GET_USER_LIST: 'getUserList/GET_USER_LIST',
  GET_ALL_APPROVERS: 'getAllApprovers/GET_ALL_APPROVERS',
  GET_ALL_ISSUERS: 'getAllIssuers/GET_ALL_ISSUERS'
};

export const getAllPlants = createAsyncThunk(
  actions.GET_ALL_PLANTS,
  async (payload) => {
    const response = await adminGet(`plants`, payload);
    return response;
  }
);
export const getAllCompanies = createAsyncThunk(
  actions.GET_ALL_COMPANIES,
  async (payload) => {
    const response = await adminGet(`companies`, payload);
    return response;
  }
);
export const getAllUsers = createAsyncThunk(
  actions.GET_ALL_USERS,
  async (payload) => {
    const response = await adminGet(`users`, payload);
    return response;
  }
);
export const getAllLocations = createAsyncThunk(
  actions.GET_ALL_LOCATIONS,
  async (payload) => {
    const response = await adminGet(`all-storage-locations`, payload);
    return response;
  }
);
export const createPlant = createAsyncThunk(
  actions.POST_CREATE_PLANTS,
  async (payload) => {
    const response = await adminPost(`plants`, payload);
    return response;
  }
);
export const createLocation = createAsyncThunk(
  actions.POST_CREATE_LOCATIONS,
  async (payload) => {
    const response = await adminPost(`storage-locations`, payload);
    return response;
  }
);
export const createCompany = createAsyncThunk(
  actions.POST_CREATE_COMPANIES,
  async (payload) => {
    const response = await adminPost(`companies`, payload);
    return response;
  }
);
export const createUser = createAsyncThunk(
  actions.POST_CREATE_USERS,
  async (payload) => {
    const response = await adminPost(`users`, payload);
    return response;
  }
);
export const getRoles = createAsyncThunk(actions.GET_ROLES, async (payload) => {
  const response = await adminGet(`roles`, payload);
  return response;
});
export const getUnmappedStorageLocation = createAsyncThunk(
  actions.GET_UNMAPPED_STORAGE_LOCATION,
  async (payload) => {
    const response = await adminGet(`unmapped-storage-locations`, payload);
    return response;
  }
);
export const postMapStorageLocation = createAsyncThunk(
  actions.POST_MAP_STORAGE_LOCATION,
  async (payload) => {
    const response = await adminPost(`map-storage-location`, payload);
    return response;
  }
);
export const postMapUser = createAsyncThunk(
  actions.POST_MAP_USER,
  async (payload) => {
    const response = await adminPost(`map-user`, payload);
    return response;
  }
);

export const getUserList = createAsyncThunk(
  actions.GET_USER_LIST,
  async (payload) => {
    const response = await adminGet(`all-users`, payload);
    return response;
  }
);
export const getAllApprovers = createAsyncThunk(
  actions.GET_ALL_APPROVERS,
  async (payload) => {
    const response = await adminGet(`approvers`, payload);
    return response;
  }
);
export const getAllIssuers = createAsyncThunk(
  actions.GET_ALL_ISSUERS,
  async (payload) => {
    const response = await adminGet(`issuers`, payload);
    return response;
  }
);

// export const searchvalues = createAsyncThunk(
//   actions.SEARCH_TICKET,
//   async (payload) => {
//     const response = await requests(
//       `gatepasses?status=${payload?.status}&search=${payload?.ticket}&page=${payload?.page}`
//     );
//     return response;
//   }
// );

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPlants.pending, (state) => {
        state.getAllPlantsLoading = true;
      })
      .addCase(getAllPlants.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllPlantsLoading = false;
        state.allPlants = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllPlants.rejected, (state) => {
        state.getAllPlantsLoading = false;
      });
    builder
      .addCase(getAllCompanies.pending, (state) => {
        state.getAllCompaniesLoading = true;
      })
      .addCase(getAllCompanies.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllCompaniesLoading = false;
        state.allCompanies = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllCompanies.rejected, (state) => {
        state.getAllCompaniesLoading = false;
      });
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.getAllUsersLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllUsersLoading = false;
        state.allUsers = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllUsers.rejected, (state) => {
        state.getAllUsersLoading = false;
      });
    builder
      .addCase(getAllLocations.pending, (state) => {
        state.getAllLocationsLoading = true;
      })
      .addCase(getAllLocations.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.getAllLocationsLoading = false;
        state.allLocations = data ? data : [];
        state.pagination = pagination || null;
      })
      .addCase(getAllLocations.rejected, (state) => {
        state.getAllLocationsLoading = false;
      });
    builder
      .addCase(createPlant.pending, (state) => {
        state.createPlantLoading = true;
      })
      .addCase(createPlant.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.createPlantLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          message.success(msg);
        }
      })
      .addCase(createPlant.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createPlantLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createLocation.pending, (state) => {
        state.createLocationLoading = true;
      })
      .addCase(createLocation.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.createLocationLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          message.success(msg);
        }
      })
      .addCase(createLocation.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createLocationLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createCompany.pending, (state) => {
        state.createCompanyLoading = true;
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.createCompanyLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          message.success(msg);
        }
      })
      .addCase(createCompany.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createCompanyLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createUser.pending, (state) => {
        state.createUserLoading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.createUserLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          message.success(msg);
        }
      })
      .addCase(createUser.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.createUserLoading = false;
        message.error(msg);
      });
    builder
      .addCase(getRoles.pending, (state) => {
        state.getRolesLoading = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.getRolesLoading = false;
        state.allRoles = data ? data : [];
      })
      .addCase(getRoles.rejected, (state) => {
        state.getRolesLoading = false;
      });
    builder
      .addCase(getUnmappedStorageLocation.pending, (state) => {
        state.getUnmappedStorageLocationLoading = true;
      })
      .addCase(getUnmappedStorageLocation.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.getUnmappedStorageLocationLoading = false;
        state.allUnmappedStorageLocation = data ? data : [];
      })
      .addCase(getUnmappedStorageLocation.rejected, (state) => {
        state.getUnmappedStorageLocationLoading = false;
      });

    builder
      .addCase(postMapStorageLocation.pending, (state) => {
        state.postMapStorageLocationLoading = true;
      })
      .addCase(postMapStorageLocation.fulfilled, (state, action) => {
        const { success, msg: msg } = action.payload;
        state.postMapStorageLocationLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          message.success(msg);
        }
      })
      .addCase(postMapStorageLocation.rejected, (state, action) => {
        const { msg: msg } = action.error;
        state.postMapStorageLocationLoading = false;
        message.error(msg);
      });

    builder
      .addCase(postMapUser.pending, (state) => {
        state.postMapUserLoading = true;
      })
      .addCase(postMapUser.fulfilled, (state, action) => {
        const { success, msg: msg } = action.payload;
        state.postMapUserLoading = false;
        if (!success) {
          message.error(msg);
        } else {
          message.success(msg);
        }
      })
      .addCase(postMapUser.rejected, (state, action) => {
        const { msg: msg } = action.error;
        state.postMapUserLoading = false;
        message.error(msg);
      });
    builder
      .addCase(getUserList.pending, (state) => {
        state.getUseListLoading = true;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.getUseListLoading = false;
        state.userList = data ? data : [];
      })
      .addCase(getUserList.rejected, (state) => {
        state.getUseListLoading = false;
      });
    builder
      .addCase(getAllApprovers.pending, (state) => {
        state.getAllApproversLoading = true;
      })
      .addCase(getAllApprovers.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.getAllApproversLoading = false;
        state.allApprovers = data ? data : [];
      })
      .addCase(getAllApprovers.rejected, (state) => {
        state.getAllApproversLoading = false;
      });
    builder
      .addCase(getAllIssuers.pending, (state) => {
        state.getAllIssuersLoading = true;
      })
      .addCase(getAllIssuers.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.getAllIssuersLoading = false;
        state.allIssuers = data ? data : [];
      })
      .addCase(getAllIssuers.rejected, (state) => {
        state.getAllIssuersLoading = false;
      });
    // builder
    //   .addCase(searchvalues.pending, (state) => {
    //     state.getAllApproverLoading = true;
    //   })
    //   .addCase(searchvalues.fulfilled, (state, action) => {
    //     const { success, data, pagination } = action.payload;
    //     state.getAllApproverLoading = false;
    //     state.allRequests = data ? data : [];
    //     state.pagination = pagination || null;

    //     if (!success) {
    //       // message.error(msg);
    //     }
    //   })
    //   .addCase(searchvalues.rejected, (state) => {
    //     // const { message: msg } = action.error;

    //     state.getAllApproverLoading = false;

    //     // message.error(msg);
    //   });
  }
});
export default adminSlice.reducer;
