import React from 'react';
import { Table } from 'antd';
import styles from './index.module.less';

const CustomTable = ({ dataSource, ...rest }) => {
  return (
    <div
      className={`${styles.custom_table_container}${
        !dataSource?.length ? ` ${styles.no_data}` : ''
      }`}
    >
      <Table dataSource={dataSource} {...rest} />
    </div>
  );
};

export default CustomTable;
