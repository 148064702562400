import React, { useEffect } from 'react';
import CustomLayout from '@/common/Layout';
import ViewDetails from './components/viewdetails';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneratedGpById } from './redux/slice';
import { useParams } from 'react-router-dom';

const ViewGeneratedGP = () => {
  const { generatedGpData } = useSelector((state) => state.view);
  const dispatch = useDispatch();
  console.log('generatedGpData', generatedGpData);
  const params = useParams();
  useEffect(() => {
    handleGetRequestById();
  }, []);
  const handleGetRequestById = () => {
    // payload['id'] = params.id;
    const payload = { gpid: params.id };
    dispatch(getGeneratedGpById(payload));
  };
  return (
    <>
      <CustomLayout sider="true">
        <ViewDetails requester_data={generatedGpData} />
      </CustomLayout>
    </>
  );
};

export default ViewGeneratedGP;
