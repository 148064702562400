import React, { useState } from 'react';
import styles from './index.module.less';
import { Col, Row, Form, Space, Typography, Divider } from 'antd';
import CustomButton from '@/common/CustomButton';
import FormItem from 'common/FormItem';
import RejectModal from '../RejectModal';
import ApproveModal from '../ApproveModal';
import GPModal from '../GPModal';
import ItemsModal from '../ItemsModal';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';
import { EditOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

const viewdetails = ({ requester_data }) => {
  const onFinish = (values) => {
    console.log('Values', values);
  };
  console.log('requester_data>>>>>>>>', requester_data?.status);
  console.log('requester_data?.Items>>>>>>>>', requester_data?.Items);
  const params = useParams();
  const user = getLoggedInUser();
  const [form] = Form.useForm();
  const [rejectModal, setRejectModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [itemsModal, setItemsModal] = useState(false);
  return (
    <>
      <div className={`mb-4 ${styles.container}`}>
        <Row gutter={16} style={{ marginTop: '-9px' }}>
          <Col span={21}>
            <div className={`${styles.page_title1}`}>
              Ticket no:{' '}
              <span style={{ fontSize: '18px' }}>
                {requester_data ? requester_data?.ticket : 'NA'}
              </span>
            </div>
            <div className={`${styles.page_sub_title}`}>
              Please review it and take action
            </div>
          </Col>
          {user?.user_details?.role === 'issuer' &&
          requester_data?.status == 'Approved' ? (
            <Col span={2}>
              <a href={`/edit-draft-request/${params?.id}`}>
                <CustomButton
                  size="small"
                  icon={<EditOutlined />}
                  className={`${styles.customButton_import_style}`}
                >
                  <span>Edit</span>
                </CustomButton>
              </a>
            </Col>
          ) : (
            ''
          )}
        </Row>
        <br />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <Row gutter={16}>
            <Col span={6}>
              <FormItem
                label="Project location"
                className={`${styles.select_styles}`}
              >
                <Typography>
                  {requester_data
                    ? requester_data?.Location?.location_name
                    : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Type" className={`${styles.select_styles}`}>
                <Typography>
                  {requester_data ? requester_data?.Type?.type : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Category" className={`${styles.select_styles}`}>
                <Typography>
                  {requester_data ? requester_data?.Category?.category : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Method" className={`${styles.select_styles}`}>
                <Typography>
                  {requester_data
                    ? requester_data?.Method_type?.method_type
                    : 'NA'}
                </Typography>
              </FormItem>
            </Col>
          </Row>
          <Divider
            style={{
              margin: 0,
              marginTop: '25px !important',
              marginBottom: '25px'
            }}
          />
          <Row gutter={16}>
            <Col span={6}>
              <FormItem
                label="Reference documents"
                className={`${styles.select_styles}`}
              >
                <Typography>
                  {requester_data
                    ? requester_data?.Document_master?.document_name
                    : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Requester name"
                className={`${styles.select_styles}`}
              >
                <Typography>
                  {requester_data ? requester_data?.requester_name : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Requester Dept."
                className={`${styles.select_styles}`}
              >
                <Typography>
                  {requester_data ? requester_data?.requester_department : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Vendor name"
                className={`${styles.select_styles}`}
              >
                <Typography>
                  {requester_data ? requester_data?.vendor_name : 'NA'}
                </Typography>
              </FormItem>
            </Col>
          </Row>
          <Divider
            style={{
              margin: 0,
              marginTop: '250px !important',
              marginBottom: '25px'
            }}
          />
          <Row gutter={16}>
            <Col span={6}>
              <FormItem label="Address" className={`${styles.select_styles}`}>
                <Typography className={`${styles.addr_typo}`}>
                  {requester_data ? requester_data?.address : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Date" className={`${styles.select_styles}`}>
                <Typography>
                  {requester_data
                    ? moment(requester_data?.date).format('DD, MMM YYYY')
                    : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Material belongs to"
                className={`${styles.select_styles}`}
              >
                <Typography>
                  {requester_data ? requester_data?.Material_name : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Expected date of return"
                className={`${styles.select_styles}`}
              >
                <Typography>
                  {requester_data
                    ? moment(requester_data?.expected_date_return).format(
                        'DD, MMM YYYY'
                      )
                    : 'NA'}
                </Typography>
              </FormItem>
            </Col>
          </Row>
          <Divider
            style={{
              margin: 0,
              marginTop: '250px !important',
              marginBottom: '25px'
            }}
          />
          <Row gutter={16}>
            <Col span={6}>
              <FormItem
                label="Actual return date"
                className={`${styles.select_styles}`}
              >
                <Typography>
                  {requester_data
                    ? moment(requester_data?.actual_return_date).format(
                        'DD, MMM YYYY'
                      )
                    : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Vehicle number"
                className={`${styles.select_styles}`}
              >
                <Typography>
                  {requester_data ? requester_data?.vehicle_number : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="LR number" className={`${styles.select_styles}`}>
                <Typography>
                  {requester_data ? requester_data?.lr_number : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="LR Date" className={`${styles.select_styles}`}>
                <Typography>
                  {requester_data
                    ? moment(requester_data?.lr_date).format('DD, MMM YYYY')
                    : 'NA'}
                </Typography>
              </FormItem>
            </Col>
          </Row>
          <Divider
            style={{
              margin: 0,
              marginTop: '250px !important',
              marginBottom: '25px'
            }}
          />
          <Row gutter={16}>
            <Col span={6}>
              <FormItem
                label="Name of the carrier person"
                className={`${styles.select_styles}`}
              >
                <Typography>
                  {requester_data ? requester_data?.carrier_person_name : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Approver name"
                className={`${styles.select_styles}`}
              >
                <Typography>
                  {requester_data
                    ? requester_data?.approver?.first_name?.concat(
                        ' ',
                        requester_data?.approver?.last_name
                      )
                    : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            {/* <Col span={6}>
              <FormItem
                label="Sr No. item code"
                className={`${styles.select_styles}`}
              >
                <Typography>
                  {requester_data ? requester_data.item_code : 'NA'}
                </Typography>
              </FormItem>
            </Col> 
            <Col span={6}>
              <FormItem label="UOM" className={`${styles.select_styles}`}>
                <Typography>
                  {requester_data ? requester_data.uom : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Qty" className={`${styles.select_styles}`}>
                <Typography>
                  {requester_data ? requester_data.quantity : 'NA'}
                </Typography>
              </FormItem>
            </Col>*/}
          </Row>
          <Divider
            style={{
              margin: 0,
              marginTop: '250px !important',
              marginBottom: '25px'
            }}
          />
          {/* <div>Details Items:</div> */}
          <a
            className={`${styles.itemsDetails}`}
            // htmlType="submit"
            size="small"
            onClick={() => setItemsModal(true)}
          >
            Item Details
          </a>
          <br />
          <br />
          <br />
          <Divider
            style={{
              margin: 0,
              marginTop: '250px !important',
              marginBottom: '25px'
            }}
          />
          <Row gutter={16}>
            <Col span={6}>
              <FormItem
                label="Reason for sending"
                className={`${styles.select_styles}`}
              >
                <Typography className={`${styles.addr_typo}`}>
                  {requester_data ? requester_data?.Reason?.reason_name : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Detailed reason"
                className={`${styles.select_styles}`}
              >
                <Typography className={`${styles.addr_typo}`}>
                  {requester_data ? requester_data?.detailed_reason : 'NA'}
                </Typography>
              </FormItem>
            </Col>

            <Col span={6}>
              <FormItem
                label="Item description"
                className={`${styles.select_styles}`}
              >
                <Typography className={`${styles.addr_typo}`}>
                  {requester_data ? requester_data?.item_description : 'NA'}
                </Typography>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Remarks" className={`${styles.select_styles}`}>
                <Typography className={`${styles.addr_typo}`}>
                  {requester_data ? requester_data?.remarks : 'NA'}
                </Typography>
              </FormItem>
            </Col>
          </Row>

          <FormItem>
            <Space>
              {user?.user_details?.role === 'approver' &&
              requester_data?.status == 'Requested' ? (
                <CustomButton
                  className={`${styles.import_style_success}`}
                  htmlType="submit"
                  size="small"
                  onClick={() => setApproveModal(true)}
                >
                  Approve
                </CustomButton>
              ) : user?.user_details?.role === 'issuer' &&
                requester_data?.status == 'Approved' ? (
                <CustomButton
                  className={`${styles.import_style_success}`}
                  htmlType="submit"
                  size="small"
                  onClick={() => setApproveModal(true)}
                >
                  Generate GP
                </CustomButton>
              ) : (
                ''
              )}
              {(user?.user_details?.role === 'approver' &&
                requester_data?.status == 'Requested') ||
              (user?.user_details?.role === 'issuer' &&
                requester_data?.status == 'Approved') ? (
                <CustomButton
                  className={`${styles.import_style_danger}`}
                  htmlType="submit"
                  size="small"
                  onClick={() => setRejectModal(true)}
                >
                  Reject
                </CustomButton>
              ) : (
                ''
              )}
            </Space>
          </FormItem>
        </Form>
        {rejectModal ? (
          <RejectModal
            rejectModal={rejectModal}
            setRejectModal={setRejectModal}
          />
        ) : (
          ''
        )}
        {approveModal ? (
          user?.user_details?.role === 'approver' ? (
            <ApproveModal
              approveModal={approveModal}
              setApproveModal={setApproveModal}
            />
          ) : (
            <GPModal
              approveModal={approveModal}
              setApproveModal={setApproveModal}
            />
          )
        ) : (
          ''
        )}
        {itemsModal ? (
          <ItemsModal
            itemsModal={itemsModal}
            setItemsModal={setItemsModal}
            requester_data={requester_data}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default viewdetails;
