import React, { useState, useEffect } from 'react';
import CustomLayout from '@/common/Layout';
import List from './components/list';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllRequests,
  deleteRequest_sap,
  deleteDraft_sap
  //searchvalues
} from './redux/slice';

const ReqSAPDashboard = () => {
  window.localStorage.setItem(
    'set_Radiofilter_Admin',
    JSON?.stringify('Plants')
  );

  const { allRequests, pagination, getAllApproverLoading } = useSelector(
    (state) => state.dashboard_sap
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter')) === null
      ? ''
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter'))
  );
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [showDateranga, setShowDateranga] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [searchRequest, setSearchRequest] = useState('');

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetRequests();
  }, [page, pageSize]);

  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        handleGetRequests();
      } else {
        setPage(1);
      }
    }
  }, [radiofilter, searchRequest]); //page,
  const handleGetRequests = () => {
    let payload = {
      page,
      per_page: pageSize,
      status: radiofilter,
      search: searchRequest
    };
    dispatch(getAllRequests(payload));
  };

  const handleDeleteRequest = (payload) => {
    const id = { gpid: payload.id };
    dispatch(deleteRequest_sap(id)).then(function (response) {
      if (response) {
        handleGetRequests();
      }
    });
  };
  const handleDeleteDraft = (payload) => {
    const id = { gpid: payload.id };
    dispatch(deleteDraft_sap(id)).then(function (response) {
      if (response) {
        handleGetRequests();
      }
    });
  };

  return (
    <>
      <CustomLayout sider="true">
        <List
          allRequests={allRequests}
          pagination={pagination}
          loading={getAllApproverLoading}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          handleDeleteRequest={handleDeleteRequest}
          handleDeleteDraft={handleDeleteDraft}
          setSelectedRequest={setSelectedRequest}
          selectedRequest={selectedRequest}
          radiofilter={radiofilter}
          setRadiofilter={setRadiofilter}
          showDisablePopup={showDisablePopup}
          setShowDisablePopup={setShowDisablePopup}
          // handleChange={handleChange}
          setShowDateranga={setShowDateranga}
          showDateranga={showDateranga}
          setTriggerFilter={setTriggerFilter}
          setSearchRequest={setSearchRequest}
        />
      </CustomLayout>
    </>
  );
};

export default ReqSAPDashboard;
