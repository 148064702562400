import React, { useCallback } from 'react';
import styles from './index.module.less';
//import emptyState from 'assets/images/empty_state.svg';
import {
  Card,
  //Col,
  Row,
  Badge,
  Radio,
  Space,
  //Divider,
  Popconfirm,
  Image,
  //Button,
  Tooltip,
  Empty
} from 'antd';
import CustomTable from 'common/Content/CustomTable';
import moment from 'moment';
import SearchBar from '@/common/Content/searchBar';
import printIcon from 'assets/images/print.svg';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
//import ReactToPrint from 'react-to-print';

// import CustomButton from 'common/CustomButton';
// import {
//   CloudUploadOutlined,
//   CloudDownloadOutlined
//   // PlusOutlined
// } from '@ant-design/icons';

const filtersIssuer = [
  {
    name: 'All',
    value: ''
  },
  /*  {
    name: 'Draft',
    value: 'Draft'
  },*/
  /*  {
    name: 'Requested',
    value: 'Requested'
  }, */
  {
    name: 'Approved',
    value: 'Approved'
  },
  /* {
    name: 'Pending',
    value: 'Pending'
  }, */
  {
    name: 'Rejected',
    value: 'Rejected'
  },
  {
    name: 'Generated',
    value: 'Generated'
  },
  {
    name: 'Printed',
    value: 'Printed'
  },
  {
    name: 'Partially Received',
    value: 'Partially received'
  },

  {
    name: 'Closed',
    value: 'Closed'
  }
  /* {
    name: 'Cancelled',
    value: 'Cancelled'
  } */
];
const IssuerGatepassListPage = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  //allRequests,
  allRequestsIssuer,
  setRadiofilter,
  secondpagination,
  radiofilter,
  user,
  //handleChange,
  showDisablePopup,
  setShowDisablePopup,
  handlePrintRequest,
  loading,
  setTriggerFilter,
  setSelectedRequest,
  handleDeleteRequest,
  handleDeleteDraft,
  setSearchRequest
  //url
}) => {
  //console.log('allRequests', allRequests);

  const tableDataIssuer = allRequestsIssuer?.map((item) => ({
    key: item?.id,
    id: item?.id,
    ticket: item?.ticket,
    Method_type: item?.Method_type?.method_type,
    Location: item?.Location?.location_name,
    Type: item?.Type?.type,
    Category: item?.Category?.category,
    date: item?.date,
    //status: item?.Approvers[0]?.status,
    status: item?.status,
    approver_status: item?.approver_status,
    issuer_status: item?.issuer_status,
    gp_number: item?.gp_number,
    Project_location: item?.project_location_master?.name,
    date_of_generated: item?.date_of_generated,
    updatedAt: item?.updatedAt
  }));

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  const issuercolumns = [
    {
      title: 'Request #',
      dataIndex: 'ticket',
      key: 'ticket',
      width: '100px',
      ellipsis: true,
      sorter: (a, b) => a?.ticket?.localeCompare(b?.ticket),
      render: (ticket, record) => {
        return (
          <>
            <Tooltip
              title={ticket}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {
                record.status === 'Printed' &&
                record.Category === 'Returnable Gate Pass (RGP)' ? (
                  <a href={`edit-partially/${record.id}`}>{ticket}</a>
                ) : record.status === 'Partially received' ? (
                  <a href={`edit-partially/${record.id}`}>{ticket}</a>
                ) : (
                  <a href={`view-requests/${record.id}`}>
                    {' '}
                    {ticket === null ? 'Not generated' : ticket}
                  </a>
                )
                /*  record.status === 'Printed'||
                record.status === 'Closed' ||
                record.status === 'Partially received' ? (
                  <a href={`edit-partially/${record.id}`}>{ticket}</a>
                ) : (
                  <a href={`view-requests/${record.id}`}>{ticket}</a>
                ) */
              }
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Gate Pass #',
      dataIndex: 'gp_number',
      key: 'gp_number',
      //width: 180,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.gp_number?.localeCompare(b?.gp_number),
      render: (gp_number) => {
        return (
          <>
            <Tooltip
              title={gp_number}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {gp_number === null ? 'Not generated' : gp_number}
            </Tooltip>
          </>
        );
      }
    },

    {
      title: 'Location',
      dataIndex: 'Project_location',
      key: 'Project_location',
      ellipsis: true,
      sorter: (a, b) => a?.Project_location?.localeCompare(b?.Project_location),
      render: (Project_location) => {
        return (
          <>
            <Tooltip
              title={Project_location}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {Project_location}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      ellipsis: true,
      width: '100px',
      sorter: (a, b) => a?.Type?.localeCompare(b?.Type),
      render: (Type) => {
        return (
          <>
            <Tooltip
              title={Type}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {Type}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Category',
      dataIndex: 'Category',
      key: 'Category',
      width: '80px',
      ellipsis: true,
      sorter: (a, b) => a?.Category?.localeCompare(b?.Category),
      render: (Category) => {
        if (Category === 'Returnable Gate Pass (RGP)') {
          return (
            <>
              <Tooltip
                title={Category}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                RGP {/* {Category} */}
              </Tooltip>
            </>
          );
        } else if (Category === 'Non-returnable Gate Pass (NRGP)') {
          return (
            <>
              <Tooltip
                title={Category}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                NRGP {/* {Category} */}
              </Tooltip>
            </>
          );
        }
      }
    },
    {
      title: 'Date',
      dataIndex: 'date_of_generated',
      key: 'date_of_generated',
      ellipsis: true,
      width: '100px',
      sorter: (a, b) =>
        moment(a[`date_of_generated`]).unix() -
        moment(b[`date_of_generated`]).unix(),
      render: (date_of_generated) => (
        <>
          <Tooltip
            title={
              date_of_generated === null
                ? 'Not generated'
                : moment(date_of_generated).format('DD MMM YYYY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {date_of_generated === null
              ? 'Not generated'
              : moment(date_of_generated).format('DD MMM YYYY')}
          </Tooltip>
        </>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '180px',
      ellipsis: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (text, record) => (
        <Badge
          className={
            record?.status === 'Rejected'
              ? `${styles.badgeRejStyles}`
              : record.status === 'Cancelled'
              ? `${styles.badgeCanStyles}`
              : record.status === 'Requested'
              ? `${styles.badgeReqStyles}`
              : record.status === 'Approved'
              ? `${styles.badgeStyles}`
              : record.status === 'Generated'
              ? `${styles.badgeGpGenStyles}`
              : record.status === 'Partially received'
              ? `${styles.badgePartRecivStyles}`
              : record.status === 'Printed'
              ? `${styles.badgeGpPriStyles}`
              : record.status === 'Closed'
              ? `${styles.badgeCloseStyles}`
              : `${styles.badgePendStyles}`
          }
          color={
            record?.status === 'Rejected' || record?.status === 'Rejected'
              ? 'red'
              : record?.status === 'Cancelled'
              ? '#820b03'
              : record?.status === 'Requested'
              ? 'blue'
              : record?.status === 'Approved'
              ? 'green'
              : record?.status === 'Generated'
              ? ' #059720'
              : record?.status === 'Partially received'
              ? '#C11574'
              : record?.status === 'Printed'
              ? '#344054'
              : record?.status === 'Closed'
              ? '#18068b'
              : '#046f89'
          }
          text={
            record?.status === 'Rejected'
              ? record?.status?.charAt(0).toUpperCase() +
                record?.status?.slice(1)
              : record?.status?.charAt(0).toUpperCase() +
                record?.status?.slice(1)
          }
        />
      )
    },

    {
      title: 'Action',
      key: 'action',
      width: 80,
      render: (record) => (
        <Space size="middle">
          <>
            {record?.status === 'Generated' ? (
              <Popconfirm
                title="Are you sure you want to print this gate pass?"
                visible={visibility(record)}
                onConfirm={() => {
                  handlePrintRequest({
                    id: record?.id
                  });
                }}
                onCancel={() => {
                  setShowDisablePopup({ [record?.id]: false });
                }}
                okText="Print"
                cancelText="No"
                okButtonProps={{ type: 'danger' }}
              >
                <Image
                  className="cursor-pointer"
                  alt="users_delete"
                  src={printIcon}
                  preview={false}
                  onClick={() => {}}
                />
              </Popconfirm>
            ) : record.status === 'Requested' ? (
              <>
                <Popconfirm
                  title="Are you sure do you want to cancel this request?"
                  visible={visibility(record)}
                  onConfirm={() => {
                    handleDeleteRequest({
                      id: record.id
                    });
                  }}
                  onCancel={() => {
                    setShowDisablePopup({});
                  }}
                  okText="Cancel"
                  cancelText="No"
                  okButtonProps={{ type: 'danger' }}
                >
                  <DeleteFilled
                    className={`${styles.delete_icon}`}
                    onClick={() => {
                      setShowDisablePopup({ [record.id]: true });
                    }}
                  />
                </Popconfirm>
                <a href={`edit-requestedGP/${record.id}`}>
                  <EditFilled
                    className={`${styles.edit_icon}`}
                    onClick={() => {
                      // setEditMode(true);
                      setSelectedRequest(record);
                    }}
                  />
                </a>
              </>
            ) : record.status === 'Draft' ? (
              <>
                <Popconfirm
                  title="Are you sure do you want to delete this request?"
                  visible={visibility(record)}
                  onConfirm={() => {
                    handleDeleteDraft({
                      id: record.id
                    });
                  }}
                  onCancel={() => {
                    setShowDisablePopup({});
                  }}
                  okText="Delete"
                  cancelText="No"
                  okButtonProps={{ type: 'danger' }}
                >
                  <DeleteFilled
                    className={`${styles.delete_icon}`}
                    onClick={() => {
                      setShowDisablePopup({ [record.id]: true });
                    }}
                  />
                </Popconfirm>
                <a href={`draft-edit-IssuerRequest/${record.id}`}>
                  <EditFilled
                    className={`${styles.edit_icon}`}
                    onClick={() => {
                      //setEditMode(true);
                      setSelectedRequest(record);
                    }}
                  />
                </a>
              </>
            ) : (
              ''
            )}
          </>
        </Space>
      )
    }
  ];

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        {/*  <Image alt="users_empty_state" src={emptyState} preview={false} /> */}
        <div className={styles.empty_state_text}>
          There are no gate pass requests to display
        </div>
      </span>
    )
  };
  return (
    <>
      <Card className={`${styles.cardStyles_approver}`}>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Radio.Group
              value={radiofilter}
              size="small"
              className={
                /* user?.user_details?.roles[0] === 'approver'
                  ? `${styles.button_import_style}`
                  :  */
                `${styles.button_import_style_issuer}`
              }
            >
              {user?.user_details?.roles[0] === 'issuer'
                ? filtersIssuer.map((item, index) => {
                    return (
                      <Radio.Button
                        value={item.value}
                        key={index}
                        className={`${styles.RadioButton_import_style_issuer}`}
                        onChange={() => {
                          window.localStorage.setItem(
                            'set_Radiofilter',
                            JSON?.stringify(item.value)
                          );
                          setRadiofilter(item.value);
                          setTriggerFilter(true);
                        }}
                      >
                        {item.name}
                      </Radio.Button>
                    );
                  })
                : ''}
            </Radio.Group>
          </div>
          <div style={{ width: '300px' }}>
            <SearchBar
              //onChange={(e) => handleChange(e)}
              onChange={(e) => {
                setSearchRequest(e.target.value);
                setTriggerFilter(true);
              }}
              className={`w-100 ${styles.searchbar}`}
              justify="start"
              align="start"
              inputProps={{
                placeholder: 'Search by gate pass request#'
              }}
            />
          </div>
        </Row>

        <div className={`${styles.table_container}`}>
          <CustomTable
            locale={locale}
            loading={loading}
            dataSource={tableDataIssuer}
            columns={issuercolumns}
            pagination={{
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              size: 'small',
              total: secondpagination?.total_records || 0,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default IssuerGatepassListPage;
