import React, { useEffect, useState, useMemo } from 'react';
import styles from './index.module.less';
// import {XLSX} from 'xlsx';
//import { read, utils, writeFile } from 'xlsx';
import * as XLSX from 'xlsx/xlsx.mjs';
//import { Link } from 'react-router-dom';
import FormData from 'form-data';
import {
  Col,
  Row,
  Select,
  Form,
  Divider,
  Input,
  DatePicker,
  message,
  Button,
  Radio,
  Tag,
  Spin
} from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import FormItem from 'common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllProject,
  getAllProjectLocations,
  getAlltypes,
  getAllcategories,
  getAllmethods,
  getAllreferenceDocs,
  getAllmaterils,
  getAllreasontosend,
  //createRequest,
  getAllapprovers,
  getAllItems,
  getAllVendors,
  getAllVendorLocation,
  getAllissuers,
  createDraftUpload,
  get_Check_DocumentNumber
  // getERP_Document
  //createDraftItems
} from '../../redux/slice';
import { getLoggedInUser } from 'utilities/helpers';
import { history } from '@/app/history';
import moment from 'moment';
import { debounce } from 'lodash';
import Vendor_IRISO_MRR from './Vendor_IRISO_MRR';
import VendorERP_Modal from './vendorERP_Modal';
//import ERPvendor from './ERPvendor';

const { Option } = Select;
const { TextArea } = Input;
const filters = [
  {
    id: 1,
    name: 'Gate Pass details',
    value: 'GP_information'
  },
  {
    id: 2,
    name: 'Item details',
    value: 'item_details'
  }
];

const gatepassrequest = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    project,
    projectLocations,
    types,
    categories,
    methods,
    docs,
    materials,
    reasons,
    approvers,
    //items,
    vendors,
    vendorLocation,
    issuers
    //erp_DocumentList
    //createDraftLoading
  } = useSelector((state) => state.requests);
  const user = getLoggedInUser();
  const [documentNumber, setDocumentNumber] = useState();
  const [documentID, setDocumentID] = useState();
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [formData1, setFormData1] = useState({});
  const [formItemData, setFormItemData] = useState({});
  //const [formTabeOneData, setFormTabeOneData] = useState({});
  const [itemVal, setItemVal] = useState({});
  const [vendorAddress, setVendorAddress] = useState([]);
  const [vendorName, setVendorName] = useState([]);
  const [vendorAddressValue, setVendorAddressValue] = useState('');
  const [newDocs, setNewDocs] = useState([1]);
  const [supportingDoc, setSupportingDoc] = useState([]);
  const [draft, setDraft] = useState();
  const [submit, setSubmit] = useState();
  const [vendorList, setVendorList] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [itemsJsonData, setItemsJsonData] = useState(['']);
  const [other, setOther] = useState('');
  const [otherVendorName, setOtherVendorName] = useState('');
  const [otherVendorLocation, setOtherVendorLocation] = useState('');
  const [otherVendorAddress, setOtherVendorAddress] = useState('');
  const [vendorSearch, setVendorSearch] = useState('');
  const [draftLoading, setDraftLoading] = useState(false);
  const [proccedLoading, setProccedLoading] = useState(false);
  const [value, setOnProceedValue] = useState({});
  const [fileValue, setFileValue] = useState('');
  const [gstNumberVal, setGstNumberVal] = useState([]);
  const [gstNumber, setGstNumber] = useState('');
  const [projectLocationName, setProjectLocationName] = useState('');
  const [projectLocationID, setProjectLocationID] = useState('');
  // const [documentSearch, setDocumentSearch] = useState('');
  // const [projectName, setProjectName] = useState();
  // const [projectLocationName, setProjectLocationName] = useState();
  // const [documentName, setDocumentName] = useState();
  const [fetchedItems, setFetchedItems] = useState([]);
  const [fetchedItems1, setFetchedItems1] = useState([]);
  // const [vendorId, setVendorId] = useState('');
  const [entityNum, setEntityNum] = useState('');
  const [projectId, setProjectId] = useState('');
  const [fetchedType_of_issue, setFetchedType_of_issue] = useState('');
  const [vendorERP, setVendorERP] = useState([]);
  const [erpLoading, setErpLoading] = useState(false);
  const [erpVendorLoading, setErpVendorLoading] = useState(false);
  const [erpParseItems, setErpParseItems] = useState({});
  const [vendorERPLocationName, setVendorERPLocationName] = useState('');
  const [vendorERPModal, setVendorERPModal] = useState(false);
  const [projectSearch, setProjectSearch] = useState('');
  const [projectLocationSearch, setProjectLocationSearch] = useState('');
  const [check_DocumentNumberLoading, setCheck_DocumentNumberLoading] =
    useState(false);
  const [approverSearch, setApproverSearch] = useState('');
  const [issuerSearch, setIssuerSearch] = useState('');

  const [disable, setDisable] = useState(false);

  const [fetchedItems2, setFetchedItems2] = useState([]);
  const [vendorERPShow, setVendorERPShow] = useState(false);

  console.log(other); //do not delete

  // console.log('documentID', documentID);
  // console.log('"category_id"', formData1?.category_id);
  //formData1?.category_id===1 && methodType_id ===1 && documentID 2(MRR_RTV) && documentID 2(IRISO)
  // console.log('doc_numbers', doc_numbers);
  //console.log('vendorERP?.vendorCode', vendorERP);
  // console.log('vendorERP?.vendorName', vendorERP?.vendorName);
  // console.log('VendorERPLocationName', vendorERPLocationName);
  // console.log('ERP_ParseItems', erpParseItems);
  // console.log('vendorLocationUSESELECTOR........', vendorLocation);
  // console.log('vendorAddress????????????...........', vendorAddress);
  // console.log('vendorName[0]?.vendorCode', vendorName[0]?.vendorCode);
  // console.log('vendorName[0]?.vendorName', vendorName[0]?.vendorName);
  console.log('vendorsVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV', vendors);
  // console.log(
  //   'vendors[0]VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV',
  //   vendors[0]?.vendorName
  // );
  // console.log('formData1?.vendor_location.......', formData1?.vendor_location);
  // console.log('vendorERPLocationName.......', vendorERPLocationName);
  // console.log('vendorAddressValue.......', vendorAddressValue);

  useEffect(() => {
    handlegetAllRequest();
  }, []);
  const handlegetAllRequest = () => {
    //dispatch(getAllProjectLocations());
    dispatch(getAlltypes());
    dispatch(getAllcategories());
    dispatch(getAllmethods());
    dispatch(getAllreferenceDocs());
    dispatch(getAllmaterils());
    // dispatch(getAllapprovers());
    dispatch(getAllItems());
    //dispatch(getAllissuers());
  };
  useEffect(() => {
    if (formData1?.location_id !== undefined) {
      const payload = {
        project_location_id: formData1?.location_id
      };
      if (issuerSearch) payload['search'] = issuerSearch;
      dispatch(getAllissuers(payload));
    }
  }, [issuerSearch, formData1?.location_id]);

  useEffect(() => {
    if (formData1?.location_id !== undefined) {
      const payload = {
        project_location_id: formData1?.location_id
      };
      if (approverSearch) payload['search'] = approverSearch;
      dispatch(getAllapprovers(payload));
    }
  }, [approverSearch, formData1?.location_id]);

  useEffect(() => {
    const payload = {}; //vendorId
    if (projectSearch) payload['search'] = projectSearch;
    dispatch(getAllProject(payload));
  }, [projectSearch]); //vendorId

  // const handleGetAllProject = () => {
  //   const payload = {}; //vendorId
  //   if (projectSearch) payload['search'] = projectSearch;
  //   dispatch(getAllProject(payload));
  // };
  useEffect(() => {
    let payload = {
      id: formData1?.project_id
    };
    if (projectLocationSearch) payload['search'] = projectLocationSearch;
    formData1?.project_id === undefined
      ? ''
      : dispatch(getAllProjectLocations(payload));
  }, [formData1?.project_id, projectLocationSearch]);

  useEffect(() => {
    if (formData1?.project_id === undefined) {
      form.setFieldsValue({
        project_id: project[0]?.id
      });
      if (project?.length > 0) {
        let payload = {
          id: project[0]?.id
        };
        dispatch(getAllProjectLocations(payload));
      }
    }
  }, [formData1?.project_id]);

  useEffect(() => {
    let gstValues = projectLocations?.filter(
      (item) => item?.id === formData1?.location_id
    );
    setGstNumberVal(gstValues);
    setGstNumber(gstValues[0]?.gst_number);
    setProjectLocationName(gstValues[0]?.name);
    setProjectLocationID(gstValues[0]?.id);
  }, [formData1?.location_id]);

  useEffect(() => {
    let payload = {
      //per_page: 20
    };
    if (vendorSearch) payload['search'] = vendorSearch;
    dispatch(getAllVendors(payload));
  }, [vendorSearch]);

  useEffect(() => {
    const vendors1 = [...vendors, { vendorName: 'other', id: 'other' }];
    setVendorList(vendors1);
  }, [vendors]);

  useEffect(() => {
    if (formData1?.type_id === 1) {
      dispatch(
        getAllreasontosend({
          category: 'RGP'
        })
      );
    } else {
      dispatch(
        getAllreasontosend({
          category: formData1?.category_id === 1 ? 'RGP' : 'NRGP'
        })
      );
    }
  }, [formData1?.category_id, formData1?.type_id]);

  useEffect(() => {
    if (formData1?.vendor_id) {
      formData1?.vendor_id === 'other'
        ? ''
        : dispatch(getAllVendorLocation({ vendorId: formData1?.vendor_id }));
    }
  }, [formData1?.vendor_id]);

  useEffect(() => {
    let VendorValues = vendors?.filter(
      (item) => item?.id === formData1?.vendor_id
    );
    setVendorName(VendorValues);
  }, [formData1?.vendor_id]);

  useEffect(() => {
    let locationValues = vendorLocation?.filter(
      (item) => item?.id === formData1?.vendor_location
    );
    setVendorAddress(locationValues);
    setVendorAddressValue(locationValues[0]?.addressLine1);
  }, [formData1?.vendor_location]);

  useEffect(() => {
    form.setFieldsValue({
      material_id:
        formData1?.methodType_id === 1 ? materials[0]?.material_name : undefined
    });
  }, [formData1?.methodType_id]);

  useEffect(() => {
    if (formData1?.type_id === 1) {
      formData1['category_id'] = categories[0]?.id;
    }
    form.setFieldsValue({
      category_id: formData1?.type_id === 1 ? categories[0]?.id : undefined
    });
  }, [formData1?.type_id]);

  useEffect(() => {
    form.setFieldsValue({
      requester_name: user?.user_details?.fullname,
      requester_department: user?.user_details?.roles[0],
      document_number: documentNumber,
      date: moment() || ''
    });
  });

  const handleChangedocument = (values) => {
    setDocumentID(values);
    let doc_numbers = docs.filter((docs) => docs.id === values);

    setDocumentNumber(doc_numbers[0].document_number);

    form.setFieldsValue({
      // location_id: undefined,
      vendor_id: undefined,
      vendor_location: undefined
    });
  };

  const handleOtherVendorName = (e) => {
    setOtherVendorName(e.target.value);
  };
  const handleOtherVendorLocation = (e) => {
    setOtherVendorLocation(e.target.value);
  };
  const handleOtherVendorAddress = (e) => {
    setOtherVendorAddress(e.target.value);
  };

  // const onChangeProject = (value) => {
  //   // setVendorId(value);
  //   setProjectSearch(value);
  // };
  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);
    if (slug === 'project_id') {
      setGstNumber('');
      setProjectId(value);
      //let projectName1 = project.filter((item) => item.id === value);
      //setProjectName(projectName1[0]?.project_name);
      form.setFieldsValue({
        vendor_id: undefined,
        vendor_location: undefined,
        location_id: undefined
      });
    }
    if (slug === 'location_id') {
      setGstNumber(gstNumberVal[0]?.gst_number);
      // let projectLocationName1 = projectLocations.filter(
      //   (item) => item.id === value
      // );
      // setProjectLocationName(projectLocationName1[0]?.name);
    }
    if (slug === 'vendor_id') {
      setOther(value);

      setVendorAddressValue('');
      form.setFieldsValue({
        vendor_location: undefined
      });
    }
    if (slug === 'vendor_location') {
      setVendorAddressValue(vendorAddress[0]?.addressLine1);
    }
    if (slug === 'category_id') {
      setOther(value);
      form.setFieldsValue({
        reason_id: undefined
      });
    }
    if (slug === 'location_id' || slug === 'project_id') {
      form.setFieldsValue({
        approver_id: undefined,
        issuer_id: undefined
      });
    }
    // console.log('slug,value', slug, value);
    setFormData1({
      ...formData1,
      [slug]: value,
      ['files']: [supportingDoc],
      ['vendor_address']: vendorAddress[0]?.addressLine1
    });
  };

  const handleChangeItem = (value, slug) => {
    setItemVal({
      ...itemVal,
      [slug]: value
    });
  };

  const onFinish = (values) => {
    setFormItemData(values);
  };

  const saveAsDraft = () => {
    setDraftLoading(true);
    let formdata = new FormData();
    formdata.append('is_submitting', 'no');

    formData1?.methodType_id === 1
      ? formdata.append(
          'items',
          fetchedItems2 === undefined ? '' : JSON.stringify(fetchedItems2) //----------3
        )
      : ''; // formdata.append('items', JSON.stringify(formItemData?.items));

    formdata.append(
      'isIrisoVendor',
      formData1?.methodType_id === 1 && documentID === 3 ? true : false
    );

    for (let i in supportingDoc) {
      formdata.append('files', supportingDoc[i]);
    }

    formdata.append(
      'project_id',
      formData1?.project_id === undefined
        ? project[0]?.id
        : formData1?.project_id
    );
    formdata.append(
      'location_id',
      formData1?.location_id === undefined ? '' : formData1?.location_id
    );

    formdata.append(
      'approver_id',
      formData1?.approver_id === undefined ? '' : formData1?.approver_id
    );
    formdata.append(
      'issuer_id',
      formData1?.issuer_id === undefined ? '' : formData1?.issuer_id
    );
    formdata.append(
      'type_id',
      formData1?.type_id === undefined ? '' : formData1?.type_id
    );
    formData1?.type_id === 1
      ? formdata.append('category_id', categories[0]?.id)
      : formdata.append(
          'category_id',
          formData1?.category_id === undefined ? '' : formData1?.category_id
        );
    formdata.append(
      'methodType_id',
      formData1?.methodType_id === undefined ? '' : formData1?.methodType_id
    );
    formData1?.methodType_id === 1
      ? formdata.append(
          'document_id',
          documentID === undefined ? '' : documentID
        )
      : '';
    formData1?.methodType_id === 1
      ? formdata.append(
          'document_number',
          entityNum === undefined ? '' : entityNum
        )
      : '';
    formdata.append(
      'reason_id',
      formData1?.reason_id === undefined ? '' : formData1?.reason_id
    );

    if (formData1?.methodType_id === 1 && documentID === 1) {
      if (
        (formData1?.category_id === 2 &&
          erpParseItems?.type_of_issue === 'CHARGEABLE' &&
          formData1?.type_id === 2 &&
          erpParseItems?.type === 'CONTRACTOR') ||
        (formData1?.category_id === 2 &&
          erpParseItems?.type_of_issue === 'CHARGEABLE' &&
          formData1?.type_id === 2 &&
          erpParseItems?.type === 'CLIENT')
      ) {
        formdata.append(
          'vendor_name',
          erpParseItems?.vendor_name === undefined ||
            erpParseItems?.vendor_name === null
            ? ''
            : erpParseItems?.vendor_name
        );
        formdata.append(
          'vendor_code',
          erpParseItems?.vendor_name === undefined ||
            erpParseItems?.vendor_name === null
            ? ''
            : erpParseItems?.vendor_code === null
            ? ''
            : erpParseItems?.vendor_code
        );

        formdata.append(
          'vendor_location',
          erpParseItems?.vendor_location === undefined ||
            erpParseItems?.vendor_location === null
            ? ''
            : erpParseItems?.vendor_location
        );

        formdata.append(
          'vendor_address',
          erpParseItems?.vendor_address === undefined ||
            erpParseItems?.vendor_address === null
            ? ''
            : erpParseItems?.vendor_address
        );
      } else {
        formdata.append(
          'vendor_name',
          vendorName[0]?.vendorName === undefined
            ? vendorERP?.vendorName === undefined
              ? ''
              : vendorERP?.vendorName
            : vendorName[0]?.vendorName
        );
        formdata.append(
          'vendor_code',
          vendorName[0]?.vendorCode === undefined
            ? vendorERP?.vendorCode === undefined ||
              vendorERP?.vendorCode === null
              ? ''
              : vendorERP?.vendorCode
            : vendorName[0]?.vendorCode === null
            ? ''
            : vendorName[0]?.vendorCode
        );
        /*  formdata.append(
        'vendor_location',
        vendorERPLocationName === undefined ? '' : formData1?.vendor_location //vendorERPLocationName
      );  */ //formData1?.vendor_location
        formdata.append(
          'vendor_location',
          vendorAddress?.length === 0
            ? vendorERPLocationName
            : vendorAddress[0]?.vendorSite
        );
        /* formdata.append(
        'vendor_address',
        vendorAddressValue === undefined ? '' : vendorAddressValue
      ); */
        formdata.append(
          'vendor_address',
          vendorAddress?.length === 0
            ? vendorAddressValue === undefined ||
              vendorAddressValue === null ||
              vendorAddressValue === ''
              ? ''
              : vendorAddressValue
            : vendorAddress[0]?.addressLine1
        );
      }
    } else if (formData1?.methodType_id === 1 && documentID === 2) {
      formdata.append(
        'vendor_name',
        erpParseItems?.vendor_code === undefined
          ? ''
          : `${erpParseItems?.vendor_name}`
      );

      formdata.append(
        'vendor_code',
        erpParseItems?.vendor_code === undefined ||
          erpParseItems?.vendor_code === null
          ? ''
          : erpParseItems?.vendor_code
      );
      /* formdata.append(
        'vendor_name',
        vendorERP?.vendorName === undefined ? '' : vendorERP?.vendorName
      );
      formdata.append(
        'vendor_code',
        vendorERP?.vendorCode === undefined ? '' : vendorERP?.vendorCode
      ); */
      formdata.append(
        'vendor_location',
        erpParseItems?.vendor_location === undefined
          ? ''
          : erpParseItems?.vendor_location
      ); //formData1?.vendor_location
      formdata.append(
        'vendor_address',
        erpParseItems?.vendor_address === undefined ||
          erpParseItems?.vendor_address === '' ||
          erpParseItems?.vendor_address === null
          ? ''
          : erpParseItems?.vendor_address
      );
    } else if (formData1?.methodType_id === 1 && documentID === 3) {
      formdata.append(
        'vendor_name',
        erpParseItems?.vendor_code === undefined
          ? ''
          : `${erpParseItems?.vendor_name}`
      );

      formdata.append(
        'vendor_code',
        erpParseItems?.vendor_code === undefined ||
          erpParseItems?.vendor_code === null
          ? ''
          : erpParseItems?.vendor_code
      );
      formdata.append(
        'vendor_location',
        erpParseItems?.vendor_location === undefined
          ? ''
          : erpParseItems?.vendor_location
      ); //formData1?.vendor_location
      formdata.append(
        'vendor_address',
        erpParseItems?.vendor_address === undefined ||
          erpParseItems?.vendor_address === '' ||
          erpParseItems?.vendor_address === null
          ? ''
          : erpParseItems?.vendor_address
      );
    } else if (formData1?.vendor_id === 'other') {
      formdata.append(
        'vendor_name',
        otherVendorName === undefined ? '' : otherVendorName
      );
      formdata.append(
        'vendor_location',
        otherVendorLocation === undefined ? '' : otherVendorLocation
      );
      formdata.append(
        'vendor_address',
        otherVendorAddress === undefined ? '' : otherVendorAddress
      );
    } else if (formData1?.methodType_id === 2) {
      formdata.append(
        'vendor_name',
        vendorName[0]?.vendorName === undefined ? '' : vendorName[0]?.vendorName
      );
      formdata.append(
        'vendor_code',
        vendorName[0]?.vendorCode === undefined ||
          vendorName[0]?.vendorCode === null
          ? ''
          : vendorName[0]?.vendorCode
      );
      formdata.append(
        'vendor_location',
        vendorAddress[0]?.vendorSite === undefined
          ? ''
          : vendorAddress[0]?.vendorSite
      ); //formData1?.vendor_location
      formdata.append('vendor_address', vendorAddress[0]?.addressLine1);
    }
    /* if (formData1?.methodType_id === 1) {
      formdata.append('vendor_name', vendorERP?.vendorName);
      formdata.append('vendor_code', vendorERP?.vendorCode);
      formdata.append(
        'vendor_location',
        vendorAddress[0]?.vendorSite === undefined
          ? ''
          : vendorAddress[0]?.vendorSite
      ); //formData1?.vendor_location
      formdata.append(
        'vendor_address',
        vendorAddress[0]?.addressLine1 === undefined
          ? ''
          : vendorAddress[0]?.addressLine1
      );
    } else if (formData1?.vendor_id === 'other') {
      // formdata.append('vendor_master_id', '');
      // formdata.append('vendor_site_master_id', '');
      formdata.append('vendor_name', otherVendorName);
      formdata.append('vendor_location', otherVendorLocation);
      formdata.append('vendor_address', otherVendorAddress);
    } else {
      formdata.append(
        'vendor_name',
        vendorName[0]?.vendorName === undefined ? '' : vendorName[0]?.vendorName
      );
      formdata.append(
        'vendor_code',
        vendorName[0]?.vendorCode === undefined ? '' : vendorName[0]?.vendorCode
      );
      formdata.append(
        'vendor_location',
        vendorAddress[0]?.vendorSite === undefined
          ? ''
          : vendorAddress[0]?.vendorSite
      ); //formData1?.vendor_location
      formdata.append(
        'vendor_address',
        vendorAddress[0]?.addressLine1 === undefined
          ? ''
          : vendorAddress[0]?.addressLine1
      );
    } */

    formdata.append(
      'date',
      moment().format()
      // formData1?.date === undefined
      //   ? moment().format()
      //   : moment(formData1?.date).format()
    );
    formData1?.methodType_id === undefined
      ? formdata.append('material_id', '')
      : formData1?.methodType_id === 1
      ? formdata.append('material_id', materials[0]?.id)
      : formData1?.material_id === undefined
      ? formdata.append('material_id', '')
      : formdata.append('material_id', formData1?.material_id);

    formData1?.category_id === 1 || formData1?.type_id === 1
      ? formdata.append(
          'expected_date_return',
          formData1?.expected_date_return === undefined
            ? ''
            : moment(formData1?.expected_date_return).format()
        )
      : '';
    formdata.append(
      'detailed_reason',
      formData1?.detailed_reason === undefined ? '' : formData1?.detailed_reason
    );
    formdata.append(
      'vehicle_number',
      formData1?.vehicle_number === undefined ? '' : formData1?.vehicle_number
    );
    formdata.append(
      'lr_number',
      formData1?.lr_number === undefined ? '' : formData1?.lr_number
    );
    formdata.append(
      'lr_date',
      formData1?.lr_date === undefined
        ? '' //moment().format()
        : moment(formData1?.lr_date).format()
    );
    formdata.append(
      'carrier_person_name',
      formData1?.carrier_person_name === undefined
        ? ''
        : formData1?.carrier_person_name
    );

    formdata.append(
      'remarks',
      formData1?.remarks === undefined ? '' : formData1?.remarks
    );
    //-----------------------------------------------
    for (let pair of formdata.entries()) {
      console.log('PAYLOAD', pair[0], pair[1]);
    }
    //-----------------------------------------------------------
    dispatch(createDraftUpload(formdata)).then(function (response) {
      if (response?.payload?.success) {
        message.success(response.payload.msg);
        setDraftLoading(false);
        user?.user_details?.roles[0] === 'issuer'
          ? history.push('/issuer-requester')
          : history.push('/dashboard');
      } else {
        message.error(response.payload.msg);
        setDraftLoading(false);
      }
    });
  };

  const handleGetERPVendorData = (parseItems) => {
    setErpVendorLoading(true);
    // console.log('parseItems_vendorList', parseItems);
    // console.log('vendorList', vendorList);
    const payload = { vendorCode: parseItems?.vendor_code };
    dispatch(getAllVendors(payload)).then((response) => {
      /* if (response?.payload?.success) { */
      if (response?.payload?.success && response?.payload?.data?.length === 0) {
        setErpVendorLoading(false);
        setVendorERPModal(true);
      } else if (
        response?.payload?.success &&
        response?.payload?.data?.length > 0
      ) {
        // const vendors = response?.payload?.data?.filter(
        //   (item) => item.vendorCode == parseItems?.vendor_code
        // );
        setErpVendorLoading(false);
        console.log(
          'ERPvendors_for vendor details',
          response?.payload?.data[0]
        );
        setVendorERP(response?.payload?.data[0]);

        form.setFieldsValue({
          vendor_id: response?.payload?.data[0]?.id
        });

        (formData1?.methodType_id === 1 && documentID === 2) ||
        (formData1?.methodType_id === 1 && documentID === 3)
          ? ''
          : dispatch(
              getAllVendorLocation({
                vendorId: response?.payload?.data[0]?.id
              })
            ).then((response) => {
              if (response?.payload?.success) {
                setDisable(false);
                //console.log('Condition-77777_for vendor location');
                parseItems?.vendor_location !== '';
                {
                  // const vendor_location = response?.payload?.data?.filter(
                  //   (item) => item.vendorSite == parseItems?.vendor_location
                  // );
                  //console.log('vendor_location_under_111111', vendor_location);
                  console.log(
                    'vendor_location[0]?.vendorSite_under_2222222',
                    response?.payload?.data[0]?.vendorSite
                  );
                  setVendorERPLocationName(
                    response?.payload?.data[0]?.vendorSite
                  );
                  form.setFieldsValue({
                    vendor_location: response?.payload?.data[0]?.vendorSite // vendor_location[0]?.id
                  });
                  setVendorAddressValue(
                    response?.payload?.data[0]?.addressLine1
                  );
                }
              } else {
                // setDisable(true);
                message.error(
                  'Vendor location details could not fetched successfully'
                );
              }
            });
      } else {
        /*  } */
        setDisable(true);
        message.error('Vendor details could not fetched successfully');
      }
    });
  };
  /* ERP function--------------------- */
  const handleGetERPData = () => {
    setCheck_DocumentNumberLoading(true);
    const check_DocumentNumbe_payload = {
      document_id: documentID,
      document_number: entityNum,
      location_id: projectLocationID
    };
    dispatch(get_Check_DocumentNumber(check_DocumentNumbe_payload)).then(
      function (response) {
        if (response?.payload?.success) {
          setCheck_DocumentNumberLoading(false);
          console.log(
            'response?.payload_of_CHECK_DocumentNumber',
            response?.payload?.isTheDocumentExist
          );
          // message.success(response.payload.msg);

          setErpLoading(true);

          var myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');

          // for Dev only
          /*   myHeaders.append(
            'Authorization',
            'Basic R0FURVBBU1NfT0lDREVWOkJyaWRnZTJAMjRUcGxNQVI='
          ); */
          // for Prod only
          myHeaders.append(
            'Authorization',
            'Basic R0FURVBBU1NfT0lDUFJEOkJyaWRnZTJFUlBATUFSVHBsUFJE'
          );

          const projects = project.filter((item) => item.id == projectId);
          let doc_id = docs.filter((item) => item.id === documentID);

          var raw = JSON.stringify({
            document_details: {
              entity_type: doc_id[0]?.document_name,
              entity_number: entityNum,
              project_code: projects[0]?.project_code,
              project_site: projectLocationName
            }
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            mode: 'cors',
            redirect: 'follow'
          };
          /* For Production only
          https://tplhydprdoic-bmjw2cymlhvm-hy.integration.ocp.oraclecloud.com:443/ic/api/integration/v1/flows/rest/TPL001_GATEPASS_GP2EBS/1.0/tpl/gatepass
         */
          /* for Dev only
          'https://tplhyddevoic-bmjw2cymlhvm-hy.integration.ocp.oraclecloud.com:443/ic/api/integration/v1/flows/rest/TPL001_GATEPASS_GP2EBS/1.0/tpl/gatepass',
          */
          fetch(
            'https://tplhydprdoic-bmjw2cymlhvm-hy.integration.ocp.oraclecloud.com:443/ic/api/integration/v1/flows/rest/TPL001_GATEPASS_GP2EBS/1.0/tpl/gatepass',
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              const parseItems = JSON.parse(result);
              setErpLoading(false);
              setErpParseItems(parseItems);
              setVendorERPShow(true);

              console.log('parseItems', parseItems);
              console.log('parseItems.vendorCode', parseItems.vendor_code);
              // console.log('parseItems.itemdetails', parseItems.itemdetails);

              if (
                parseItems.vendor_code === '' ||
                parseItems.vendor_code === null ||
                parseItems.vendor_code === undefined
              ) {
                setVendorERPModal(true);
                console.log('Vendor is null', parseItems?.vendor_code);
              }
              if (
                (parseItems.itemdetails.length &&
                  parseItems.vendor_code !== '') ||
                (parseItems.itemdetails.length &&
                  parseItems.vendor_code !== null)
              ) {
                if (formData1?.methodType_id === 1) {
                  const itemsERP = parseItems?.itemdetails?.map((item) => ({
                    item_code: item?.item,
                    uom: item?.UOM,
                    quantity: item?.quantity,
                    item_description: item?.description
                  }));
                  setFetchedItems(itemsERP);
                  setFetchedItems1(parseItems?.itemdetails);

                  setFetchedItems2(
                    parseItems?.itemdetails?.map((itm) => ({
                      item_code: itm?.item,
                      uom: itm?.UOM,
                      item_description: itm?.description,
                      quantity: itm?.quantity
                      //item_remarks: event.target.value
                    }))
                  );

                  setFetchedType_of_issue(parseItems?.type_of_issue);
                  if (
                    documentID === 1 &&
                    parseItems.itemdetails.length > 0 &&
                    parseItems?.type_of_issue === 'FREE' &&
                    formData1?.type_id === 2
                  ) {
                    setDisable(false);
                    message.success('Item details fetched successfully');
                    handleGetERPVendorData(parseItems);
                  } else if (
                    documentID === 1 &&
                    parseItems.itemdetails.length > 0 &&
                    parseItems?.type_of_issue === 'FREE' &&
                    formData1?.type_id === 1 &&
                    categories[0]?.id === 1
                  ) {
                    setDisable(false);
                    message.success('Item details fetched successfully');
                    handleGetERPVendorData(parseItems);
                  } else if (
                    (documentID === 1 &&
                      parseItems.itemdetails.length > 0 &&
                      formData1?.category_id === 2 &&
                      parseItems?.type_of_issue === 'CHARGEABLE' &&
                      formData1?.type_id === 2 &&
                      parseItems?.type === 'CONTRACTOR') ||
                    (documentID === 1 &&
                      parseItems.itemdetails.length > 0 &&
                      formData1?.category_id === 2 &&
                      parseItems?.type_of_issue === 'CHARGEABLE' &&
                      formData1?.type_id === 2 &&
                      parseItems?.type === 'CLIENT')
                  ) {
                    setDisable(false);
                    message.success('Item details fetched successfully');
                    // handleGetERPVendorData(parseItems);
                  } else if (
                    documentID === 3 &&
                    parseItems.itemdetails.length > 0
                  ) {
                    setDisable(false);
                    message.success('Item details fetched successfully');
                    // handleGetERPVendorData(parseItems);
                  } else if (
                    documentID === 2 &&
                    parseItems?.itemdetails?.length > 0
                  ) {
                    setDisable(false);
                    message.success('Item details fetched successfully');
                    //handleGetERPVendorData(parseItems);
                  } /*else if (
                    documentID === 2 &&
                    parseItems?.itemdetails?.length === 0
                    //formData1?.category_id === 2 &&
                  ) {
                    console.log('Condition-6666');
                    message.error(
                      'Item details could not fetched successfully. Please enter the correct document number'
                    );
                    setDisable(true);
                    console.log('disable--Condition-6666', disable);
                  } */ else {
                    setVendorERPModal(true);
                    console.log('OUTER_else_Trigger@@@@@@');
                    // handleGetERPVendorData(parseItems);

                    /*  const payload = { search: parseItems?.vendor_code };
                   
                      dispatch(getAllVendors(payload)).then((response) => {
                      if (response?.payload?.success) {
                        // const vendors = response?.payload?.data?.filter(
                        //   (item) => item.vendorCode == parseItems?.vendor_code
                        // );
                        console.log('Condition-77777');
                        console.log('ERPvendors', response?.payload?.data[0]);
                        setVendorERP(response?.payload?.data[0]);
      
                        form.setFieldsValue({
                          vendor_id: response?.payload?.data[0]?.id
                        });
                        dispatch(
                          getAllVendorLocation({
                            vendorId: response?.payload?.data[0]?.id
                          })
                        ).then((response) => {
                          if (response?.payload?.success) {
                            parseItems?.vendor_location !== '';
                            {
                              const vendor_location = response?.payload?.data?.filter(
                                (item) =>
                                  item.vendorSite == parseItems?.vendor_location
                              );
                              form.setFieldsValue({
                                vendor_location: vendor_location[0]?.id
                              });
                              setVendorAddressValue(vendor_location[0]?.addressLine1);
                            }
                          }
                        });
                      }
                    }); */

                    //  console.log(vendors);
                  }
                }
                // setOnProceedValue(value);
                // setSelectedTabId(filters?.[1]?.id);
              } else if (
                documentID === 2 &&
                parseItems.itemdetails?.length === 0
                //formData1?.category_id === 2 &&
              ) {
                message.error(
                  'Item details could not fetched successfully. Please enter the correct document number'
                );
                setDisable(true);
              } else if (
                documentID === 3 &&
                parseItems.itemdetails.length === 0
              ) {
                setDisable(true);
                message.error(
                  'Item details could not fetched successfully. Please enter the correct document number'
                );
              } else {
                //message.error(parseItems.message);
                message.error(
                  'Item details could not fetched successfully. Please enter the correct document number'
                );
              }
            })

            .catch((error) => {
              const parseItems = JSON.parse(error);
              if (
                (parseItems.itemdetails.length === 0 && documentID === 3) ||
                (parseItems.itemdetails.length === 0 && documentID === 2)
              ) {
                message.error(
                  'Item details could not fetched successfully. Please enter the correct document number'
                );
              } else {
                // message.error(parseItems.message);
                message.error(
                  'Item details could not fetched successfully. Please enter the correct document number1111111111'
                );
              }
            });
        } else {
          setCheck_DocumentNumberLoading(false);
          message.error(
            'The document number has already been used for another Gate Pass request'
          );
          form.setFieldsValue({
            doc_number: undefined
          });
          // window.location.reload();
        }
      }
    );
  };

  const onFinishTabOne = (value) => {
    setOnProceedValue(value);
    setSelectedTabId(filters?.[1]?.id);
  };

  useEffect(() => {
    setSelectedTabId(filters?.[0]?.id || null);
  }, []);

  const handleOnClick = () => {
    let docsCopy = [...newDocs];

    docsCopy.push({
      supporting_docs: '',
      docs: ''
    });

    setNewDocs(docsCopy);
  };

  const handleRemove = (e, i) => {
    let uploadeddocs = [...newDocs];
    uploadeddocs.splice(i, 1);
    setNewDocs(uploadeddocs);
  };

  const handlechangeDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: e.target.files[0] };

    setSupportingDoc(newDocValues);
  };

  const handleclearDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: '' };

    setSupportingDoc(newDocValues);
  };

  useEffect(() => {
    if (submit === 'yes') {
      setProccedLoading(true);
      let formdata = new FormData();
      formdata.append('is_submitting', 'yes');
      value['address'] = vendorAddress[0]?.addressLine1;

      formdata.append(
        'isIrisoVendor',
        formData1?.methodType_id === 1 && documentID === 3 ? true : false
      );

      formData1?.methodType_id === 1
        ? formdata.append(
            'items',
            fetchedItems2 === undefined ? '' : JSON.stringify(fetchedItems2)
          )
        : formdata.append(
            'items',
            formItemData?.items === undefined
              ? ''
              : JSON.stringify(formItemData?.items)
          );

      for (let i in supportingDoc) {
        formdata.append('files', supportingDoc[i]);
      }
      formdata.append('project_id', value?.project_id);
      formdata.append('location_id', value?.location_id);
      formdata.append('type_id', value?.type_id);
      formData1?.type_id === 1
        ? formdata.append('category_id', categories[0]?.id)
        : formdata.append('category_id', value?.category_id);

      formdata.append('methodType_id', value?.methodType_id);
      formData1?.methodType_id === 1
        ? formdata.append(
            'document_id',
            documentID === undefined ? '' : documentID
          )
        : '';
      formData1?.methodType_id === 1
        ? formdata.append(
            'document_number',
            entityNum === undefined ? '' : entityNum
          )
        : '';
      formdata.append('reason_id', value?.reason_id);

      if (formData1?.methodType_id === 1 && documentID === 1) {
        if (
          (formData1?.category_id === 2 &&
            erpParseItems?.type_of_issue === 'CHARGEABLE' &&
            formData1?.type_id === 2 &&
            erpParseItems?.type === 'CONTRACTOR') ||
          (formData1?.category_id === 2 &&
            erpParseItems?.type_of_issue === 'CHARGEABLE' &&
            formData1?.type_id === 2 &&
            erpParseItems?.type === 'CLIENT')
        ) {
          formdata.append(
            'vendor_name',
            erpParseItems?.vendor_name === undefined ||
              erpParseItems?.vendor_name === null
              ? ''
              : erpParseItems?.vendor_name
          );
          formdata.append(
            'vendor_code',
            erpParseItems?.vendor_name === undefined ||
              erpParseItems?.vendor_name === null
              ? ''
              : erpParseItems?.vendor_code
          );

          formdata.append(
            'vendor_location',
            erpParseItems?.vendor_location === undefined ||
              erpParseItems?.vendor_location === null
              ? ''
              : erpParseItems?.vendor_location
          );

          formdata.append(
            'vendor_address',
            erpParseItems?.vendor_address === undefined ||
              erpParseItems?.vendor_address === null
              ? ''
              : erpParseItems?.vendor_address
          );
        } else {
          formdata.append(
            'vendor_name',
            vendorName[0]?.vendorName === undefined
              ? vendorERP?.vendorName === undefined
                ? ''
                : vendorERP?.vendorName
              : vendorName[0]?.vendorName
          );
          formdata.append(
            'vendor_code',
            vendorName[0]?.vendorCode === undefined
              ? vendorERP?.vendorCode === undefined ||
                vendorERP?.vendorCode === null
                ? ''
                : vendorERP?.vendorCode
              : vendorName[0]?.vendorCode === null
              ? ''
              : vendorName[0]?.vendorCode
          );
          /*  formdata.append(
          'vendor_location',
          vendorERPLocationName === undefined ? '' : formData1?.vendor_location //vendorERPLocationName
        );  */ //formData1?.vendor_location
          formdata.append(
            'vendor_location',
            vendorAddress?.length === 0
              ? vendorERPLocationName
              : vendorAddress[0]?.vendorSite
          );
          /* formdata.append(
          'vendor_address',
          vendorAddressValue === undefined ? '' : vendorAddressValue
        ); */
          formdata.append(
            'vendor_address',
            vendorAddress?.length === 0 //value?.vendor_location === undefined
              ? vendorAddressValue
              : vendorAddress[0]?.addressLine1
          );
        }
      } else if (formData1?.methodType_id === 1 && documentID === 2) {
        formdata.append(
          'vendor_name',
          erpParseItems?.vendor_code === undefined
            ? ''
            : `${erpParseItems?.vendor_name}`
        );

        formdata.append(
          'vendor_code',
          erpParseItems?.vendor_code === undefined
            ? ''
            : erpParseItems?.vendor_code
        );
        /* formdata.append(
          'vendor_name',
          vendorERP?.vendorName === undefined ? '' : vendorERP?.vendorName
        );
        formdata.append(
          'vendor_code',
          vendorERP?.vendorCode === undefined ? '' : vendorERP?.vendorCode
        ); */
        formdata.append(
          'vendor_location',
          erpParseItems?.vendor_location === undefined
            ? ''
            : erpParseItems?.vendor_location
        ); //formData1?.vendor_location
        formdata.append(
          'vendor_address',
          erpParseItems?.vendor_address === undefined
            ? ''
            : erpParseItems?.vendor_address
        );
      } else if (formData1?.methodType_id === 1 && documentID === 3) {
        formdata.append(
          'vendor_name',
          erpParseItems?.vendor_code === undefined
            ? ''
            : `${erpParseItems?.vendor_name}`
        );
        formdata.append('vendor_code', erpParseItems?.vendor_code);
        formdata.append(
          'vendor_location',
          erpParseItems?.vendor_location === undefined
            ? ''
            : erpParseItems?.vendor_location
        ); //formData1?.vendor_location
        formdata.append(
          'vendor_address',
          erpParseItems?.vendor_address === undefined
            ? ''
            : erpParseItems?.vendor_address
        );
      } else if (formData1?.vendor_id === 'other') {
        formdata.append('vendor_name', otherVendorName);
        formdata.append('vendor_location', otherVendorLocation);
        formdata.append('vendor_address', otherVendorAddress);
      } else if (formData1?.methodType_id === 2) {
        formdata.append(
          'vendor_name',
          vendorName[0]?.vendorName === undefined
            ? ''
            : vendorName[0]?.vendorName
        );
        formdata.append(
          'vendor_code',
          vendorName[0]?.vendorCode === undefined
            ? ''
            : vendorName[0]?.vendorCode
        );
        formdata.append(
          'vendor_location',
          vendorAddress[0]?.vendorSite === undefined
            ? ''
            : vendorAddress[0]?.vendorSite
        ); //formData1?.vendor_location
        formdata.append(
          'vendor_address',
          value?.vendor_location === undefined
            ? ''
            : vendorAddress[0]?.addressLine1
        );
      }

      formdata.append(
        'date',
        moment().format()
        // value?.date === undefined
        //   ? moment().format()
        //   : moment(value?.date).format()
      );

      formData1?.methodType_id === 1
        ? formdata.append('material_id', materials[0]?.id)
        : formdata.append(
            'material_id',
            value?.material_id === undefined ? '' : value?.material_id
          );

      formData1?.category_id === 1 || formData1?.type_id === 1
        ? formdata.append(
            'expected_date_return',
            value?.expected_date_return === undefined
              ? ''
              : moment(value?.expected_date_return).format()
          )
        : '';

      formdata.append(
        'detailed_reason',
        value?.detailed_reason === undefined ? '' : value?.detailed_reason
      );
      formdata.append(
        'vehicle_number',
        value?.vehicle_number === undefined ? '' : value?.vehicle_number
      );
      formdata.append(
        'lr_number',
        value?.lr_number === undefined ? '' : value?.lr_number
      );
      formdata.append(
        'lr_date',
        value?.lr_date === undefined
          ? '' // moment().format()
          : moment(value?.lr_date).format()
      );

      formdata.append(
        'carrier_person_name',
        value?.carrier_person_name === undefined
          ? ''
          : value?.carrier_person_name
      );
      formdata.append(
        'remarks',
        value?.remarks === undefined ? '' : value?.remarks
      );
      formdata.append('approver_id', value?.approver_id);
      formdata.append('issuer_id', value?.issuer_id);

      //   for (const value of formdata.values()) {
      //   console.log('payload:', value);
      // }
      // for (const key of formdata.keys()) {
      //   console.log(key);
      // }
      console.log(
        'vendor_location_PAYLOAD.................',
        formdata.getAll('vendor_location')
      );
      console.log(
        'vendor_address_PAYLOAD.................',
        formdata.getAll('vendor_address')
      );
      console.log(
        'vendor_name_PAYLOAD.................',
        formdata.getAll('vendor_name')
      );
      console.log(
        'vendor_code_PAYLOAD.................',
        formdata.getAll('vendor_code')
      );

      //--------------------------------------------
      for (let pair of formdata.entries()) {
        console.log('PAYLOAD#################', pair[0], pair[1]);
      }
      //----------------------------------------------------

      if (
        value?.approver_id === undefined ||
        value?.issuer_id === undefined ||
        value?.project_id === undefined ||
        value?.location_id === undefined ||
        value?.type_id === undefined ||
        categories[0]?.id === undefined ||
        value?.methodType_id === undefined ||
        value?.reason_id === undefined ||
        value?.material_id === undefined ||
        (formData1?.methodType_id === 1 && fetchedItems2?.length === 0) ||
        (formData1?.methodType_id === 2 && formItemData?.items?.length === 0)
      ) {
        message.error(
          'There are mandatory fields left blank. Please make sure all required fields contain data.'
        );
        setProccedLoading(false);
      } else {
        dispatch(createDraftUpload(formdata)).then(function (response) {
          if (response?.payload?.success) {
            message.success(
              `${response.payload.msg}. Ticket ${response?.payload?.Ticket}`
            );
            user?.user_details?.roles[0] === 'issuer'
              ? history.push('/issuer-requester')
              : history.push('/dashboard');
            setProccedLoading(false);
          } else {
            message.error(response.payload?.err);
            setProccedLoading(false);
          }
        });
      }
    }
    if (draft === 'no') {
      setDraftLoading(true);
      let formdata = new FormData();
      formdata.append('is_submitting', 'no');
      value['address'] = vendorAddress[0]?.addressLine1;
      formdata.append(
        'isIrisoVendor',
        formData1?.methodType_id === 1 && documentID === 3 ? true : false
      );
      formData1?.methodType_id === 1
        ? formdata.append(
            'items',
            fetchedItems2 === undefined ? '' : JSON.stringify(fetchedItems2)
          )
        : formdata.append(
            'items',
            formItemData?.items === undefined
              ? ''
              : JSON.stringify(formItemData?.items)
          );
      //formdata.append('items', JSON.stringify(formItemData?.items));

      for (let i in supportingDoc) {
        formdata.append('files', supportingDoc[i]);
      }
      formdata.append(
        'project_id',
        value?.project_id === undefined ? project[0]?.id : value?.project_id
      );
      formdata.append(
        'location_id',
        value?.location_id === undefined ? '' : value?.location_id
      );
      formdata.append(
        'type_id',
        value?.type_id === undefined ? '' : value?.type_id
      );
      // formdata.append(
      //   'category_id',
      //   value?.category_id === undefined ? '' : value?.category_id
      // );
      formData1?.type_id === 1
        ? formdata.append('category_id', categories[0]?.id)
        : formdata.append(
            'category_id',
            value?.category_id === undefined ? '' : value?.category_id
          );
      formdata.append(
        'methodType_id',
        value?.methodType_id === undefined ? '' : value?.methodType_id
      );
      formData1?.methodType_id === 1
        ? formdata.append(
            'document_id',
            documentID === undefined ? '' : documentID
          )
        : '';
      formData1?.methodType_id === 1
        ? formdata.append(
            'document_number',
            entityNum === undefined ? '' : entityNum
          )
        : '';
      formdata.append(
        'reason_id',
        value?.reason_id === undefined ? '' : value?.reason_id
      );

      if (formData1?.methodType_id === 1 && documentID === 1) {
        if (
          (formData1?.category_id === 2 &&
            erpParseItems?.type_of_issue === 'CHARGEABLE' &&
            formData1?.type_id === 2 &&
            erpParseItems?.type === 'CONTRACTOR') ||
          (formData1?.category_id === 2 &&
            erpParseItems?.type_of_issue === 'CHARGEABLE' &&
            formData1?.type_id === 2 &&
            erpParseItems?.type === 'CLIENT')
        ) {
          formdata.append(
            'vendor_name',
            erpParseItems?.vendor_name === undefined ||
              erpParseItems?.vendor_name === null
              ? ''
              : erpParseItems?.vendor_name
          );
          formdata.append(
            'vendor_code',
            erpParseItems?.vendor_name === undefined ||
              erpParseItems?.vendor_name === null
              ? ''
              : erpParseItems?.vendor_code === null
              ? ''
              : erpParseItems?.vendor_code
          );

          formdata.append(
            'vendor_location',
            erpParseItems?.vendor_location === undefined ||
              erpParseItems?.vendor_location === null
              ? ''
              : erpParseItems?.vendor_location
          );

          formdata.append(
            'vendor_address',
            erpParseItems?.vendor_address === undefined ||
              erpParseItems?.vendor_address === null
              ? ''
              : erpParseItems?.vendor_address
          );
        } else {
          formdata.append(
            'vendor_name',
            vendorName[0]?.vendorName === undefined
              ? vendorERP?.vendorName === undefined
                ? ''
                : vendorERP?.vendorName
              : vendorName[0]?.vendorName
          );
          formdata.append(
            'vendor_code',
            vendorName[0]?.vendorCode === undefined
              ? vendorERP?.vendorCode === undefined ||
                vendorERP?.vendorCode === null
                ? ''
                : vendorERP?.vendorCode
              : vendorName[0]?.vendorCode === null
              ? ''
              : vendorName[0]?.vendorCode
          );
          /*  formdata.append(
          'vendor_location',
          vendorERPLocationName === undefined ? '' : formData1?.vendor_location //vendorERPLocationName
        );  */ //formData1?.vendor_location
          formdata.append(
            'vendor_location',
            vendorAddress?.length === 0
              ? vendorERPLocationName
              : vendorAddress[0]?.vendorSite
          );
          /* formdata.append(
          'vendor_address',
          vendorAddressValue === undefined ? '' : vendorAddressValue
        ); */
          formdata.append(
            'vendor_address',
            vendorAddress?.length === 0 //value?.vendor_location === undefined
              ? vendorAddressValue
              : vendorAddress[0]?.addressLine1
          );
        }
      } else if (formData1?.methodType_id === 1 && documentID === 2) {
        formdata.append(
          'vendor_name',
          erpParseItems?.vendor_code === undefined
            ? ''
            : `${erpParseItems?.vendor_name}`
        );

        formdata.append(
          'vendor_code',
          erpParseItems?.vendor_code === undefined ||
            erpParseItems?.vendor_code === null
            ? ''
            : erpParseItems?.vendor_code
        );
        /* formdata.append(
          'vendor_name',
          vendorERP?.vendorName === undefined ? '' : vendorERP?.vendorName
        );
        formdata.append(
          'vendor_code',
          vendorERP?.vendorCode === undefined ? '' : vendorERP?.vendorCode
        ); */
        formdata.append(
          'vendor_location',
          erpParseItems?.vendor_location === undefined
            ? ''
            : erpParseItems?.vendor_location
        ); //formData1?.vendor_location
        formdata.append(
          'vendor_address',
          erpParseItems?.vendor_address === undefined
            ? ''
            : erpParseItems?.vendor_address
        );
      } else if (formData1?.methodType_id === 1 && documentID === 3) {
        formdata.append(
          'vendor_name',
          erpParseItems?.vendor_code === undefined
            ? ''
            : `${erpParseItems?.vendor_name}`
        );
        formdata.append('vendor_code', erpParseItems?.vendor_code);
        formdata.append(
          'vendor_location',
          erpParseItems?.vendor_location === undefined ||
            erpParseItems?.vendor_location === null
            ? ''
            : erpParseItems?.vendor_location
        ); //formData1?.vendor_location
        formdata.append(
          'vendor_address',
          erpParseItems?.vendor_address === undefined
            ? ''
            : erpParseItems?.vendor_address
        );
      } else if (formData1?.vendor_id === 'other') {
        formdata.append('vendor_name', otherVendorName);
        formdata.append('vendor_location', otherVendorLocation);
        formdata.append('vendor_address', otherVendorAddress);
      } else if (formData1?.methodType_id === 2) {
        formdata.append(
          'vendor_name',
          vendorName[0]?.vendorName === undefined
            ? ''
            : vendorName[0]?.vendorName
        );
        formdata.append(
          'vendor_code',
          vendorName[0]?.vendorCode === undefined ||
            vendorName[0]?.vendorCode === null
            ? ''
            : vendorName[0]?.vendorCode
        );
        formdata.append(
          'vendor_location',
          vendorAddress[0]?.vendorSite === undefined
            ? ''
            : vendorAddress[0]?.vendorSite
        ); //formData1?.vendor_location
        formdata.append(
          'vendor_address',
          value?.vendor_location === undefined
            ? ''
            : vendorAddress[0]?.addressLine1
        );
      }
      /* if (formData1?.methodType_id === 1) {
        formdata.append('vendor_name', vendorERP?.vendorName);
        formdata.append('vendor_code', vendorERP?.vendorCode);
        formdata.append(
          'vendor_location',
          vendorAddress[0]?.vendorSite === undefined
            ? ''
            : vendorAddress[0]?.vendorSite
        ); //formData1?.vendor_location
        formdata.append(
          'vendor_address',
          value?.vendor_location === undefined
            ? ''
            : vendorAddress[0]?.addressLine1
        );
      } else if (formData1?.vendor_id === 'other') {
        formdata.append('vendor_name', otherVendorName);
        formdata.append('vendor_location', otherVendorLocation);
        formdata.append('vendor_address', otherVendorAddress);
      } else {
        formdata.append(
          'vendor_name',
          vendorName[0]?.vendorName === undefined
            ? ''
            : vendorName[0]?.vendorName
        );
        formdata.append(
          'vendor_code',
          vendorName[0]?.vendorCode === undefined
            ? ''
            : vendorName[0]?.vendorCode
        );
        formdata.append(
          'vendor_location',
          vendorAddress[0]?.vendorSite === undefined
            ? ''
            : vendorAddress[0]?.vendorSite
        ); //formData1?.vendor_location
        formdata.append(
          'vendor_address',
          value?.vendor_location === undefined
            ? ''
            : vendorAddress[0]?.addressLine1
        );
      } */

      formdata.append(
        'date',
        moment().format()
        // value?.date === undefined
        //   ? moment().format()
        //   : moment(value?.date).format()
      );

      formData1?.methodType_id === 1
        ? formdata.append('material_id', materials[0]?.id)
        : formdata.append(
            'material_id',
            value?.material_id === undefined ? '' : value?.material_id
          );

      formData1?.category_id === 1 || formData1?.type_id === 1
        ? formdata.append(
            'expected_date_return',
            value?.expected_date_return === undefined
              ? ''
              : moment(value?.expected_date_return).format()
          )
        : '';

      formdata.append(
        'detailed_reason',
        value?.detailed_reason === undefined ? '' : value?.detailed_reason
      );
      formdata.append(
        'vehicle_number',
        value?.vehicle_number === undefined ? '' : value?.vehicle_number
      );
      formdata.append(
        'lr_number',
        value?.lr_number === undefined ? '' : value?.lr_number
      );
      formdata.append(
        'lr_date',
        value?.lr_date === undefined
          ? '' //moment().format()
          : moment(value?.lr_date).format()
      );

      formdata.append(
        'carrier_person_name',
        value?.carrier_person_name === undefined
          ? ''
          : value?.carrier_person_name
      );
      formdata.append(
        'remarks',
        value?.remarks === undefined ? '' : value?.remarks
      );
      formdata.append(
        'approver_id',
        value?.approver_id === undefined ? '' : value?.approver_id
      );
      formdata.append(
        'issuer_id',
        value?.issuer_id === undefined ? '' : value?.issuer_id
      );

      //--------------------------------------------
      for (let pair of formdata.entries()) {
        console.log('PAYLOAD333333333333:', pair[0], pair[1]);
      }
      //----------------------------------------------------

      dispatch(createDraftUpload(formdata)).then(function (response) {
        if (response?.payload?.success) {
          message.success(response.payload.msg);
          user?.user_details?.roles[0] === 'issuer'
            ? history.push('/issuer-requester')
            : history.push('/dashboard');
        } else {
          message.error(response.payload?.err);
          setDraftLoading(false);
        }
      });
    }
  }, [formItemData]);

  const submitHandler = () => {
    setSubmit('yes');
  };
  const draftHandler = () => {
    setDraft('no');
  };

  const handleChangeDocNumber = (e) => {
    setEntityNum(e.target.value);
    form.setFieldsValue({
      vendor_id: undefined,
      vendor_location: undefined
    });
  };
  useEffect(() => {
    form.setFieldsValue({
      items: itemsJsonData?.map((item) => ({
        item_code: item?.Item_Code,
        uom: item?.UOM,
        quantity: item?.Item_Quantity,
        item_description: item?.Item_Description,
        item_remarks: item?.Item_Remarks
      }))
    });
  }, [itemsJsonData]);

  const handleItemsTemplate = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const workSheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(workSheet);
    setItemsJsonData(jsonData);
    console.log(fileName);
    // e.target.value = '';
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (vendorSearch) => {
      setVendorSearch(vendorSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const debounceFetcherProject = useMemo(() => {
    const loadOptions = (projectSearch) => {
      setProjectSearch(projectSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const debounceFetcherProjectLocation = useMemo(() => {
    const loadOptions = (projectLocationSearch) => {
      setProjectLocationSearch(projectLocationSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const debounceFetcherApprover = useMemo(() => {
    const loadOptions = (approverSearch) => {
      setApproverSearch(approverSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const debounceFetcherIssuer = useMemo(() => {
    const loadOptions = (issuerSearch) => {
      setIssuerSearch(issuerSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  const downloadTemplate = () => {
    const downloadUrl = '/Item_Template.xlsx'; // '/templates/template.xlsx';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'Item_Template.xlsx'; //'template.xlsx';
    link.click();
  };

  console.log('fetchedItems', fetchedItems);

  const handleRemarksChange = (index, event) => {
    console.log('index, event', index, event);

    console.log('...fetchedItems1', fetchedItems1);

    const newItems = [...fetchedItems2];
    newItems[index].item_remarks = event.target.value;
    console.log('newItems', newItems);
    setFetchedItems2(newItems);
  };

  // console.log(
  //   'JSON.stringify(fetchedItems)@@@@@@@@@@@@@@@',
  //   JSON.stringify(fetchedItems)
  // );
  console.log('fetchedItems1111111111@@@@@@@@', fetchedItems1);
  console.log('fetchedItems22222', fetchedItems2);
  return (
    <>
      <div className={`mb-4 ${styles.container}`}>
        <div className={`${styles.page_title}`}>
          <span>Create new gate pass request</span>
          <span style={{ float: 'right', marginRight: '20px' }}>
            {' '}
            <a
              href={
                user?.user_details?.roles[0] === 'issuer'
                  ? '/issuer-requester'
                  : '/dashboard'
              }
            >
              <Button className={`${styles.back_button}`}>
                <ArrowLeftOutlined className={`${styles.back_icon}`} />
                Back
              </Button>
            </a>
          </span>
        </div>
        <div className={`${styles.page_sub_title}`}>
          Enter the following fields to create the request to issue the gate
          pass
        </div>
        <div>
          <Radio.Group
            value={selectedTabId}
            size="middle"
            optionType="button"
            buttonStyle="solid"
            className={`${styles.tab_btn_style}`}
          >
            {filters.map((item, id) => {
              return (
                <Radio.Button
                  name="radiogroup"
                  defaultValue={item[0]?.value}
                  value={item?.id}
                  key={id}
                  //onChange={() => setRadiofilter(item.id)}
                  className={`${styles.tab_broder_style}
                    ${selectedTabId === item?.id ? ` ${styles.selected}` : ''}
                  `}
                  onClick={() => {
                    setSelectedTabId(item?.id);
                  }}
                >
                  {item.name}
                </Radio.Button>
              );
            })}
          </Radio.Group>
          <Divider style={{ margin: 0, marginBottom: '20px' }}></Divider>
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
          initialValues={{ items: [''] }}
        >
          {selectedTabId === 1 && (
            <div>
              <Form form={form} onFinish={onFinishTabOne} autoComplete="off">
                <div
                  style={{
                    fontSize: '16px',
                    color: '#2364ad',
                    fontFamily: 'SFProText Bold',
                    marginBottom: '10px'
                  }}
                >
                  General details:
                </div>
                <Row gutter={16}>
                  <Col span={6}>
                    <FormItem
                      label="Project"
                      name="project_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                      // onChange={(e) => onChangeProject(e.target.value)}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onSearch={debounceFetcherProject}
                        onChange={(value) =>
                          handleFormDataOnChange('project_id', value)
                        }
                        placeholder="Select Project"
                      >
                        {project.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.project_name} ({item.project_code})
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Project Location"
                      name="location_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          // console.log('input', input);
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onSearch={debounceFetcherProjectLocation}
                        onChange={(value) =>
                          handleFormDataOnChange('location_id', value)
                        }
                        placeholder="Select Project Location"
                      >
                        {projectLocations.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="GST Number"
                      //name="gst"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        value={gstNumber}
                        allowClear
                        placeholder="Please enter GST number"
                        disabled
                        onChange={(e) =>
                          handleFormDataOnChange('gst', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Type"
                      name="type_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        placeholder="Select Type"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          // console.log('input', input);
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('type_id', value)
                        }
                      >
                        {types.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.type}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Spin spinning={check_DocumentNumberLoading}>
                  <Row gutter={16}>
                    <Col span={6}>
                      <FormItem
                        label="Category"
                        name="category_id"
                        rules={[
                          {
                            required: true,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          disabled={formData1?.type_id === 1}
                          placeholder="Select Category"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                          onChange={(value) =>
                            handleFormDataOnChange('category_id', value)
                          }
                        >
                          {categories.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {item.category}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem
                        label="Method"
                        name="methodType_id"
                        rules={[
                          {
                            required: true,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          placeholder="Select Method"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                          onChange={(value) =>
                            handleFormDataOnChange('methodType_id', value)
                          }
                        >
                          {methods.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {item.method_type}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    {formData1?.methodType_id === 1 ? (
                      <>
                        <Col span={6}>
                          <FormItem
                            label="Reference documents"
                            name="document_id"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Select
                              placeholder="Select Reference Document"
                              onChange={handleChangedocument}
                            >
                              {docs
                                ? docs.map((item, index) => {
                                    return (
                                      <Option value={item.id} key={index}>
                                        {item.document_name}
                                      </Option>
                                    );
                                  })
                                : ''}
                            </Select>
                          </FormItem>
                        </Col>
                        {/* <Col span={6}>
                        <FormItem
                          label="Document number"
                          name="document_number"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            allowClear
                            placeholder="Please enter document number"
                            // disabled
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'document_number',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </Col> */}
                        <Col span={6}>
                          <FormItem
                            label="Document number"
                            name="doc_number"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                            onChange={(e) => handleChangeDocNumber(e)}
                          >
                            <Input
                              disabled={
                                (formData1?.category_id === 1 &&
                                  formData1?.methodType_id === 1 &&
                                  documentID === 2) ||
                                (formData1?.category_id === 1 &&
                                  formData1?.methodType_id === 1 &&
                                  documentID === 3)
                              }
                              className={`${styles.input_lineHeight}`}
                              placeholder="Enter document number"
                            />
                            {/* <Select
                            showSearch
                            allowClear={true}
                            optionFilterProp="children"
                            placeholder="Select document number"
                            onSearch={debounceFetcherDocument}
                            onChange={(value) =>
                              handleFormDataOnChange('document_number', value)
                            }
                            filterOption={(input, option) => {
                              return (
                                option?.key
                                  ?.toString()
                                  ?.toLowerCase()
                                  ?.indexOf(input?.toLowerCase()) >= 0 ||
                                option?.title
                                  ?.toString()
                                  ?.toLowerCase()
                                  ?.indexOf(input?.toLowerCase()) >= 0
                              );
                            }}
                          > */}
                            {/*  {erp_DocumentList?.map((item, index) => (
                            <Option
                              title={item.document_number}
                              key={index}
                              value={item?.id}
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase'
                                }}
                              >
                                <span style={{ marginRight: '4px' }}>
                                  {item?.document_number}
                                </span>
                                <span>
                                  {item?.vendorCode === null ||
                                  item?.vendorCode === undefined
                                    ? ''
                                    : `(${item?.vendorCode})`}
                                </span>
                              </span>
                            </Option>
                          ))} */}
                            {/* </Select> */}
                          </FormItem>
                          {/*  <Spin spinning={erpLoading}> */}
                          <a
                            style={{
                              cursor: 'pointer',
                              float: 'right',
                              marginTop: '-20px',
                              fontWeight: '600'
                            }}
                            onClick={() =>
                              (formData1?.category_id === 1 &&
                                formData1?.methodType_id === 1 &&
                                documentID === 2) ||
                              (formData1?.category_id === 1 &&
                                formData1?.methodType_id === 1 &&
                                documentID === 3)
                                ? ''
                                : handleGetERPData()
                            }
                            /* onClick={() => setVendorERPModal(true)} */
                            disabled={
                              (formData1?.category_id === 1 &&
                                formData1?.methodType_id === 1 &&
                                documentID === 2) ||
                              (formData1?.category_id === 1 &&
                                formData1?.methodType_id === 1 &&
                                documentID === 3)
                            }
                          >
                            Fetch
                          </a>
                          {/*  </Spin> */}
                        </Col>
                      </>
                    ) : null}
                  </Row>
                </Spin>
                <Divider className={`${styles.divider_style}`}></Divider>
                <div
                  style={{
                    fontWeight: 600,
                    color: '#000000',
                    fontSize: '14px',
                    lineHeight: ' 20px',
                    fontFamily: 'Cerebri Sans'
                  }}
                >
                  <span
                    style={{
                      fontSize: '16px',
                      color: '#2364ad',
                      fontFamily: 'SFProText Bold'
                    }}
                  >
                    Shipped to:
                  </span>
                </div>
                <br />

                <Spin spinning={erpLoading}>
                  <Spin spinning={erpVendorLoading}>
                    {formData1?.methodType_id === 1 /* && documentID === 2) ||
                  (formData1?.methodType_id === 1 && documentID === 3) */ ? (
                      <Vendor_IRISO_MRR
                        debounceFetcher={debounceFetcher}
                        handleFormDataOnChange={handleFormDataOnChange}
                        vendors={vendors}
                        formData1={formData1}
                        documentID={documentID}
                        erpParseItems={erpParseItems}
                        vendorAddressValue={vendorAddressValue}
                        vendorLocation={vendorLocation}
                        vendorERPShow={vendorERPShow}
                      />
                    ) : (
                      <Row gutter={16}>
                        <Col span={8}>
                          <FormItem
                            label={'Vendor name and code'}
                            name="vendor_id"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Select
                              showSearch
                              allowClear={true}
                              optionFilterProp="children"
                              placeholder="Select Vendor Name"
                              onSearch={debounceFetcher}
                              onChange={(value) =>
                                handleFormDataOnChange('vendor_id', value)
                              }
                              filterOption={(input, option) => {
                                return (
                                  option?.key
                                    ?.toString()
                                    ?.toLowerCase()
                                    ?.indexOf(input?.toLowerCase()) >= 0 ||
                                  option?.title
                                    ?.toString()
                                    ?.toLowerCase()
                                    ?.indexOf(input?.toLowerCase()) >= 0
                                );
                              }}
                            >
                              {vendorList?.map((item, index) => (
                                <Option
                                  title={item.vendorName}
                                  key={index}
                                  value={item?.id}
                                >
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      textTransform: 'uppercase'
                                    }}
                                  >
                                    <span style={{ marginRight: '4px' }}>
                                      {item?.vendorName}
                                    </span>
                                    {/* <span>
                                {item?.vendorCode === null ||
                                item?.vendorCode === undefined
                                  ? ''
                                  : `(${item?.vendorCode})`}
                              </span> */}
                                  </span>
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </Col>
                        {formData1?.vendor_id === 'other' &&
                        formData1?.methodType_id === 2 ? (
                          ''
                        ) : (
                          <>
                            {' '}
                            <Col span={8}>
                              <FormItem
                                label="Location"
                                name="vendor_location"
                                rules={[
                                  formData1?.vendor_id === 'other'
                                    ? {
                                        required: false,
                                        message: 'Required field'
                                      }
                                    : {
                                        required: true,
                                        message: 'Required field'
                                      }
                                ]}
                                className={`${styles.select_styles}`}
                              >
                                <Select
                                  // disabled={formData1?.vendor_id === 'other'}
                                  disabled={
                                    (documentID === 1 &&
                                      formData1?.category_id === 1 &&
                                      fetchedType_of_issue === 'CHARGEABLE') ||
                                    (documentID === 1 &&
                                      fetchedType_of_issue ===
                                        'STOCK ADJUSTMENT')
                                  }
                                  optionFilterProp="children"
                                  filterOption={(input, option) => {
                                    return option?.children
                                      ?.toString()
                                      ?.toLowerCase()
                                      ?.includes(input?.toLowerCase());
                                  }}
                                  onChange={(value) =>
                                    handleFormDataOnChange(
                                      'vendor_location',
                                      value
                                    )
                                  }
                                  placeholder="Select Location"
                                >
                                  {vendorLocation.map((item, index) => {
                                    return (
                                      <Option value={item.id} key={index}>
                                        <span
                                          style={{
                                            fontSize: '12px',
                                            textTransform: 'uppercase'
                                          }}
                                        >
                                          {' '}
                                          {item.vendorSite}, {item.city},{' '}
                                          {item.state}
                                        </span>
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </FormItem>
                            </Col>
                            <Col span={8}>
                              <FormItem
                                label="Address"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles}`}
                              >
                                <Input
                                  disabled
                                  //value={vendorAddress[0]?.addressLine1}
                                  value={vendorAddressValue}
                                  allowClear
                                  placeholder="Enter Address"
                                  onChange={(e) =>
                                    handleFormDataOnChange(
                                      'vendor_address',
                                      e.target.value
                                    )
                                  }
                                />
                              </FormItem>
                            </Col>
                          </>
                        )}
                      </Row>
                    )}
                    {formData1?.vendor_id === 'other' &&
                      formData1?.methodType_id === 2 && (
                        <Row gutter={16}>
                          <Col span={8}>
                            <FormItem
                              label="Vendor name"
                              name="vendorName"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                allowClear
                                placeholder="Please enter Vendor Name"
                                onChange={handleOtherVendorName}
                              />
                            </FormItem>
                          </Col>
                          <Col span={8}>
                            <FormItem
                              label="Location"
                              name="vendorLocation1"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                allowClear
                                placeholder="Please enter Location"
                                onChange={handleOtherVendorLocation}
                              />
                            </FormItem>
                          </Col>
                          <Col span={8}>
                            <FormItem
                              label="Address"
                              name="vendorAddress"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                // disabled
                                // value={vendorAddress[0]?.addressLine1}
                                allowClear
                                placeholder="Please enter Address"
                                onChange={handleOtherVendorAddress}
                              />
                            </FormItem>
                          </Col>
                        </Row>
                      )}
                  </Spin>
                </Spin>
                <Divider className={`${styles.divider_style}`}></Divider>
                <div
                  style={{
                    fontWeight: 600,
                    color: '#000000',
                    fontSize: '14px',
                    lineHeight: ' 20px',
                    fontFamily: 'Cerebri Sans'
                  }}
                >
                  <span
                    style={{
                      fontSize: '16px',
                      color: '#2364ad',
                      fontFamily: 'SFProText Bold'
                    }}
                  >
                    {' '}
                    Shipment details:
                  </span>
                </div>
                <br />
                <Row gutter={16}>
                  <Col span={8}>
                    <FormItem
                      label="Date"
                      name="date"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.date_styles}`}
                    >
                      <DatePicker
                        format={'DD-MMM-YYYY'}
                        onChange={(_, dateValue) =>
                          handleFormDataOnChange('date', dateValue)
                        }
                        disabledDate={(current) => {
                          return (
                            moment().add(-1, 'days') >= current ||
                            moment().add(0, 'month') <= current
                          );
                        }}
                      ></DatePicker>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    {/*  {formData1?.methodType_id === 1 ? ( */}
                    {/*  <FormItem
                        label="Material belongs to"
                        name="material_id"
                        rules={[
                          {
                            required: true,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input
                          className={`${styles.materialInput_styles}`}
                          disabled={formData1?.methodType_id === 1}
                         // value={materials[0]?.material_id}
                          // defaultValue={materials[0]?.material_name}
                          // initialValues={materials[0]?.material_name}
                          // placeholder="Please enter name"
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'material_id',
                              e.target.value
                            )
                          }
                        />
                      </FormItem> */}
                    {/*   ) : ( */}
                    <FormItem
                      label="Material belongs to"
                      name="material_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        // initialValues={materials[0]?.material_id}
                        placeholder="Select Material"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('material_id', value)
                        }
                        disabled={formData1?.methodType_id === 1}
                      >
                        {materials.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.material_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                    {/*  )} */}
                  </Col>
                  {formData1?.category_id === 1 || formData1?.type_id === 1 ? (
                    <>
                      <Col span={8}>
                        <FormItem
                          label="Expected date of return"
                          name="expected_date_return"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.date_styles}`}
                        >
                          <DatePicker
                            onChange={(_, dateValue) =>
                              handleFormDataOnChange(
                                'expected_date_return',
                                dateValue
                              )
                            }
                            disabledDate={(current) => {
                              return moment().add(-1, 'days') >= current;
                            }}
                            format={'DD-MMM-YYYY'}
                          ></DatePicker>
                        </FormItem>
                      </Col>
                      {/* <Col span={6}>
                        <FormItem
                          label="Actual return date"
                          name="actual_return_date"
                          rules={[
                            {
                              required: false,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.date_styles}`}
                        >
                          <DatePicker
                            disabled
                            defaultValue={moment()}
                            format={'DD-MMM-YYYY'}
                            onChange={(_, dateValue) =>
                              handleFormDataOnChange(
                                'actual_return_date',
                                dateValue
                              )
                            }
                          ></DatePicker>
                        </FormItem>
                      </Col> */}
                    </>
                  ) : (
                    ''
                  )}
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem
                      label="Reason for sending"
                      name="reason_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        placeholder="Select reason"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('reason_id', value)
                        }
                      >
                        {reasons.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.reason_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label={
                        <span>
                          Detailed reason{' '}
                          <span
                            style={{
                              fontSize: '11px',
                              color: '#9E9E9E',
                              fontWeight: 500
                            }}
                          >
                            (150 characters)
                          </span>
                        </span>
                      }
                      name="detailed_reason"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.textarea_styles}`}
                    >
                      <TextArea
                        allowClear
                        maxLength={150}
                        placeholder="Enter detailed reason"
                        size="small"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'detailed_reason',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Divider className={`${styles.divider_style}`}></Divider>
                <Row gutter={16}>
                  <Col span={6}>
                    <FormItem
                      label="Vehicle Number"
                      name="vehicle_number"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        maxLength={10}
                        placeholder="Please enter vehicle number"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'vehicle_number',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="LR number"
                      name="lr_number"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter LR number"
                        onChange={(e) =>
                          handleFormDataOnChange('lr_number', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="LR Date"
                      name="lr_date"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.date_styles}`}
                    >
                      <DatePicker
                        //disabled
                        //defaultValue={moment() || ''}
                        format={'DD-MMM-YYYY'}
                        onChange={(_, dateValue) =>
                          handleFormDataOnChange('lr_date', dateValue)
                        }
                        disabledDate={(current) => {
                          return moment().add(0, 'month') <= current;
                        }}
                        // moment().add(-1, 'days') >= current ||
                      ></DatePicker>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Name of the carrier person"
                      name="carrier_person_name"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter name"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'carrier_person_name',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
                {newDocs.map((item, index) => (
                  <>
                    {supportingDoc[index] !== '' &&
                    supportingDoc[index] !== undefined ? (
                      <>
                        <div
                          key={index}
                          className={`mb-2`}
                          /* style={{
                              display: 'flex',
                              justifyContent: 'flex-start'
                            }} */
                        >
                          <span>
                            <Tag color="geekblue">
                              {supportingDoc[index].name}
                            </Tag>
                            <DeleteOutlined
                              style={{ color: ' rgb(215, 67, 67)' }}
                              onClick={(e) => handleclearDoc(e, index)}
                            />
                          </span>

                          {index === 0 ? (
                            <span>
                              <Button
                                className={`${styles?.upload_button}`}
                                onClick={handleOnClick}
                              >
                                Add Document
                              </Button>
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start'
                          }}
                        >
                          <FormItem
                            name="files"
                            rules={[
                              {
                                required: false,
                                message: 'Enter type'
                              }
                            ]}
                          >
                            <label
                              htmlFor="file-upload"
                              className={styles.custom_file_upload}
                            >
                              <UploadOutlined /> Upload
                            </label>
                            <Input
                              accept="*"
                              className={styles.upload}
                              type="file"
                              onChange={(e) => handlechangeDoc(e, index)}
                              id="file-upload"
                            />
                          </FormItem>

                          {index === 0 ? (
                            <span>
                              <Button
                                className={`${styles.upload_button}`}
                                onClick={handleOnClick}
                              >
                                Add Document
                              </Button>
                            </span>
                          ) : (
                            <span>
                              <Button
                                style={{
                                  padding: '6px !important',
                                  fontSize: '11px !important'
                                }}
                                className={`${styles.remove_button}`}
                                onClick={(e) => handleRemove(e, index)}
                              >
                                Remove
                              </Button>
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  </>
                ))}
                <Row gutter={16}>
                  <Col span={24}>
                    <FormItem
                      label={
                        <span>
                          Remarks{' '}
                          <span
                            style={{
                              fontSize: '11px',
                              color: '#9E9E9E',
                              fontWeight: 500
                            }}
                          >
                            (200 characters)
                          </span>
                        </span>
                      }
                      name="remarks"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.textarea_styles}`}
                    >
                      <TextArea
                        maxLength={200}
                        //rows={5}
                        allowClear
                        placeholder="Enter remarks"
                        size="small"
                        onChange={(e) =>
                          handleFormDataOnChange('remarks', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem
                      label="Approver"
                      name="approver_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        showSearch
                        placeholder="Select approver"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onSearch={debounceFetcherApprover}
                        onChange={(value) =>
                          handleFormDataOnChange('approver_id', value)
                        }
                      >
                        {approvers.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {`${item.name} (${item.email})`}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Issuer"
                      name="issuer_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        showSearch
                        placeholder="Select issuer"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onSearch={debounceFetcherIssuer}
                        onChange={(value) =>
                          handleFormDataOnChange('issuer_id', value)
                        }
                      >
                        {issuers.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {`${item.name} (${item.email})`}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  {/* <Col span={6}>
                    <FormItem
                      label="Vendor Code"
                      name="vendor_code"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter Vendor Code"
                        onChange={(e) =>
                          handleFormDataOnChange('vendor_code', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col> */}
                  {/* <Col span={6}>
                    <FormItem
                      label="Project"
                      name="project"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter Project"
                        onChange={(e) =>
                          handleFormDataOnChange('project', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col> */}
                  {/* <Col span={6}>
                    <FormItem
                      label="Location"
                      name="location"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter Location"
                        onChange={(e) =>
                          handleFormDataOnChange('location', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col> */}
                  {/* <Col span={6}>
                    <FormItem
                      label="Document Type"
                      name="document_type"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter document type"
                        onChange={(e) =>
                          handleFormDataOnChange('doc_type', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col> */}
                  {/* <Col span={6}>
                    <FormItem
                      label="Document Number"
                      name="doc_number"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter document number"
                        onChange={(e) =>
                          handleFormDataOnChange('doc_number', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col> */}
                </Row>
              </Form>
              <space>
                <span style={{ marginRight: '10px' }}>
                  <Button
                    loading={draftLoading}
                    // htmlType="submit"
                    onClick={saveAsDraft}
                    className={`${styles.Save_draft_button_style}`}
                  >
                    Save as draft
                  </Button>
                </span>
                <span>
                  <Button
                    //loading={proccedLoading}
                    htmlType="submit"
                    // onClick={onFinishTabOneNext}
                    className={`${styles.first_next_button_style}
                    ${
                      selectedTabId === filters?.[1]?.id
                        ? ` ${styles.selected}`
                        : ''
                    }
                  `}
                    disabled={disable === true}
                  >
                    Proceed to Item Details
                  </Button>
                </span>
              </space>
            </div>
          )}
          {selectedTabId === 2 && (
            <div className={`${styles.margin_grey_holder}`}>
              {/*  <Divider class={`${styles.divider_bottom_margin}`}></Divider> */}
              <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <div className={`${styles.items_title}`}>
                    Item details for the gate pass:
                  </div>
                </div>
                {formData1?.methodType_id === 1 ? (
                  ''
                ) : (
                  <div>
                    <span style={{ marginRight: '15px' }}>
                      {/* <a
                        href={
                          'https://1drv.ms/x/s!AsoL48RdL4ndoZgWSWwm0idiluWnwg?e=uGBSKa'
                        }
                        target="_blank"
                        rel="noreferrer"
                        download="ItemsTemplate.xlsx"
                        className={`${styles.items_title1}`}
                      >
                        <span className={`${styles.items_import}`}>
                          Download item import template
                        </span>
                      </a> */}
                      <a onClick={downloadTemplate}>
                        Download item import template
                      </a>
                    </span>
                    <span>
                      <label
                        htmlFor="file-upload"
                        className={styles.custom_file_upload}
                      >
                        <UploadOutlined /> Browse
                      </label>
                      <Input
                        value={fileValue}
                        accept="*"
                        className={styles.upload}
                        type="file"
                        onChange={(e) => {
                          setFileValue('');
                          handleItemsTemplate(e);
                        }}
                        id="file-upload"
                      />
                      {/*  {fileName && (
                      <div>
                        <span
                          style={{
                            fontSize: '12px',
                            fontFamily: 'SFProText Regular',
                            color: '#9E9E9E'
                          }}
                        >
                          {fileName}
                        </span>
                      </div>
                    )} */}
                    </span>
                  </div>
                )}
              </Row>
              {fetchedItems1?.map((item, index) => {
                return (
                  <Row gutter={24} key={index}>
                    <Col span={4}>
                      <FormItem
                        label="Item"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input allowClear value={item?.item} />
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem
                        label="Description"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input allowClear value={item?.description} />
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem
                        label="UOM"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input allowClear value={item?.UOM} />
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem
                        label="Quantity"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input allowClear value={item?.quantity} />
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem
                        label="Remarks"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.textarea_styles} ${styles.padding_styles}`}
                      >
                        <TextArea
                          allowClear
                          placeholder="Enter item remarks"
                          size="small"
                          value={item.remarks}
                          onChange={(e) => handleRemarksChange(index, e)}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                );
              })}
              {formData1?.methodType_id === 1 ? (
                // 'ERP DATA'
                ''
              ) : (
                /*  <Form form={form} onFinish={onFinishItem} autoComplete="off">  */
                <Form.List
                  name="items"
                  className={`${styles.form_list_margin}`}
                  //initialValues={{ items: [''] }}
                  // initialValue={itemsJsonData}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <>
                          <Row
                            key={key}
                            gutter={16}
                            className={`${styles.grey_holder}  w-100 mt-1`}
                          >
                            <Col span={4}>
                              <FormItem
                                /*  {...restField}
                                name={[name, 'item_name']}
                                label="Item name"
                                fieldKey={[fields.fieldKey, 'item_name']} */
                                label="Item Code"
                                {...restField}
                                name={[name, 'item_code']}
                                rules={[
                                  {
                                    required: false,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles} ${styles.padding_styles}`}
                              >
                                <Input
                                  //defaultValue={itemVal[0]?.qty_available}
                                  allowClear
                                  placeholder="Please enter item code"
                                  onChange={(e) =>
                                    handleChangeItem(
                                      e.target.value,
                                      'item_code',
                                      e.target.value
                                    )
                                  }
                                  /* onChange={(e) =>
                                    handleChangeItem('item_name', e.target.value)
                                  } */
                                />
                                {/* <Select
                                    placeholder="Please enter item name"
                                    onChange={handleChangeItem}
                                  >
                                    {items?.rows?.map((item, index) => {
                                      return (
                                        <Option
                                          value={item?.item_name}
                                          key={index}
                                        >
                                          <span style={{ marginLeft: '10px' }}>
                                            {' '}
                                            {item?.item_name} (
                                            {`${item?.item_code}`})
                                          </span>
                                        </Option>
                                      );
                                    })}
                                  </Select> */}
                              </FormItem>
                            </Col>
                            <Col span={6}>
                              <FormItem
                                label="Item Description"
                                {...restField}
                                name={[name, 'item_description']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                ]}
                                /*   className={`${styles.textarea_styles} ${styles.padding_styles}`}
                              > */ className={`${styles.input_styles} ${styles.padding_styles}`}
                              >
                                <Input
                                  allowClear
                                  placeholder="Enter item description"
                                  onChange={(e) =>
                                    handleChangeItem(
                                      e.target.value,
                                      'item_description',
                                      e.target.value
                                    )
                                  }
                                />
                                {/* <TextArea
                                  allowClear
                                  placeholder="Enter item description"
                                  size="small"
                                  onChange={(e) =>
                                    handleChangeItem(
                                      e.target.value,
                                      'item_description',
                                      e.target.value
                                    )
                                  }
                                /> */}
                              </FormItem>
                            </Col>

                            <Col span={4}>
                              <FormItem
                                label="UOM"
                                {...restField}
                                name={[name, 'uom']}
                                // initialValues={itemVal[0]?.uom}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles} ${styles.padding_styles}`}
                              >
                                <Input
                                  //defaultValue={itemVal[0]?.qty_available}
                                  allowClear
                                  placeholder="Please enter UOM"
                                  onChange={(e) =>
                                    handleChangeItem(
                                      e.target.value,
                                      'uom',
                                      e.target.value
                                    )
                                  }
                                />
                              </FormItem>
                            </Col>

                            <Col span={4}>
                              <FormItem
                                label="Quantity"
                                {...restField}
                                name={[name, 'quantity']}
                                // initialValues={itemVal[0]?.qty_available}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                  /* {
                                    validator: async (_, value) => {
                                      let num = 0;
                                      if (
                                        num < value */
                                  /*   &&
                                        value === value.replace(/[^0-9]/g, '') */
                                  /* ) {
                                        return Promise.resolve();
                                      } else {
                                        return Promise.reject(
                                          'Enter valid number'
                                        );
                                      }
                                    }
                                  } */
                                ]}
                                className={`${styles.input_styles} ${styles.padding_styles}`}
                              >
                                <Input
                                  //defaultValue={itemVal[0]?.qty_available}
                                  type="number"
                                  step="0.001"
                                  min={0}
                                  onKeyPress={preventMinus}
                                  allowClear
                                  placeholder="Please enter Quantity "
                                  onChange={(e) =>
                                    handleChangeItem('quantity', e.target.value)
                                  }
                                />
                              </FormItem>
                            </Col>
                            <Col span={6}>
                              <Row>
                                <Col span={21}>
                                  <FormItem
                                    label="Item Remarks"
                                    {...restField}
                                    name={[name, 'item_remarks']}
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Required field'
                                      }
                                    ]}
                                    className={`${styles.textarea_styles} ${styles.padding_styles}`}
                                  >
                                    <TextArea
                                      allowClear
                                      placeholder="Enter item remarks"
                                      size="small"
                                      onChange={(e) =>
                                        handleChangeItem(
                                          e.target.value,
                                          'item_remarks',
                                          e.target.value
                                        )
                                      }
                                    />
                                  </FormItem>
                                </Col>
                                <Col
                                  span={3}
                                  className={`${styles.delete_icon}`}
                                >
                                  {fields.length > 1 ? (
                                    <div className={`${styles.delete_styles}`}>
                                      <DeleteOutlined
                                        onClick={() => remove(name)}
                                      />
                                    </div>
                                  ) : null}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      ))}
                      <Form.Item className={`${styles.formItem_marginBottom}`}>
                        <Button
                          className={`${styles.add_button_style}`}
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add item
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                /*   </Form>   */
              )}
              <Row gutter={24} className={`${styles.button_margin_left}`}>
                <Button
                  // loading={createDraftLoading}
                  loading={draftLoading}
                  name="draft"
                  htmlType="submit"
                  className={`${styles.next_button_style}`}
                  onClick={draftHandler}
                >
                  Save as draft
                </Button>

                <FormItem>
                  <div className={`${styles.button_margin}`}>
                    <Button
                      //loading={createDraftLoading}
                      loading={proccedLoading}
                      name="submit"
                      className={`${styles.first_next_button_style}`}
                      htmlType="submit"
                      size="middle"
                      onClick={submitHandler}
                    >
                      Submit
                    </Button>
                  </div>
                </FormItem>
              </Row>
            </div>
          )}
        </Form>
        {vendorERPModal ? (
          <VendorERP_Modal
            vendorERPModal={vendorERPModal}
            setVendorERPModal={setVendorERPModal}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default gatepassrequest;
