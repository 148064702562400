import React, { useState, useMemo, useEffect } from 'react';
import styles from './index.module.less';
import CustomLayout from '@/common/Layout';

import { Row, Col, Select, Form, DatePicker, Card, Button } from 'antd';
import { getLoggedInUser } from 'utilities/helpers';
import moment from 'moment';
import { history } from 'app/history';
import { apiUrl } from 'utilities/apiClient';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import FormItem from 'common/FormItem';
import {
  getAllProject,
  getAllProjectLocations,
  getAlltypes,
  getAllcategories,
  getAllmethods
} from '../createGP/redux/slice';

const { Option } = Select;
//const { TextArea } = Input;

const Reports = () => {
  const user = getLoggedInUser();
  const role = user?.user_details?.roles[0];

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [filterPayload, setFilterPayload] = useState(''); //[moment(), moment()]
  const [loader, setLoader] = useState(false);
  const [formData1, setFormData1] = useState({});
  const [projectSearch, setProjectSearch] = useState('');

  useEffect(() => {
    handlegetAllRequest();
  }, []);
  const handlegetAllRequest = () => {
    //dispatch(getAllProjectLocations());
    dispatch(getAlltypes());
    dispatch(getAllcategories());
    dispatch(getAllmethods());
  };
  useEffect(() => {
    const payload = {}; //vendorId
    if (projectSearch) payload['search'] = projectSearch;
    dispatch(getAllProject(payload));
  }, [projectSearch]); //vendorId
  useEffect(() => {
    let payload = {
      id: formData1?.project_id
    };
    formData1?.project_id === undefined
      ? ''
      : dispatch(getAllProjectLocations(payload));
  }, [formData1?.project_id]);

  const { project, projectLocations, types, categories, methods } = useSelector(
    (state) => state.requests
  );
  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);
    if (slug === 'project_id') {
      // setGstNumber('');
      // setProjectId(value);
      //let projectName1 = project.filter((item) => item.id === value);
      //setProjectName(projectName1[0]?.project_name);
      form.setFieldsValue({
        location_id: undefined
      });
    }
    setFormData1({
      ...formData1,
      [slug]: value
    });
  };

  const debounceFetcherProject = useMemo(() => {
    const loadOptions = (projectSearch) => {
      setProjectSearch(projectSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const onFinish = (value) => {
    console.log('value', value);
    handleGetRequests();
    // setOnProceedValue(value);
    // setSelectedTabId(filters?.[1]?.id);
  };

  const handleGetRequests = () => {
    setLoader(true);
    console.log(
      'fromDate***********',
      moment(filterPayload[0]?._d)?.format('YYYY-MM-DD')
    );
    console.log(
      'toDate************',
      moment(filterPayload[1]?._d)?.format('YYYY-MM-DD')
    );

    const authToken = user?.token;
    console.log(authToken);

    const fromDate =
      moment(filterPayload[0]?._d)?.format('YYYY-MM-DD') ||
      moment()?.format('YYYY-MM-DD');
    const toDate =
      moment(filterPayload[1]?._d)?.format('YYYY-MM-DD') ||
      moment()?.format('YYYY-MM-DD');

    const payload = {
      project_id:
        formData1?.project_id === undefined ? '' : formData1?.project_id,
      project_location_id:
        formData1?.location_id === undefined ? '' : formData1?.location_id,
      type_id: formData1?.type_id === undefined ? '' : formData1?.type_id,
      category_id:
        formData1?.category_id === undefined ? '' : formData1?.category_id,
      method_id:
        formData1?.methodType_id === undefined ? '' : formData1?.methodType_id
      // from_date: fromDate,
      // to_date: toDate
    };

    fetch(
      `${apiUrl}/export?from_date=${fromDate}&to_date=${toDate}&project_id=${payload?.project_id}&project_location_id=${payload?.project_location_id}&type_id=${payload?.type_id}&category_id=${payload?.category_id}&method_id=${payload?.method_id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `${authToken}`
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        setLoader(false);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Eexport_report.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };
  //console.log('formData1', formData1);
  return (
    <CustomLayout sider="true">
      <div className={`${styles.main_container}`}>
        <div className={`${styles.reports_title}`}>Export Reports</div>
        <div className={`${styles.card_container}`}>
          <Card className={`${styles.cardStyles}`}>
            <Form form={form} onFinish={onFinish} autoComplete="off">
              {/*  <div className={`${styles.dateRange_container}`}>
              <div
                style={{
                  marginBottom: '0px',
                  color: 'black',
                  fontWeight: 600,
                  fontFamily: 'SFProText Regular',
                  fontSize: '14px'
                }}
              >
                Select Date Range:
              </div> */}
              <Row className={`${styles.dateRange_RowContainer}`}>
                <Col span={24}>
                  <FormItem
                    label="Select Date Range"
                    name="x"
                    rules={[
                      {
                        required: true,
                        message: 'Required field'
                      }
                    ]}
                    className={`${styles.select_styles}`}
                    // onChange={(e) => onChangeProject(e.target.value)}
                  >
                    <DatePicker.RangePicker
                      className={`${styles.dateRange_picker}`}
                      format={'DD-MMM-YYYY'}
                      onChange={(x) => {
                        setFilterPayload(x);
                      }}
                      disabledDate={(current) => {
                        return current && current.valueOf() > Date.now();
                      }}
                      value={filterPayload}
                      // separator={'-'}
                      // style={{ width: '100%' }}
                    />
                  </FormItem>
                </Col>
              </Row>

              {/* </div> */}
              <Row gutter={16}>
                <Col span={12}>
                  <div>
                    <div className={`${styles.selectTitle_styles}`}>
                      Project:
                    </div>
                    <FormItem
                      // label="Project"
                      name="project_id"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                      // onChange={(e) => onChangeProject(e.target.value)}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onSearch={debounceFetcherProject}
                        onChange={(value) =>
                          handleFormDataOnChange('project_id', value)
                        }
                        placeholder="Select Project"
                      >
                        {project.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.project_name} ({item.project_code})
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <div className={`${styles.selectTitle_styles}`}>
                      Project Location:
                    </div>
                    <FormItem
                      // label="Project Location"
                      name="location_id"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          // console.log('input', input);
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('location_id', value)
                        }
                        placeholder="Select Project Location"
                      >
                        {projectLocations.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <div>
                    <div className={`${styles.selectTitle_styles}`}>Type:</div>
                    <FormItem
                      // label="Type"
                      name="type_id"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        placeholder="Select Type"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          // console.log('input', input);
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('type_id', value)
                        }
                      >
                        {types.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.type}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <div className={`${styles.selectTitle_styles}`}>
                      Category:
                    </div>
                    <FormItem
                      //label="Category"
                      name="category_id"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        placeholder="Select Category"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('category_id', value)
                        }
                      >
                        {categories.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.category}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <div className={`${styles.selectTitle_styles}`}>
                      Method:
                    </div>
                    <FormItem
                      //label="Method"
                      name="methodType_id"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        placeholder="Select Method"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('methodType_id', value)
                        }
                      >
                        {methods.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.method_type}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </div>
                </Col>
              </Row>
              <FormItem>
                <Row className={`${styles.buttons_container}`}>
                  <Button
                    className={`${styles.cancel_button}`}
                    size="middle"
                    onClick={() =>
                      role === 'requester'
                        ? history.push('/dashboard')
                        : role === 'issuer'
                        ? history.push('/issuer-gatepass')
                        : role === 'approver'
                        ? history.push('/gatepass-requests')
                        : role === 'ho'
                        ? history.push('/ho-requests')
                        : ''
                    }
                  >
                    Back
                  </Button>
                  <Button
                    loading={loader}
                    // name="submit"
                    className={`${styles.submit_button}`}
                    htmlType="submit"
                    size="middle"
                    // onClick={handleGetRequests}
                  >
                    Download
                  </Button>
                </Row>
              </FormItem>
            </Form>
          </Card>
        </div>
      </div>
    </CustomLayout>
  );
};

export default Reports;
