import React, { useState } from 'react';
import { Row } from 'antd';
import styles from './index.module.less';
import AuthWrapper from 'common/AuthWrapper/index';
import logo from '../../images/Ellipse_1.png';
import check from '../../images/check.svg';
import CustomButton from '@/common/CustomButton';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slice';
import LocalStorage from 'utilities/localStorage';
import { history } from 'app/history';
import { useLocation } from 'react-router-dom';
import { Message, Icon } from 'semantic-ui-react';

const LoginPage = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [errorMsg] = useState('');
  const dispatch = useDispatch();
  let location = useLocation();
  const pageInfo = [
    {
      text: 'Manage GP & request a new GP.',
      icon: <img src={check} />
    },
    {
      text: 'Review and approval of the gatepass.',
      icon: <img src={check} />
    },
    {
      text: 'Audit and maintenance of reports.',
      icon: <img src={check} />
    }
  ];

  const handleOnClick = () => {
    const user_details = LocalStorage.getItem('gatepass_user');
    console.log(user_details ? user_details : 'No response');
    instance
      .loginPopup({
        scopes: ['User.Read']
      })
      .then((result) => {
        console.log(result);
        return dispatch(login({ token: result.accessToken }));
      })

      .then((result) => {
        console.log(result);
        if (result.payload.success) {
          LocalStorage.setItem('gatepass_user', JSON.stringify(result.payload));
          const user = result.payload;
          console.log('user', user.user_details.roles[0]);
          if (user.user_details.roles[0] === 'requester') {
            location.state?.from?.pathname
              ? history.push(`${location.state?.from?.pathname}`)
              : history.push('/requesters-sap-dashboard'); //history.push('/dashboard'); //history.push('/requesters-sap-dashboard');
          } else if (user.user_details.roles[0] === 'approver') {
            location.state?.from?.pathname
              ? history.push(`${location.state?.from?.pathname}`)
              : history.push('/dashboard-approver-sap'); //history.push('/gatepass-requests'); //history.push('/dashboard-approver-sap');
          } else if (user.user_details.roles[0] === 'issuer') {
            location.state?.from?.pathname
              ? history.push(`${location.state?.from?.pathname}`)
              : history.push('/dashboard-issuer-sap'); //history.push('/issuer-gatepass'); //history.push('/dashboard-issuer-sap');
          } else if (user.user_details.roles[0] === 'ho') {
            location.state?.from?.pathname
              ? history.push(`${location.state?.from?.pathname}`)
              : history.push('/ho-requests_sap'); //history.push('/ho-requests'); // history.push('/ho-requests_sap');
          }
        } else {
          console.log('$$$$$$$$$$$$$$$$$$$');
        }
      })
      .catch((err) => {
        console.log('$$$$$$$$$$$$$$$$$$$', err, err);
        isAuthenticated
          ? instance.logoutRedirect({
              account: LocalStorage.homeAccountId,
              postLogoutRedirectUri: '/',
              mainWindowRedirectUri: '/'
            })
          : '';
      });
  };

  return (
    <AuthWrapper>
      <Row>
        <div className={styles.right_ellipse}>
          <img src={logo} />
        </div>
        <div className={styles.content}>
          <div className={`${styles.title}`}>Welcome to Gate Pass App</div>
          <div className={`${styles.sub_title}`}>
            Management of GPs and approvals in one place.
          </div>
          {pageInfo?.map((item, i) => (
            <div key={i} className={`mb-2 ${styles.rows_checkox}`}>
              <span className="mr-2"> {item.icon}</span>{' '}
              <span className={`${styles.rows_checkox_text}`}>{item.text}</span>
            </div>
          ))}

          <Row>
            <CustomButton
              className={`mt-3 ${styles.secondary_button}`}
              // src={microsoft}
              // id="microsoft-login"
              // centered="true"
              // alt="Microsoft"
              onClick={handleOnClick}
            >
              <b> Go to your Account</b>
            </CustomButton>
          </Row>
        </div>
      </Row>
      <div>
        {errorMsg && (
          <Message negative>
            <Icon name="warning" />
            {errorMsg}
          </Message>
        )}
      </div>
    </AuthWrapper>
  );
};

export default LoginPage;
