import React, { useState } from 'react';
import styles from './index.module.less';
import CustomLayout from '@/common/Layout';
import { DownloadOutlined } from '@ant-design/icons';
import { Row, Card, Button, Tooltip } from 'antd';
import { getLoggedInUser } from 'utilities/helpers';
import { history } from 'app/history';
import { apiUrl2 } from 'utilities/apiClient';

const ReportsSAPAdmin = () => {
  window.localStorage.setItem('set_Radiofilter', JSON?.stringify(''));
  window.localStorage.setItem(
    'set_Radiofilter_Admin',
    JSON?.stringify('Plants')
  );

  const user = getLoggedInUser();

  const [loader, setLoader] = useState(false);

  const handleUserMapping = () => {
    setLoader(true);
    const authToken = user?.token;
    fetch(`${apiUrl2}/admin/user-report`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/csv',
        Authorization: `${authToken}`
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        setLoader(false);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `user-report.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };
  const handlePlantStorageLocation = () => {
    setLoader(true);
    const authToken = user?.token;
    console.log(authToken);
    fetch(`${apiUrl2}/admin/plant-storagelocation-report`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/csv',
        Authorization: `${authToken}`
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        setLoader(false);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `plant-storage-location.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };
  const handleUserPlant = () => {
    setLoader(true);

    const authToken = user?.token;
    console.log(authToken);

    fetch(`${apiUrl2}/admin/user-mapping-report`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/csv',
        Authorization: `${authToken}`
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        setLoader(false);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `user-plant-storage-location.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };
  return (
    <CustomLayout sider="true">
      <div className={`${styles.main_container}`}>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div className={`${styles.reports_title}`}>Export Admin Reports</div>
          <div>
            {' '}
            <Button
              className={`${styles.cancel_button}`}
              size="middle"
              onClick={() => history.push('/admin-sap')}
            >
              Back
            </Button>
          </div>
        </Row>

        <div className={`${styles.card_container}`}>
          <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.buttons_container}`}>
              <div className={`${styles.download_title}`}>
                User to role mapping report
              </div>
              <Tooltip title="Download">
                <DownloadOutlined
                  loading={loader}
                  className={`${styles.download_icon}`}
                  onClick={handleUserMapping}
                />
              </Tooltip>
            </Row>
          </Card>
          <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.buttons_container}`}>
              <div className={`${styles.download_title}`}>
                Plant and its Storage location mapping report
              </div>
              <Tooltip title="Download">
                <DownloadOutlined
                  loading={loader}
                  className={`${styles.download_icon}`}
                  onClick={handlePlantStorageLocation}
                />
              </Tooltip>
            </Row>
          </Card>
          <Card className={`${styles.cardStyles}`}>
            <Row className={`${styles.buttons_container}`}>
              <div className={`${styles.download_title}`}>
                User with Plant and Storage location mapping report
              </div>
              <Tooltip title="Download">
                <DownloadOutlined
                  loading={loader}
                  className={`${styles.download_icon}`}
                  onClick={handleUserPlant}
                />
              </Tooltip>
            </Row>
          </Card>
        </div>
      </div>
    </CustomLayout>
  );
};

export default ReportsSAPAdmin;
