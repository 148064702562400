import apiClient from 'utilities/apiClient';

export const requests = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.requester_sap}/${url}`, payload, true);
};

export const delete_requests = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.requester_sap}/${url}`,
    payload,
    true
  );
};

export const delete_draft = (url, payload) => {
  return apiClient.delete(
    `${apiClient.Urls.requester_sap}/${url}`,
    payload,
    true
  );
};
