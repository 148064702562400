import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import {
  Col,
  Row,
  Select,
  Form,
  Divider,
  Input,
  DatePicker,
  message,
  Button,
  Radio
} from 'antd';

import FormItem from 'common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  editRequest,
  getrequestByid,
  getAllProjectLocations,
  getAlltypes,
  getAllcategories,
  getAllmethods,
  getAllreferenceDocs,
  getAllmaterils,
  getAllreasontosend,
  getAllapprovers,
  getrequesterdata,
  createRequest
} from '../../redux/slice';
import { history } from '@/app/history';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';

const { Option } = Select;
const { TextArea } = Input;
const filters = [
  {
    id: 1,
    name: 'GP information',
    value: 'GP_information'
  },
  {
    id: 2,
    name: 'Item details',
    value: 'item_details'
  }
  /*  {
    id: 3,
    name: 'Approver',
    value: 'approver'
  } */
];

const EditGPByIssuer = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const user = getLoggedInUser();
  const {
    requestbyid,
    projectLocations,
    types,
    categories,
    methods,
    docs,
    materials,
    reasons
    // approvers
  } = useSelector((state) => state.edit);

  console.log('requestbyid', requestbyid);

  const [documentNumber, setDocumentNumber] = useState('');
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [formData, setFormData] = useState({});
  // const [formItemData, setFormItemData] = useState({});
  const [formTabeOneData, setFormTabeOneData] = useState({});

  useEffect(() => {
    handlegetRequestbyid();
  }, []);
  const handlegetRequestbyid = () => {
    {
      user?.user_details?.role === 'requester'
        ? dispatch(getrequesterdata(params.id))
        : '';
    }
    {
      user?.user_details?.role === 'issuer'
        ? dispatch(getrequestByid(params.id))
        : '';
    }
    dispatch(getAllProjectLocations());
    dispatch(getAlltypes());
    dispatch(getAllcategories());
    dispatch(getAllmethods());
    dispatch(getAllreferenceDocs());
    dispatch(getAllmaterils());
    dispatch(getAllreasontosend());
    dispatch(getAllapprovers());
  };

  useEffect(() => {
    const {
      location_id,
      type_id,
      category_id,
      methodType_id,
      document_id,
      requester_name,
      requester_department,
      vendor_name,
      address,
      date,
      material_id,
      expected_date_return,
      actual_return_date,
      vehicle_number,
      lr_number,
      lr_date,
      carrier_person_name,
      approver_user_id,
      reason_id,
      detailed_reason,
      remarks,
      document_number
    } = requestbyid;
    form.setFieldsValue({
      requester_name: requester_name,
      requester_department: requester_department,
      location_id: location_id,
      type_id: type_id,
      category_id,
      methodType_id,
      document_id,
      vendor_name,
      location: requestbyid?.Location?.location_name,
      address,
      date: date ? moment(date) : null,
      material_id,
      expected_date_return: expected_date_return
        ? moment(expected_date_return)
        : null,
      actual_return_date: actual_return_date
        ? moment(actual_return_date)
        : null,
      vehicle_number,
      lr_number,
      lr_date: lr_date ? moment(lr_date) : null,
      carrier_person_name,
      user_id: approver_user_id,
      reason_id: `${reason_id}`,
      detailed_reason,
      remarks,
      document_number: document_number,
      items: requestbyid?.Items?.map((item) => ({
        item_name: item?.item_name,
        item_code: item?.item_code,
        uom: item?.uom,
        quantity: item?.quantity,
        item_description: item?.item_description
      }))
    });
  }, [requestbyid]);

  useEffect(() => {
    form.setFieldsValue({
      document_number: documentNumber
    });
  }, [documentNumber]);

  const handleChangedocument = (values) => {
    let doc_numbers = docs.filter((docs) => docs.id === values);
    setDocumentNumber(doc_numbers[0].document_number);
  };

  const handleFormDataOnChange = (slug, value) => {
    setFormData({
      ...formData,
      [slug]: value
    });
  };
  const onFinishTabOne = (value) => {
    console.log('value_onFinishTabOne', value);
    setFormTabeOneData(value);
    setSelectedTabId(filters?.[1]?.id);
  };

  useEffect(() => {
    setSelectedTabId(filters?.[0]?.id || null);
  }, []);

  const onFinish = (values) => {
    console.log('Value....', values);
    // setFormItemData(values);
    values['id'] = params.id;
    const payload = {
      id: params.id,
      ...formTabeOneData,
      ...values,
      doc_numbers: documentNumber
    };
    console.log('Payload_values', payload);
    user?.user_details?.role === 'requester'
      ? dispatch(createRequest(payload)).then(function (response) {
          if (response) {
            history.push('/dashboard');
          }
        })
      : dispatch(editRequest(payload)).then(function (response) {
          if (response) {
            message.success(response.payload.msg);
            history.push(`/view-requests/${params.id}`);
          }
        });
  };

  return (
    <>
      <div className={`mb-4 ${styles.container}`}>
        <div className={`${styles.page_title}`}>Create the Request</div>
        <div className={`${styles.page_sub_title}`}>
          Enter the following fields to create the request to issue the gate
          pass
        </div>
        <div>
          <Radio.Group
            value={selectedTabId}
            size="middle"
            optionType="button"
            buttonStyle="solid"
            className={`${styles.tab_btn_style}`}
          >
            {filters.map((item, id) => {
              return (
                <Radio.Button
                  name="radiogroup"
                  defaultValue={item[0]?.value}
                  value={item?.id}
                  key={id}
                  //onChange={() => setRadiofilter(item.id)}
                  className={`${styles.tab_broder_style}
                    ${selectedTabId === item?.id ? ` ${styles.selected}` : ''}
                  `}
                  onClick={() => {
                    setSelectedTabId(item?.id);
                  }}
                >
                  {item.name}
                </Radio.Button>
              );
            })}
          </Radio.Group>
          <Divider style={{ margin: 0, marginBottom: '20px' }}></Divider>
        </div>

        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
          initialValues={{ items: [''] }}
        >
          {selectedTabId === 1 && (
            <div>
              <Form
                form={form}
                onFinish={onFinishTabOne}
                autoComplete="off"
                {...requestbyid?.Items}
              >
                <Row gutter={16}>
                  <Col span={6}>
                    <FormItem
                      label="Project location"
                      name="location_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        disabled
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          // console.log('input', input);
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('location_id', value)
                        }
                        placeholder="Select Project location"
                        // onChange={(event, value) =>
                        // handleFormData(event, value)
                        // }

                        // onChange={handleChangedocument1}
                      >
                        {projectLocations.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.project_location_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Type"
                      name="type_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        disabled
                        placeholder="Select Type"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          // console.log('input', input);
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('type_id', value)
                        }
                      >
                        {types.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.type}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Category"
                      name="category_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        disabled
                        placeholder="Select Category"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('category_id', value)
                        }
                      >
                        {categories.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.category}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Method"
                      name="methodType_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        disabled
                        placeholder="Select Method"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('methodType_id', value)
                        }
                      >
                        {methods.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.method_type}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                {formData?.methodType_id === 1 ? (
                  <Row gutter={16}>
                    <Col span={12}>
                      <FormItem
                        label="Reference documents"
                        name="document_id"
                        rules={[
                          {
                            required: true,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          disabled
                          placeholder="Select Reference Document"
                          onChange={handleChangedocument}
                        >
                          {docs.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {item.document_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        label="Document number"
                        name="document_number"
                        rules={[
                          {
                            required: true,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input
                          disabled
                          allowClear
                          placeholder="Please enter document number"
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'document_number',
                              e.target.value
                            )
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                ) : null}
                <Divider className={`${styles.divider_style}`}></Divider>
                <div
                  style={{
                    fontWeight: 600,
                    color: '#000000',
                    fontSize: '14px',
                    lineHeight: ' 20px',
                    fontFamily: 'Cerebri Sans'
                  }}
                >
                  Shipped to:
                </div>
                <br />
                <Row gutter={16}>
                  <Col span={8}>
                    <FormItem
                      label="Vendor name"
                      name="vendor_name"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        disabled
                        onChange={(e) =>
                          handleFormDataOnChange('vendor_name', e.target.value)
                        }
                        allowClear
                        placeholder="Enter vendor name"
                        // onChange={handleFormData('vendor_name')}
                      />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem
                      label="Location"
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        disabled
                        allowClear
                        placeholder="Enter location"
                        onChange={(e) =>
                          handleFormDataOnChange('location', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem
                      label="Address"
                      name="address"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        disabled
                        allowClear
                        placeholder="Enter address"
                        onChange={(e) =>
                          handleFormDataOnChange('address', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Divider className={`${styles.divider_style}`}></Divider>
                <div
                  style={{
                    fontWeight: 600,
                    color: '#000000',
                    fontSize: '14px',
                    lineHeight: ' 20px',
                    fontFamily: 'Cerebri Sans'
                  }}
                >
                  Shipment details:
                </div>
                <br />
                <Row gutter={16}>
                  <Col span={6}>
                    <FormItem
                      label="Date"
                      name="date"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.date_styles}`}
                    >
                      <DatePicker
                        disabled
                        //value={moment(value)}
                        format={'DD-MMM-YYYY'}
                        onChange={(_, dateValue) =>
                          handleFormDataOnChange('date', dateValue)
                        }
                        disabledDate={(current) => {
                          return (
                            moment().add(-1, 'days') >= current ||
                            moment().add(0, 'month') <= current
                          );
                        }}
                      ></DatePicker>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Material belongs to"
                      name="material_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        disabled
                        placeholder="Select Material"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('material_id', value)
                        }
                      >
                        {materials.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.material_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Expected date of return"
                      name="expected_date_return"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.date_styles}`}
                    >
                      <DatePicker
                        disabled
                        onChange={(_, dateValue) =>
                          handleFormDataOnChange(
                            'expected_date_return',
                            dateValue
                          )
                        }
                        disabledDate={(current) => {
                          return moment().add(-1, 'days') >= current;
                        }}
                        format={'DD-MMM-YYYY'}
                      ></DatePicker>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Actual return date"
                      name="actual_return_date"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.date_styles}`}
                    >
                      <DatePicker
                        disabled
                        onChange={(_, dateValue) =>
                          handleFormDataOnChange(
                            'actual_return_date',
                            dateValue
                          )
                        }
                        defaultValue={moment()}
                        format={'DD-MMM-YYYY'}
                      ></DatePicker>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem
                      label="Reason for sending"
                      name="reason_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        disabled
                        placeholder="Select reason"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('reason_id', value)
                        }
                      >
                        {reasons.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.reason_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Detailed reason"
                      name="detailed_reason"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.textarea_styles}`}
                    >
                      <TextArea
                        disabled
                        allowClear
                        placeholder="Enter detailed reason"
                        size="small"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'detailed_reason',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Divider className={`${styles.divider_style}`}></Divider>
                <Row gutter={16}>
                  <Col span={6}>
                    <FormItem
                      label="Vehicle number"
                      name="vehicle_number"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter vehicle number"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'vehicle_number',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="LR number"
                      name="lr_number"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter LR number"
                        onChange={(e) =>
                          handleFormDataOnChange('lr_number', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="LR Date"
                      name="lr_date"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.date_styles}`}
                    >
                      <DatePicker
                        defaultValue={moment()}
                        format={'DD-MMM-YYYY'}
                        onChange={(_, dateValue) =>
                          handleFormDataOnChange('lr_date', dateValue)
                        }
                      ></DatePicker>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Name of the carrier person"
                      name="carrier_person_name"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        allowClear
                        placeholder="Please enter name"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'carrier_person_name',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Form>
              <div>
                <Button
                  htmlType="submit"
                  className={`${styles.first_next_button_style}
                    ${
                      selectedTabId === filters?.[1]?.id
                        ? ` ${styles.selected}`
                        : ''
                    }
                  `}
                  // onClick={() => {
                  //   setSelectedTabId(filters?.[1]?.id);
                  // }}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
          {selectedTabId === 2 && (
            <div className={`${styles.margin_grey_holder}`}>
              <Divider class={`${styles.divider_bottom_margin}`}></Divider>
              <div className={`${styles.items_title}`}>Items:</div>

              <Form.List name="items" className={`${styles.form_list_margin}`}>
                {(fields) => (
                  <>
                    {' '}
                    {fields?.map(({ key, name }) => (
                      <>
                        <Row
                          key={key}
                          gutter={16}
                          className={`${styles.grey_holder}  w-100 mt-0 mb-1`}
                        >
                          <Col span={6}>
                            <FormItem
                              label="Item name"
                              name={[name, 'item_name']}
                              className={`${styles.input_styles} ${styles.padding_styles}`}
                            >
                              <Input
                                disabled
                                allowClear
                                placeholder="Please enter item name"
                              />
                              {/*  <Select
                                disabled
                                placeholder="Please enter item name"
                                onChange={handleChangeItem}
                              >
                                {ItemsList?.map((item, index) => {
                                  return (
                                    <Option value={item?.item_name} key={index}>
                                      <span style={{ marginLeft: '10px' }}>
                                        {' '}
                                        {item?.item_name} (
                                        {`${item?.item_code}`})
                                      </span>
                                    </Option>
                                  );
                                })}
                              </Select> */}
                            </FormItem>
                          </Col>
                          <Col span={6}>
                            <FormItem
                              label="UOM"
                              name={[name, 'uom']}
                              rules={[
                                {
                                  required: false,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles} ${styles.padding_styles}`}
                            >
                              <Input
                                disabled
                                allowClear
                                placeholder="Please enter UOM"
                              />
                            </FormItem>
                          </Col>
                          <Col span={6}>
                            <FormItem
                              label="Quantity"
                              name={[name, 'quantity']}
                              rules={[
                                {
                                  required: false,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles} ${styles.padding_styles}`}
                            >
                              <Input
                                disabled
                                allowClear
                                placeholder="Please enter Quantity"
                              />
                            </FormItem>
                          </Col>
                          <Col span={6}>
                            <Row>
                              <Col span={24}>
                                <FormItem
                                  label="Item description"
                                  name={[name, 'item_description']}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.textarea_styles} ${styles.padding_styles}`}
                                >
                                  <TextArea
                                    disabled
                                    allowClear
                                    placeholder="Enter item description"
                                    size="small"
                                  />
                                </FormItem>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    ))}
                  </>
                )}
              </Form.List>

              <FormItem>
                <div className={`${styles.button_margin}`}>
                  <Button
                    className={`${styles.first_next_button_style}`}
                    htmlType="submit"
                    size="middle"
                  >
                    Submit
                  </Button>
                </div>
              </FormItem>
            </div>
          )}
        </Form>
      </div>
    </>
  );
};

export default EditGPByIssuer;
