import CustomLayout from '@/common/Layout';
import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import {
  Col,
  Row,
  Select,
  Form,
  Divider,
  Input,
  DatePicker,
  message,
  Button,
  Radio,
  Tag,
  Pagination,
  Tooltip
} from 'antd';
import SearchBar from '@/common/Content/searchBar';
import FormItem from 'common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  getrequestByid,
  // getAllProjectLocations,
  // getAlltypes,
  // getAllcategories,
  // getAllmethods,
  // getAllreferenceDocs,
  // getAllmaterils,
  // getAllreasontosend,
  // getAllapprovers,
  getrequesterdata,
  createRequest,
  partially_Edit_request,
  partiallyData_expected_date,
  getGatepassItems,
  get_gp_history
} from '../../redux/slice';
import { history } from '@/app/history';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';
import {
  DeleteOutlined,
  FolderViewOutlined,
  UploadOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import UploadedDocumentsModal from '../uploadedDocumentsModal';
import RemarksHistotyModal from '../remarksHistotyModal';
import PartiallyReturnItemModal from '../partiallyReturnItemModal';

const { Option } = Select;
const { TextArea } = Input;
const filters = [
  {
    id: 1,
    name: 'GP information',
    value: 'GP_information'
  },
  {
    id: 2,
    name: 'Item details',
    value: 'item_details'
  }
];

const EditPartiallyGP = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const user = getLoggedInUser();
  const {
    requestbyid,
    projectLocations,
    types,
    categories,
    methods,
    docs,
    materials,
    reasons,
    // approvers,
    gatepassItems,
    itemsPagination,
    gpHistory,
    partiallyDataLoading,
    partiallyReturnLoading
  } = useSelector((state) => state.edit);
  // console.log('gatepassItems>>>>>>>>>>', gatepassItems);
  console.log(' requestbyid>>>>>>>>>>', requestbyid);
  //console.log(' categories>>>>>>>>>>', categories);
  // console.log(
  //   'gatepassItems?.qty_returned>>>>>>>>>>',
  //   gatepassItems?.qty_returned
  // );

  const [documentNumber, setDocumentNumber] = useState('');
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [formData, setFormData] = useState({});
  //const [formItemData, setFormItemData] = useState({});
  const [formTabeOneData, setFormTabeOneData] = useState({});
  //const [itemId] = useState([]);
  const [newDocs, setNewDocs] = useState([1]);
  const [supportingDoc, setSupportingDoc] = useState([]);
  const [uploadedDocumentsVisible, setUploadedDocumentsVisible] =
    useState(false);
  const [remarksHistotyVisible, setRemarksHistotyVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [per_page, setPer_Page] = useState(10);
  const [itemSearchValue, setItemSearchValue] = useState();
  const [returnItemModal, setReturnItemModal] = useState(false);
  const [itemID, setItemID] = useState();

  useEffect(() => {
    handlegetRequestbyid();
  }, [params]);
  const handlegetRequestbyid = () => {
    {
      user?.user_details?.roles[0] === 'requester'
        ? dispatch(getrequesterdata(params.id))
        : '';
    }
    {
      user?.user_details?.roles[0] === 'issuer'
        ? dispatch(getrequestByid(params.id))
        : '';
    }
    //dispatch(getAllProjectLocations());
    // dispatch(getAlltypes());
    //  dispatch(getAllcategories());
    // dispatch(getAllmethods());
    //  dispatch(getAllreferenceDocs());
    //  dispatch(getAllmaterils());
    // dispatch(getAllreasontosend());
    // dispatch(getAllapprovers());
  };
  useEffect(() => {
    handleGetGatepassItems();
  }, [page, itemSearchValue]);

  const handleGetGatepassItems = () => {
    dispatch(
      getGatepassItems({
        gpid: parseInt(params.id),
        page: page,
        per_page: per_page,
        search: itemSearchValue === undefined ? '' : itemSearchValue
      })
    );
  };

  useEffect(() => {
    handleGet_gp_history();
  }, [params.id]);
  const handleGet_gp_history = () => {
    dispatch(
      get_gp_history({
        gpid: params.id,
        action: 'Date extended'
      })
    );
  };

  /* useEffect(() => {
    dispatch(
      get_gp_history({
        gpid: requestbyid?.id,
        action: 'Date extended'
      })
    );
  }, [requestbyid?.id]); */
  useEffect(() => {
    const {
      requester_name,
      requester_department,
      date,
      expected_date_return,
      actual_return_date,
      vehicle_number,
      lr_number,
      lr_date,
      carrier_person_name,
      approver_user_id,
      detailed_reason,
      remarks
    } = requestbyid;
    form.setFieldsValue({
      requester_name: requester_name,
      requester_department: requester_department,
      project_id: requestbyid?.project_master?.project_name,
      location_id: requestbyid?.project_location_master?.name,
      type_id: requestbyid?.Type?.type,
      category_id: requestbyid?.Category?.category,
      methodType_id: requestbyid?.Method_type?.method_type,
      document_id: requestbyid?.Document_master?.document_name,
      document_number: requestbyid?.document_number,
      vendor_name: requestbyid?.Vendor_Master?.vendorName,
      location: requestbyid?.vendor_location_name, //Vendor_Site_Master?.vendorSite,
      address: requestbyid?.vendor_location_address, // Vendor_Site_Master?.addressLine1,
      date: date ? moment(date) : null,
      material_id: requestbyid?.MaterialBelong?.material_name,
      expected_date_return: expected_date_return
        ? moment(expected_date_return)
        : null,
      actual_return_date:
        actual_return_date !== null
          ? moment(actual_return_date).format('LL')
          : null,
      vehicle_number,
      lr_number,
      lr_date: lr_date ? moment(lr_date) : null,
      carrier_person_name,
      user_id: approver_user_id,
      reason_id: requestbyid?.Reason?.reason_name, // `${reason_id}`,
      detailed_reason,
      remarks,
      items: gatepassItems?.map((item) => ({
        id: item?.id,
        item_name: item?.item_name,
        item_code: item?.item_code,
        uom: item?.uom,
        quantity: item?.quantity, //total qty
        qty_returned: item?.qty_returned, //partially returned
        item_description: item?.item_description,
        item_remarks: item?.item_remarks,
        qty_received: 0
      }))
    });
  }, [requestbyid, gatepassItems]);

  useEffect(() => {
    form.setFieldsValue({
      document_number: documentNumber
    });
  }, [documentNumber]);

  console.log('supportingDoc', supportingDoc);

  const handleOnClick = () => {
    let docsCopy = [...newDocs];
    docsCopy.push({
      supporting_docs: '',
      docs: ''
    });
    setNewDocs(docsCopy);
  };
  const handleRemove = (e, i) => {
    let uploadeddocs = [...newDocs];
    uploadeddocs.splice(i, 1);
    setNewDocs(uploadeddocs);
  };
  const handlechangeDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: e.target.files[0] };

    setSupportingDoc(newDocValues);
  };

  const handleclearDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: '' };

    setSupportingDoc(newDocValues);
  };

  const handleChangedocument = (values) => {
    let doc_numbers = docs.filter((docs) => docs.id === values);
    setDocumentNumber(doc_numbers[0].document_number);
  };

  const handleFormDataOnChange = (slug, value) => {
    setFormData({
      ...formData,
      [slug]: value
    });
  };
  const onFinishTabOne = (value) => {
    let formdata = new FormData();
    value['file'] = [supportingDoc];
    value['gp_id'] = requestbyid?.id;
    formdata.append('gp_id', value?.gp_id === undefined ? '' : value?.gp_id);
    console.log('value_onFinishTabOne', value);

    for (let i in supportingDoc) {
      formdata.append('files', supportingDoc[i]);
    }
    formdata.append(
      'expected_date',
      value?.expected_date_return === undefined
        ? ''
        : value?.expected_date_return
    );
    /* formdata.append(
      'actual_return_date',
      value?.actual_return_date === undefined ? '' : value?.actual_return_date
    ); */
    formdata.append(
      'remarks',
      value?.remarks === undefined ? '' : value?.remarks
    );
    setFormTabeOneData(value);
    dispatch(partiallyData_expected_date(formdata)).then(function (response) {
      if (response?.payload?.success) {
        message.success(response?.payload?.msg);
        setSelectedTabId(filters?.[1]?.id);
      } else {
        message.error(response?.payload?.msg);
      }
    });
  };

  useEffect(() => {
    setSelectedTabId(filters?.[0]?.id || null);
  }, []);

  /*  useEffect(() => {
    for (let i = 0; i < requestbyid?.Items?.length; i++) {
      const id = requestbyid?.Items[i]?.id;
      itemId?.push({ id: i, gp_id: id });
    }
  }, [requestbyid?.Items]);
 */
  // console.log('ItemsId....', itemId);

  /* const arr1 = [
    { id: '123', name: 'name 1' },
    { id: '456', name: 'name 2' }
  ];
  const arr2 = [
    { id: '123', address: 'address 1' },
    { id: '456', address: 'address 2' }
  ]; */
  /* const mergeArrays = (arr1, arr2) => {
    let res = [];
    res = arr1.map((obj) => {
      const index = arr2.findIndex((el) => el['id'] == obj['id']);
      const { gp_id } = index !== -1 ? arr2[index] : {};
      return {
        ...obj,
        gp_id
      };
    });
    return res;
  }; */
  //console.log("ddddddddddddddddddddddddd",mergeArrays(arr1, arr2));

  const onFinish = (values) => {
    //let formdata = new FormData();
    //  console.log('onFinish_values@@@@@@@@', values);
    values['id'] = params.id;
    /* for (let i in supportingDoc) {
      formdata.append('file', supportingDoc[i]);
    } */

    /* const items = values?.items?.map((val, index) => ({
      id: index,
      qty_received: val?.qty_received
    }));

    const newItemsArray = mergeArrays(items, itemId);
    console.log('pppppppppppp', newItemsArray);

    const newItems = newItemsArray?.map((val) => ({
      id: val?.gp_id,
      qty_received: parseInt(val?.qty_received)
    }));
 */
    const newItems = values?.items?.map((val) => ({
      id: val?.id,
      qty_received: parseInt(val?.qty_received),
      remarks: val?.item_remarks
      // upload: val?.upload
    }));
    console.log('newItems', newItems);
    const payload = {
      id: params.id,
      ...formTabeOneData,
      ...values,
      doc_numbers: documentNumber
    };
    /*  for (let i in supportingDoc) {
      formdata.append('file', supportingDoc[i]);
    }
     */

    // formdata.append('gp_id', params.id);
    // formdata.append('items', JSON.stringify(newItems));
    const partiallyPayload = {
      gp_id: params.id,
      items: newItems
    };

    console.log('partially_Payload....', partiallyPayload);
    // console.log('Payload_values', payload);
    user?.user_details?.roles[0] === 'requester'
      ? dispatch(createRequest(payload)).then(function (response) {
          if (response) {
            history.push('/dashboard');
          }
        })
      : dispatch(partially_Edit_request(partiallyPayload)).then(function (
          response
        ) {
          if (response) {
            message.success(response.payload.msg);
            history.push(`/view-requests/${params.id}`);
          }
        });
  };
  const handleChangeSearch = (e) => {
    const value = e.target.value;
    // console.log("SEARCH_value",value)
    setItemSearchValue(value);
  };

  return (
    <CustomLayout sider="true">
      <div className={`mb-4 ${styles.container}`}>
        <div className={`${styles.page_title}`}>
          <span>Gate Pass no: {requestbyid?.gp_number}</span>
          <span style={{ float: 'right', marginRight: '25px' }}>
            {' '}
            <a href={'/issuer-gatepass'}>
              <Button className={`${styles.back_button}`}>
                <ArrowLeftOutlined className={`${styles.back_icon}`} />
                Back
              </Button>
              {/* <div className={`${styles.back_icon_div}`}>
                <ArrowLeftOutlined className={`${styles.back_icon}`} />
              </div> */}
            </a>
          </span>
        </div>
        <div className={`${styles.page_sub_title}`}>
          Enter the following fields to return the items
        </div>
        <div>
          <Radio.Group
            value={selectedTabId}
            size="middle"
            optionType="button"
            buttonStyle="solid"
            className={`${styles.tab_btn_style}`}
          >
            {filters.map((item, id) => {
              return (
                <Radio.Button
                  name="radiogroup"
                  defaultValue={item[0]?.value}
                  value={item?.id}
                  key={id}
                  //onChange={() => setRadiofilter(item.id)}
                  className={`${styles.tab_broder_style}
                    ${selectedTabId === item?.id ? ` ${styles.selected}` : ''}
                  `}
                  onClick={() => {
                    setSelectedTabId(item?.id);
                  }}
                >
                  {item.name}
                </Radio.Button>
              );
            })}
          </Radio.Group>
          <Divider style={{ margin: 0, marginBottom: '20px' }}></Divider>
        </div>

        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
          initialValues={{ items: [''] }}
        >
          {selectedTabId === 1 && (
            <div>
              <Form
                form={form}
                onFinish={onFinishTabOne}
                autoComplete="off"
                {...requestbyid?.Items}
              >
                <Row gutter={16}>
                  <Col span={6}>
                    <FormItem
                      label="Project"
                      name="project_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        disabled
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          // console.log('input', input);
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('project_id', value)
                        }
                        placeholder="Select Project"
                        // onChange={(event, value) =>
                        // handleFormData(event, value)
                        // }

                        // onChange={handleChangedocument1}
                      >
                        {projectLocations.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.project_location_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Project location"
                      name="location_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        disabled
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          // console.log('input', input);
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('location_id', value)
                        }
                        placeholder="Select Project location"
                        // onChange={(event, value) =>
                        // handleFormData(event, value)
                        // }

                        // onChange={handleChangedocument1}
                      >
                        {projectLocations.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.project_location_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Type"
                      name="type_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        disabled
                        placeholder="Select Type"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          // console.log('input', input);
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('type_id', value)
                        }
                      >
                        {types.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.type}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Category"
                      name="category_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        disabled
                        placeholder="Select Category"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('category_id', value)
                        }
                      >
                        {categories.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.category}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <FormItem
                      label="Method"
                      name="methodType_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        disabled
                        placeholder="Select Method"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('methodType_id', value)
                        }
                      >
                        {methods.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.method_type}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  {requestbyid?.Method_type?.method_type === 'ERP' ? (
                    <>
                      <Col span={8}>
                        <FormItem
                          label="Reference documents"
                          name="document_id"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            disabled
                            placeholder="Select Reference Document"
                            onChange={handleChangedocument}
                          >
                            {docs.map((item, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {item.document_name}
                                </Option>
                              );
                            })}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="Document number"
                          name="document_number"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            disabled
                            allowClear
                            placeholder="Please enter document number"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'document_number',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </Col>
                    </>
                  ) : null}
                </Row>
                <Divider className={`${styles.divider_style}`}></Divider>
                <div
                  style={{
                    fontWeight: 600,
                    color: '#000000',
                    fontSize: '14px',
                    lineHeight: ' 20px',
                    fontFamily: 'Cerebri Sans'
                  }}
                >
                  Shipped to:
                </div>
                <br />
                <Row gutter={16}>
                  <Col span={8}>
                    <FormItem
                      label="Vendor name"
                      name="vendor_name"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        disabled
                        onChange={(e) =>
                          handleFormDataOnChange('vendor_name', e.target.value)
                        }
                        allowClear
                        placeholder="Enter vendor name"
                        // onChange={handleFormData('vendor_name')}
                      />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem
                      label="Location"
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        disabled
                        allowClear
                        placeholder="Enter location"
                        onChange={(e) =>
                          handleFormDataOnChange('location', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem
                      label="Address"
                      name="address"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        defaultValue={requestbyid?.Vendor_Master?.addressLine1}
                        disabled
                        allowClear
                        placeholder="Enter address"
                        onChange={(e) =>
                          handleFormDataOnChange('address', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Divider className={`${styles.divider_style}`}></Divider>
                <div
                  style={{
                    fontWeight: 600,
                    color: '#000000',
                    fontSize: '14px',
                    lineHeight: ' 20px',
                    fontFamily: 'Cerebri Sans'
                  }}
                >
                  Shipment details:
                </div>
                <br />
                <Row gutter={16}>
                  <Col span={6}>
                    <FormItem
                      label="Date"
                      name="date"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.date_styles}`}
                    >
                      <DatePicker
                        disabled
                        //value={moment(value)}
                        format={'DD-MMM-YYYY'}
                        onChange={(_, dateValue) =>
                          handleFormDataOnChange('date', dateValue)
                        }
                        disabledDate={(current) => {
                          return (
                            moment().add(-1, 'days') >= current ||
                            moment().add(0, 'month') <= current
                          );
                        }}
                      ></DatePicker>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Material belongs to"
                      name="material_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        disabled
                        placeholder="Select Material"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('material_id', value)
                        }
                      >
                        {materials.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.material_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  {requestbyid?.Category?.category ===
                  'Returnable Gate Pass (RGP)' ? (
                    <>
                      <Col span={6}>
                        <FormItem
                          label="Expected date of return"
                          name="expected_date_return"
                          rules={[
                            {
                              required: false,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.date_styles}`}
                        >
                          <DatePicker
                            // disabled
                            onChange={(_, dateValue) =>
                              handleFormDataOnChange(
                                'expected_date_return',
                                dateValue
                              )
                            }
                            disabledDate={(current) => {
                              return moment().add(-1, 'days') >= current;
                            }}
                            format={'DD-MMM-YYYY'}
                          ></DatePicker>
                        </FormItem>

                        {/*  <div className={`${styles.view_upload}`}> <Button type="link" >
                        View
                        </Button></div> */}
                      </Col>
                      <Col span={6}>
                        <FormItem
                          label="Actual return date"
                          name="actual_return_date"
                          rules={[
                            {
                              required: false,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.input_styles}`}
                          /* className={`${styles.date_styles}`} */
                        >
                          <Input
                            disabled
                            allowClear
                            placeholder="Select date"
                          />
                          {/* <DatePicker
                            disabled
                            onChange={(_, dateValue) =>
                              handleFormDataOnChange(
                                'actual_return_date',
                                dateValue
                              )
                            }
                            // defaultValue={moment()}
                            format={'DD-MMM-YYYY'}
                            // disabledDate={(current) => {
                            //   return moment().add(-1, 'days') >= current;
                            // }}
                          ></DatePicker> */}
                        </FormItem>
                      </Col>
                    </>
                  ) : (
                    ''
                  )}
                </Row>
                {requestbyid?.Category?.category ===
                'Returnable Gate Pass (RGP)' ? (
                  <Row gutter={16}>
                    <Col span={12}>
                      <Row gutter={16}>
                        <Col span={18}>
                          <FormItem
                            label="Remarks"
                            name="remarks"
                            rules={[
                              {
                                required: false,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.textarea_styles}`}
                          >
                            <TextArea
                              // disabled={requestbyid?.Category?.category !== 'RGP'}
                              allowClear
                              placeholder="Enter remarks"
                              size="small"
                              onChange={(e) =>
                                handleFormDataOnChange(
                                  'remarks',
                                  e.target.value
                                )
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col span={3}>
                          <Tooltip
                            title="Extension History"
                            color={'white'}
                            overlayInnerStyle={{
                              color: '#32324D',
                              fontSize: '12px',
                              fontWeight: '500'
                            }}
                            arrowPointAtCenter="false"
                          >
                            <Button
                              className={`${styles.uploadedDoc_button}`}
                              onClick={() => setRemarksHistotyVisible(true)}
                            >
                              <FolderViewOutlined
                                className={`${styles.uploadedDoc_icon}`}
                              />
                            </Button>
                          </Tooltip>
                        </Col>
                        <Col span={3}>
                          <Tooltip
                            title="Uploded documents"
                            color={'white'}
                            overlayInnerStyle={{
                              color: '#32324D',
                              fontSize: '12px',
                              fontWeight: '500'
                            }}
                            arrowPointAtCenter="false"
                          >
                            <Button
                              className={`${styles.uploadedDoc_button}`}
                              onClick={() => setUploadedDocumentsVisible(true)}
                            >
                              <FolderViewOutlined
                                className={`${styles.uploadedDoc_icon}`}
                              />
                            </Button>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={12} className={`${styles.upload_remarks}`}>
                      {newDocs.map((item, index) => (
                        <>
                          {supportingDoc[index] !== '' &&
                          supportingDoc[index] !== undefined ? (
                            <>
                              <div key={index} className={`mb-2`}>
                                <span>
                                  <Tag color="geekblue">
                                    {supportingDoc[index].name}
                                  </Tag>
                                  <DeleteOutlined
                                    style={{ color: ' rgb(215, 67, 67)' }}
                                    onClick={(e) => handleclearDoc(e, index)}
                                  />
                                </span>

                                {index === 0 ? (
                                  <span>
                                    <Button
                                      className={`${styles.upload_button}`}
                                      onClick={handleOnClick}
                                    >
                                      Add Document
                                    </Button>
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start'
                                }}
                              >
                                <FormItem
                                  name="file"
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Enter type'
                                    }
                                  ]}
                                >
                                  <label
                                    htmlFor="file-upload"
                                    className={styles.custom_file_upload}
                                  >
                                    <UploadOutlined /> Upload
                                  </label>
                                  <Input
                                    accept="*"
                                    className={styles.upload}
                                    type="file"
                                    onChange={(e) => handlechangeDoc(e, index)}
                                    id="file-upload"
                                  />
                                </FormItem>

                                {index === 0 ? (
                                  <span>
                                    <Button
                                      className={`${styles.upload_button}`}
                                      onClick={handleOnClick}
                                    >
                                      Add Document
                                    </Button>
                                  </span>
                                ) : (
                                  <span>
                                    <Button
                                      style={{
                                        padding: '6px !important',
                                        fontSize: '11px !important'
                                      }}
                                      className={`${styles.remove_button}`}
                                      onClick={(e) => handleRemove(e, index)}
                                    >
                                      Remove
                                    </Button>
                                  </span>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ))}
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem
                      label="Reason for sending"
                      name="reason_id"
                      rules={[
                        {
                          required: true,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.select_styles}`}
                    >
                      <Select
                        disabled
                        placeholder="Select reason"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option?.children
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase());
                        }}
                        onChange={(value) =>
                          handleFormDataOnChange('reason_id', value)
                        }
                      >
                        {reasons.map((item, index) => {
                          return (
                            <Option value={item.id} key={index}>
                              {item.reason_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Detailed reason"
                      name="detailed_reason"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.textarea_styles}`}
                    >
                      <TextArea
                        disabled
                        allowClear
                        placeholder="Enter detailed reason"
                        size="small"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'detailed_reason',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Divider className={`${styles.divider_style}`}></Divider>
                <Row gutter={16}>
                  <Col span={6}>
                    <FormItem
                      label="Vehicle number"
                      name="vehicle_number"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        },
                        {
                          validator: async (_, value) => {
                            if (value?.length <= 10) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                'Not exceed more than 10 characters'
                              );
                            }
                          }
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        disabled
                        allowClear
                        placeholder="Please enter vehicle number"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'vehicle_number',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="LR number"
                      name="lr_number"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        disabled
                        allowClear
                        placeholder="Please enter LR number"
                        onChange={(e) =>
                          handleFormDataOnChange('lr_number', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="LR Date"
                      name="lr_date"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.date_styles}`}
                    >
                      <DatePicker
                        disabled
                        defaultValue={moment()}
                        format={'DD-MMM-YYYY'}
                        onChange={(_, dateValue) =>
                          handleFormDataOnChange('lr_date', dateValue)
                        }
                      ></DatePicker>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Name of the carrier person"
                      name="carrier_person_name"
                      rules={[
                        {
                          required: false,
                          message: 'Required field'
                        }
                      ]}
                      className={`${styles.input_styles}`}
                    >
                      <Input
                        disabled
                        allowClear
                        placeholder="Please enter name"
                        onChange={(e) =>
                          handleFormDataOnChange(
                            'carrier_person_name',
                            e.target.value
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Form>

              {requestbyid?.Category?.category ===
              'Returnable Gate Pass (RGP)' ? (
                requestbyid?.status === 'Closed' ? (
                  ''
                ) : (
                  <div>
                    <Button
                      loading={partiallyDataLoading}
                      htmlType="submit"
                      className={`${styles.first_next_button_style}
                    ${
                      selectedTabId === filters?.[1]?.id
                        ? ` ${styles.selected}`
                        : ''
                    }
                  `}
                      // onClick={() => {
                      //   setSelectedTabId(filters?.[1]?.id);
                      // }}
                    >
                      Save
                    </Button>
                  </div>
                )
              ) : (
                ''
              )}
            </div>
          )}
          {selectedTabId === 2 && (
            <div className={`${styles.margin_grey_holder}`}>
              <SearchBar
                onChange={(e) => handleChangeSearch(e)}
                className={`${styles.searchbar}`}
                justify="start"
                align="start"
                inputProps={{
                  placeholder: 'Search by item description'
                }}
              />
              <Divider class={`${styles.divider_bottom_margin}`}></Divider>
              <div className={`${styles.items_title}`}>Items:</div>

              <Form.List name="items" className={`${styles.form_list_margin}`}>
                {(fields) => (
                  <>
                    {' '}
                    {fields?.map(({ key, name }) => (
                      <>
                        <Row
                          key={key}
                          gutter={16}
                          className={`${styles.grey_holder}  w-100 mt-0 mb-1`}
                        >
                          {requestbyid?.Category?.category ===
                          'Returnable Gate Pass (RGP)' ? (
                            <>
                              {' '}
                              <Col span={4}>
                                <FormItem
                                  label="Item code"
                                  name={[name, 'item_code']}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles} ${styles.padding_styles}`}
                                >
                                  <Input
                                    // defaultValue={item?.item_name}
                                    disabled
                                    allowClear
                                    placeholder="Please enter item code"
                                  />
                                </FormItem>
                              </Col>
                              <Col span={6}>
                                <FormItem
                                  label="Item description"
                                  // {...restField}
                                  name={[name, 'item_description']}
                                  // initialValue={ item?.item_description}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.textarea_styles} ${styles.padding_styles}`}
                                >
                                  <TextArea
                                    // defaultValue={item?.item_description}
                                    disabled
                                    allowClear
                                    placeholder="Enter item description"
                                    size="small"
                                  />
                                </FormItem>
                              </Col>
                              <Col span={4}>
                                <FormItem
                                  label="UOM"
                                  // {...restField}
                                  name={[name, 'uom']}
                                  // initialValue={item?.uom}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles} ${styles.padding_styles}`}
                                >
                                  <Input
                                    // defaultValue={item?.uom}
                                    disabled
                                    allowClear
                                    placeholder="Please enter UOM"
                                  />
                                </FormItem>
                              </Col>
                              <Col span={4}>
                                <FormItem
                                  label="Quantity"
                                  //  {...restField}
                                  name={[name, 'quantity']}
                                  // initialValue={item?.quantity}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles} ${styles.padding_styles}`}
                                >
                                  <Input
                                    disabled
                                    allowClear
                                    placeholder="Total Quantity"
                                  />
                                </FormItem>
                              </Col>
                              <Col span={4}>
                                <FormItem
                                  label="Returned Quantity"
                                  name={[name, 'qty_returned']}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles} ${styles.padding_styles}`}
                                >
                                  <Input
                                    disabled
                                    allowClear
                                    placeholder="Returned qty"
                                  />
                                </FormItem>
                              </Col>
                              {/*  <Col span={3}>
                                <FormItem
                                  label="Quantity-Received"
                                  name={[name, 'qty_received']}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    },
                                    {
                                      validator: async (_, value) => {
                                        const qty =
                                          gatepassItems[key]?.quantity -
                                          gatepassItems[key]?.qty_returned;
                                        if (qty >= value) {
                                          return Promise.resolve();
                                        } else {
                                          return Promise.reject(
                                            `Quantity-Received <= ${qty}`
                                          );
                                        }
                                      }
                                    }
                                  ]}
                                  className={`${styles.input_styles} ${styles.padding_styles}`}
                                >
                                  <Input
                                    disabled={
                                      gatepassItems[key]?.quantity ===
                                      gatepassItems[key]?.qty_returned
                                    }
                                    allowClear
                                    placeholder="Please enter Qty for Return"
                                  />
                                </FormItem>
                              </Col> */}
                              {/* <Col span={4}>
                                <FormItem
                                  label="Item Remarks"
                                  name={[name, 'item_remarks']}
                                  // initialValue={ item?.item_description}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.textarea_styles} ${styles.padding_styles}`}
                                >
                                  <TextArea
                                    // defaultValue={item?.item_description}
                                    // disabled
                                    allowClear
                                    placeholder="Enter item remarks"
                                    size="small"
                                  />
                                </FormItem>
                              </Col> */}
                              <Col
                                span={2}
                                className={`${styles.upload_remarks2}`}
                              >
                                <FormItem
                                  name={[name, 'upload']}
                                  className={`${styles.input_styles}`}
                                >
                                  <Button
                                    /*  disabled={
                                      gatepassItems[key]?.quantity ===
                                      gatepassItems[key]?.qty_returned
                                    } */
                                    className={`${styles.itemReturn_button}`}
                                    onClick={() => {
                                      setReturnItemModal(true);
                                      setItemID(gatepassItems[key]?.id);
                                    }}
                                  >
                                    Return
                                  </Button>
                                  {/*  <Upload
                                    name="logo"
                                    //action="/upload.do"
                                    //  listType="picture"
                                  >
                                    <Button
                                      icon={<UploadOutlined />}
                                      className={styles.custom_file_upload2}
                                    >
                                      upload
                                    </Button>
                                  </Upload> */}
                                </FormItem>
                              </Col>
                            </>
                          ) : (
                            <>
                              {' '}
                              <Col span={5}>
                                <FormItem
                                  label="Item code"
                                  name={[name, 'item_code']}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles} ${styles.padding_styles}`}
                                >
                                  <Input
                                    // defaultValue={item?.item_name}
                                    disabled
                                    allowClear
                                    placeholder="Please enter item code"
                                  />
                                </FormItem>
                              </Col>
                              <Col span={5}>
                                <FormItem
                                  label="Item description"
                                  // {...restField}
                                  name={[name, 'item_description']}
                                  // initialValue={ item?.item_description}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles} ${styles.padding_styles}`}
                                >
                                  <Input
                                    // defaultValue={item?.item_description}
                                    disabled
                                    allowClear
                                    placeholder="Enter item description"
                                    //size="small"
                                  />
                                </FormItem>
                              </Col>
                              <Col span={5}>
                                <FormItem
                                  label="UOM"
                                  // {...restField}
                                  name={[name, 'uom']}
                                  // initialValue={item?.uom}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles} ${styles.padding_styles}`}
                                >
                                  <Input
                                    // defaultValue={item?.uom}
                                    disabled
                                    allowClear
                                    placeholder="Please enter UOM"
                                  />
                                </FormItem>
                              </Col>
                              <Col span={5}>
                                <FormItem
                                  label="Quantity"
                                  //  {...restField}
                                  name={[name, 'quantity']}
                                  // initialValue={item?.quantity}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles} ${styles.padding_styles}`}
                                >
                                  <Input
                                    disabled
                                    allowClear
                                    placeholder="Total Quantity"
                                  />
                                </FormItem>
                              </Col>
                              <Col span={4}>
                                <FormItem
                                  label="Item Remarks"
                                  name={[name, 'item_remarks']}
                                  // initialValue={ item?.item_description}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.textarea_styles} ${styles.padding_styles}`}
                                >
                                  <TextArea
                                    // defaultValue={item?.item_description}
                                    disabled
                                    allowClear
                                    placeholder="Enter item remarks"
                                    size="small"
                                  />
                                </FormItem>
                              </Col>
                            </>
                          )}
                        </Row>
                      </>
                    ))}
                  </>
                )}
              </Form.List>

              {/* <FormItem>
                <div className={`${styles.button_margin}`}>
                  <Button
                    loading={partiallyReturnLoading}
                    className={`${styles.first_next_button_style}`}
                    htmlType="submit"
                    size="middle"
                  >
                    Return
                  </Button>
                </div>
              </FormItem> */}
            </div>
          )}
        </Form>
        <Pagination
          size="small"
          current={page}
          pageSize={per_page}
          total={itemsPagination?.total_records || 0}
          onChange={(page, per_page) => {
            setPage(page);
            setPer_Page(per_page);
          }}
          className="text-right"
        />
        {uploadedDocumentsVisible ? (
          <UploadedDocumentsModal
            uploadedDocumentsVisible={uploadedDocumentsVisible}
            setUploadedDocumentsVisible={setUploadedDocumentsVisible}
            requestbyid={requestbyid}
          />
        ) : (
          ''
        )}
        {remarksHistotyVisible ? (
          <RemarksHistotyModal
            remarksHistotyVisible={remarksHistotyVisible}
            setRemarksHistotyVisible={setRemarksHistotyVisible}
            gpHistory={gpHistory}
          />
        ) : (
          ''
        )}
        {returnItemModal ? (
          <PartiallyReturnItemModal
            returnItemModal={returnItemModal}
            setReturnItemModal={setReturnItemModal}
            requestbyid={requestbyid}
            gatepassItems={gatepassItems}
            itemID={itemID}
            handleGetGatepassItems={handleGetGatepassItems}
            partiallyReturnLoading={partiallyReturnLoading}
          />
        ) : (
          ''
        )}
      </div>
    </CustomLayout>
  );
};

export default EditPartiallyGP;
