import React, { useEffect, useState, useMemo } from 'react';
import styles from './index.module.less';
import CustomLayout from '@/common/Layout';
import SearchBar from '@/common/Content/searchBar';
import {
  Col,
  Row,
  Select,
  Form,
  Divider,
  Input,
  DatePicker,
  message,
  Button,
  Radio,
  Tag,
  //Pagination,
  Spin
} from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import FormItem from 'common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDraftUpload_sap,
  getrequestByid,
  getrequesterdata,
  getGatepassItems,
  delete_uploaded_docs_sap
} from '../editGP_SAP/redux/slice';
import {
  getAllVendors,
  getAllVendorLocation,
  getAllissuers,
  get_Check_DocumentNumber,
  getAllPlants,
  getAllPlantLocations,
  getAllmethodsSAP,
  getAlltypes,
  getAllcategories,
  getAllreferenceDocs,
  getAllmaterils,
  getAllreasontosend,
  getAllapprovers,
  vendorLocationAddresses,
  getAllPlants_forSAP
} from '../createGP_SAP/redux/slice';
import { history } from '@/app/history';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';
import { debounce } from 'lodash';
import * as XLSX from 'xlsx/xlsx.mjs';
import VendorERP_Modal from '../createGP_SAP/components/gatepassrequest_SAP/vendorERP_Modal';
import LocalStorage from 'utilities/localStorage';

const { Option } = Select;
const { TextArea } = Input;

const filters = [
  {
    id: 1,
    name: 'GP information',
    value: 'GP_information'
  },
  {
    id: 2,
    name: 'Item details',
    value: 'item_details'
  }
];
const DraftGP_SAP = () => {
  const userToken = LocalStorage.getItem('gatepass_user');
  const params = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const user = getLoggedInUser();
  const {
    requestbyid,
    //projectLocations,
    // types,
    // categories,
    //methods,
    // docs,
    // materials,
    // reasons,
    // approvers,
    gatepassItems,
    //itemsPagination,
    getrequestByidLoading
    // createDraftLoading
  } = useSelector((state) => state.edit_sap);
  const {
    plants,
    plantLocations,
    methods_sap,
    types,
    categories,
    docs,
    materials,
    reasons,
    approvers,
    vendors,
    vendorLocation,
    issuers,
    vendorLocationAddressList,
    plants_forSAP
  } = useSelector((state) => state.requests_sap);

  const [documentNumber, setDocumentNumber] = useState();
  const [documentID, setDocumentID] = useState();
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [formData1, setFormData1] = useState({});
  const [formItemData, setFormItemData] = useState({});
  //const [formTabeOneData, setFormTabeOneData] = useState({});
  const [vendorAddress, setVendorAddress] = useState([]);
  const [vendorName, setVendorName] = useState([]);
  const [newDocs, setNewDocs] = useState([1]);
  const [supportingDoc, setSupportingDoc] = useState([]);
  //const [inputVenderName] = useState();
  const [itemVal, setItemVal] = useState({});
  const [draft, setDraft] = useState();
  const [submit, setSubmit] = useState();
  //const [gpId, setGpId] = useState();
  const [page] = useState(1); //, setPage
  //const [per_page, setPer_Page] = useState();
  const [itemSearchValue, setItemSearchValue] = useState();
  const [materialIdValue, setMaterialIdValue] = useState();
  //const [other, setOther] = useState('');
  const [otherVendorName, setOtherVendorName] = useState('');
  const [otherVendorLocation, setOtherVendorLocation] = useState('');
  const [otherVendorAddress, setOtherVendorAddress] = useState('');
  const [vendorSearch, setVendorSearch] = useState('');
  const [vendorList, setVendorList] = useState([]);
  const [value, setOnProceedValue] = useState({});
  const [draftLoading, setDraftLoading] = useState(false);
  const [proccedLoading, setProccedLoading] = useState(false);
  const [docVisibility, setDocVisibility] = useState(false);
  //const [expectedDateVisibility, setExpectedDateVisibility] = useState(false);
  const [tempDocList, setTempDocList] = useState([]);
  //const [vendorAddressValue, setVendorAddressValue] = useState('');
  const [itemsJsonData, setItemsJsonData] = useState(['']);
  const [fileName, setFileName] = useState(null);
  const [fileValue, setFileValue] = useState('');
  // const [gstNumberVal, setGstNumberVal] = useState([]);
  const [gstNumber, setGstNumber] = useState('');
  //const [fetchedItems, setFetchedItems] = useState([]);
  const [fetchedItems1, setFetchedItems1] = useState([]);
  // const [vendorId, setVendorId] = useState('');
  const [entityNum, setEntityNum] = useState('');
  //const [projectId, setProjectId] = useState('');
  //const [fetchedType_of_issue, setFetchedType_of_issue] = useState('');
  //const [vendorERP, setVendorERP] = useState([]);
  const [erpLoading, setErpLoading] = useState(false);
  //const [erpVendorLoading, setErpVendorLoading] = useState(false);
  //const [erpParseItems, setErpParseItems] = useState({});
  //const [vendorERPLocationName, setVendorERPLocationName] = useState('');
  const [vendorERPModal, setVendorERPModal] = useState(false);
  const [projectSearch, setProjectSearch] = useState('');
  const [projectLocationSearch, setProjectLocationSearch] = useState('');
  // const [projectLocationName, setProjectLocationName] = useState('');
  // const [projectLocationID, setProjectLocationID] = useState('');
  const [check_DocumentNumberLoading, setCheck_DocumentNumberLoading] =
    useState(false);
  const [approverSearch, setApproverSearch] = useState('');
  const [issuerSearch, setIssuerSearch] = useState('');
  const [disable, setDisable] = useState(false);

  const [totalValue, setTotalValue] = useState(0);
  const [itemNewValue, setItemNewValue] = useState({});
  const [itemKey, setItemKey] = useState();
  const [company, setCompany] = useState('');
  const [SAPdata, setSAPdata] = useState({});
  const [SapTotalValue, setSapTotalValue] = useState(0);

  //console.log('VendorERPLocationName', vendorERPLocationName);

  //console.log('other:', other); //do not delete
  useEffect(() => {
    handlegetRequestbyid();
  }, []);

  const handlegetRequestbyid = () => {
    {
      user?.user_details?.roles[0] === 'requester' ||
      user?.user_details?.roles[0] === 'issuer'
        ? dispatch(getrequesterdata(params?.id)).then((response) => {
            if (response.payload.success) {
              setTempDocList(response?.payload?.data?.SapDocuments);
            }
          })
        : '';
    }
    {
      user?.user_details?.roles[0] === 'issuer'
        ? dispatch(getrequestByid(params.id))
        : '';
    }
    dispatch(getAlltypes());
    dispatch(getAllcategories());
    dispatch(getAllmethodsSAP());
    dispatch(getAllreferenceDocs());
    dispatch(getAllmaterils());
  };

  /* useEffect(() => {
    if (
      formData1?.methodType_id === undefined &&
      requestbyid?.SapMethodTypeMasterId === 1
    ) {
      const payload = {
        SapPlantId:
          formData1?.project_id === undefined &&
          requestbyid?.document_number === null
            ? SAPdata?.PlantId
            : formData1?.project_id === undefined &&
              requestbyid?.document_number !== null
            ? requestbyid?.SapPlantId
            : requestbyid?.SapPlantId,
        SapStorageLocationId:
          formData1?.location_id === undefined
            ? requestbyid?.SapStorageLocationId === null
              ? SAPdata?.StorageLocationId
              : requestbyid?.SapStorageLocationId
            : SAPdata?.StorageLocationId
      };
      if (issuerSearch) payload['search'] = issuerSearch;
      dispatch(getAllissuers(payload));
    } else if (
      formData1?.methodType_id === 1 &&
      requestbyid?.document_number === null
    ) {
      const payload = {
        SapPlantId: SAPdata?.PlantId,
        SapStorageLocationId: SAPdata?.StorageLocationId
      };
      if (issuerSearch) payload['search'] = issuerSearch;
      dispatch(getAllissuers(payload));
    } else if (
      formData1?.methodType_id === 2 ||
      (formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 2)
      // formData1?.location_id !== undefined ||
      // (formData1?.location_id === undefined &&
      //   requestbyid?.SapStorageLocationId !== null)
    ) {
      const payload = {
        SapPlantId:
          formData1?.project_id === undefined
            ? requestbyid?.SapPlantId
            : formData1?.project_id,
        SapStorageLocationId:
          formData1?.location_id === undefined
            ? requestbyid?.SapStorageLocationId
            : formData1?.location_id
      };
      if (issuerSearch) payload['search'] = issuerSearch;
      dispatch(getAllissuers(payload));
    }
  }, [issuerSearch, formData1?.location_id, requestbyid, SAPdata]); */

  useEffect(() => {
    if (
      formData1?.location_id !== undefined ||
      (formData1?.location_id === undefined &&
        requestbyid?.SapStorageLocationId)
    ) {
      const payload = {
        SapPlantId:
          formData1?.project_id === undefined
            ? requestbyid?.SapPlantId
            : formData1?.project_id,
        SapStorageLocationId:
          formData1?.location_id === undefined
            ? requestbyid?.SapStorageLocationId
            : formData1?.location_id
      };
      if (issuerSearch) payload['search'] = issuerSearch;
      dispatch(getAllissuers(payload));
    } else if (formData1?.methodType_id === 1) {
      const payload = {
        SapPlantId: SAPdata?.PlantId,
        SapStorageLocationId: SAPdata?.StorageLocationId
      };
      if (issuerSearch) payload['search'] = issuerSearch;
      dispatch(getAllissuers(payload));
    } else if (
      formData1?.methodType_id === undefined &&
      requestbyid?.SapMethodTypeMasterId === 1
    ) {
      const payload = {
        SapPlantId:
          formData1?.project_id === undefined
            ? requestbyid?.SapPlantId === null
              ? SAPdata?.PlantId
              : requestbyid?.SapPlantId
            : SAPdata?.PlantId,
        SapStorageLocationId:
          formData1?.location_id === undefined
            ? requestbyid?.SapStorageLocationId === null
              ? SAPdata?.StorageLocationId
              : requestbyid?.SapStorageLocationId
            : SAPdata?.StorageLocationId
      };
      if (issuerSearch) payload['search'] = issuerSearch;
      dispatch(getAllissuers(payload));
    }
  }, [issuerSearch, formData1?.location_id, requestbyid, SAPdata]);

  useEffect(() => {
    if (
      formData1?.location_id !== undefined ||
      (formData1?.location_id === undefined &&
        requestbyid?.SapStorageLocationId)
    ) {
      const payload = {
        SapPlantId:
          formData1?.project_id === undefined
            ? requestbyid?.SapPlantId
            : formData1?.project_id,
        SapStorageLocationId:
          formData1?.location_id === undefined
            ? requestbyid?.SapStorageLocationId
            : formData1?.location_id
      };
      if (approverSearch) payload['search'] = approverSearch;
      dispatch(getAllapprovers(payload));
    } else if (formData1?.methodType_id === 1) {
      const payload = {
        SapPlantId: SAPdata?.PlantId,
        SapStorageLocationId: SAPdata?.StorageLocationId
      };
      if (approverSearch) payload['search'] = approverSearch;
      dispatch(getAllapprovers(payload));
    } else if (
      formData1?.methodType_id === undefined &&
      requestbyid?.SapMethodTypeMasterId === 1
    ) {
      const payload = {
        SapPlantId:
          formData1?.project_id === undefined
            ? requestbyid?.SapPlantId === null
              ? SAPdata?.PlantId
              : requestbyid?.SapPlantId
            : SAPdata?.PlantId,
        SapStorageLocationId:
          formData1?.location_id === undefined
            ? requestbyid?.SapStorageLocationId === null
              ? SAPdata?.StorageLocationId
              : requestbyid?.SapStorageLocationId
            : SAPdata?.StorageLocationId
      };
      if (approverSearch) payload['search'] = approverSearch;
      dispatch(getAllapprovers(payload));
    }
  }, [approverSearch, formData1?.location_id, requestbyid, SAPdata]);

  useEffect(() => {
    if (
      formData1?.vendor_location ||
      (formData1?.vendor_location === undefined &&
        requestbyid?.VendorSiteMasterId)
    ) {
      formData1?.vendor_id === 'other'
        ? ''
        : dispatch(
            vendorLocationAddresses({
              vendorSiteId:
                formData1?.vendor_location === undefined
                  ? requestbyid?.VendorSiteMasterId
                  : formData1?.vendor_location
            })
          );
    }
  }, [requestbyid, formData1?.vendor_location]);

  const debounceFetcherApprover = useMemo(() => {
    const loadOptions = (approverSearch) => {
      setApproverSearch(approverSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const debounceFetcherIssuer = useMemo(() => {
    const loadOptions = (issuerSearch) => {
      setIssuerSearch(issuerSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  useEffect(() => {
    handleGetAllProject();
  }, [projectSearch, SAPdata, requestbyid]);

  const handleGetAllProject = () => {
    if (
      formData1?.methodType_id === 1 ||
      (formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 1)
    ) {
      const payload = {
        id:
          SAPdata?.PlantId === undefined
            ? requestbyid?.SapPlantId
            : SAPdata?.PlantId,
        page: 1,
        per_page: 1000
      };

      if (projectSearch) payload['search'] = projectSearch;
      dispatch(getAllPlants_forSAP(payload));
    } else {
      const payload = { page: 1, per_page: 1000 };
      if (projectSearch) payload['search'] = projectSearch;
      dispatch(getAllPlants(payload));
    }
  };
  console.log('SAPdata?.PlantId:', SAPdata?.PlantId);
  // useEffect(() => {
  //   let payload = {
  //     id:
  //       formData1?.project_id === undefined
  //         ? requestbyid?.project_master_id
  //         : formData1?.project_id
  //   };

  //   if (projectLocationSearch) payload['search'] = projectLocationSearch;

  //   requestbyid?.project_master_id === undefined
  //     ? ''
  //     : dispatch(getAllProjectLocations(payload));
  // }, [requestbyid, projectLocationSearch, formData1?.project_id]);

  // useEffect(() => {
  //   let payload = {
  //     id: formData1?.project_id
  //   };
  //   formData1?.project_id === undefined
  //     ? ''
  //     : dispatch(getAllProjectLocations(payload));
  // }, [formData1?.project_id]);

  // useEffect(() => {
  //   let gstValues = projectLocations?.filter(
  //     (item) => item?.id === formData1?.location_id
  //   );

  //   setGstNumberVal(gstValues);
  //   setGstNumber(gstValues[0]?.gst_number);
  //   setProjectLocationName(gstValues[0]?.name);
  //   setProjectLocationID(gstValues[0]?.id);
  // }, [formData1?.location_id]);
  useEffect(() => {
    let payload = {
      SapPlantId:
        formData1?.project_id === undefined
          ? requestbyid?.SapPlantId
          : formData1?.project_id
    };

    if (projectLocationSearch) payload['search'] = projectLocationSearch;

    requestbyid?.SapPlantId === undefined
      ? ''
      : dispatch(getAllPlantLocations(payload));
  }, [requestbyid?.SapPlantId, projectLocationSearch, formData1?.project_id]);

  useEffect(() => {
    let selectedPlant = plants?.filter(
      (item) => item?.id === formData1?.project_id
    );

    setGstNumber(selectedPlant[0]?.gst);
    setCompany(selectedPlant[0]?.SapCompanyMaster?.company_name);
    //setProjectLocationName(selectedPlant[0]?.name);
    //setProjectLocationID(selectedPlant[0]?.id);
  }, [formData1?.project_id]);

  useEffect(() => {
    let payload = {
      per_page: 20
    };
    if (vendorSearch) payload['search'] = vendorSearch;
    dispatch(getAllVendors(payload));
  }, [vendorSearch]);

  useEffect(() => {
    const vendors1 = [...vendors, { vendorName: 'other', id: 'other' }];
    setVendorList(vendors1);
  }, [vendors]);

  useEffect(() => {
    dispatch(
      getGatepassItems({
        gpid: parseInt(params.id),
        page: page,
        // per_page: per_page,
        search: itemSearchValue,
        all: 1
      })
    );
  }, [page, itemSearchValue]);
  // console.log('gatepassItems>>>>>>>>>>', gatepassItems);
  console.log('formData1:', formData1);
  // console.log('vendorName', vendorName);
  // console.log('vendorAddress###########', vendorAddress);
  //console.log('supportingDoc###########', supportingDoc);
  /* vendorAddress, setVendorAddress] = useState([]);
  const [vendorName, */
  useEffect(() => {
    dispatch(
      getAllreasontosend({
        category: requestbyid?.SapCategoryMasterId === 1 ? 'RGP' : 'NRGP'
      })
    );
  }, [requestbyid]);
  useEffect(() => {
    if (formData1?.type_id === 1) {
      dispatch(
        getAllreasontosend({
          category: 'RGP'
        })
      );
    } else {
      dispatch(
        getAllreasontosend({
          category: formData1?.category_id === 1 ? 'RGP' : 'NRGP'
        })
      );
    }
  }, [formData1?.category_id, formData1?.type_id]);

  useEffect(() => {
    if (formData1?.vendor_id) {
      formData1?.vendor_id === 'other'
        ? ''
        : dispatch(getAllVendorLocation({ vendorId: formData1?.vendor_id }));
    }
  }, [formData1?.vendor_id]);

  useEffect(() => {
    let VendorValues = vendors?.filter(
      (item) => item?.id === formData1?.vendor_id
    );
    setVendorName(VendorValues);
  }, [formData1?.vendor_id]);

  useEffect(() => {
    let locationValues = vendorLocation?.filter(
      (item) => item?.id === formData1?.vendor_location
    );
    setVendorAddress(locationValues);
  }, [formData1?.vendor_location]);

  useEffect(() => {
    if (formData1?.methodType_id === 1) {
      setMaterialIdValue(materials[0]?.id);
    } else {
      setMaterialIdValue(formData1?.material_id);
    }
  }, [formData1?.methodType_id]);

  useEffect(() => {
    const {
      SapCategoryMasterId,
      //SapMaterialBelongsMasterId,
      //SapMethodTypeMasterId,
      SapPlantId,
      SapReasonToSendMasterId,
      SapStorageLocationId,
      SapTypeMasterId,
      //UserId,
      //VendorMasterId,
      requester_name,
      requester_department,
      date,
      expected_date_return,
      vehicle_number,
      lr_number,
      carrier_person_name,
      detailed_reason,
      remarks
    } = requestbyid;
    form.setFieldsValue({
      requester_name: requester_name,
      requester_department: requester_department,
      project_id: SapPlantId,
      location_id: SapStorageLocationId,
      type_id: SapTypeMasterId,
      category_id: SapCategoryMasterId,
      methodType_id: requestbyid?.SapMethodTypeMasterId,
      document_id: requestbyid?.SapDocumentMasterId,
      sap_year: requestbyid?.year === null ? '' : moment(requestbyid?.year),
      doc_number: requestbyid?.document_number,
      //vendor_id: VendorMasterId === null ? '' : VendorMasterId,
      vendor_id:
        requestbyid?.Vendor_Master === null
          ? ''
          : `${requestbyid?.Vendor_Master?.vendorName} ${
              requestbyid?.Vendor_Master?.vendorCode
                ? `(${requestbyid?.Vendor_Master?.vendorCode})`
                : ''
            }`,
      vendor_location:
        requestbyid?.vendor_location_name === null
          ? ''
          : requestbyid?.vendor_location_name,

      vendor_location_address:
        requestbyid?.vendor_location_address === null
          ? ''
          : requestbyid?.vendor_location_address,
      date: moment(date),
      material_id:
        requestbyid?.SapMaterialBelongsMasterId === 1
          ? requestbyid?.SapMaterialBelongsMaster?.material_name
          : requestbyid?.SapMaterialBelongsMasterId,
      //SapMaterialBelongsMasterId,
      expected_date_return: expected_date_return
        ? moment(requestbyid?.expected_date_return)
        : null,
      vehicle_number,
      lr_number,
      lr_date:
        requestbyid?.lr_date === null ? '' : moment(requestbyid?.lr_date),
      carrier_person_name,
      //user_id: approver_user_id,
      reason_id: SapReasonToSendMasterId, // requestbyid?.reason_id,
      detailed_reason,
      remarks,

      items: gatepassItems?.map((item) => ({
        id: item?.id,
        item_name: item?.item_name,
        item_code: item?.item_code === null ? '' : item?.item_code,
        uom: item?.uom,
        quantity: item?.quantity,
        item_description: item?.item_description,
        item_remarks: item?.item_remarks === null ? '' : item?.item_remarks,
        value: item?.value
      })),
      issuer_id:
        requestbyid?.SapApprovers?.length > 0
          ? requestbyid?.SapApprovers[1]?.User?.id
          : '', //issuer_user_id,
      approver_id:
        requestbyid?.SapApprovers?.length > 0
          ? requestbyid?.SapApprovers[0]?.User?.id
          : '' //approver_user_id
    });
    const gatepassItemsERP = gatepassItems?.map((item) => ({
      id: item?.id,
      item_name: item?.item_name,
      item_code: item?.item_code === null ? '' : item?.item_code,
      uom: item?.uom,
      quantity: item?.quantity,
      value: item?.value,
      item_description: item?.item_description,
      item_remarks: item?.item_remarks === null ? '' : item?.item_remarks
    }));
    setFetchedItems1(gatepassItemsERP);
  }, [requestbyid, gatepassItems]);
  //console.log('requestbyid', requestbyid);

  useEffect(() => {
    if (formData1?.methodType_id === 1) {
      const gatepassItemsERP = gatepassItems?.map((item) => ({
        id: item?.id,
        item_name: item?.item_name,
        item_code: item?.item_code === null ? '' : item?.item_code,
        uom: item?.uom,
        quantity: item?.quantity,
        value: item?.value,
        item_description: item?.item_description,
        item_remarks: item?.item_remarks === null ? '' : item?.item_remarks
      }));
      setFetchedItems1(gatepassItemsERP); //---------------2
    }
    if (formData1?.methodType_id === 2) {
      form.setFieldsValue({
        items: gatepassItems?.map((item) => ({
          id: item?.id,
          item_name: item?.item_name,
          item_code: item?.item_code,
          uom: item?.uom,
          quantity: item?.quantity,
          item_description: item?.item_description,
          item_remarks: item?.item_remarks,
          value: item?.value
        }))
      });
    }
  }, [formData1?.methodType_id]);

  const downloadTemplate = () => {
    const downloadUrl = '/Item_Template_sap.xlsx'; // '/templates/template.xlsx';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'Item_Template_sap.xlsx'; //'template.xlsx';
    link.click();
  };

  const handleRemarksChange = (index, value) => {
    // console.log('Draft-index, event', index, event);

    // console.log('Draft-...fetchedItems', fetchedItems);

    const newItems = [...fetchedItems1];
    newItems[index].item_remarks = value;
    setFetchedItems1(newItems);

    // const newItems = [...fetchedItems];
    // newItems[index].item_remarks = event.target.value;
    // console.log('Draft-newItems', newItems);
    // setFetchedItems1(newItems);
  };
  console.log('Draft-fetchedItems111111111@@@@@@@@', fetchedItems1);

  useEffect(() => {
    form.setFieldsValue({
      requester_name: user?.user_details?.fullname,
      requester_department: user?.user_details?.roles[0],
      document_number: documentNumber,
      date: moment() || ''
      //lr_date: moment() || ''
    });
  });
  //console.log('requestbyid', requestbyid);

  useEffect(() => {
    if (requestbyid?.SapMethodTypeMasterId === 1) {
      setDocVisibility(true);
    } else {
      setDocVisibility(false);
    }
  }, [requestbyid]);
  useEffect(() => {
    if (formData1?.methodType_id === 1) {
      setDocVisibility(true);
      form.setFieldsValue({
        document_id: undefined,
        document_number: undefined
      });
    } else {
      setDocVisibility(false);
    }
  }, [formData1?.methodType_id]);

  useEffect(() => {
    if (
      formData1?.type_id === 1 ||
      (formData1?.type_id === undefined && requestbyid?.SapTypeMasterId === 1)
    ) {
      formData1['category_id'] = categories[0]?.id;
      form.setFieldsValue({
        category_id: categories[0]?.id
      });
    }
  }, [formData1?.type_id, requestbyid?.SapTypeMasterId]);

  useEffect(() => {
    if (formData1?.type_id === 2) {
      form.setFieldsValue({
        category_id: undefined
      });
    }
  }, [formData1?.type_id]);
  // console.log('DocumentID', documentID);
  const handleChangedocument = (values) => {
    console.log('handleChangedocument', values);
    setDocumentID(values);
    let doc_numbers = docs.filter((docs) => docs.id === values);
    setDocumentNumber(doc_numbers[0].document_number);
    form.setFieldsValue({
      //location_id: undefined,
      vendor_id: undefined,
      vendor_location: undefined,
      doc_number: undefined
    });
  };

  const handleChangeDocNumber = (e) => {
    setEntityNum(e.target.value);
    form.setFieldsValue({
      vendor_id: undefined,
      vendor_location: undefined
    });
  };

  // console.log('EntityNum@@@@@@@@@@@@@@@', entityNum);

  const handleOtherVendorName = (e) => {
    console.log('OtherVendorName', e.target.value);
    setOtherVendorName(e.target.value);
  };
  const handleOtherVendorLocation = (e) => {
    console.log('OtherVendorName', e.target.value);
    setOtherVendorLocation(e.target.value);
  };
  const handleOtherVendorAddress = (e) => {
    console.log('OtherVendorName', e.target.value);
    setOtherVendorAddress(e.target.value);
  };

  // const handleChangeItem = (value, slug) => {
  //   setItemVal({
  //     ...itemVal,
  //     [slug]: value
  //   });
  // };
  const handleChangeItem = (value, slug, keyname, key) => {
    if (`${keyname}_${key}` === `value_${key}`) {
      setItemNewValue({
        ...itemNewValue,
        [slug]: value
      });
    }
    setItemVal({
      ...itemVal,
      [slug]: value
    });
  };

  //adding total value by BulkUpload
  useEffect(() => {
    const updatedData = itemsJsonData?.map((item, index) => ({
      [`value_${index}`]: parseFloat(item?.Item_value) || 0
    }));

    const resultObject = updatedData?.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});

    //console.log('resultObject', resultObject);
    setItemNewValue(resultObject);
    let total = 0;
    for (const key in itemNewValue) {
      if (Object.prototype.hasOwnProperty.call(itemNewValue, key)) {
        total += parseFloat(itemNewValue[key] || 0);
      }
    }
    setTotalValue(total);
  }, [itemsJsonData]);

  //adding total value for Edit
  useEffect(() => {
    const updatedData = gatepassItems?.map((item, index) => ({
      [`value_${index}`]: parseFloat(item?.value) || 0
    }));

    const resultObject = updatedData?.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});

    console.log('resultObject', resultObject);
    setItemNewValue(resultObject);
    let total = 0;
    for (const key in itemNewValue) {
      if (Object.prototype.hasOwnProperty.call(itemNewValue, key)) {
        total += parseFloat(itemNewValue[key] || 0);
      }
    }
    setTotalValue(total);
  }, [gatepassItems]);

  //adding total value
  useEffect(() => {
    let total = 0;
    for (const key in itemNewValue) {
      if (Object.prototype.hasOwnProperty.call(itemNewValue, key)) {
        total += parseFloat(itemNewValue[key] || 0);
      }
    }
    setTotalValue(total);
  }, [requestbyid, itemNewValue]);

  //reducing deleted field value from total value
  useEffect(() => {
    let total = totalValue;
    for (const key in itemNewValue) {
      if (key === `value_${itemKey}`) {
        total = totalValue - parseFloat(itemNewValue[key]);
        delete itemNewValue[key];
      }
    }
    setTotalValue(total);
  }, [requestbyid, itemKey]);

  /* --SAP Total Value-- */
  useEffect(() => {
    let total = 0;
    fetchedItems1.forEach((item) => {
      if (item?.value !== '') {
        console.log('TTTTparseFloat(item?.value):', parseFloat(item?.value));
        total += parseFloat(item?.value);
      }
    });

    console.log('TTTTTotal:', total);
    setSapTotalValue(total);
  }, [fetchedItems1]);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);
    if (slug === 'methodType_id') {
      form.setFieldsValue({
        vendor_id: undefined,
        vendor_location: undefined,
        location_id: undefined,
        vendor_location_address: undefined,
        document_id: undefined,
        sap_year: undefined,
        doc_number: undefined,
        reason_id: undefined,
        project_id: undefined,
        type_id: undefined,
        category_id: undefined,
        approver_id: undefined,
        issuer_id: undefined
      });
    }
    if (slug === 'sap_year') {
      form.setFieldsValue({
        doc_number: undefined
      });
    }

    if (slug === 'vendor_id') {
      // setOther(value);
      form.setFieldsValue({
        vendor_location: undefined,
        vendor_location_address: undefined
      });
    }

    if (slug === 'vendor_location') {
      form.setFieldsValue({
        vendor_location_address: undefined
      });
    }
    if (slug === 'material_id') {
      setMaterialIdValue(value);
    }
    if (slug === 'type_id') {
      form.setFieldsValue({
        document_id: undefined,
        sap_year: undefined,
        doc_number: undefined
      });
    }
    if (slug === 'category_id') {
      //setOther(value);
      form.setFieldsValue({
        reason_id: undefined,
        document_id: undefined,
        sap_year: undefined,
        doc_number: undefined
      });
      form.setFieldsValue({
        expected_date_return: undefined
      });
    }
    if (slug === 'project_id') {
      setGstNumber('');
      //setProjectId(value);
      form.setFieldsValue({
        vendor_id: undefined,
        vendor_location: undefined,
        location_id: undefined,
        vendor_location_address: undefined
      });
    }

    // if (slug === 'location_id') {
    //   setGstNumber(gstNumberVal[0]?.gst_number);
    // }

    if (slug === 'location_id' || slug === 'project_id') {
      form.setFieldsValue({
        approver_id: undefined,
        issuer_id: undefined
      });
    }

    setFormData1({
      ...formData1,
      [slug]: value,
      ['files']: [supportingDoc],
      ['address']: vendorAddress[0]?.addressLine1,
      ['vendor_address']: vendorAddress[0]?.addressLine1
    });
  };
  // console.log('materialIdValue__material_id', materialIdValue);

  useEffect(() => {
    form.setFieldsValue({
      material_id:
        formData1?.methodType_id === 1
          ? materials[0]?.material_name
          : formData1?.material_id
    });
  }, [formData1?.methodType_id]);

  useEffect(() => {
    setSelectedTabId(filters?.[0]?.id || null);
  }, []);

  const handleOnClick = () => {
    let docsCopy = [...newDocs];

    docsCopy.push({
      supporting_docs: '',
      docs: ''
    });

    setNewDocs(docsCopy);
  };

  const handleRemove = (e, i) => {
    let uploadeddocs = [...newDocs];
    uploadeddocs.splice(i, 1);
    setNewDocs(uploadeddocs);
  };

  const handlechangeDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: e.target.files[0] };

    setSupportingDoc(newDocValues);
  };

  const handleclearDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: '' };

    setSupportingDoc(newDocValues);
  };

  const handleGetERPData = () => {
    setCheck_DocumentNumberLoading(true);
    const check_DocumentNumbe_payload = {
      year: formData1?.sap_year,
      document_number: entityNum
    };

    dispatch(get_Check_DocumentNumber(check_DocumentNumbe_payload)).then(
      function (response) {
        if (response?.payload?.success) {
          setCheck_DocumentNumberLoading(false);

          setErpLoading(true);

          const apiUrl = `${process.env.REACT_APP_API_URL2}/sap-materials`;

          var raw = JSON.stringify({
            document_details: {
              Year: formData1?.sap_year,
              Reference_Documents: entityNum
            }
          });

          fetch(apiUrl, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${userToken?.token}`,
              'Content-Type': 'application/json'
            },
            body: raw
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then((data) => {
              console.log('DATA:', data);
              const SAP_data = data?.data;
              setSAPdata(SAP_data);
              setErpLoading(false);

              if (
                SAP_data.VendorCode === '' ||
                SAP_data.VendorCode === null ||
                SAP_data.VendorCode === undefined
              ) {
                setVendorERPModal(true);
                console.log('Vendor is null', SAP_data.VendorCode);
              }
              if (
                (SAP_data?.HeaderToItems?.results?.length > 0 &&
                  SAP_data.VendorCode !== '') ||
                (SAP_data?.HeaderToItems?.results?.length > 0 &&
                  SAP_data.VendorCode !== null)
              ) {
                if (
                  formData1?.methodType_id === 1 ||
                  (formData1?.methodType_id === undefined &&
                    requestbyid?.SapMethodTypeMasterId === 1)
                ) {
                  const itemsERP = SAP_data?.HeaderToItems?.results?.map(
                    (item) => ({
                      item_code: item?.MaterialCode,
                      uom: item?.UOM,
                      quantity: item?.quantity,
                      item_description: item?.description,
                      value: item?.Value === '' ? 0 : item?.Value,
                      item_remarks: item?.Remarks
                    })
                  );
                  if (documentID === 1) {
                    const movementTypeDec1 = [122, 161];
                    const movementTypeExist = movementTypeDec1?.includes(
                      parseInt(SAP_data?.MovementType)
                    );

                    if (movementTypeExist) {
                      setDisable(false);
                      setFetchedItems1(itemsERP);
                      message.success('Item Details fetched successfully');
                    } else {
                      setDisable(true);
                      message.error(
                        'Document Type mismatched with Document Number'
                      );
                    }
                  } else if (documentID === 2) {
                    const movementTypeDec1 = [221, 261];
                    const movementTypeExist = movementTypeDec1?.includes(
                      parseInt(SAP_data?.MovementType)
                    );

                    if (movementTypeExist) {
                      setDisable(false);
                      setFetchedItems1(itemsERP);
                      message.success('Item Details fetched successfully');
                    } else {
                      setDisable(true);
                      message.error(
                        'Document Type mismatched with Document Number'
                      );
                    }
                  } else if (documentID === 3) {
                    const movementTypeDec1 = [
                      541, 311, 313, 641, 643, 301, 303
                    ];
                    const movementTypeExist = movementTypeDec1?.includes(
                      parseInt(SAP_data?.MovementType)
                    );

                    if (movementTypeExist) {
                      setDisable(false);
                      setFetchedItems1(itemsERP);
                      message.success('Item Details fetched successfully');
                    } else {
                      setDisable(true);
                      message.error(
                        'Document Type mismatched with Document Number'
                      );
                    }
                  } else if (documentID === 4) {
                    const movementTypeDec1 = [601];
                    const movementTypeExist = movementTypeDec1?.includes(
                      parseInt(SAP_data?.MovementType)
                    );

                    if (movementTypeExist) {
                      setDisable(false);
                      setFetchedItems1(itemsERP);
                      message.success('Item Details fetched successfully');
                    } else {
                      setDisable(true);
                      message.error(
                        'Document Type mismatched with Document Number'
                      );
                    }
                  }
                }
              }
            })
            .catch((error) => {
              console.error(
                'Response payload did not fetch successfully. Contact your TPL admin',
                error
              );
            });
        } else {
          setCheck_DocumentNumberLoading(false);
          message.error(
            'The document number has already been used for another Gate Pass request. Verify the Document Number and enter again'
          );
          form.setFieldsValue({
            doc_number: undefined
          });
          // window.location.reload();
        }
      }
    );
  };
  const onFinish = (values) => {
    // console.log('valuesITEMS', values);
    setFormItemData(values);
  };

  console.log('fetchedItems1', fetchedItems1);

  const saveAsDraft = () => {
    setDraftLoading(true);
    let formdata = new FormData();
    formdata.append('gpid', parseInt(params.id));
    formdata.append('is_submitting', 'no');

    formData1?.methodType_id === 1 ||
    (formData1?.methodType_id === undefined &&
      requestbyid?.SapMethodTypeMasterId === 1)
      ? formdata.append(
          'items',
          fetchedItems1 === undefined ? '[]' : JSON.stringify(fetchedItems1)
        )
      : formdata.append(
          'items',
          JSON.stringify(fetchedItems1)
          // Object.keys(formItemData).length > 0
          //   ? JSON.stringify(formItemData?.items)
          //   : '[]'
        );

    for (let i in supportingDoc) {
      formdata.append('files', supportingDoc[i]);
    }

    if (
      requestbyid?.SapMethodTypeMasterId === 1 &&
      requestbyid?.document_number !== null
    ) {
      formdata.append(
        'all_items_value',
        parseFloat(requestbyid?.all_items_value).toFixed(2) || 0
      );
    } else if (
      formData1?.methodType_id === 1 ||
      (requestbyid?.SapMethodTypeMasterId === 1 &&
        requestbyid?.document_number === null)
    ) {
      formdata.append('all_items_value', SapTotalValue);
    } else {
      formdata.append('all_items_value', totalValue || 0);
    }
    if (
      formData1?.methodType_id === 1 ||
      (formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 1)
    ) {
      formdata.append(
        'SapPlantId',
        requestbyid?.document_number !== null
          ? requestbyid?.SapPlantId || ''
          : SAPdata?.PlantId || ''
      );
      formdata.append(
        'SapStorageLocationId',
        requestbyid?.document_number !== null
          ? requestbyid?.SapStorageLocationId || ''
          : SAPdata?.StorageLocationId || ''
      );
      formdata.append(
        'company',
        requestbyid?.document_number !== null
          ? requestbyid?.company || ''
          : plants_forSAP?.SapCompanyMaster?.company_name // SAPdata?.CompanyName || ''
      );
    } else {
      formdata.append(
        'SapPlantId',
        formData1?.project_id === undefined
          ? requestbyid?.SapPlantId === null
            ? ''
            : requestbyid?.SapPlantId
          : formData1?.project_id === undefined
          ? ''
          : formData1?.project_id
      );
      formdata.append(
        'SapStorageLocationId',
        formData1?.location_id === undefined
          ? requestbyid?.SapStorageLocationId === null
            ? ''
            : requestbyid?.SapStorageLocationId
          : formData1?.location_id === undefined
          ? ''
          : formData1?.location_id
      );
      formdata.append(
        'company',
        formData1?.project_id === undefined
          ? requestbyid?.company === null
            ? ''
            : requestbyid?.company
          : company === undefined
          ? ''
          : company
      );
    }
    /*  formdata.append(
      'SapPlantId',
      formData1?.project_id === undefined
        ? requestbyid?.SapPlantId === null
          ? ''
          : requestbyid?.SapPlantId
        : formData1?.project_id
    );
    formdata.append(
      'SapStorageLocationId',
      formData1?.location_id === undefined
        ? requestbyid?.SapStorageLocationId === null
          ? ''
          : requestbyid?.SapStorageLocationId
        : formData1?.location_id
    );
    formdata.append(
      'company',
      formData1?.project_id === undefined
        ? requestbyid?.company === null
          ? ''
          : requestbyid?.company
        : company
    ); */
    // formdata.append(
    //   'type_id',
    //   formData1?.type_id === undefined
    //     ? requestbyid?.type_id === null
    //       ? ''
    //       : requestbyid?.type_id
    //     : formData1?.type_id
    // );
    formdata.append(
      'SapTypeMasterId',
      formData1?.type_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === null
        ? ''
        : formData1?.type_id === undefined &&
          requestbyid?.SapMethodTypeMasterId !== null
        ? requestbyid?.SapTypeMasterId
        : formData1?.type_id
    );
    formdata.append(
      'approver_id',
      formData1?.approver_id === undefined
        ? requestbyid?.SapApprovers?.length === 0
          ? ''
          : requestbyid?.SapApprovers[0]?.User !== null
          ? requestbyid?.SapApprovers[0]?.User?.id
          : ''
        : formData1?.approver_id
    );
    formdata.append(
      'issuer_id',
      formData1?.issuer_id === undefined
        ? requestbyid?.SapApprovers?.length === 0
          ? ''
          : requestbyid?.SapApprovers[1]?.User !== null
          ? requestbyid?.SapApprovers[1]?.User?.id
          : ''
        : formData1?.issuer_id
    );

    // formData1?.type_id === undefined &&
    // formData1?.category_id === undefined &&
    // requestbyid?.SapCategoryMasterId === null
    //   ? formdata.append('category_id', '')
    //   : formData1?.type_id === undefined &&
    //     formData1?.category_id === undefined &&
    //     requestbyid?.category_id !== null
    //   ? formdata.append('category_id', requestbyid?.category_id)
    //   : (formData1?.type_id === undefined &&
    //       formData1?.category_id !== undefined) ||
    //     (formData1?.type_id === undefined && requestbyid?.type_id === 1)
    //   ? formdata.append('category_id', formData1?.category_id)
    //   : formData1?.type_id === 1 && formData1?.category_id === undefined
    //   ? formdata.append('category_id', categories[0]?.id)
    //   : formdata.append('category_id', formData1?.category_id);
    formData1?.type_id === undefined &&
    formData1?.category_id === undefined &&
    requestbyid?.SapCategoryMasterId === null
      ? formdata.append('SapCategoryMasterId', '')
      : formData1?.type_id === undefined &&
        formData1?.category_id === undefined &&
        requestbyid?.SapCategoryMasterId !== null
      ? formdata.append('SapCategoryMasterId', requestbyid?.SapCategoryMasterId)
      : (formData1?.type_id === undefined &&
          formData1?.category_id !== undefined) ||
        (formData1?.type_id === undefined && requestbyid?.SapTypeMasterId === 1)
      ? formdata.append('SapCategoryMasterId', formData1?.category_id)
      : formData1?.type_id === 1 && formData1?.category_id === undefined
      ? formdata.append('SapCategoryMasterId', categories[0]?.id)
      : formdata.append('SapCategoryMasterId', formData1?.category_id);

    if (
      formData1?.methodType_id === 1 ||
      (formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 1)
    ) {
      formdata.append('SapMethodTypeMasterId', 1);
    } else {
      formdata.append(
        'SapMethodTypeMasterId',
        formData1?.methodType_id === undefined
          ? requestbyid?.SapMethodTypeMasterId === null
            ? ''
            : requestbyid?.SapMethodTypeMasterId
          : formData1?.methodType_id
      );
    }

    /* formData1?.methodType_id === 1 ||
    (formData1?.methodType_id === undefined &&
      requestbyid?.SapMethodTypeMasterId === 1)
      ? formdata.append(
          'SapDocumentMasterId',
          documentID === undefined
            ? requestbyid?.SapDocumentMasterId === null
              ? ''
              : requestbyid?.SapDocumentMasterId
            : documentID
        )
      : '';

    (formData1?.methodType_id === undefined &&
      requestbyid?.SapMethodTypeMasterId === 1) ||
    formData1?.methodType_id === 1
      ? formdata.append(
          'document_number',
          entityNum === '' || entityNum === undefined || entityNum === null
            ? requestbyid?.document_number === null
              ? ''
              : requestbyid?.document_number
            : entityNum //documentNumber
        )
      : '';
    requestbyid?.SapMethodTypeMasterId === 1
      ? formData1?.sap_year === undefined
        ? formdata.append('year', requestbyid?.year)
        : formdata.append('year', formData1?.sap_year)
      : ''; */
    formData1?.methodType_id === 1 ||
    (formData1?.methodType_id === undefined &&
      requestbyid?.SapMethodTypeMasterId === 1)
      ? formdata.append(
          'SapDocumentMasterId',
          documentID === undefined
            ? requestbyid?.SapDocumentMasterId || ''
            : documentID
        )
      : '';
    formData1?.methodType_id === 1 ||
    (formData1?.methodType_id === undefined &&
      requestbyid?.SapMethodTypeMasterId === 1)
      ? formdata.append(
          'document_number',
          entityNum === undefined || entityNum === '' || entityNum === null
            ? requestbyid?.document_number === null
              ? ''
              : requestbyid?.document_number
            : entityNum //documentNumber
        )
      : '';

    formData1?.methodType_id === 1 ||
    (formData1?.methodType_id === undefined &&
      requestbyid?.SapMethodTypeMasterId === 1)
      ? formData1?.sap_year === undefined
        ? formdata.append('year', requestbyid?.year || '')
        : formdata.append('year', formData1?.sap_year || '')
      : '';

    formdata.append(
      'SapReasonToSendMasterId',
      formData1?.reason_id === undefined
        ? requestbyid?.SapReasonToSendMasterId === null
          ? ''
          : requestbyid?.SapReasonToSendMasterId
        : formData1?.reason_id
    );

    if (
      formData1?.methodType_id === undefined &&
      requestbyid?.SapMethodTypeMasterId === 1 &&
      requestbyid?.document_number !== null
    ) {
      formdata.append('vendor_name', requestbyid?.Vendor_Master?.vendorName);
      formdata.append('vendor_code', requestbyid?.Vendor_Master?.vendorCode);
      formdata.append('vendor_location', requestbyid?.vendor_location_name);
      formdata.append('vendor_address', requestbyid?.vendor_location_address);
    } else if (
      (formData1?.methodType_id === 1 && Object.keys(SAPdata).length > 0) ||
      (requestbyid?.SapMethodTypeMasterId === 1 &&
        requestbyid?.document_number === null)
    ) {
      formdata.append(
        'vendor_name',
        SAPdata?.VendorName ? SAPdata?.VendorName : ''
      );
      formdata.append(
        'vendor_code',
        SAPdata?.VendorCode ? SAPdata?.VendorCode : ''
      );
      formdata.append(
        'vendor_location',
        SAPdata?.VendorLocation ? SAPdata?.VendorLocation : ''
      );
      formdata.append(
        'vendor_address',
        SAPdata?.vendor_address ? SAPdata?.vendor_address : ''
      );
    } else if (formData1?.vendor_id === 'other') {
      formdata.append('vendor_name', otherVendorName || '');
      formdata.append('vendor_location', otherVendorLocation || '');
      formdata.append('vendor_address', otherVendorAddress || '');
    } else if (
      formData1?.methodType_id === 2 ||
      (formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 2)
    ) {
      formdata.append(
        'vendor_name',
        vendorName[0]?.vendorName === undefined
          ? requestbyid?.Vendor_Master?.vendorName || ''
          : vendorName[0]?.vendorName || ''
      );
      formdata.append(
        'vendor_code',
        vendorName[0]?.vendorCode === undefined
          ? requestbyid?.Vendor_Master?.vendorCode || ''
          : vendorName[0]?.vendorCode || ''
      );
      formdata.append(
        'vendor_location',
        vendorAddress[0]?.vendorSite === undefined
          ? requestbyid?.vendor_location_name || ''
          : vendorAddress[0]?.vendorSite || ''
      ); //formData1?.vendor_location
      formdata.append(
        'vendor_address',
        formData1?.vendor_location_address === undefined
          ? requestbyid?.vendor_location_address || ''
          : formData1?.vendor_location_address || ''
      );
    } else {
      formdata.append(
        'vendor_name',
        vendorName[0]?.vendorName === undefined
          ? requestbyid?.Vendor_Master?.vendorName || ''
          : vendorName[0]?.vendorName || ''
      );
      formdata.append(
        'vendor_code',
        vendorName[0]?.vendorCode === undefined
          ? requestbyid?.Vendor_Master?.vendorCode || ''
          : vendorName[0]?.vendorCode || ''
      );
      formdata.append(
        'vendor_location',
        vendorAddress[0]?.vendorSite === undefined
          ? requestbyid?.vendor_location_name || ''
          : vendorAddress[0]?.vendorSite || ''
      ); //formData1?.vendor_location
      formdata.append(
        'vendor_address',
        formData1?.vendor_location_address === undefined
          ? requestbyid?.vendor_location_address || ''
          : formData1?.vendor_location_address || ''
      );
    }

    formdata.append(
      'date',
      formData1?.date === undefined
        ? moment().format()
        : moment(formData1?.date).format()
    );

    formdata.append(
      'SapMaterialBelongsMasterId',
      formData1?.methodType_id === 1
        ? materials[0]?.id
        : formData1?.material_id === undefined
        ? requestbyid?.SapMaterialBelongsMasterId === null
          ? ''
          : requestbyid?.SapMaterialBelongsMasterId
        : materialIdValue
    );

    (formData1?.type_id === undefined &&
      formData1?.category_id === undefined &&
      requestbyid?.SapCategoryMasterId === 1) ||
    (formData1?.type_id === undefined && requestbyid?.SapTypeMasterId === 1) ||
    formData1?.type_id === 1 ||
    formData1?.category_id === 1
      ? formdata.append(
          'expected_date_return',
          formData1?.expected_date_return === undefined &&
            requestbyid?.expected_date_return === null
            ? ''
            : formData1?.expected_date_return === undefined &&
              requestbyid?.expected_date_return !== null
            ? requestbyid?.expected_date_return
            : moment(formData1?.expected_date_return).format()
        )
      : '';

    formdata.append(
      'detailed_reason',
      formData1?.detailed_reason === undefined
        ? requestbyid?.detailed_reason === null
          ? ''
          : requestbyid?.detailed_reason
        : formData1?.detailed_reason
    );
    formdata.append(
      'vehicle_number',
      formData1?.vehicle_number === undefined
        ? requestbyid?.vehicle_number === null
          ? ''
          : requestbyid?.vehicle_number
        : formData1?.vehicle_number
    );
    formdata.append(
      'lr_number',
      formData1?.lr_number === undefined
        ? requestbyid?.lr_number === null
          ? ''
          : requestbyid?.lr_number
        : formData1?.lr_number
    );
    formdata.append(
      'lr_date',
      requestbyid?.lr_date === null && formData1?.lr_date === undefined
        ? '' // moment().format()
        : requestbyid?.lr_date !== null && formData1?.lr_date === undefined
        ? moment(requestbyid?.lr_date).format()
        : moment(formData1?.lr_date).format()
    );
    formdata.append(
      'carrier_person_name',
      formData1?.carrier_person_name === undefined
        ? requestbyid?.carrier_person_name === null
          ? ''
          : requestbyid?.carrier_person_name
        : formData1?.carrier_person_name
    );

    formdata.append(
      'remarks',
      formData1?.remarks === undefined
        ? requestbyid?.remarks === null
          ? ''
          : requestbyid?.remarks
        : formData1?.remarks
    );
    //-----------PAYLOAD---------------------------------
    for (let pair of formdata.entries()) {
      console.log(pair[0], pair[1]);
    }
    //  ----------------------------------------------------
    dispatch(createDraftUpload_sap(formdata)).then(function (response) {
      if (response?.payload?.success) {
        message.success(response.payload.msg);
        //setGpId(response?.payload?.data?.id);
        setDraftLoading(false);
        user?.user_details?.roles[0] === 'issuer'
          ? history.push('/issuer-requester-sap')
          : history.push('/requesters-sap-dashboard');
      } else {
        // setInitiate(false);
        message.error(response.payload.msg);
        setDraftLoading(false);
      }
    });
  };

  const onFinishTabOne = (value) => {
    setOnProceedValue(value);
    setSelectedTabId(filters?.[1]?.id);
  };

  // console.log(' formData1?.methodType_id,  ', formData1?.methodType_id);
  // console.log('entityNum  ', entityNum);
  // console.log('  requestbyid?.document_number  ', requestbyid?.document_number);

  useEffect(() => {
    if (submit === 'yes') {
      setProccedLoading(true);
      let formdata = new FormData();
      formdata.append('gpid', parseInt(params.id));
      formdata.append('is_submitting', 'yes');

      //formdata.append('items', JSON.stringify(formItemData?.items));

      formData1?.methodType_id === 1 ||
      (formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 1)
        ? formdata.append(
            'items',
            fetchedItems1 === undefined ? '' : JSON.stringify(fetchedItems1)
          )
        : formdata.append('items', JSON.stringify(formItemData?.items));

      value['address'] = vendorAddress[0]?.addressLine1;
      value['material_id'] =
        formData1?.methodType_id === 1
          ? materials[0]?.material_id
          : value?.material_id;

      for (let i in supportingDoc) {
        formdata.append('files', supportingDoc[i]);
      }
      if (
        requestbyid?.SapMethodTypeMasterId === 1 &&
        requestbyid?.document_number !== null
      ) {
        formdata.append(
          'all_items_value',
          parseFloat(requestbyid?.all_items_value).toFixed(2) || 0
        );
      } else if (
        formData1?.methodType_id === 1 ||
        (requestbyid?.SapMethodTypeMasterId === 1 &&
          requestbyid?.document_number === null)
      ) {
        formdata.append('all_items_value', SapTotalValue || 0);
      } else {
        formdata.append('all_items_value', totalValue || 0);
      }

      if (
        formData1?.methodType_id === 1 ||
        (formData1?.methodType_id === undefined &&
          requestbyid?.SapMethodTypeMasterId === 1)
      ) {
        formdata.append(
          'SapPlantId',
          requestbyid?.document_number !== null
            ? requestbyid?.SapPlantId
            : SAPdata?.PlantId
        );
        formdata.append(
          'SapStorageLocationId',
          requestbyid?.document_number !== null
            ? requestbyid?.SapStorageLocationId
            : SAPdata?.StorageLocationId
        );
        formdata.append(
          'company',
          requestbyid?.document_number !== null
            ? requestbyid?.company
            : plants_forSAP?.SapCompanyMaster?.company_name //SAPdata?.CompanyName
        );
      } else {
        formdata.append(
          'SapPlantId',
          formData1?.project_id === undefined
            ? requestbyid?.SapPlantId || ''
            : formData1?.project_id
        );
        formdata.append(
          'SapStorageLocationId',
          formData1?.location_id === undefined
            ? requestbyid?.SapStorageLocationId || ''
            : formData1?.location_id
        );
        formdata.append(
          'company',
          formData1?.project_id === undefined
            ? requestbyid?.company || ''
            : company
        );
      }

      // formdata.append(
      //   'SapTypeMasterId',
      //   requestbyid?.SapMethodTypeMasterId === 1
      //     ? requestbyid?.SapTypeMasterId
      //     : requestbyid?.SapMethodTypeMasterId === 2 &&
      //       formData1?.type_id === undefined
      //     ? requestbyid?.SapTypeMasterId || ''
      //     : formData1?.type_id
      // );
      formdata.append(
        'SapTypeMasterId',
        formData1?.type_id === undefined &&
          requestbyid?.SapMethodTypeMasterId === null
          ? ''
          : formData1?.type_id === undefined &&
            requestbyid?.SapMethodTypeMasterId !== null
          ? requestbyid?.SapTypeMasterId
          : formData1?.type_id
      );

      formData1?.type_id === undefined &&
      formData1?.category_id === undefined &&
      requestbyid?.SapCategoryMasterId === null
        ? formdata.append('SapCategoryMasterId', '')
        : formData1?.type_id === undefined &&
          formData1?.category_id === undefined &&
          requestbyid?.SapCategoryMasterId !== null
        ? formdata.append(
            'SapCategoryMasterId',
            requestbyid?.SapCategoryMasterId || ''
          )
        : (formData1?.type_id === undefined &&
            formData1?.category_id !== undefined) ||
          (formData1?.type_id === undefined &&
            requestbyid?.SapTypeMasterId === 1)
        ? formdata.append('SapCategoryMasterId', formData1?.category_id)
        : formData1?.type_id === 1 && formData1?.category_id === undefined
        ? formdata.append('SapCategoryMasterId', categories[0]?.id)
        : formdata.append('SapCategoryMasterId', formData1?.category_id);

      if (
        formData1?.methodType_id === 1 ||
        (formData1?.methodType_id === undefined &&
          requestbyid?.SapMethodTypeMasterId === 1)
      ) {
        formdata.append('SapMethodTypeMasterId', 1);
      } else {
        formdata.append('SapMethodTypeMasterId', value?.methodType_id);
      }

      formData1?.methodType_id === 1 ||
      (formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 1)
        ? formdata.append(
            'SapDocumentMasterId',
            documentID === undefined
              ? requestbyid?.SapDocumentMasterId || ''
              : documentID
          )
        : '';
      formData1?.methodType_id === 1 ||
      (formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 1)
        ? formdata.append(
            'document_number',
            entityNum === undefined || entityNum === '' || entityNum === null
              ? requestbyid?.document_number === null
                ? ''
                : requestbyid?.document_number
              : entityNum //documentNumber
          )
        : '';

      formData1?.methodType_id === 1 ||
      (formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 1)
        ? formData1?.sap_year === undefined
          ? formdata.append('year', requestbyid?.year)
          : formdata.append('year', formData1?.sap_year)
        : '';

      formdata.append(
        'SapReasonToSendMasterId',
        typeof value?.reason_id === 'string' ||
          value?.reason_id instanceof String
          ? requestbyid?.SapReasonToSendMasterId === null
            ? ''
            : requestbyid?.SapReasonToSendMasterId
          : value?.reason_id
      );

      if (
        formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 1 &&
        requestbyid?.document_number !== null
      ) {
        formdata.append('vendor_name', requestbyid?.Vendor_Master?.vendorName);
        formdata.append('vendor_code', requestbyid?.Vendor_Master?.vendorCode);
        formdata.append('vendor_location', requestbyid?.vendor_location_name);
        formdata.append('vendor_address', requestbyid?.vendor_location_address);
      } else if (
        (formData1?.methodType_id === 1 && Object.keys(SAPdata).length > 0) ||
        (requestbyid?.SapMethodTypeMasterId === 1 &&
          requestbyid?.document_number === null)
      ) {
        formdata.append('vendor_name', SAPdata?.VendorName);
        formdata.append('vendor_code', SAPdata?.VendorCode);
        formdata.append('vendor_location', SAPdata?.VendorLocation);
        formdata.append('vendor_address', SAPdata?.vendor_address);
      } else if (formData1?.vendor_id === 'other') {
        formdata.append('vendor_name', otherVendorName || '');
        formdata.append('vendor_location', otherVendorLocation || '');
        formdata.append('vendor_address', otherVendorAddress || '');
      } else if (
        formData1?.methodType_id === 2 ||
        (formData1?.methodType_id === undefined &&
          requestbyid?.SapMethodTypeMasterId === 2)
      ) {
        formdata.append(
          'vendor_name',
          vendorName[0]?.vendorName === undefined
            ? requestbyid?.Vendor_Master?.vendorName || ''
            : vendorName[0]?.vendorName || ''
        );
        formdata.append(
          'vendor_code',
          vendorName[0]?.vendorCode === undefined
            ? requestbyid?.Vendor_Master?.vendorCode || ''
            : vendorName[0]?.vendorCode || ''
        );
        formdata.append(
          'vendor_location',
          vendorAddress[0]?.vendorSite === undefined
            ? requestbyid?.vendor_location_name || ''
            : vendorAddress[0]?.vendorSite || ''
        ); //formData1?.vendor_location
        formdata.append(
          'vendor_address',
          formData1?.vendor_location_address === undefined
            ? requestbyid?.vendor_location_address || ''
            : formData1?.vendor_location_address || ''
        );
      } else {
        formdata.append(
          'vendor_name',
          vendorName[0]?.vendorName === undefined
            ? requestbyid?.Vendor_Master?.vendorName || ''
            : vendorName[0]?.vendorName || ''
        );
        formdata.append(
          'vendor_code',
          vendorName[0]?.vendorCode === undefined
            ? requestbyid?.Vendor_Master?.vendorCode || ''
            : vendorName[0]?.vendorCode || ''
        );
        formdata.append(
          'vendor_location',
          vendorAddress[0]?.vendorSite === undefined
            ? requestbyid?.vendor_location_name || ''
            : vendorAddress[0]?.vendorSite || ''
        ); //formData1?.vendor_location
        formdata.append(
          'vendor_address',
          formData1?.vendor_location_address === undefined
            ? requestbyid?.vendor_location_address || ''
            : formData1?.vendor_location_address || ''
        );
      }

      formdata.append(
        'date',
        value?.date === undefined ? '' : moment(value?.date).format()
      );

      formdata.append(
        'SapMaterialBelongsMasterId',
        formData1?.methodType_id === 1
          ? materials[0]?.id
          : formData1?.material_id === undefined
          ? requestbyid?.SapMaterialBelongsMasterId === null
            ? ''
            : requestbyid?.SapMaterialBelongsMasterId
          : formData1?.material_id
      );

      (formData1?.type_id === undefined &&
        formData1?.category_id === undefined &&
        requestbyid?.SapCategoryMasterId === 1) ||
      (formData1?.type_id === undefined &&
        requestbyid?.SapTypeMasterId === 1) ||
      formData1?.type_id === 1 ||
      formData1?.category_id === 1
        ? formdata.append(
            'expected_date_return',
            formData1?.expected_date_return === undefined &&
              requestbyid?.expected_date_return === null
              ? ''
              : formData1?.expected_date_return === undefined &&
                requestbyid?.expected_date_return !== null
              ? requestbyid?.expected_date_return
              : moment(formData1?.expected_date_return).format()
          )
        : '';

      formdata.append(
        'detailed_reason',
        value?.detailed_reason === undefined ? '' : value?.detailed_reason || ''
      );
      formdata.append(
        'vehicle_number',
        value?.vehicle_number === undefined ? '' : value?.vehicle_number || ''
      );
      formdata.append(
        'lr_number',
        value?.lr_number === undefined ? '' : value?.lr_number || ''
      );
      formdata.append(
        'lr_date',
        requestbyid?.lr_date === null && formData1?.lr_date === undefined
          ? '' // moment().format()
          : requestbyid?.lr_date !== null && formData1?.lr_date === undefined
          ? moment(requestbyid?.lr_date).format()
          : moment(formData1?.lr_date).format()
      );
      formdata.append(
        'carrier_person_name',
        value?.carrier_person_name === undefined
          ? ''
          : value?.carrier_person_name || ''
      );
      formdata.append(
        'remarks',
        value?.remarks === undefined ? '' : value?.remarks || ''
      );
      formdata.append('approver_id', value?.approver_id);
      formdata.append('issuer_id', value?.issuer_id);
      //-----------PAYLOAD---------------------------------
      for (let pair of formdata.entries()) {
        console.log(pair[0], pair[1]);
      }
      //----------------------------------------------------
      // if (
      //   value?.approver_id === undefined ||
      //   value?.issuer_id === undefined ||
      //   value?.project_id === undefined ||
      //   value?.location_id === undefined ||
      //   value?.type_id === undefined ||
      //   categories[0]?.id === undefined ||
      //   value?.methodType_id === undefined ||
      //   value?.reason_id === undefined ||
      //   value?.material_id === undefined ||
      //   (formData1?.methodType_id === 1 && fetchedItems1?.length === 0) ||
      //   (formData1?.methodType_id === undefined &&
      //     requestbyid?.SapMethodTypeMasterId === 1 &&
      //     fetchedItems1?.length === 0) ||
      //   (formData1?.methodType_id === 2 && formItemData?.items?.length === 0) ||
      //   (formData1?.methodType_id === undefined &&
      //     requestbyid?.SapMethodTypeMasterId === 2 &&
      //     formItemData?.items?.length === 0)
      // ) {
      //   message.error(
      //     'There are mandatory fields left blank. Please make sure all required fields contain data.'
      //   );
      //   setProccedLoading(false);
      // } else {
      dispatch(createDraftUpload_sap(formdata)).then(function (response) {
        if (response?.payload?.success) {
          message.success(
            `${response.payload.msg}. Ticket ${response?.payload?.Ticket}`
          );
          // setGpId(response?.payload?.data?.id);
          user?.user_details?.roles[0] === 'issuer'
            ? history.push('/issuer-requester-sap')
            : history.push('/requesters-sap-dashboard');
          setProccedLoading(false);
        } else {
          // setInitiate(false);
          message.error(response.payload.err);
          setProccedLoading(false);
        }
      });
      // }
    }
    if (draft === 'no') {
      setDraftLoading(true);
      let formdata = new FormData();
      formdata.append('gpid', parseInt(params.id));
      formdata.append('is_submitting', 'no');

      //formdata.append('items', JSON.stringify(formItemData?.items));
      formData1?.methodType_id === 1 ||
      (formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 1)
        ? formdata.append(
            'items',
            fetchedItems1 === undefined ? '' : JSON.stringify(fetchedItems1)
          )
        : formdata.append('items', JSON.stringify(formItemData?.items));

      for (let i in supportingDoc) {
        formdata.append('files', supportingDoc[i]);
      }

      if (
        requestbyid?.SapMethodTypeMasterId === 1 &&
        requestbyid?.document_number !== null
      ) {
        formdata.append(
          'all_items_value',
          parseFloat(requestbyid?.all_items_value).toFixed(2) || 0
        );
      } else if (
        formData1?.methodType_id === 1 ||
        (requestbyid?.SapMethodTypeMasterId === 1 &&
          requestbyid?.document_number === null)
      ) {
        formdata.append('all_items_value', SapTotalValue || 0);
      } else {
        formdata.append('all_items_value', totalValue || 0);
      }

      // formdata.append(
      //   'SapPlantId',
      //   formData1?.project_id === undefined
      //     ? requestbyid?.SapPlantId === null
      //       ? ''
      //       : requestbyid?.SapPlantId
      //     : formData1?.project_id
      // );
      // formdata.append(
      //   'SapStorageLocationId',
      //   formData1?.location_id === undefined
      //     ? requestbyid?.SapStorageLocationId === null
      //       ? ''
      //       : requestbyid?.SapStorageLocationId
      //     : formData1?.location_id
      // );
      // formdata.append(
      //   'company',
      //   formData1?.project_id === undefined
      //     ? requestbyid?.company === null
      //       ? ''
      //       : requestbyid?.company
      //     : company
      // );
      if (
        formData1?.methodType_id === 1 ||
        (formData1?.methodType_id === undefined &&
          requestbyid?.SapMethodTypeMasterId === 1)
      ) {
        formdata.append(
          'SapPlantId',
          requestbyid?.document_number !== null
            ? requestbyid?.SapPlantId
            : SAPdata?.PlantId
        );
        formdata.append(
          'SapStorageLocationId',
          requestbyid?.document_number !== null
            ? requestbyid?.SapStorageLocationId
            : SAPdata?.StorageLocationId
        );
        formdata.append(
          'company',
          requestbyid?.document_number !== null
            ? requestbyid?.company
            : plants_forSAP?.SapCompanyMaster?.company_name //SAPdata?.CompanyName
        );
      } else {
        formdata.append(
          'SapPlantId',
          formData1?.project_id === undefined
            ? requestbyid?.SapPlantId || ''
            : formData1?.project_id
        );
        formdata.append(
          'SapStorageLocationId',
          formData1?.location_id === undefined
            ? requestbyid?.SapStorageLocationId || ''
            : formData1?.location_id
        );
        formdata.append(
          'company',
          formData1?.project_id === undefined
            ? requestbyid?.company || ''
            : company
        );
      }
      // formdata.append(
      //   'SapTypeMasterId',
      //   requestbyid?.SapMethodTypeMasterId === 1
      //     ? requestbyid?.SapTypeMasterId || ''
      //     : requestbyid?.SapMethodTypeMasterId === 2 &&
      //       formData1?.type_id === undefined
      //     ? requestbyid?.SapTypeMasterId || ''
      //     : formData1?.type_id
      // );
      formdata.append(
        'SapTypeMasterId',
        formData1?.type_id === undefined &&
          requestbyid?.SapMethodTypeMasterId === null
          ? ''
          : formData1?.type_id === undefined &&
            requestbyid?.SapMethodTypeMasterId !== null
          ? requestbyid?.SapTypeMasterId
          : formData1?.type_id
      );
      formdata.append(
        'approver_id',
        formData1?.approver_id === undefined
          ? requestbyid?.SapApprovers?.length === 0
            ? ''
            : requestbyid?.SapApprovers[0]?.User !== null
            ? requestbyid?.SapApprovers[0]?.User?.id
            : ''
          : formData1?.approver_id
      );
      formdata.append(
        'issuer_id',
        formData1?.issuer_id === undefined
          ? requestbyid?.SapApprovers?.length === 0
            ? ''
            : requestbyid?.SapApprovers[1]?.User !== null
            ? requestbyid?.SapApprovers[1]?.User?.id
            : ''
          : formData1?.issuer_id
      );

      formData1?.type_id === undefined &&
      formData1?.category_id === undefined &&
      requestbyid?.SapCategoryMasterId === null
        ? formdata.append('SapCategoryMasterId', '')
        : formData1?.type_id === undefined &&
          formData1?.category_id === undefined &&
          requestbyid?.SapCategoryMasterId !== null
        ? formdata.append(
            'SapCategoryMasterId',
            requestbyid?.SapCategoryMasterId
          )
        : (formData1?.type_id === undefined &&
            formData1?.category_id !== undefined) ||
          (formData1?.type_id === undefined &&
            requestbyid?.SapTypeMasterId === 1)
        ? formdata.append('SapCategoryMasterId', formData1?.category_id)
        : formData1?.type_id === 1 && formData1?.category_id === undefined
        ? formdata.append('SapCategoryMasterId', categories[0]?.id)
        : formdata.append('SapCategoryMasterId', formData1?.category_id);

      if (
        formData1?.methodType_id === 1 ||
        (formData1?.methodType_id === undefined &&
          requestbyid?.SapMethodTypeMasterId === 1)
      ) {
        formdata.append('SapMethodTypeMasterId', 1);
      } else {
        formdata.append(
          'SapMethodTypeMasterId',
          formData1?.methodType_id === undefined
            ? requestbyid?.SapMethodTypeMasterId === null
              ? ''
              : requestbyid?.SapMethodTypeMasterId
            : formData1?.methodType_id
        );
      }

      // formData1?.methodType_id === 1 ||
      // (formData1?.methodType_id === undefined &&
      //   requestbyid?.SapMethodTypeMasterId === 1)
      //   ? formdata.append(
      //       'SapDocumentMasterId',
      //       documentID === undefined
      //         ? requestbyid?.SapDocumentMasterId === null
      //           ? ''
      //           : requestbyid?.SapDocumentMasterId
      //         : documentID
      //     )
      //   : '';

      // (formData1?.methodType_id === undefined &&
      //   requestbyid?.SapMethodTypeMasterId === 1) ||
      // formData1?.methodType_id === 1
      //   ? formdata.append(
      //       'document_number',
      //       entityNum === '' || entityNum === undefined || entityNum === null
      //         ? requestbyid?.document_number === null
      //           ? ''
      //           : requestbyid?.document_number
      //         : entityNum //documentNumber
      //     )
      //   : '';
      // requestbyid?.SapMethodTypeMasterId === 1
      //   ? formData1?.sap_year === undefined
      //     ? formdata.append('year', requestbyid?.year)
      //     : formdata.append('year', formData1?.sap_year)
      //   : '';
      formData1?.methodType_id === 1 ||
      (formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 1)
        ? formdata.append(
            'SapDocumentMasterId',
            documentID === undefined
              ? requestbyid?.SapDocumentMasterId || ''
              : documentID
          )
        : '';
      formData1?.methodType_id === 1 ||
      (formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 1)
        ? formdata.append(
            'document_number',
            entityNum === undefined || entityNum === '' || entityNum === null
              ? requestbyid?.document_number === null
                ? ''
                : requestbyid?.document_number
              : entityNum //documentNumber
          )
        : '';

      formData1?.methodType_id === 1 ||
      (formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 1)
        ? formData1?.sap_year === undefined
          ? formdata.append('year', requestbyid?.year)
          : formdata.append('year', formData1?.sap_year)
        : '';

      formdata.append(
        'SapReasonToSendMasterId',
        formData1?.reason_id === undefined
          ? requestbyid?.SapReasonToSendMasterId === null
            ? ''
            : requestbyid?.SapReasonToSendMasterId
          : formData1?.reason_id
      );

      if (
        formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 1 &&
        requestbyid?.document_number !== null
      ) {
        formdata.append('vendor_name', requestbyid?.Vendor_Master?.vendorName);
        formdata.append('vendor_code', requestbyid?.Vendor_Master?.vendorCode);
        formdata.append('vendor_location', requestbyid?.vendor_location_name);
        formdata.append('vendor_address', requestbyid?.vendor_location_address);
      } else if (
        (formData1?.methodType_id === 1 && Object.keys(SAPdata).length > 0) ||
        (requestbyid?.SapMethodTypeMasterId === 1 &&
          requestbyid?.document_number === null)
      ) {
        formdata.append('vendor_name', SAPdata?.VendorName);
        formdata.append('vendor_code', SAPdata?.VendorCode);
        formdata.append('vendor_location', SAPdata?.VendorLocation);
        formdata.append('vendor_address', SAPdata?.vendor_address);
      } else if (formData1?.vendor_id === 'other') {
        formdata.append('vendor_name', otherVendorName || '');
        formdata.append('vendor_location', otherVendorLocation || '');
        formdata.append('vendor_address', otherVendorAddress || '');
      } else if (
        formData1?.methodType_id === 2 ||
        (formData1?.methodType_id === undefined &&
          requestbyid?.SapMethodTypeMasterId === 2)
      ) {
        formdata.append(
          'vendor_name',
          vendorName[0]?.vendorName === undefined
            ? requestbyid?.Vendor_Master?.vendorName || ''
            : vendorName[0]?.vendorName || ''
        );
        formdata.append(
          'vendor_code',
          vendorName[0]?.vendorCode === undefined
            ? requestbyid?.Vendor_Master?.vendorCode || ''
            : vendorName[0]?.vendorCode || ''
        );
        formdata.append(
          'vendor_location',
          vendorAddress[0]?.vendorSite === undefined
            ? requestbyid?.vendor_location_name || ''
            : vendorAddress[0]?.vendorSite || ''
        ); //formData1?.vendor_location
        formdata.append(
          'vendor_address',
          formData1?.vendor_location_address === undefined
            ? requestbyid?.vendor_location_address || ''
            : formData1?.vendor_location_address || ''
        );
      } else {
        formdata.append(
          'vendor_name',
          vendorName[0]?.vendorName === undefined
            ? requestbyid?.Vendor_Master?.vendorName || ''
            : vendorName[0]?.vendorName || ''
        );
        formdata.append(
          'vendor_code',
          vendorName[0]?.vendorCode === undefined
            ? requestbyid?.Vendor_Master?.vendorCode || ''
            : vendorName[0]?.vendorCode || ''
        );
        formdata.append(
          'vendor_location',
          vendorAddress[0]?.vendorSite === undefined
            ? requestbyid?.vendor_location_name || ''
            : vendorAddress[0]?.vendorSite || ''
        ); //formData1?.vendor_location
        formdata.append(
          'vendor_address',
          formData1?.vendor_location_address === undefined
            ? requestbyid?.vendor_location_address || ''
            : formData1?.vendor_location_address || ''
        );
      }

      formdata.append(
        'date',
        formData1?.date === undefined
          ? moment().format()
          : moment(formData1?.date).format()
      );

      formdata.append(
        'SapMaterialBelongsMasterId',
        formData1?.methodType_id === 1
          ? materials[0]?.id
          : formData1?.material_id === undefined
          ? requestbyid?.SapMaterialBelongsMasterId === null
            ? ''
            : requestbyid?.SapMaterialBelongsMasterId
          : materialIdValue
      );

      (formData1?.type_id === undefined &&
        formData1?.category_id === undefined &&
        requestbyid?.SapCategoryMasterId === 1) ||
      (formData1?.type_id === undefined &&
        requestbyid?.SapTypeMasterId === 1) ||
      formData1?.type_id === 1 ||
      formData1?.category_id === 1
        ? formdata.append(
            'expected_date_return',
            formData1?.expected_date_return === undefined &&
              requestbyid?.expected_date_return === null
              ? ''
              : formData1?.expected_date_return === undefined &&
                requestbyid?.expected_date_return !== null
              ? requestbyid?.expected_date_return
              : moment(formData1?.expected_date_return).format()
          )
        : '';

      formdata.append(
        'detailed_reason',
        formData1?.detailed_reason === undefined
          ? requestbyid?.detailed_reason === null
            ? ''
            : requestbyid?.detailed_reason
          : formData1?.detailed_reason
      );
      formdata.append(
        'vehicle_number',
        formData1?.vehicle_number === undefined
          ? requestbyid?.vehicle_number === null
            ? ''
            : requestbyid?.vehicle_number
          : formData1?.vehicle_number
      );
      formdata.append(
        'lr_number',
        formData1?.lr_number === undefined
          ? requestbyid?.lr_number === null
            ? ''
            : requestbyid?.lr_number
          : formData1?.lr_number
      );
      formdata.append(
        'lr_date',
        requestbyid?.lr_date === null && formData1?.lr_date === undefined
          ? '' // moment().format()
          : requestbyid?.lr_date !== null && formData1?.lr_date === undefined
          ? moment(requestbyid?.lr_date).format()
          : moment(formData1?.lr_date).format()
      );
      formdata.append(
        'carrier_person_name',
        formData1?.carrier_person_name === undefined
          ? requestbyid?.carrier_person_name === null
            ? ''
            : requestbyid?.carrier_person_name
          : formData1?.carrier_person_name
      );

      formdata.append(
        'remarks',
        formData1?.remarks === undefined
          ? requestbyid?.remarks === null
            ? ''
            : requestbyid?.remarks
          : formData1?.remarks
      );
      //-----------PAYLOAD---------------------------------
      for (let pair of formdata.entries()) {
        console.log(pair[0], pair[1]);
      }
      //----------------------------------------------------
      dispatch(createDraftUpload_sap(formdata)).then(function (response) {
        if (response?.payload?.success) {
          message.success(response.payload.msg);
          //setGpId(response?.payload?.data?.id);
          setDraftLoading(false);
          user?.user_details?.roles[0] === 'issuer'
            ? history.push('/issuer-requester-sap')
            : history.push('/requesters-sap-dashboard');
        } else {
          // setInitiate(false);
          message.error(response.payload.err);
          setDraftLoading(false);
        }
      });
    }
  }, [formItemData]);

  const submitHandler = () => {
    setSubmit('yes');
  };
  const draftHandler = () => {
    setDraft('no');
  };

  const handleChangeSearch = (e) => {
    const value = e.target.value;
    //console.log('SEARCH_value', value);
    setItemSearchValue(value);
  };

  const debounceFetcherProject = useMemo(() => {
    const loadOptions = (projectSearch) => {
      setProjectSearch(projectSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const debounceFetcherProjectLocation = useMemo(() => {
    const loadOptions = (projectLocationSearch) => {
      setProjectLocationSearch(projectLocationSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (vendorSearch) => {
      setVendorSearch(vendorSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleDeleteUploadedDoc = (id) => {
    const docsID = [];
    docsID?.push(id);
    const payload = {
      gpid: params.id,
      docs: docsID
    };
    dispatch(delete_uploaded_docs_sap(payload)).then((response) => {
      if (response?.payload?.success) {
        dispatch(getrequesterdata(params?.id)).then((response) => {
          if (response.payload.success) {
            setTempDocList(response?.payload?.data?.SapDocuments);
          }
        });
      }
    });
  };

  // useEffect(() => {
  //   form.setFieldsValue({
  //     items: itemsJsonData?.map((item) => ({
  //       item_code: item?.Item_Code,
  //       uom: item?.UOM,
  //       quantity: item?.Item_Quantity,
  //       item_description: item?.Item_Description,
  //       item_remarks: item?.Item_Remarks
  //     }))
  //   });
  // }, [itemsJsonData]);

  // const handleItemsTemplate = async (e) => {
  //   const file = e.target.files[0];
  //   setFileName(file.name);
  //   const data = await file.arrayBuffer();
  //   const workbook = XLSX.read(data);
  //   const workSheet = workbook.Sheets[workbook.SheetNames[0]];
  //   const jsonData = XLSX.utils.sheet_to_json(workSheet);
  //   setItemsJsonData(jsonData);
  //   console.log(fileName);
  // };
  useEffect(() => {
    form.setFieldsValue({
      items: itemsJsonData?.map((item) => ({
        item_code: item?.Item_Code,
        uom: item?.UOM,
        quantity: item?.Item_Quantity,
        value: parseFloat(item?.Item_value).toFixed(2), //parseInt(item?.Value),
        item_description: item?.Item_Description,
        item_remarks: item?.Item_Remarks
      }))
    });
  }, [itemsJsonData]);

  const handleItemsTemplate = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const workSheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(workSheet);
    setItemsJsonData(jsonData);
    console.log(fileName);
    // e.target.value = '';
  };

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  const handleFetchDocument = (fileName) => {
    window?.open(
      `${process.env.REACT_APP_API_URL}/fetch-file?fileName=${fileName}`,
      '_blank'
    );
  };

  return (
    <CustomLayout sider="true">
      <>
        <div className={`mb-4 ${styles.container}`}>
          <div className={`${styles.page_title}`}>
            <span>Edit the draft request</span>
            <span style={{ float: 'right', marginRight: '20px' }}>
              {' '}
              <a
                href={
                  user?.user_details?.roles[0] === 'issuer'
                    ? '/issuer-requester-sap'
                    : '/requesters-sap-dashboard'
                }
              >
                <Button className={`${styles.back_button}`}>
                  <ArrowLeftOutlined className={`${styles.back_icon}`} />
                  Back
                </Button>
              </a>
            </span>
          </div>
          <div className={`${styles.page_sub_title}`}>
            Enter the following fields to create the request to issue the gate
            pass
          </div>
          <div>
            <Radio.Group
              value={selectedTabId}
              size="middle"
              optionType="button"
              buttonStyle="solid"
              className={`${styles.tab_btn_style}`}
            >
              {filters.map((item, id) => {
                return (
                  <Radio.Button
                    name="radiogroup"
                    defaultValue={item[0]?.value}
                    value={item?.id}
                    key={id}
                    className={`${styles.tab_broder_style}
                    ${selectedTabId === item?.id ? ` ${styles.selected}` : ''}
                  `}
                    onClick={() => {
                      setSelectedTabId(item?.id);
                    }}
                  >
                    {item.name}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
            <Divider style={{ margin: 0, marginBottom: '20px' }}></Divider>
          </div>
          <Spin spinning={getrequestByidLoading}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              className={`${styles.formstyles}`}
              initialValues={{ items: [''] }}
            >
              {selectedTabId === 1 && (
                <div>
                  <Form
                    form={form}
                    onFinish={onFinishTabOne}
                    autoComplete="off"
                  >
                    <div
                      style={{
                        fontSize: '16px',
                        color: '#2364ad',
                        fontFamily: 'SFProText Bold',
                        marginBottom: '10px'
                      }}
                    >
                      General details:
                    </div>
                    <Row gutter={16}>
                      <Col span={6}>
                        <FormItem
                          label="Method"
                          name="methodType_id"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            disabled={requestbyid?.SapMethodTypeMasterId === 1}
                            placeholder="Select Method"
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return option?.children
                                ?.toString()
                                ?.toLowerCase()
                                ?.includes(input?.toLowerCase());
                            }}
                            onChange={(value) =>
                              handleFormDataOnChange('methodType_id', value)
                            }
                          >
                            {methods_sap.map((item, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {item.method_type}
                                </Option>
                              );
                            })}
                          </Select>
                        </FormItem>
                      </Col>
                      {formData1?.methodType_id === 1 ||
                      (formData1?.methodType_id === undefined &&
                        requestbyid?.SapMethodTypeMasterId === 1) ||
                      (formData1?.methodType_id === undefined &&
                        requestbyid?.SapMethodTypeMasterId === 1 &&
                        requestbyid?.document_number !== null) ? (
                        <>
                          <Col span={6}>
                            <FormItem
                              label="Plant Code and Name"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                disabled
                                value={
                                  requestbyid?.SapMethodTypeMasterId === 1 &&
                                  requestbyid?.document_number !== null
                                    ? `${requestbyid?.SapPlant?.plant_code} (${requestbyid?.SapPlant?.plant_name})`
                                    : requestbyid?.SapMethodTypeMasterId ===
                                        1 &&
                                      requestbyid?.document_number === null
                                    ? `${
                                        SAPdata?.PlantCode
                                          ? `${SAPdata?.PlantCode} (${SAPdata?.PlantName})`
                                          : ''
                                      }`
                                    : formData1?.methodType_id === 1 &&
                                      SAPdata?.PlantCode !== undefined
                                    ? `${
                                        SAPdata?.PlantCode
                                          ? `${SAPdata?.PlantCode} (${SAPdata?.PlantName})`
                                          : ''
                                      }`
                                    : ''
                                }
                                allowClear
                                placeholder="Select Plant Code and Name"
                                onChange={(value) =>
                                  handleFormDataOnChange('project_id', value)
                                }
                              />
                            </FormItem>
                          </Col>
                          <Col span={6}>
                            <FormItem
                              label="Storage Location"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                disabled
                                value={
                                  requestbyid?.SapMethodTypeMasterId === 1 &&
                                  requestbyid?.document_number !== null
                                    ? `${requestbyid?.SapStorageLocation?.code} (${requestbyid?.SapStorageLocation?.name})`
                                    : requestbyid?.SapMethodTypeMasterId ===
                                        1 &&
                                      requestbyid?.document_number === null
                                    ? `${
                                        SAPdata?.StorageLocation
                                          ? `${SAPdata?.StorageLocation} (${SAPdata?.StorageLocationName})`
                                          : ''
                                      }`
                                    : formData1?.methodType_id === 1 &&
                                      SAPdata?.PlantCode !== undefined
                                    ? `${
                                        SAPdata?.StorageLocation
                                          ? `${SAPdata?.StorageLocation} (${SAPdata?.StorageLocationName})`
                                          : ''
                                      }`
                                    : ''
                                }
                                allowClear
                                placeholder="Select Storage Location"
                                onChange={(value) =>
                                  handleFormDataOnChange('location_id', value)
                                }
                              />
                            </FormItem>
                          </Col>
                          <Col span={6}>
                            <FormItem
                              label="GST Number"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                value={
                                  // requestbyid?.SapMethodTypeMasterId === 1 &&
                                  // requestbyid?.document_number !== null
                                  //   ? requestbyid?.SapPlant?.gst
                                  //   : formData1?.methodType_id === 1 &&
                                  //     SAPdata?.PlantGSTNumber === undefined
                                  //   ? `${
                                  //       SAPdata?.PlantGSTNumber
                                  //         ? SAPdata?.PlantGSTNumber
                                  //         : ''
                                  //     }`
                                  //   : ''
                                  requestbyid?.SapMethodTypeMasterId === 1 &&
                                  requestbyid?.document_number !== null
                                    ? requestbyid?.SapPlant?.gst
                                    : requestbyid?.SapMethodTypeMasterId ===
                                        1 &&
                                      requestbyid?.document_number === null
                                    ? `${
                                        SAPdata?.PlantGSTNumber
                                          ? SAPdata?.PlantGSTNumber
                                          : ''
                                      }`
                                    : formData1?.methodType_id === 1 &&
                                      SAPdata?.PlantCode !== undefined
                                    ? `${
                                        SAPdata?.PlantGSTNumber
                                          ? SAPdata?.PlantGSTNumber
                                          : ''
                                      }`
                                    : ''
                                }
                                allowClear
                                placeholder="Please enter GST number"
                                disabled
                                onChange={(e) =>
                                  handleFormDataOnChange('gst', e.target.value)
                                }
                              />
                            </FormItem>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={6}>
                            <FormItem
                              label="Plant Code and Name"
                              name="project_id"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.select_styles}`}
                              // onChange={(e) => onChangeProject(e.target.value)}
                            >
                              <Select
                                // disabled={
                                //   formData1?.methodType_id === 1 &&
                                //   requestbyid?.vendor_master_id !== null
                                // }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                  // console.log('input', input);
                                  return option?.children
                                    ?.toString()
                                    ?.toLowerCase()
                                    ?.includes(input?.toLowerCase());
                                }}
                                onSearch={debounceFetcherProject}
                                onChange={(value) =>
                                  handleFormDataOnChange('project_id', value)
                                }
                                placeholder="Select Plant Code and Name"
                              >
                                {plants?.map((item, index) => {
                                  return (
                                    <Option value={item.id} key={index}>
                                      {`${item?.plant_code || ''} (${
                                        item?.plant_name
                                      })`}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </FormItem>
                          </Col>
                          <Col span={6}>
                            <FormItem
                              label="Storage Location"
                              name="location_id"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.select_styles}`}
                            >
                              <Select
                                showSearch
                                // disabled={
                                //   requestbyid?.methodType_id === 1 &&
                                //   requestbyid?.vendor_master_id !== null
                                // }
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                  // console.log('input', input);
                                  return option?.children
                                    ?.toString()
                                    ?.toLowerCase()
                                    ?.includes(input?.toLowerCase());
                                }}
                                onSearch={debounceFetcherProjectLocation}
                                onChange={(value) =>
                                  handleFormDataOnChange('location_id', value)
                                }
                                placeholder="Select Storage Location"
                              >
                                {plantLocations?.map((item, index) => {
                                  return (
                                    <Option value={item.id} key={index}>
                                      {`${item?.code} (${item?.name})`}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </FormItem>
                          </Col>
                          <Col span={6}>
                            <FormItem
                              label="GST Number"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                value={
                                  gstNumber === undefined
                                    ? requestbyid?.SapPlant?.gst
                                    : gstNumber
                                }
                                allowClear
                                placeholder="Please enter GST number"
                                disabled
                                onChange={(e) =>
                                  handleFormDataOnChange('gst', e.target.value)
                                }
                              />
                            </FormItem>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Spin spinning={check_DocumentNumberLoading}>
                      <Row gutter={16}>
                        <Col span={8}>
                          <FormItem
                            label="Company"
                            //name="company"
                            rules={[
                              {
                                required: false,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              //value={company}
                              value={
                                formData1?.project_id === undefined &&
                                requestbyid?.SapMethodTypeMasterId === 1 &&
                                requestbyid?.document_number !== null
                                  ? requestbyid?.company || ''
                                  : formData1?.project_id === undefined &&
                                    requestbyid?.SapMethodTypeMasterId === 1 &&
                                    requestbyid?.document_number === null
                                  ? `${
                                      plants_forSAP?.SapCompanyMaster
                                        ?.company_name
                                        ? plants_forSAP?.SapCompanyMaster
                                            ?.company_name
                                        : ''
                                    }`
                                  : formData1?.methodType_id === 1
                                  ? `${
                                      plants_forSAP?.SapCompanyMaster
                                        ?.company_name
                                        ? plants_forSAP?.SapCompanyMaster
                                            ?.company_name
                                        : ''
                                    }`
                                  : formData1?.project_id === undefined
                                  ? requestbyid?.company || ''
                                  : company
                              }
                              disabled
                              allowClear
                              placeholder="Enter Company"
                              onChange={(e) =>
                                handleFormDataOnChange(
                                  'company',
                                  e.target.value
                                )
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Type"
                            name="type_id"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Select
                              disabled={
                                requestbyid?.SapMethodTypeMasterId === 1 &&
                                requestbyid?.document_number !== null
                              }
                              placeholder="Select Type"
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                // console.log('input', input);
                                return option?.children
                                  ?.toString()
                                  ?.toLowerCase()
                                  ?.includes(input?.toLowerCase());
                              }}
                              onChange={(value) =>
                                handleFormDataOnChange('type_id', value)
                              }
                            >
                              {types?.map((item, index) => {
                                return (
                                  <Option value={item.id} key={index}>
                                    {item.type}
                                  </Option>
                                );
                              })}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Category"
                            name="category_id"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Select
                              disabled={
                                (formData1?.type_id === undefined &&
                                  requestbyid?.SapTypeMasterId === 1) ||
                                formData1?.type_id === 1 ||
                                (requestbyid?.SapMethodTypeMasterId === 1 &&
                                  requestbyid?.document_number !== null)
                              }
                              placeholder="Select Category"
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                return option?.children
                                  ?.toString()
                                  ?.toLowerCase()
                                  ?.includes(input?.toLowerCase());
                              }}
                              onChange={(value) =>
                                handleFormDataOnChange('category_id', value)
                              }
                            >
                              {categories.map((item, index) => {
                                return (
                                  <Option value={item.id} key={index}>
                                    {item.category}
                                  </Option>
                                );
                              })}
                            </Select>
                          </FormItem>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        {docVisibility && (
                          <>
                            <Col span={8}>
                              <FormItem
                                label="Reference documents"
                                name="document_id"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.select_styles}`}
                              >
                                <Select
                                  disabled={
                                    requestbyid?.SapMethodTypeMasterId === 1 &&
                                    requestbyid?.document_number !== null
                                  }
                                  placeholder="Select Reference Document"
                                  onChange={handleChangedocument}
                                >
                                  {docs
                                    ? docs.map((item, index) => {
                                        return (
                                          <Option value={item.id} key={index}>
                                            {item.document_name}
                                          </Option>
                                        );
                                      })
                                    : ''}
                                </Select>
                              </FormItem>
                            </Col>
                            <Col span={8}>
                              <FormItem
                                label="Year"
                                name="sap_year"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.date_styles}`}
                              >
                                <DatePicker
                                  disabled={
                                    requestbyid?.SapMethodTypeMasterId === 1 &&
                                    requestbyid?.document_number !== null
                                  }
                                  format={'YYYY'}
                                  picker="year"
                                  onChange={(_, dateValue) =>
                                    handleFormDataOnChange(
                                      'sap_year',
                                      dateValue
                                    )
                                  }
                                  disabledDate={(current) => {
                                    const currentYear = moment().year();
                                    const previousYear = moment()
                                      .add(-1, 'year')
                                      .year();
                                    const nextYear = moment()
                                      .add(1, 'year')
                                      .year();
                                    const selectedYear = moment(current).year();
                                    return (
                                      selectedYear !== currentYear &&
                                      selectedYear !== nextYear &&
                                      selectedYear !== previousYear
                                    );
                                  }}
                                ></DatePicker>
                              </FormItem>
                            </Col>
                            <Col span={8}>
                              <FormItem
                                label="Document number"
                                name="doc_number"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles}`}
                                onChange={(e) => handleChangeDocNumber(e)}
                              >
                                <Input
                                  disabled
                                  // disabled={
                                  //   requestbyid?.SapMethodTypeMasterId === 1 &&
                                  //   requestbyid?.document_number !== null
                                  // }
                                  className={`${styles.input_lineHeight}`}
                                  placeholder="Enter document number"
                                />
                              </FormItem>
                              <a
                                style={{
                                  cursor: 'pointer',
                                  float: 'right',
                                  marginTop: '-20px',
                                  fontWeight: '600'
                                }}
                                onClick={() =>
                                  requestbyid?.SapMethodTypeMasterId === 1 &&
                                  requestbyid?.VendorMasterId !== null
                                    ? ''
                                    : handleGetERPData()
                                }
                                disabled={
                                  (requestbyid?.SapMethodTypeMasterId === 1 &&
                                    requestbyid?.document_number !== null) ||
                                  (formData1?.type_id === undefined &&
                                    requestbyid?.SapTypeMasterId === null) ||
                                  (formData1?.type_id === 2 &&
                                    formData1?.category_id === undefined &&
                                    requestbyid?.SapCategoryMasterId ===
                                      null) ||
                                  (documentID === undefined &&
                                    requestbyid?.SapDocumentMasterId === null)
                                }
                              >
                                {/* Fetch */}
                              </a>
                            </Col>
                          </>
                        )}
                      </Row>
                    </Spin>
                    <Divider className={`${styles.divider_style}`}></Divider>
                    <div
                      style={{
                        fontWeight: 600,
                        color: '#000000',
                        fontSize: '14px',
                        lineHeight: ' 20px',
                        fontFamily: 'Cerebri Sans'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '16px',
                          color: '#2364ad',
                          fontFamily: 'SFProText Bold'
                        }}
                      >
                        Shipped to:
                      </span>
                    </div>
                    <br />
                    <Spin spinning={erpLoading}>
                      {formData1?.methodType_id === 1 ||
                      (formData1?.methodType_id === undefined &&
                        requestbyid?.SapMethodTypeMasterId === 1) ? (
                        <>
                          <Row gutter={16}>
                            <Col span={8}>
                              <FormItem
                                label={'Vendor name and code'}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles}`}
                              >
                                <Input
                                  disabled
                                  value={
                                    formData1?.methodType_id === undefined &&
                                    requestbyid?.SapMethodTypeMasterId === 1
                                      ? requestbyid?.Vendor_Master === null
                                        ? Object.keys(SAPdata).length > 0
                                          ? `${SAPdata?.VendorCode} (${SAPdata?.VendorName})`
                                          : ''
                                        : `${requestbyid?.Vendor_Master?.vendorCode} (${requestbyid?.Vendor_Master?.vendorName})`
                                      : formData1?.methodType_id === 1 &&
                                        Object.keys(SAPdata).length > 0
                                      ? `${SAPdata?.VendorCode} (${SAPdata?.VendorName})`
                                      : ''
                                  }
                                  allowClear
                                  placeholder={'Vendor name and code'}
                                  onChange={(value) =>
                                    handleFormDataOnChange('vendor_id', value)
                                  }
                                />
                              </FormItem>
                            </Col>
                            <Col span={8}>
                              <FormItem
                                label="Location"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles}`}
                              >
                                <Input
                                  disabled
                                  value={
                                    formData1?.methodType_id === undefined &&
                                    requestbyid?.SapMethodTypeMasterId === 1
                                      ? requestbyid?.vendor_location_name ===
                                        null
                                        ? Object.keys(SAPdata).length > 0
                                          ? `${SAPdata?.VendorLocation}`
                                          : ''
                                        : `${requestbyid?.vendor_location_name} `
                                      : formData1?.methodType_id === 1 &&
                                        Object.keys(SAPdata).length > 0
                                      ? `${SAPdata?.VendorLocation}`
                                      : ''
                                  }
                                  allowClear
                                  placeholder="Enter Location"
                                  onChange={(value) =>
                                    handleFormDataOnChange(
                                      'vendor_location',
                                      value
                                    )
                                  }
                                />
                              </FormItem>
                            </Col>
                            <Col span={8}>
                              <FormItem
                                label="Address"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles}`}
                              >
                                <Input
                                  disabled
                                  value={
                                    formData1?.methodType_id === undefined &&
                                    requestbyid?.SapMethodTypeMasterId === 1
                                      ? requestbyid?.vendor_location_address ===
                                        null
                                        ? Object.keys(SAPdata).length > 0
                                          ? `${SAPdata?.vendor_address}`
                                          : ''
                                        : `${requestbyid?.vendor_location_address} `
                                      : formData1?.methodType_id === 1 &&
                                        Object.keys(SAPdata).length > 0
                                      ? `${SAPdata?.vendor_address}`
                                      : ''
                                  }
                                  allowClear
                                  placeholder="Enter Address"
                                  onChange={(e) =>
                                    handleFormDataOnChange(
                                      'vendor_address',
                                      e.target.value
                                    )
                                  }
                                />
                              </FormItem>
                            </Col>
                          </Row>{' '}
                        </>
                      ) : (
                        <Row gutter={16}>
                          <Col span={8}>
                            <FormItem
                              label="Vendor name and code"
                              name="vendor_id"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.select_styles}`}
                            >
                              {/*  <Select
                                showSearch
                                allowClear={true}
                                optionFilterProp="children"
                                placeholder="Select Vendor Name"
                                //onSearch={inputVenderName}
                                onSearch={debounceFetcher}
                                onChange={(value) =>
                                  handleFormDataOnChange('vendor_id', value)
                                }
                                filterOption={(input, option) => {
                                  return (
                                    option.key
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0 ||
                                    option.title
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }}
                              >
                                {vendorList?.map((item, index) => (
                                  <Option
                                    title={item.vendorName}
                                    key={index}
                                    value={item?.id}
                                  >
                                    <span
                                      style={{
                                        fontSize: '12px',
                                        textTransform: 'uppercase'
                                      }}
                                    >
                                      <span style={{ marginRight: '4px' }}>
                                        {item?.vendorName}
                                      </span>
                                      <span>
                                        {item?.vendorCode === null ||
                                        item?.vendorCode === undefined
                                          ? ''
                                          : `(${item?.vendorCode})`}
                                      </span>
                                    </span>
                                  </Option>
                                ))}
                              </Select> */}
                              <Select
                                placeholder="Select vendor name and code"
                                //mode="multiple"
                                allowClear={true}
                                showSearch
                                optionFilterProp="children"
                                onSearch={debounceFetcher}
                                onChange={(value) =>
                                  handleFormDataOnChange('vendor_id', value)
                                }
                              >
                                {vendorList?.map((item) => (
                                  <Option
                                    key={item?.id}
                                    value={item?.id}
                                    style={{
                                      fontSize: '12px',
                                      textTransform: 'uppercase'
                                    }}
                                  >{`${item.vendorName} ${
                                    item?.vendorCode
                                      ? `(${item?.vendorCode})`
                                      : ''
                                  }`}</Option>
                                ))}
                              </Select>
                            </FormItem>
                          </Col>
                          {(formData1?.vendor_id === 'other' &&
                            formData1?.methodType_id === 2) ||
                          (requestbyid?.SapMethodTypeMasterId === 2 &&
                            formData1?.vendor_id === 'other') ? (
                            ''
                          ) : (
                            <>
                              <Col span={8}>
                                <FormItem
                                  label="Location"
                                  name="vendor_location"
                                  rules={[
                                    formData1?.vendor_id === 'other'
                                      ? {
                                          required: false,
                                          message: 'Required field'
                                        }
                                      : {
                                          required: true,
                                          message: 'Required field'
                                        }
                                  ]}
                                  className={`${styles.select_styles}`}
                                >
                                  <Select
                                    disabled={formData1?.vendor_id === 'other'}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                      return option?.children
                                        ?.toString()
                                        ?.toLowerCase()
                                        ?.includes(input?.toLowerCase());
                                    }}
                                    onChange={(value) =>
                                      handleFormDataOnChange(
                                        'vendor_location',
                                        value
                                      )
                                    }
                                    placeholder="Select Location"
                                  >
                                    {vendorLocation.map((item, index) => {
                                      return (
                                        <Option value={item.id} key={index}>
                                          <span
                                            style={{
                                              fontSize: '12px',
                                              textTransform: 'uppercase'
                                            }}
                                          >
                                            {' '}
                                            {item.vendorSite}, {item.city},{' '}
                                            {item.state}
                                          </span>
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </FormItem>
                              </Col>
                              {/*  <Col span={8}>
                                <FormItem
                                  label="Address"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles}`}
                                >
                                  <Input
                                    disabled
                                    value={
                                      vendorAddressValue === undefined
                                        ? requestbyid?.Vendor_Site_Master
                                            ?.addressLine1
                                        : vendorAddressValue
                                    }
                                    allowClear
                                    placeholder="Enter Address"
                                    onChange={(e) =>
                                      handleFormDataOnChange(
                                        'vendor_address',
                                        e.target.value
                                      )
                                    }
                                  />
                                </FormItem>
                              </Col> */}
                              <Col span={8}>
                                <FormItem
                                  label="Address"
                                  name="vendor_location_address"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.select_styles}`}
                                >
                                  <Select
                                    disabled={formData1?.vendor_id === 'other'}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                      return option?.children
                                        ?.toString()
                                        ?.toLowerCase()
                                        ?.includes(input?.toLowerCase());
                                    }}
                                    onChange={(value) =>
                                      handleFormDataOnChange(
                                        'vendor_location_address',
                                        value
                                      )
                                    }
                                    placeholder="Select Address"
                                  >
                                    {vendorLocationAddressList?.map(
                                      (item, index) => {
                                        return (
                                          <Option
                                            value={item?.vendorSiteAddress}
                                            key={index}
                                          >
                                            <span
                                              style={{
                                                fontSize: '12px',
                                                textTransform: 'uppercase'
                                              }}
                                            >
                                              {' '}
                                              {item?.vendorSiteAddress}
                                            </span>
                                          </Option>
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormItem>
                              </Col>
                            </>
                          )}
                        </Row>
                      )}
                      {formData1?.methodType_id === 1 ? (
                        ''
                      ) : (formData1?.vendor_id === 'other' &&
                          formData1?.methodType_id === 2) ||
                        (requestbyid?.SapMethodTypeMasterId === 2 &&
                          formData1?.vendor_id === 'other') ? (
                        <Row gutter={16}>
                          <Col span={8}>
                            <FormItem
                              label="Vendor name"
                              name="vendorName"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                allowClear
                                placeholder="Please enter Vendor Name"
                                onChange={handleOtherVendorName}
                              />
                            </FormItem>
                          </Col>
                          <Col span={8}>
                            <FormItem
                              label="Location"
                              name="vendorLocation"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                allowClear
                                placeholder="Please enter Location"
                                onChange={handleOtherVendorLocation}
                              />
                            </FormItem>
                          </Col>
                          <Col span={8}>
                            <FormItem
                              label="Address"
                              name="vendorAddress"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input
                                // disabled
                                // value={vendorAddress[0]?.addressLine1}
                                allowClear
                                placeholder="Please enter Address"
                                onChange={handleOtherVendorAddress}
                              />
                            </FormItem>
                          </Col>
                        </Row>
                      ) : (
                        ''
                      )}
                    </Spin>
                    <Divider className={`${styles.divider_style}`}></Divider>
                    <div
                      style={{
                        fontWeight: 600,
                        color: '#000000',
                        fontSize: '14px',
                        lineHeight: ' 20px',
                        fontFamily: 'Cerebri Sans'
                      }}
                    >
                      <span
                        style={{
                          fontSize: '16px',
                          color: '#2364ad',
                          fontFamily: 'SFProText Bold'
                        }}
                      >
                        {' '}
                        Shipment details:
                      </span>
                    </div>
                    <br />
                    <Row gutter={16}>
                      <Col span={8}>
                        <FormItem
                          label="Date"
                          name="date"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.date_styles}`}
                        >
                          <DatePicker
                            format={'DD-MMM-YYYY'}
                            onChange={(_, dateValue) =>
                              handleFormDataOnChange('date', dateValue)
                            }
                            disabledDate={(current) => {
                              return (
                                moment().add(-1, 'days') >= current ||
                                moment().add(0, 'month') <= current
                              );
                            }}
                          ></DatePicker>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        {formData1?.methodType_id === 1 ||
                        (formData1?.methodType_id === undefined &&
                          requestbyid?.SapMethodTypeMasterId === 1) ? (
                          <FormItem
                            label="Material belongs to"
                            name="material_id"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              className={`${styles.materialInput_styles}`}
                              disabled
                              value={
                                requestbyid?.SapMaterialBelongsMasterId === 1
                                  ? requestbyid?.SapMaterialBelongsMaster
                                      ?.material_name
                                  : materials[0]?.material_name
                              }
                              //defaultValue={materials[0]?.material_name}
                              // placeholder="Please enter name"
                              onChange={(e) =>
                                handleFormDataOnChange(
                                  'material_id',
                                  e.target.value
                                )
                              }
                            />
                          </FormItem>
                        ) : (
                          <FormItem
                            label="Material belongs to"
                            name="material_id"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Select
                              placeholder="Select Material"
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                return option?.children
                                  ?.toString()
                                  ?.toLowerCase()
                                  ?.includes(input?.toLowerCase());
                              }}
                              onChange={(value) =>
                                handleFormDataOnChange('material_id', value)
                              }
                              disabled={formData1?.methodType_id === 1}
                            >
                              {materials?.map((item, index) => {
                                return (
                                  <Option value={item.id} key={index}>
                                    {item.material_name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </FormItem>
                        )}
                      </Col>
                      {(formData1?.type_id === undefined &&
                        formData1?.category_id === undefined &&
                        requestbyid?.SapCategoryMasterId === 1) ||
                      (formData1?.type_id === undefined &&
                        requestbyid?.SapTypeMasterId === 1) ||
                      formData1?.category_id === 1 ||
                      formData1?.type_id === 1 ? (
                        <>
                          <Col span={8}>
                            <FormItem
                              label="Expected date of return"
                              name="expected_date_return"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.date_styles}`}
                            >
                              <DatePicker
                                onChange={(_, dateValue) =>
                                  handleFormDataOnChange(
                                    'expected_date_return',
                                    dateValue
                                  )
                                }
                                disabledDate={(current) => {
                                  return moment().add(-1, 'days') >= current;
                                }}
                                format={'DD-MMM-YYYY'}
                              ></DatePicker>
                            </FormItem>
                          </Col>
                          {/*  <Col span={6}>
                        <FormItem
                          label="Actual return date"
                          name="actual_return_date"
                          rules={[
                            {
                              required: false,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.date_styles}`}
                        >
                          <DatePicker
                            disabled
                            defaultValue={moment()}
                            format={'DD-MMM-YYYY'}
                            onChange={(_, dateValue) =>
                              handleFormDataOnChange(
                                'actual_return_date',
                                dateValue
                              )
                            }
                          ></DatePicker>
                        </FormItem>
                      </Col> */}
                        </>
                      ) : (
                        ''
                      )}
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <FormItem
                          label="Reason for sending"
                          name="reason_id"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            placeholder="Select reason"
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return option?.children
                                ?.toString()
                                ?.toLowerCase()
                                ?.includes(input?.toLowerCase());
                            }}
                            onChange={(value) =>
                              handleFormDataOnChange('reason_id', value)
                            }
                          >
                            {reasons?.map((item, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {item.reason_name}
                                </Option>
                              );
                            })}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          label="Detailed reason"
                          name="detailed_reason"
                          rules={[
                            {
                              required: false,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.textarea_styles}`}
                        >
                          <TextArea
                            allowClear
                            placeholder="Enter detailed reason"
                            size="small"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'detailed_reason',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <Divider className={`${styles.divider_style}`}></Divider>
                    <Row gutter={16}>
                      <Col span={6}>
                        <FormItem
                          label="Vehicle number"
                          name="vehicle_number"
                          rules={[
                            {
                              required: false,
                              message: 'Required field'
                            }
                            /*  {
                            validator: async (_, value) => {
                              if (value?.length <= 10) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  'Not exceed more than 10 characters'
                                );
                              }
                            }
                          } */
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            allowClear
                            maxLength={20}
                            placeholder="Please enter vehicle number"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'vehicle_number',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col span={6}>
                        <FormItem
                          label="LR number"
                          name="lr_number"
                          rules={[
                            {
                              required: false,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            allowClear
                            placeholder="Please enter LR number"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'lr_number',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col span={6}>
                        <FormItem
                          label="LR Date"
                          name="lr_date"
                          rules={[
                            {
                              required: false,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.date_styles}`}
                        >
                          <DatePicker
                            //disabled
                            //defaultValue={moment() || ''}
                            format={'DD-MMM-YYYY'}
                            onChange={(_, dateValue) =>
                              handleFormDataOnChange('lr_date', dateValue)
                            }
                            disabledDate={(current) => {
                              return moment().add(0, 'month') <= current;
                            }}
                            // moment().add(-1, 'days') >= current ||
                          ></DatePicker>
                        </FormItem>
                      </Col>
                      <Col span={6}>
                        <FormItem
                          label="Name of the carrier person"
                          name="carrier_person_name"
                          rules={[
                            {
                              required: false,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            allowClear
                            placeholder="Please enter name"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                'carrier_person_name',
                                e.target.value
                              )
                            }
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    {tempDocList?.length > 0 ? (
                      <>
                        <div
                          style={{
                            fontWeight: 600,
                            color: 'rgba(0, 0, 0, 0.85)',
                            fontSize: '12px',
                            marginBottom: '8px',
                            flexDirection: 'row',
                            fontFamily: 'SFProText Bold'
                          }}
                        >
                          <span> Uploaded documents</span>

                          <ol>
                            {tempDocList.map((item, index) => (
                              <li
                                key={index}
                                style={{
                                  color: '#2364ad !important',
                                  marginTop: '2px'
                                }}
                              >
                                <span>
                                  {/*  <a
                                    className={`${styles.uploadedDoc_style}`}
                                    href={item?.fileUrl}
                                    download
                                  >
                                    {item?.originalName}
                                  </a> */}
                                  <Button
                                    type="link"
                                    onClick={() => {
                                      handleFetchDocument(item?.fileName);
                                    }}
                                  >
                                    {item?.originalName}
                                  </Button>
                                  <DeleteOutlined
                                    className={`${styles.uploadedDoc_icon_style}`}
                                    onClick={() =>
                                      handleDeleteUploadedDoc(item.id)
                                    }
                                  />
                                </span>
                              </li>
                            ))}
                          </ol>
                        </div>

                        <Divider />
                      </>
                    ) : null}
                    {newDocs?.map((item, index) => (
                      <>
                        {supportingDoc[index] !== '' &&
                        supportingDoc[index] !== undefined ? (
                          <>
                            <div key={index} className={`mb-2`}>
                              <span>
                                <Tag color="geekblue">
                                  {supportingDoc[index].name}
                                </Tag>
                                <DeleteOutlined
                                  style={{ color: ' rgb(215, 67, 67)' }}
                                  onClick={(e) => handleclearDoc(e, index)}
                                />
                              </span>

                              {index === 0 ? (
                                <span>
                                  <Button
                                    className={`${styles.upload_button}`}
                                    onClick={handleOnClick}
                                  >
                                    Add Document
                                  </Button>
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start'
                              }}
                            >
                              <FormItem
                                name="file"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Enter type'
                                  }
                                ]}
                              >
                                <label
                                  htmlFor="file-upload"
                                  className={styles.custom_file_upload}
                                >
                                  <UploadOutlined /> Upload
                                </label>
                                <Input
                                  accept="*"
                                  className={styles.upload}
                                  type="file"
                                  onChange={(e) => handlechangeDoc(e, index)}
                                  id="file-upload"
                                />
                              </FormItem>

                              {index === 0 ? (
                                <span>
                                  <Button
                                    className={`${styles.upload_button}`}
                                    onClick={handleOnClick}
                                  >
                                    Add Document
                                  </Button>
                                </span>
                              ) : (
                                <span>
                                  <Button
                                    style={{
                                      padding: '6px !important',
                                      fontSize: '11px !important'
                                    }}
                                    className={`${styles.remove_button}`}
                                    onClick={(e) => handleRemove(e, index)}
                                  >
                                    Remove
                                  </Button>
                                </span>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ))}

                    <Row gutter={16}>
                      <Col span={24}>
                        <FormItem
                          label="Remarks"
                          name="remarks"
                          rules={[
                            {
                              required: false,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.textarea_styles}`}
                        >
                          <TextArea
                            allowClear
                            placeholder="Enter remarks"
                            size="small"
                            onChange={(e) =>
                              handleFormDataOnChange('remarks', e.target.value)
                            }
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <FormItem
                          label="Approver"
                          name="approver_id"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            showSearch
                            placeholder="Select approver"
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return option?.children
                                ?.toString()
                                ?.toLowerCase()
                                ?.includes(input?.toLowerCase());
                            }}
                            onChange={(value) =>
                              handleFormDataOnChange('approver_id', value)
                            }
                            onSearch={debounceFetcherApprover}
                          >
                            {approvers.map((item, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {`${item.name} (${item.email})`}
                                </Option>
                              );
                            })}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          label="Issuer"
                          name="issuer_id"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            showSearch
                            placeholder="Select issuer"
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return option?.children
                                ?.toString()
                                ?.toLowerCase()
                                ?.includes(input?.toLowerCase());
                            }}
                            onSearch={debounceFetcherIssuer}
                            onChange={(value) =>
                              handleFormDataOnChange('issuer_id', value)
                            }
                          >
                            {issuers.map((item, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {`${item.name} (${item.email})`}
                                </Option>
                              );
                            })}
                          </Select>
                        </FormItem>
                      </Col>
                    </Row>
                  </Form>
                  <space>
                    {/*  {requestbyid?.status === 'Requested' ? (
                  ''
                ) : ( */}
                    <span style={{ marginRight: '10px' }}>
                      <Button
                        loading={draftLoading}
                        // htmlType="submit"
                        onClick={saveAsDraft}
                        className={`${styles.Save_draft_button_style}`}
                      >
                        Save as draft
                      </Button>
                    </span>
                    {/*   )} */}
                    <span>
                      <Button
                        // loading={createDraftLoading}
                        htmlType="submit"
                        // onClick={onFinishTabOneNext}
                        className={`${styles.first_next_button_style}
                    ${
                      selectedTabId === filters?.[1]?.id
                        ? ` ${styles.selected}`
                        : ''
                    }
                  `}
                        disabled={disable === true}
                      >
                        Proceed to Item Details
                      </Button>
                    </span>
                  </space>
                  {/* <div>
                <Button
                  htmlType="submit"
                  className={`${styles.first_next_button_style}
                    ${
                      selectedTabId === filters?.[1]?.id
                        ? ` ${styles.selected}`
                        : ''
                    }
                  `}
                >
                  Next
                </Button>
              </div> */}
                </div>
              )}
              {selectedTabId === 2 && (
                <div className={`${styles.margin_grey_holder}`}>
                  <Row
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      <div className={`${styles.items_title}`}>
                        Item details for the gate pass:
                      </div>
                    </div>
                    {formData1?.methodType_id === 1 ||
                    (formData1?.methodType_id === undefined &&
                      requestbyid?.SapMethodTypeMasterId === 1) ? (
                      ''
                    ) : (
                      <div>
                        <Row>
                          {/* <span style={{ marginRight: '15px' }}>
                            <a
                              href={
                                'https://1drv.ms/x/s!AsoL48RdL4ndoZgWSWwm0idiluWnwg?e=uGBSKa'
                              }
                              target="_blank"
                              rel="noreferrer"
                              download="ItemsTemplate.xlsx"
                              className={`${styles.items_title1}`}
                            >
                              <span className={`${styles.items_import}`}>
                                Download item import template
                              </span>
                            </a>
                          </span> */}
                          <span style={{ marginRight: '15px' }}>
                            <a onClick={downloadTemplate}>
                              Download item import template
                            </a>
                          </span>
                          <span>
                            <label
                              htmlFor="file-upload"
                              className={styles.custom_file_upload}
                            >
                              <UploadOutlined /> Browse
                            </label>
                            <Input
                              value={fileValue}
                              accept="*"
                              className={styles.upload}
                              type="file"
                              onChange={(e) => {
                                setFileValue('');
                                handleItemsTemplate(e);
                              }}
                              id="file-upload"
                            />
                          </span>
                          <span>
                            <SearchBar
                              onChange={(e) => handleChangeSearch(e)}
                              className={`${styles.searchbar}`}
                              inputProps={{
                                placeholder: 'Search by item description'
                              }}
                            />
                          </span>
                        </Row>
                      </div>
                    )}
                  </Row>

                  {formData1?.methodType_id === 1 ||
                  (formData1?.methodType_id === undefined &&
                    requestbyid?.SapMethodTypeMasterId === 1) ? (
                    <>
                      {fetchedItems1?.map((item, index) => {
                        return (
                          <>
                            <Row gutter={24} key={index}>
                              <Col span={4}>
                                <FormItem
                                  label="Item"
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles}`}
                                >
                                  <Input allowClear value={item?.item_code} />
                                </FormItem>
                              </Col>
                              <Col span={4}>
                                <FormItem
                                  label="Description"
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles}`}
                                >
                                  <Input
                                    allowClear
                                    value={item?.item_description}
                                  />
                                </FormItem>
                              </Col>
                              <Col span={4}>
                                <FormItem
                                  label="UOM"
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles}`}
                                >
                                  <Input allowClear value={item?.uom} />
                                </FormItem>
                              </Col>
                              <Col span={4}>
                                <FormItem
                                  label="Quantity"
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles}`}
                                >
                                  <Input allowClear value={item?.quantity} />
                                </FormItem>
                              </Col>
                              <Col span={4}>
                                <FormItem
                                  label="Value"
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles}`}
                                >
                                  <Input allowClear value={item?.value} />
                                </FormItem>
                              </Col>
                              <Col span={4}>
                                <FormItem
                                  label="Item Remarks"
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.textarea_styles} ${styles.padding_styles}`}
                                >
                                  <TextArea
                                    allowClear
                                    size="small"
                                    value={
                                      item?.item_remarks
                                        ? item?.item_remarks
                                        : ''
                                    }
                                    onChange={(e) =>
                                      handleRemarksChange(
                                        index,
                                        e.target.value,
                                        'item_remarks'
                                      )
                                    }
                                  />
                                </FormItem>
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                      <Row gutter={24}>
                        <Col span={4}>
                          <FormItem
                            label="Total Value"
                            rules={[
                              {
                                required: false,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              allowClear
                              value={
                                requestbyid?.document_number === null
                                  ? SapTotalValue
                                  : parseFloat(
                                      requestbyid?.all_items_value
                                    ).toFixed(2)
                              }
                            />
                          </FormItem>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ''
                  )}
                  {formData1?.methodType_id === 2 ||
                  (formData1?.methodType_id === undefined &&
                    requestbyid?.SapMethodTypeMasterId === 2) ? (
                    <Form.List
                      name="items"
                      className={`${styles.form_list_margin}`}
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields?.map(({ key, name, ...restField }) => (
                            <>
                              <Row
                                key={key}
                                gutter={8}
                                className={`${styles.grey_holder}  w-100 mt-1`}
                              >
                                <Col span={3}>
                                  <FormItem
                                    label="Item Code"
                                    {...restField}
                                    name={[name, 'item_code']}
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Required field'
                                      }
                                    ]}
                                    className={`${styles.input_styles} ${styles.padding_styles}`}
                                  >
                                    <Input
                                      allowClear
                                      placeholder="Please enter item code"
                                      onChange={(e) =>
                                        handleChangeItem(
                                          e.target.value,
                                          'item_code'
                                        )
                                      }
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={4}>
                                  <FormItem
                                    label="Item Description"
                                    {...restField}
                                    name={[name, 'item_description']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Required field'
                                      }
                                    ]}
                                    className={`${styles.input_styles} ${styles.padding_styles}`}
                                  >
                                    <Input
                                      allowClear
                                      placeholder="Enter item description"
                                      onChange={(e) =>
                                        handleChangeItem(
                                          e.target.value,
                                          'item_description'
                                        )
                                      }
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={4}>
                                  <FormItem
                                    label="UOM"
                                    {...restField}
                                    name={[name, 'uom']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Required field'
                                      }
                                    ]}
                                    className={`${styles.input_styles} ${styles.padding_styles}`}
                                  >
                                    <Input
                                      allowClear
                                      placeholder="Please enter UOM"
                                      onChange={(e) =>
                                        handleChangeItem(e.target.value, 'uom')
                                      }
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={4}>
                                  <FormItem
                                    label="Quantity"
                                    {...restField}
                                    name={[name, 'quantity']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Required field'
                                      }
                                    ]}
                                    className={`${styles.input_styles} ${styles.padding_styles}`}
                                  >
                                    <Input
                                      type="number"
                                      step="0.001"
                                      min={0}
                                      onKeyPress={preventMinus}
                                      allowClear
                                      placeholder="Please enter Quantity "
                                      onChange={(e) =>
                                        handleChangeItem(
                                          e.target.value,
                                          'quantity'
                                        )
                                      }
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={4}>
                                  <FormItem
                                    label="Value"
                                    {...restField}
                                    name={[name, 'value']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Required field'
                                      }
                                    ]}
                                    className={`${styles.input_styles} ${styles.padding_styles}`}
                                  >
                                    <Input
                                      type="number"
                                      step="0.001"
                                      min={0}
                                      onKeyPress={preventMinus}
                                      allowClear
                                      placeholder="Please enter value"
                                      onChange={(e) => {
                                        handleChangeItem(
                                          parseFloat(e.target.value),
                                          `value_${restField?.fieldKey}`,
                                          'value',
                                          restField?.fieldKey
                                        );
                                      }}
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={5}>
                                  <Row gutter={2}>
                                    <Col span={21}>
                                      <FormItem
                                        label="Item Remarks"
                                        {...restField}
                                        name={[name, 'item_remarks']}
                                        rules={[
                                          {
                                            required: false,
                                            message: 'Required field'
                                          }
                                        ]}
                                        className={`${styles.textarea_styles} ${styles.padding_styles}`}
                                      >
                                        <TextArea
                                          allowClear
                                          placeholder="Enter item remarks"
                                          size="small"
                                          onChange={(e) =>
                                            handleChangeItem(
                                              e.target.value,
                                              'item_remarks'
                                            )
                                          }
                                        />
                                      </FormItem>
                                    </Col>
                                    <Col
                                      span={3}
                                      className={`${styles.delete_icon}`}
                                    >
                                      {fields.length > 1 ? (
                                        <div
                                          className={`${styles.delete_styles}`}
                                        >
                                          <DeleteOutlined
                                            onClick={() => {
                                              setItemKey(restField?.fieldKey);
                                              remove(name);
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          ))}
                          <Row gutter={0}>
                            <Col span={3}>
                              <Form.Item
                                className={`${styles.formItem_marginBottom}`}
                              >
                                <Button
                                  className={`${styles.add_button_style}`}
                                  onClick={() => {
                                    add();
                                  }}
                                  icon={<PlusOutlined />}
                                >
                                  Add item
                                </Button>
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <FormItem
                                label="Total Value"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles}`}
                              >
                                <Input
                                  allowClear
                                  value={parseFloat(totalValue).toFixed(2)}
                                />
                              </FormItem>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Form.List>
                  ) : (
                    ''
                  )}
                  <Row gutter={24} className={`${styles.button_margin_left}`}>
                    <Button
                      loading={draftLoading}
                      name="draft"
                      htmlType="submit"
                      className={`${styles.next_button_style}`}
                      onClick={draftHandler}
                    >
                      Save as draft
                    </Button>
                    {/*  )} */}

                    <FormItem>
                      <div className={`${styles.button_margin}`}>
                        <Button
                          // loading={createDraftLoading}
                          loading={proccedLoading}
                          name="submit"
                          className={`${styles.first_next_button_style}`}
                          htmlType="submit"
                          size="middle"
                          onClick={submitHandler}
                        >
                          Submit
                        </Button>
                      </div>
                    </FormItem>
                  </Row>
                </div>
              )}
            </Form>
          </Spin>
        </div>
        {vendorERPModal ? (
          <VendorERP_Modal
            vendorERPModal={vendorERPModal}
            setVendorERPModal={setVendorERPModal}
            SAPdata={SAPdata}
          />
        ) : (
          ''
        )}
      </>
    </CustomLayout>
  );
};

export default DraftGP_SAP;
