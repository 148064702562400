import React, { useEffect, useState, useMemo } from 'react';
import styles from './index.module.less';
import SearchBar from '@/common/Content/searchBar';
import {
  Col,
  Row,
  Select,
  Form,
  Divider,
  Input,
  DatePicker,
  message,
  Button,
  Radio,
  Tag,
  //Pagination,
  Spin
} from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import FormItem from 'common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDraftUpload_sap,
  getrequestByid,
  getrequesterdata,
  getGatepassItems,
  delete_uploaded_docs_sap
} from '../redux/slice';
import {
  getAllPlants,
  getAllPlantLocations,
  getAllmethodsSAP,
  getAlltypes,
  getAllcategories,
  getAllreferenceDocs,
  getAllmaterils,
  getAllreasontosend,
  getAllapprovers,
  getAllVendors,
  getAllVendorLocation,
  getAllissuers,
  //createDraftUpload,
  //get_Check_DocumentNumber,
  vendorLocationAddresses
} from '../../createGP_SAP/redux/slice';
import { history } from '@/app/history';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';
import { debounce } from 'lodash';
import * as XLSX from 'xlsx/xlsx.mjs';
//import Vendor_IRISO_MRR from './Vendor_IRISO_MRR';
import VendorERP_Modal from '../../createGP_SAP/components/gatepassrequest_SAP/vendorERP_Modal';

const { Option } = Select;
const { TextArea } = Input;

const filters = [
  {
    id: 1,
    name: 'GP information',
    value: 'GP_information'
  },
  {
    id: 2,
    name: 'Item details',
    value: 'item_details'
  }
];
const EditRequestedGP_SAP = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const user = getLoggedInUser();

  const {
    plants,
    plantLocations,
    methods_sap,
    types,
    categories,
    docs,
    materials,
    reasons,
    approvers,
    vendors,
    vendorLocation,
    issuers,
    vendorLocationAddressList
  } = useSelector((state) => state.requests_sap);

  const { requestbyid, gatepassItems, getrequestByidLoading } = useSelector(
    (state) => state.edit_sap
  );

  const [documentID, setDocumentID] = useState();
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [formData1, setFormData1] = useState({});
  const [formTabeOneData, setFormTabeOneData] = useState({});
  const [vendorAddress, setVendorAddress] = useState([]);
  const [newDocs, setNewDocs] = useState([1]);
  const [supportingDoc, setSupportingDoc] = useState([]);
  const [page] = useState(1); //, setPage
  // const [per_page, setPer_Page] = useState();
  const [itemSearchValue, setItemSearchValue] = useState();
  const [other, setOther] = useState('');
  const [otherVendorName, setOtherVendorName] = useState('');
  const [otherVendorLocation, setOtherVendorLocation] = useState('');
  const [otherVendorAddress, setOtherVendorAddress] = useState('');
  const [vendorSearch, setVendorSearch] = useState('');
  const [vendorList, setVendorList] = useState([]);
  const [vendorName, setVendorName] = useState([]);
  const [draftLoading, setDraftLoading] = useState(false);
  const [docVisibility, setDocVisibility] = useState(false);
  const [tempDocList, setTempDocList] = useState([]);
  //const [vendorAddressValue, setVendorAddressValue] = useState('');
  const [itemsJsonData, setItemsJsonData] = useState(['']);
  const [fileName, setFileName] = useState(null);
  const [fileValue, setFileValue] = useState('');
  console.log(other); //do not delete
  const [gstNumber, setGstNumber] = useState('');
  const [fetchedItems, setFetchedItems] = useState([]);
  // const [fetchedItems1, setFetchedItems1] = useState([]);
  const [entityNum, setEntityNum] = useState('');
  //const [vendorERP, setVendorERP] = useState([]);
  //const [erpLoading, setErpLoading] = useState(false);
  // const [erpVendorLoading, setErpVendorLoading] = useState(false);
  // const [erpParseItems, setErpParseItems] = useState({});
  const [vendorERPModal, setVendorERPModal] = useState(false);
  const [projectSearch, setProjectSearch] = useState('');
  const [projectLocationSearch, setProjectLocationSearch] = useState('');
  //const [projectLocationName, setProjectLocationName] = useState('');
  //const [projectLocationID, setProjectLocationID] = useState('');
  // const [check_DocumentNumberLoading, setCheck_DocumentNumberLoading] =
  //   useState(false);
  const [approverSearch, setApproverSearch] = useState('');
  const [issuerSearch, setIssuerSearch] = useState('');
  // const [disable, setDisable] = useState(false);
  const [company, setCompany] = useState('');
  const [totalValue, setTotalValue] = useState(0);
  const [itemNewValue, setItemNewValue] = useState({});
  const [itemKey, setItemKey] = useState();
  const [itemVal, setItemVal] = useState({});
  //const [SapTotalValue, setSapTotalValue] = useState(0);

  useEffect(() => {
    handlegetRequestbyid();
  }, []);
  const handlegetRequestbyid = () => {
    {
      user?.user_details?.roles[0] === 'requester' ||
      user?.user_details?.roles[0] === 'issuer'
        ? dispatch(getrequesterdata(params?.id)).then((response) => {
            if (response.payload.success) {
              setTempDocList(response?.payload?.data?.SapDocuments);
            }
          })
        : '';
    }
    {
      user?.user_details?.roles[0] === 'issuer'
        ? dispatch(getrequestByid(params.id))
        : '';
    }
    const payload = {
      page: 1,
      per_page: 1000
    };
    dispatch(getAllPlants(payload));
    dispatch(getAlltypes());
    dispatch(getAllcategories());
    dispatch(getAllmethodsSAP());
    dispatch(getAllreferenceDocs());
    dispatch(getAllmaterils());

    //dispatch(getAllapprovers());
    //dispatch(getAllVendors());
    //dispatch(getAllissuers());
  };

  useEffect(() => {
    if (
      formData1?.location_id !== undefined ||
      (formData1?.location_id === undefined &&
        requestbyid?.SapStorageLocationId)
    ) {
      const payload = {
        SapPlantId:
          formData1?.project_id === undefined
            ? requestbyid?.SapPlantId
            : formData1?.project_id,
        SapStorageLocationId:
          formData1?.location_id === undefined
            ? requestbyid?.SapStorageLocationId
            : formData1?.location_id
      };
      if (issuerSearch) payload['search'] = issuerSearch;
      dispatch(getAllissuers(payload));
    }
  }, [issuerSearch, formData1?.location_id, requestbyid]);

  useEffect(() => {
    if (
      formData1?.location_id !== undefined ||
      (formData1?.location_id === undefined &&
        requestbyid?.SapStorageLocationId)
    ) {
      const payload = {
        SapPlantId:
          formData1?.project_id === undefined
            ? requestbyid?.SapPlantId
            : formData1?.project_id,
        SapStorageLocationId:
          formData1?.location_id === undefined
            ? requestbyid?.SapStorageLocationId
            : formData1?.location_id
      };
      if (approverSearch) payload['search'] = approverSearch;
      dispatch(getAllapprovers(payload));
    }
  }, [approverSearch, formData1?.location_id, requestbyid]);

  const debounceFetcherApprover = useMemo(() => {
    const loadOptions = (approverSearch) => {
      setApproverSearch(approverSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const debounceFetcherIssuer = useMemo(() => {
    const loadOptions = (issuerSearch) => {
      setIssuerSearch(issuerSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  useEffect(() => {
    handleGetAllProject();
  }, [projectSearch]); //vendorId

  const handleGetAllProject = () => {
    const payload = { search: projectSearch, page: 1, per_page: 1000 }; //vendorId
    dispatch(getAllPlants(payload));
  };

  // const onChangeProject = (value) => {
  //   setProjectSearch(value);
  // };

  useEffect(() => {
    let payload = {
      SapPlantId:
        formData1?.project_id === undefined
          ? requestbyid?.SapPlantId
          : formData1?.project_id
    };

    if (projectLocationSearch) payload['search'] = projectLocationSearch;

    requestbyid?.SapPlantId === undefined
      ? ''
      : dispatch(getAllPlantLocations(payload));
  }, [requestbyid?.SapPlantId, projectLocationSearch, formData1?.project_id]);

  useEffect(() => {
    let selectedPlant = plants?.filter(
      (item) => item?.id === formData1?.project_id
    );

    setGstNumber(selectedPlant[0]?.gst);
    setCompany(selectedPlant[0]?.SapCompanyMaster?.company_name);
    //setProjectLocationName(selectedPlant[0]?.name);
    //setProjectLocationID(selectedPlant[0]?.id);
  }, [formData1?.project_id]);

  useEffect(() => {
    let payload = {
      per_page: 20
    };
    if (vendorSearch) payload['search'] = vendorSearch;
    dispatch(getAllVendors(payload));
  }, [vendorSearch]);

  useEffect(() => {
    const vendors1 = [...vendors, { vendorName: 'other', id: 'other' }];
    setVendorList(vendors1);
  }, [vendors]);

  useEffect(() => {
    dispatch(
      getAllreasontosend({
        category: requestbyid?.SapCategoryMasterId === 1 ? 'RGP' : 'NRGP'
      })
    );
  }, [requestbyid]);

  useEffect(() => {
    if (formData1?.type_id === 1) {
      dispatch(
        getAllreasontosend({
          category: 'RGP'
        })
      );
      form.setFieldsValue({
        reason_id: undefined
      });
    } else {
      dispatch(
        getAllreasontosend({
          category: formData1?.category_id === 1 ? 'RGP' : 'NRGP'
        })
      );
      form.setFieldsValue({
        reason_id: undefined
      });
    }
  }, [formData1?.category_id, formData1?.type_id]);

  useEffect(() => {
    if (requestbyid?.SapMethodTypeMasterId === 1) {
      setDocVisibility(true);
    } else {
      setDocVisibility(false);
    }
  }, [requestbyid]);

  useEffect(() => {
    if (formData1?.methodType_id === 1) {
      setDocVisibility(true);
      form.setFieldsValue({
        document_id: undefined,
        document_number: undefined
      });
    } else {
      setDocVisibility(false);
    }
  }, [formData1?.methodType_id]);

  //console.log('requestbyid', requestbyid);

  useEffect(() => {
    let payload = {
      vendorId:
        formData1?.vendor_id === undefined
          ? requestbyid?.VendorMasterId
          : formData1?.vendor_id
    };

    // if (formData1?.vendor_id) {
    //   formData1?.vendor_id === 'other'
    //     ? ''
    //     : dispatch(getAllVendorLocation({ vendorId: formData1?.vendor_id }));
    // }
    requestbyid?.VendorMasterId === undefined
      ? ''
      : formData1?.vendor_id === 'other'
      ? ''
      : dispatch(getAllVendorLocation(payload));
  }, [requestbyid, formData1?.vendor_id]);

  useEffect(() => {
    let VendorValues = vendors?.filter(
      (item) => item?.id === formData1?.vendor_id
    );
    setVendorName(VendorValues);
  }, [formData1?.vendor_id]);

  useEffect(() => {
    let locationValues = vendorLocation?.filter(
      (item) => item?.id === formData1?.vendor_location
    );
    setVendorAddress(locationValues);
    //setVendorAddressValue(locationValues[0]?.addressLine1);
  }, [formData1?.vendor_location]);

  useEffect(() => {
    if (
      formData1?.vendor_location ||
      (formData1?.vendor_location === undefined &&
        requestbyid?.VendorSiteMasterId)
    ) {
      formData1?.vendor_id === 'other'
        ? ''
        : dispatch(
            vendorLocationAddresses({
              vendorSiteId:
                formData1?.vendor_location === undefined
                  ? requestbyid?.VendorSiteMasterId
                  : formData1?.vendor_location
            })
          );
    }
  }, [requestbyid, formData1?.vendor_location]);

  // console.log('VendorName', vendorName);
  // console.log('VendorAddress', vendorAddress);
  // console.log('VendorAddressValue', vendorAddressValue);

  useEffect(() => {
    dispatch(
      getGatepassItems({
        gpid: parseInt(params.id),
        page: page,
        // per_page: per_page,
        search: itemSearchValue,
        all: 1
      })
    );
  }, [page, itemSearchValue]);

  useEffect(() => {
    const {
      SapCategoryMasterId,
      SapMaterialBelongsMasterId,
      //SapMethodTypeMasterId,
      SapPlantId,
      SapReasonToSendMasterId,
      SapStorageLocationId,
      SapTypeMasterId,
      //UserId,
      //VendorMasterId,
      requester_name,
      requester_department,
      date,
      expected_date_return,
      vehicle_number,
      lr_number,
      carrier_person_name,
      detailed_reason,
      remarks
      // project_location_master_id
    } = requestbyid;
    form.setFieldsValue({
      requester_name: requester_name,
      requester_department: requester_department,
      project_id: SapPlantId, // requestbyid?.SapPlant?.plant_name,
      location_id: SapStorageLocationId, // project_location_master_id,
      type_id: SapTypeMasterId, //type_id,
      category_id: SapCategoryMasterId,
      methodType_id: requestbyid?.SapMethodTypeMasterId,
      document_id: requestbyid?.SapDocumentMasterId,
      sap_year: requestbyid?.year === null ? '' : moment(requestbyid?.year),
      doc_number: requestbyid?.document_number,
      vendor_id: `${requestbyid?.Vendor_Master?.vendorName} ${
        requestbyid?.Vendor_Master?.vendorCode
          ? `(${requestbyid?.Vendor_Master?.vendorCode})`
          : ''
      }`, //VendorMasterId,
      vendor_location: requestbyid?.vendor_location_name, //requestbyid?.Vendor_Site_Master?.vendorSite,
      vendor_location_address: requestbyid?.vendor_location_address, // requestbyid?.Vendor_Site_Master?.addressLine1,
      date: moment(date),
      material_id: SapMaterialBelongsMasterId, // requestbyid?.MaterialBelong?.material_name,
      expected_date_return: expected_date_return
        ? moment(requestbyid?.expected_date_return)
        : null,
      vehicle_number,
      lr_number,
      lr_date:
        requestbyid?.lr_date === null ? '' : moment(requestbyid?.lr_date),
      carrier_person_name,
      //user_id: approver_user_id,
      reason_id: SapReasonToSendMasterId, // requestbyid?.reason_id,
      detailed_reason,
      remarks,

      items: gatepassItems?.map((item) => ({
        id: item?.id,
        item_name: item?.item_name,
        item_code: item?.item_code === null ? '' : item?.item_code,
        uom: item?.uom,
        quantity: item?.quantity,
        item_description: item?.item_description,
        item_remarks: item?.item_remarks === null ? '' : item?.item_remarks,
        value: item?.value
      })),
      issuer_id:
        requestbyid?.SapApprovers?.length > 0
          ? requestbyid?.SapApprovers[1]?.User?.id
          : '', //issuer_user_id,
      approver_id:
        requestbyid?.SapApprovers?.length > 0
          ? requestbyid?.SapApprovers[0]?.User?.id
          : '' //approver_user_id
    });
    const gatepassItemsERP = gatepassItems?.map((item) => ({
      id: item?.id,
      item_name: item?.item_name,
      item_code: item?.item_code === null ? '' : item?.item_code,
      uom: item?.uom,
      quantity: item?.quantity,
      value: item?.value,
      item_description: item?.item_description,
      item_remarks: item?.item_remarks === null ? '' : item?.item_remarks
    }));
    setFetchedItems(gatepassItemsERP); //-------------1
    // setTotalValue(parseFloat(requestbyid?.all_items_value).toFixed(2) || 0);
  }, [requestbyid, gatepassItems]);

  const downloadTemplate = () => {
    const downloadUrl = '/Item_Template_sap.xlsx'; // '/templates/template.xlsx';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'Item_Template_sap.xlsx'; //'template.xlsx';
    link.click();
  };

  const handleRemarksChange = (index, value) => {
    const newItems = [...fetchedItems];
    newItems[index].item_remarks = value;
    setFetchedItems(newItems);
  };

  useEffect(() => {
    if (formData1?.methodType_id === 1) {
      const gatepassItemsERP = gatepassItems?.map((item) => ({
        id: item?.id,
        item_name: item?.item_name,
        item_code: item?.item_code === null ? '' : item?.item_code,
        uom: item?.uom,
        quantity: item?.quantity,
        item_description: item?.item_description,
        value: item?.value,
        item_remarks: item?.item_remarks === null ? '' : item?.item_remarks
      }));
      setFetchedItems(gatepassItemsERP); //---------------2
    }
    if (formData1?.methodType_id === 2) {
      form.setFieldsValue({
        items: gatepassItems?.map((item) => ({
          id: item?.id,
          item_name: item?.item_name,
          item_code: item?.item_code,
          uom: item?.uom,
          quantity: item?.quantity,
          value: item?.value,
          item_description: item?.item_description,
          item_remarks: item?.item_remarks
        }))
      });
    }
  }, [formData1?.methodType_id]);

  /* useEffect(() => {
    form.setFieldsValue({
      material_id: materials[0]?.material_name 
    });
  }, [requestbyid, materials]); */

  useEffect(() => {
    form.setFieldsValue({
      requester_name: user?.user_details?.fullname,
      requester_department: user?.user_details?.roles[0],
      //document_number: requestbyid?.Document_master?.document_number,
      date: moment() || ''
      //lr_date: moment() || ''
      //expected_date_return: requestbyid?.expected_date_return
    });
  });

  const handleChangedocument = (values) => {
    console.log('handleChangedocument', values);
    setDocumentID(values);

    form.setFieldsValue({
      doc_number: undefined
    });

    //let doc_numbers = docs.filter((docs) => docs.id === values);
    //setDocumentNumber(doc_numbers[0].document_number);
    form.setFieldsValue({
      //location_id: undefined,
      vendor_id: undefined,
      vendor_location: undefined
    });
  };

  const handleOtherVendorName = (e) => {
    console.log('OtherVendorName', e.target.value);
    setOtherVendorName(e.target.value);
  };
  const handleOtherVendorLocation = (e) => {
    console.log('OtherVendorName', e.target.value);
    setOtherVendorLocation(e.target.value);
  };
  const handleOtherVendorAddress = (e) => {
    console.log('OtherVendorName', e.target.value);
    setOtherVendorAddress(e.target.value);
  };

  const handleFormDataOnChange = (slug, value) => {
    if (slug === 'project_id') {
      setGstNumber('');
      //setProjectId(value);
      form.setFieldsValue({
        location_id: undefined
      });
    }
    // if (slug === 'location_id') {
    //   setGstNumber(gstNumberVal[0]?.gst_number);
    // }
    if (slug === 'vendor_id') {
      setOther(value);
      // setVendorAddressValue('');
      form.setFieldsValue({
        vendor_location: undefined,
        vendor_location_address: undefined
      });
    }
    if (slug === 'vendor_location') {
      // setVendorAddressValue(vendorAddress[0]?.addressLine1);
      form.setFieldsValue({
        vendor_location_address: undefined
      });
    }
    if (slug === 'category_id') {
      setOther(value);
      form.setFieldsValue({
        reason_id: undefined
      });
    }
    if (slug === 'location_id' || slug === 'project_id') {
      form.setFieldsValue({
        approver_id: undefined,
        issuer_id: undefined
      });
    }
    setFormData1({
      ...formData1,
      [slug]: value
    });
  };

  const handleChangeItem = (value, slug, keyname, key) => {
    // console.log('keyname', keyname);
    // console.log('key', key);
    if (`${keyname}_${key}` === `value_${key}`) {
      setItemNewValue({
        ...itemNewValue,
        [slug]: value
      });
    }
    setItemVal({
      ...itemVal,
      [slug]: value
    });
  };

  // useEffect(() => {
  //   //console.log('itemNewValue11111', itemNewValue);
  //   let total = 0;
  //   for (const key in itemNewValue) {
  //     //console.log('key111:', key);
  //     // if (itemNewValue?.hasOwnProperty(key)) {
  //     //   total += parseFloat(itemNewValue[key] || 0);
  //     // }
  //     if (Object.prototype.hasOwnProperty.call(itemNewValue, key)) {
  //       total += parseFloat(itemNewValue[key] || 0);
  //     }
  //   }
  //   setTotalValue(total);
  // }, [itemNewValue]);

  // useEffect(() => {
  //   //console.log('itemNewValue222222', itemNewValue);
  //   let total = totalValue;
  //   for (const key in itemNewValue) {
  //     // console.log('key22222:', key);
  //     if (key === `value_${itemKey}`) {
  //       total = totalValue - parseFloat(itemNewValue[key]);
  //       delete itemNewValue[key];
  //     }
  //   }
  //   setTotalValue(total);
  // }, [itemKey]);

  // console.log(
  //   'formData1?.methodType_id && documentID:',
  //   formData1?.methodType_id,
  //   formData1?.documentID
  // );
  // console.log(
  //   'requestbyid?.Vendor_Master?.vendorName && requestbyid?.Vendor_Master?.vendorCode:',
  //   requestbyid?.Vendor_Master?.vendorName,
  //   requestbyid?.Vendor_Master?.vendorCode
  // );

  //adding total value by BulkUpload
  useEffect(() => {
    const updatedData = itemsJsonData?.map((item, index) => ({
      [`value_${index}`]: parseFloat(item?.Item_value) || 0
    }));

    const resultObject = updatedData?.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});

    console.log('resultObject', resultObject);
    setItemNewValue(resultObject);
    let total = 0;
    for (const key in itemNewValue) {
      if (Object.prototype.hasOwnProperty.call(itemNewValue, key)) {
        total += parseFloat(itemNewValue[key] || 0);
      }
    }
    setTotalValue(total);
  }, [itemsJsonData]);

  //adding total value for Edit
  useEffect(() => {
    const updatedData = gatepassItems?.map((item, index) => ({
      [`value_${index}`]: parseFloat(item?.value) || 0
    }));

    const resultObject = updatedData?.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});

    console.log('resultObject', resultObject);
    setItemNewValue(resultObject);
    let total = 0;
    for (const key in itemNewValue) {
      if (Object.prototype.hasOwnProperty.call(itemNewValue, key)) {
        total += parseFloat(itemNewValue[key] || 0);
      }
    }
    setTotalValue(total);
  }, [gatepassItems]);

  //adding total value
  useEffect(() => {
    let total = 0; //parseFloat(requestbyid?.all_items_value).toFixed(2) || 0;
    for (const key in itemNewValue) {
      if (Object.prototype.hasOwnProperty.call(itemNewValue, key)) {
        total += parseFloat(itemNewValue[key] || 0);
      }
    }
    setTotalValue(total);
  }, [requestbyid, itemNewValue]);
  console.log('Total::', totalValue);
  //reducing deleted field value from total value
  useEffect(() => {
    let total = totalValue;
    for (const key in itemNewValue) {
      if (key === `value_${itemKey}`) {
        total = totalValue - parseFloat(itemNewValue[key]);
        delete itemNewValue[key];
      }
    }
    setTotalValue(total);
  }, [requestbyid, itemKey]);

  // /* --SAP Total Value-- */
  // useEffect(() => {
  //   let total = 0;
  //   fetchedItems1.forEach((item) => {
  //     if (item?.value !== '') {
  //       total += parseFloat(item?.value);
  //     }
  //   });
  //   //console.log('TTTTTotal:', total);
  //   setSapTotalValue(total);
  // }, [requestbyid]);

  console.log('formData1', formData1);
  console.log('formTabeOneData', formTabeOneData);

  const onFinish = (values) => {
    // console.log('formTabeOneData', formTabeOneData);
    setDraftLoading(true);
    let formdata = new FormData();

    values['id'] = params.id;

    formdata.append('gpid', parseInt(params.id));
    formdata.append('is_submitting', 'yes');

    // formdata.append(
    //   'isIrisoVendor',
    //   (formData1?.methodType_id === 1 && documentID === 3) ||
    //     (documentID === undefined && requestbyid?.SapDocumentMasterId === 3)
    //     ? true
    //     : false
    // );

    for (let i in supportingDoc) {
      formdata.append('files', supportingDoc[i]);
    }

    // formdata.append('items', JSON.stringify(values?.items));

    formData1?.methodType_id === 1 || requestbyid?.SapMethodTypeMasterId === 1
      ? formdata.append(
          'items',
          fetchedItems === undefined ? '' : JSON.stringify(fetchedItems)
        )
      : formdata.append('items', JSON.stringify(values?.items));

    if (requestbyid?.SapMethodTypeMasterId === 1) {
      formdata.append(
        'all_items_value',
        parseFloat(requestbyid?.all_items_value).toFixed(2) || 0
      );
    } else {
      formdata.append('all_items_value', totalValue || 0);
    }

    formdata.append(
      'SapPlantId',
      formData1?.project_id === undefined
        ? requestbyid?.SapPlantId
        : formData1?.project_id
    );
    formdata.append(
      'SapStorageLocationId',
      formTabeOneData?.location_id === undefined
        ? requestbyid?.SapStorageLocationId
        : formTabeOneData?.location_id
    );
    formdata.append(
      'company',
      formData1?.project_id === undefined ? requestbyid?.company : company
    );
    formdata.append(
      'SapTypeMasterId',
      requestbyid?.SapMethodTypeMasterId === 1
        ? requestbyid?.SapTypeMasterId
        : requestbyid?.SapMethodTypeMasterId === 2 &&
          formData1?.type_id === undefined
        ? requestbyid?.SapTypeMasterId
        : formTabeOneData?.type_id
    );

    // formData1?.methodType_id === undefined
    //   ? formdata.append('SapCategoryMasterId', requestbyid?.SapCategoryMasterId)
    //   : formData1?.type_id === undefined && requestbyid?.SapTypeMasterId === 1
    //   ? formdata.append('SapCategoryMasterId', categories[0]?.id)
    //   : formData1?.type_id === 1
    //   ? formdata.append('SapCategoryMasterId', categories[0]?.id)
    //   : formdata.append('SapCategoryMasterId', formData1?.methodType_id);

    requestbyid?.SapMethodTypeMasterId === 1 ||
    (formData1?.category_id === undefined && formData1?.type_id === undefined)
      ? formdata.append('SapCategoryMasterId', requestbyid?.SapCategoryMasterId)
      : (requestbyid?.SapMethodTypeMasterId === 2 &&
          formData1?.type_id === undefined &&
          requestbyid?.SapTypeMasterId === 1) ||
        (requestbyid?.SapMethodTypeMasterId === 2 && formData1?.type_id === 1)
      ? formdata.append('SapCategoryMasterId', categories[0]?.id)
      : formdata.append('SapCategoryMasterId', formData1?.category_id);

    formdata.append(
      'SapMethodTypeMasterId',
      formData1?.methodType_id === undefined
        ? requestbyid?.SapMethodTypeMasterId
        : formData1?.methodType_id
    );
    formData1?.methodType_id === 1 ||
    (formData1?.methodType_id === undefined &&
      requestbyid?.SapMethodTypeMasterId === 1)
      ? formdata.append(
          'SapDocumentMasterId',
          documentID === undefined
            ? requestbyid?.SapDocumentMasterId === null
              ? ''
              : requestbyid?.SapDocumentMasterId
            : documentID
        )
      : '';

    requestbyid?.SapMethodTypeMasterId === 1
      ? formData1?.sap_year === undefined
        ? formdata.append('year', requestbyid?.year)
        : formdata.append('year', formData1?.sap_year)
      : '';

    (formData1?.methodType_id === undefined &&
      requestbyid?.SapMethodTypeMasterId === 1) ||
    formData1?.methodType_id === 1
      ? formdata.append(
          'document_number',
          entityNum === '' || entityNum === undefined || entityNum === null
            ? requestbyid?.document_number === null
              ? ''
              : requestbyid?.document_number
            : entityNum //documentNumber
        )
      : '';

    formdata.append(
      'SapReasonToSendMasterId',
      formTabeOneData?.reason_id === undefined
        ? requestbyid?.SapReasonToSendMasterId
        : formTabeOneData?.reason_id
    );

    /*  if (
      formData1?.methodType_id === undefined &&
      documentID === undefined &&
      entityNum === '' &&
      vendorName?.length === 0
    ) {
      formdata.append(
        'vendor_name',
        requestbyid?.Vendor_Master?.vendorName === null
          ? ''
          : requestbyid?.Vendor_Master?.vendorName
      );
      formdata.append(
        'vendor_code',
        requestbyid?.Vendor_Master?.vendorCode === null
          ? ''
          : requestbyid?.Vendor_Master?.vendorCode === null
          ? ''
          : requestbyid?.Vendor_Master?.vendorCode
      );

      formdata.append(
        'vendor_location',
        requestbyid?.vendor_location_name === undefined
          ? ''
          : requestbyid?.vendor_location_name
      );

      formdata.append(
        'vendor_address',
        requestbyid?.vendor_location_address === undefined ||
          requestbyid?.vendor_location_address === null ||
          requestbyid?.vendor_location_address === ''
          ? ''
          : requestbyid?.vendor_location_address
      );
    }  else if (
      (formData1?.methodType_id === 1 && documentID === 1) ||
      (formData1?.methodType_id === undefined && documentID === 1) ||
      (formData1?.methodType_id === undefined &&
        documentID === undefined &&
        requestbyid?.SapDocumentMasterId === 1 &&
        entityNum !== '')
    ) {
      formdata.append('vendor_name', vendorERP?.vendorName);
      formdata.append(
        'vendor_code',
        vendorERP?.vendorCode === null ? '' : vendorERP?.vendorCode
      );

      formdata.append(
        'vendor_location',
        vendorAddress[0]?.vendorSite === undefined
          ? ''
          : vendorAddress[0]?.vendorSite
      );

      formdata.append('vendor_address', vendorAddress[0]?.addressLine1);
    } else if (
      (formData1?.methodType_id === 1 && documentID === 2) ||
      (formData1?.methodType_id === undefined && documentID === 2) ||
      (formData1?.methodType_id === undefined &&
        documentID === undefined &&
        requestbyid?.SapDocumentMasterId === 2 &&
        entityNum !== '')
    ) {
      // formdata.append('vendor_name', vendorERP?.vendorName);
      // formdata.append('vendor_code', vendorERP?.vendorCode);
      formdata.append('vendor_name', `${erpParseItems?.vendor_name}`);
      formdata.append(
        'vendor_code',
        erpParseItems?.vendor_code === null ? '' : erpParseItems?.vendor_code
      );
      formdata.append(
        'vendor_location',
        erpParseItems?.vendor_location === undefined ||
          erpParseItems?.vendor_location === null
          ? ''
          : erpParseItems?.vendor_location
      ); //formData1?.vendor_location
      formdata.append(
        'vendor_address',
        erpParseItems?.vendor_address === undefined ||
          erpParseItems?.vendor_address === null ||
          erpParseItems?.vendor_address === ''
          ? ''
          : erpParseItems?.vendor_address
      );
    } else if (
      (formData1?.methodType_id === 1 && documentID === 3) ||
      (formData1?.methodType_id === undefined && documentID === 3) ||
      (formData1?.methodType_id === undefined &&
        documentID === undefined &&
        requestbyid?.SapDocumentMasterId === 3 &&
        entityNum !== '')
    ) {
      formdata.append('vendor_name', `${erpParseItems?.vendor_name}`);
      formdata.append(
        'vendor_code',
        erpParseItems?.vendor_code === null ? '' : erpParseItems?.vendor_code
      );
      formdata.append(
        'vendor_location',
        erpParseItems?.vendor_location === undefined ||
          erpParseItems?.vendor_location === null
          ? ''
          : erpParseItems?.vendor_location
      ); //formData1?.vendor_location
      formdata.append(
        'vendor_address',
        erpParseItems?.vendor_address === undefined ||
          erpParseItems?.vendor_address === null ||
          erpParseItems?.vendor_address === ''
          ? ''
          : erpParseItems?.vendor_address
      );
    } else if (formData1?.vendor_id === 'other') {
      formdata.append('vendor_name', otherVendorName);
      formdata.append('vendor_location', otherVendorLocation);
      formdata.append('vendor_address', otherVendorAddress);
    }  else if (
      formData1?.methodType_id === 2 ||
      (formData1?.methodType_id === undefined &&
        requestbyid?.SapMethodTypeMasterId === 2) ||
      vendorName?.length !== 0
    ) {
      formdata.append(
        'vendor_name',
        vendorName[0]?.vendorName === undefined ? '' : vendorName[0]?.vendorName
      );
      formdata.append(
        'vendor_code',
        vendorName[0]?.vendorCode === undefined
          ? ''
          : vendorName[0]?.vendorCode === null
          ? ''
          : vendorName[0]?.vendorCode
      );
      formdata.append(
        'vendor_location',
        vendorAddress[0]?.vendorSite === undefined
          ? ''
          : vendorAddress[0]?.vendorSite
      ); //formData1?.vendor_location
      formdata.append('vendor_address', vendorAddress[0]?.addressLine1);
    }*/

    if (requestbyid?.SapMethodTypeMasterId === 1) {
      formdata.append('vendor_name', requestbyid?.Vendor_Master?.vendorName);
      formdata.append('vendor_code', requestbyid?.Vendor_Master?.vendorCode);
      formdata.append('vendor_location', requestbyid?.vendor_location_name);
      formdata.append('vendor_address', requestbyid?.vendor_location_address);
    } else if (formData1?.vendor_id === 'other') {
      formdata.append('vendor_name', otherVendorName);
      formdata.append('vendor_location', otherVendorLocation);
      formdata.append('vendor_address', otherVendorAddress);
    } else if (requestbyid?.SapMethodTypeMasterId === 2) {
      formdata.append(
        'vendor_name',
        vendorName[0]?.vendorName === undefined
          ? requestbyid?.Vendor_Master?.vendorName
          : vendorName[0]?.vendorName
      );
      formdata.append(
        'vendor_code',
        vendorName[0]?.vendorCode === undefined
          ? requestbyid?.Vendor_Master?.vendorCode
          : vendorName[0]?.vendorCode
      );
      formdata.append(
        'vendor_location',
        vendorAddress[0]?.vendorSite === undefined
          ? requestbyid?.vendor_location_name
          : vendorAddress[0]?.vendorSite
      ); //formData1?.vendor_location
      formdata.append(
        'vendor_address',
        formData1?.vendor_location_address === undefined
          ? requestbyid?.vendor_location_address
          : formData1?.vendor_location_address
      );
    }

    formdata.append(
      'date',
      formData1?.date === undefined
        ? moment().format()
        : moment(formData1?.date).format()
    );
    formdata.append(
      'SapMaterialBelongsMasterId',
      requestbyid?.SapMethodTypeMasterId === 1
        ? requestbyid?.SapMaterialBelongsMasterId
        : formData1?.material_id === undefined
        ? requestbyid?.SapMaterialBelongsMasterId
        : formTabeOneData?.material_id
    );
    // formData1?.methodType_id === 1 ||
    requestbyid?.SapCategoryMasterId === 1 ||
      requestbyid?.SapTypeMaster === 1 ||
      formData1?.type_id === 1;
    formData1?.category_id === 1 ||
    (formData1?.type_id === 1 && formData1?.category_id === undefined) ||
    (requestbyid?.SapCategoryMasterId === 1 &&
      formData1?.category_id === undefined)
      ? formdata.append(
          'expected_date_return',
          formData1?.expected_date_return === undefined
            ? requestbyid?.expected_date_return === null
              ? ''
              : requestbyid?.expected_date_return
            : moment(formData1?.expected_date_return).format()
        )
      : '';
    formdata.append(
      'detailed_reason',
      formData1?.detailed_reason === undefined
        ? requestbyid?.detailed_reason === null ||
          requestbyid?.detailed_reason === 'null'
          ? ''
          : requestbyid?.detailed_reason
        : formTabeOneData?.detailed_reason
    );
    formdata.append(
      'vehicle_number',
      formData1?.vehicle_number === undefined
        ? requestbyid?.vehicle_number === null ||
          requestbyid?.vehicle_number === 'null'
          ? ''
          : requestbyid?.vehicle_number
        : formTabeOneData?.vehicle_number
    );
    formdata.append(
      'lr_number',
      formData1?.lr_number === undefined
        ? requestbyid?.lr_number === null || requestbyid?.lr_number === 'null'
          ? ''
          : requestbyid?.lr_number
        : formTabeOneData?.lr_number
    );
    formdata.append(
      'lr_date',
      requestbyid?.lr_date === null && formData1?.lr_date === undefined
        ? '' // moment().format()
        : requestbyid?.lr_date !== null && formData1?.lr_date === undefined
        ? moment(requestbyid?.lr_date).format()
        : moment(formData1?.lr_date).format()
    );
    formdata.append(
      'carrier_person_name',
      formData1?.carrier_person_name === undefined
        ? requestbyid?.carrier_person_name === null ||
          requestbyid?.carrier_person_name === 'null'
          ? ''
          : requestbyid?.carrier_person_name
        : formTabeOneData?.carrier_person_name
    );
    formdata.append(
      'approver_id',
      formTabeOneData?.approver_id === undefined
        ? requestbyid?.AllSapApprovers[0]?.User?.id
        : formTabeOneData?.approver_id
    );
    formdata.append(
      'issuer_id',
      formTabeOneData?.issuer_id === undefined
        ? requestbyid?.AllSapApprovers[1]?.User?.id
        : formTabeOneData?.issuer_id
    );
    formdata.append(
      'remarks',
      formData1?.remarks === undefined
        ? requestbyid?.remarks === null || requestbyid?.remarks === 'null'
          ? ''
          : requestbyid?.remarks
        : formTabeOneData?.remarks
    );

    //console.log('payload', payload);
    console.log('formdata', formdata);
    dispatch(createDraftUpload_sap(formdata)).then(function (response) {
      if (response?.payload?.success) {
        //message.success(response.payload.msg);
        message.success(
          `${response.payload.msg}. Ticket ${response?.payload?.Ticket}`
        );
        setDraftLoading(false);
        user?.user_details?.roles[0] === 'issuer'
          ? history.push('/issuer-requester-sap')
          : history.push('/requesters-sap-dashboard');
      } else {
        // setInitiate(false);
        //message.error(response.payload.err);
        message.error(response.payload.msg);
        setDraftLoading(false);
      }
    });
  };

  const handleChangeDocNumber = (e) => {
    setEntityNum(e.target.value);
    form.setFieldsValue({
      vendor_id: undefined,
      vendor_location: undefined
    });
  };

  // const handleGetERPVendorData = (parseItems) => {
  //   setErpVendorLoading(true);
  //   // console.log('parseItems_vendorList', parseItems);
  //   // console.log('vendorList', vendorList);
  //   const payload = { vendorCode: parseItems?.vendor_code };
  //   dispatch(getAllVendors(payload)).then((response) => {
  //     /* if (response?.payload?.success) { */

  //     if (response?.payload?.success && response?.payload?.data?.length === 0) {
  //       setVendorERPModal(true);
  //       setErpVendorLoading(false);
  //     } else if (
  //       response?.payload?.success &&
  //       response?.payload?.data?.length > 0
  //     ) {
  //       // const vendors = response?.payload?.data?.filter(
  //       //   (item) => item.vendorCode == parseItems?.vendor_code
  //       // );
  //       setErpVendorLoading(false);
  //       console.log(
  //         'ERPvendors_for vendor details',
  //         response?.payload?.data[0]
  //       );
  //       setVendorERP(response?.payload?.data[0]);

  //       form.setFieldsValue({
  //         vendor_id: response?.payload?.data[0]?.id
  //       });

  //       (formData1?.methodType_id === 1 && documentID === 2) ||
  //       (formData1?.methodType_id === 1 && documentID === 3)
  //         ? ''
  //         : dispatch(
  //             getAllVendorLocation({
  //               vendorId: response?.payload?.data[0]?.id
  //             })
  //           ).then((response) => {
  //             if (response?.payload?.success) {
  //               setDisable(false);
  //               console.log('Condition-77777_for vendor location');
  //               parseItems?.vendor_location !== '';
  //               {
  //                 const vendor_location = response?.payload?.data?.filter(
  //                   (item) => item.vendorSite == parseItems?.vendor_location
  //                 );
  //                 console.log('vendor_location_under_111111', vendor_location);
  //                 console.log(
  //                   'vendor_location[0]?.vendorSite_under_2222222',
  //                   response?.payload?.data[0]?.vendorSite
  //                 );
  //                 // setVendorERPLocationName(
  //                 //   response?.payload?.data[0]?.vendorSite
  //                 // );
  //                 form.setFieldsValue({
  //                   vendor_location: vendor_location[0]?.id
  //                 });
  //                 setVendorAddressValue(vendor_location[0]?.addressLine1);
  //               }
  //             } else {
  //               // setDisable(true);
  //               message.error(
  //                 'Vendor location details could not fetched successfully'
  //               );
  //             }
  //           });
  //     } else {
  //       /*  } */
  //       setDisable(true);
  //       message.error('Vendor details could not fetched successfully');
  //     }
  //   });
  // };

  // const handleGetERPData = () => {
  //   setCheck_DocumentNumberLoading(true);
  //   const check_DocumentNumbe_payload = {
  //     document_id:
  //       documentID === undefined
  //         ? requestbyid?.Document_master?.id
  //         : documentID,
  //     document_number:
  //       entityNum === undefined || entityNum === '' || entityNum === null
  //         ? requestbyid?.document_number
  //         : entityNum,
  //     location_id:
  //       projectLocationID === undefined
  //         ? requestbyid?.project_location_master_id
  //         : projectLocationID
  //   };
  //   dispatch(get_Check_DocumentNumber(check_DocumentNumbe_payload)).then(
  //     function (response) {
  //       if (response?.payload?.success) {
  //         setCheck_DocumentNumberLoading(false);
  //         console.log(
  //           'response?.payload_of_CHECK_DocumentNumber',
  //           response?.payload?.isTheDocumentExist
  //         );
  //         // message.success(response.payload.msg);

  //         setErpLoading(true);

  //         var myHeaders = new Headers();
  //         myHeaders.append('Content-Type', 'application/json');

  //         // for Dev only
  //         /* myHeaders.append(
  //           'Authorization',
  //           'Basic R0FURVBBU1NfT0lDREVWOkJyaWRnZTIyMkAyM1RwbA=='
  //         ); */
  //         // for Prod only
  //         myHeaders.append(
  //           'Authorization',
  //           'Basic QTJQX09JQ1BSRDpCcmlkZ2UyRVJQQEpBTlRwbFBSRA=='
  //         );

  //         //const projects = plants.filter((item) => item.id == projectId);
  //         let doc_id = docs.filter((item) => item.id === documentID);

  //         var raw = JSON.stringify({
  //           document_details: {
  //             entity_type:
  //               doc_id[0]?.document_name === undefined
  //                 ? requestbyid?.Document_master?.document_name
  //                 : doc_id[0]?.document_name,
  //             entity_number:
  //               entityNum === undefined ||
  //               entityNum === '' ||
  //               entityNum === null
  //                 ? requestbyid?.document_number
  //                 : entityNum,
  //             project_code:
  //               plants[0]?.project_code === undefined
  //                 ? requestbyid?.project_master?.project_code
  //                 : plants[0]?.project_code,
  //             project_site:
  //               projectLocationName === undefined
  //                 ? requestbyid?.project_location_master?.name
  //                 : projectLocationName
  //           }
  //         });

  //         var requestOptions = {
  //           method: 'POST',
  //           headers: myHeaders,
  //           body: raw,
  //           mode: 'cors',
  //           redirect: 'follow'
  //         };
  //         /* For Production only
  //         https://tplhydprdoic-bmjw2cymlhvm-hy.integration.ocp.oraclecloud.com:443/ic/api/integration/v1/flows/rest/TPL001_GATEPASS_GP2EBS/1.0/tpl/gatepass
  //        */
  //         /* for Dev only
  //         'https://tplhyddevoic-bmjw2cymlhvm-hy.integration.ocp.oraclecloud.com:443/ic/api/integration/v1/flows/rest/TPL001_GATEPASS_GP2EBS/1.0/tpl/gatepass',
  //         */
  //         fetch(
  //           'https://tplhydprdoic-bmjw2cymlhvm-hy.integration.ocp.oraclecloud.com:443/ic/api/integration/v1/flows/rest/TPL001_GATEPASS_GP2EBS/1.0/tpl/gatepass',
  //           requestOptions
  //         )
  //           .then((response) => response.text())
  //           .then((result) => {
  //             const parseItems = JSON.parse(result);
  //             setErpLoading(false);
  //             setErpParseItems(parseItems);

  //             // console.log('parseItems', parseItems);
  //             console.log('parseItems.vendorCode', parseItems.vendor_code);
  //             // console.log('parseItems.itemdetails', parseItems.itemdetails);

  //             if (
  //               parseItems.vendor_code === '' ||
  //               parseItems.vendor_code === null ||
  //               parseItems.vendor_code === undefined
  //             ) {
  //               setVendorERPModal(true);
  //               console.log('Vendor is null', parseItems?.vendor_code);
  //             } else if (
  //               (parseItems.itemdetails.length &&
  //                 parseItems.vendor_code !== '') ||
  //               (parseItems.itemdetails.length &&
  //                 parseItems.vendor_code !== null)
  //             ) {
  //               if (
  //                 formData1?.methodType_id === 1 ||
  //                 requestbyid?.methodType_id === 1
  //                 // requestbyid?.material_id === 1
  //               ) {
  //                 const itemsERP = parseItems?.itemdetails?.map((item) => ({
  //                   item_code: item?.item,
  //                   uom: item?.UOM,
  //                   quantity: item?.quantity,
  //                   item_description: item?.description
  //                 }));
  //                 setFetchedItems(itemsERP);
  //                 //setFetchedItems1(parseItems?.itemdetails);
  //                 //setFetchedType_of_issue(parseItems?.type_of_issue);
  //                 if (
  //                   (documentID === 1 &&
  //                     formData1?.type_id === 2 &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     parseItems?.type_of_issue === 'FREE') ||
  //                   (requestbyid?.document_master_id === 1 &&
  //                     documentID === undefined &&
  //                     requestbyid?.type_id === 2 &&
  //                     formData1?.type_id === undefined &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     parseItems?.type_of_issue === 'FREE') ||
  //                   (requestbyid?.document_master_id === 1 &&
  //                     documentID === undefined &&
  //                     formData1?.type_id === 2 &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     parseItems?.type_of_issue === 'FREE') ||
  //                   (documentID === 1 &&
  //                     requestbyid?.type_id === 2 &&
  //                     formData1?.type_id === undefined &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     parseItems?.type_of_issue === 'FREE')
  //                 ) {
  //                   setDisable(false);
  //                   message.success('Item details fetched successfully');
  //                   handleGetERPVendorData(parseItems);
  //                 } else if (
  //                   (documentID === 1 &&
  //                     formData1?.type_id === 1 &&
  //                     formData1?.category_id === 1 &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     parseItems?.type_of_issue === 'FREE') ||
  //                   (requestbyid?.document_master_id === 1 &&
  //                     documentID === undefined &&
  //                     requestbyid?.type_id === 1 &&
  //                     formData1?.type_id === undefined &&
  //                     requestbyid?.category_id === 1 &&
  //                     formData1?.category_id === undefined &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     parseItems?.type_of_issue === 'FREE') ||
  //                   (requestbyid?.document_master_id === 1 &&
  //                     documentID === undefined &&
  //                     formData1?.type_id === 1 &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     parseItems?.type_of_issue === 'FREE') ||
  //                   (documentID === 1 &&
  //                     requestbyid?.type_id === 1 &&
  //                     formData1?.type_id === undefined &&
  //                     //formData1?.category_id === 1 &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     parseItems?.type_of_issue === 'FREE') ||
  //                   (documentID === 1 &&
  //                     documentID === undefined &&
  //                     requestbyid?.type_id === 1 &&
  //                     formData1?.type_id === undefined &&
  //                     requestbyid?.category_id === 1 &&
  //                     formData1?.category_id === undefined &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     parseItems?.type_of_issue === 'FREE')
  //                 ) {
  //                   setDisable(false);
  //                   message.success('Item details fetched successfully');
  //                   handleGetERPVendorData(parseItems);
  //                 } else if (
  //                   (documentID === 1 &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     formData1?.category_id === 2 &&
  //                     parseItems?.type_of_issue === 'CHARGEABLE' &&
  //                     formData1?.type_id === 2 &&
  //                     parseItems?.type === 'CONTRACTOR') ||
  //                   (documentID === 1 &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     formData1?.category_id === 2 &&
  //                     parseItems?.type_of_issue === 'CHARGEABLE' &&
  //                     formData1?.type_id === 2 &&
  //                     parseItems?.type === 'CLIENT') ||
  //                   (requestbyid?.document_master_id === 1 &&
  //                     documentID === undefined &&
  //                     requestbyid?.category_id === 2 &&
  //                     formData1?.category_id === undefined &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     formData1?.type_id === undefined &&
  //                     requestbyid?.type_id === 2 &&
  //                     parseItems?.type_of_issue === 'CHARGEABLE' &&
  //                     parseItems?.type === 'CONTRACTOR') ||
  //                   (requestbyid?.document_master_id === 1 &&
  //                     documentID === undefined &&
  //                     requestbyid?.type_id === 2 &&
  //                     formData1?.type_id === undefined &&
  //                     requestbyid?.category_id === 2 &&
  //                     formData1?.category_id === undefined &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     parseItems?.type_of_issue === 'CHARGEABLE' &&
  //                     parseItems?.type === 'CLIENT') ||
  //                   (requestbyid?.document_master_id === 1 &&
  //                     documentID === undefined &&
  //                     formData1?.category_id === 2 &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     formData1?.type_id === undefined &&
  //                     requestbyid?.type_id === 2 &&
  //                     parseItems?.type_of_issue === 'CHARGEABLE' &&
  //                     parseItems?.type === 'CONTRACTOR') ||
  //                   (requestbyid?.document_master_id === 1 &&
  //                     documentID === undefined &&
  //                     requestbyid?.type_id === 2 &&
  //                     formData1?.type_id === undefined &&
  //                     formData1?.category_id === 2 &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     parseItems?.type_of_issue === 'CHARGEABLE' &&
  //                     parseItems?.type === 'CLIENT') ||
  //                   (requestbyid?.document_master_id === 1 &&
  //                     documentID === undefined &&
  //                     requestbyid?.category_id === 2 &&
  //                     formData1?.category_id === undefined &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     formData1?.type_id === 2 &&
  //                     parseItems?.type_of_issue === 'CHARGEABLE' &&
  //                     parseItems?.type === 'CONTRACTOR') ||
  //                   (requestbyid?.document_master_id === 1 &&
  //                     documentID === undefined &&
  //                     formData1?.type_id === 2 &&
  //                     requestbyid?.category_id === 2 &&
  //                     formData1?.category_id === undefined &&
  //                     parseItems.itemdetails.length > 0 &&
  //                     parseItems?.type_of_issue === 'CHARGEABLE' &&
  //                     parseItems?.type === 'CLIENT')
  //                 ) {
  //                   setDisable(false);
  //                   message.success('Item details fetched successfully');
  //                   handleGetERPVendorData(parseItems);
  //                 } else if (
  //                   (documentID === 3 && parseItems.itemdetails.length > 0) ||
  //                   (documentID === undefined &&
  //                     requestbyid?.document_master_id === 3 &&
  //                     parseItems.itemdetails.length > 0)
  //                 ) {
  //                   setDisable(false);
  //                   message.success('Item details fetched successfully');
  //                   // handleGetERPVendorData(parseItems);
  //                 } else if (
  //                   (documentID === 2 && parseItems?.itemdetails?.length > 0) ||
  //                   (documentID === undefined &&
  //                     requestbyid?.document_master_id === 2 &&
  //                     parseItems?.itemdetails?.length > 0)
  //                 ) {
  //                   setDisable(false);
  //                   message.success('Item details fetched successfully');
  //                   handleGetERPVendorData(parseItems);
  //                 } /*else if (
  //             documentID === 2 &&
  //             parseItems?.itemdetails?.length === 0
  //             //formData1?.category_id === 2 &&
  //           ) {
  //             console.log('Condition-6666');
  //             message.error(
  //               'Item details could not fetched successfully. Please enter the correct document number'
  //             );
  //             setDisable(true);
  //             console.log('disable--Condition-6666', disable);
  //           } */ else {
  //                   setVendorERPModal(true);
  //                   console.log('OUTER_else_Trigger@@@@@@');
  //                   console.log('MODEL3333333333');
  //                   // handleGetERPVendorData(parseItems);

  //                   /*  const payload = { search: parseItems?.vendor_code };

  //               dispatch(getAllVendors(payload)).then((response) => {
  //               if (response?.payload?.success) {
  //                 // const vendors = response?.payload?.data?.filter(
  //                 //   (item) => item.vendorCode == parseItems?.vendor_code
  //                 // );
  //                 console.log('Condition-77777');
  //                 console.log('ERPvendors', response?.payload?.data[0]);
  //                 setVendorERP(response?.payload?.data[0]);

  //                 form.setFieldsValue({
  //                   vendor_id: response?.payload?.data[0]?.id
  //                 });
  //                 dispatch(
  //                   getAllVendorLocation({
  //                     vendorId: response?.payload?.data[0]?.id
  //                   })
  //                 ).then((response) => {
  //                   if (response?.payload?.success) {
  //                     parseItems?.vendor_location !== '';
  //                     {
  //                       const vendor_location = response?.payload?.data?.filter(
  //                         (item) =>
  //                           item.vendorSite == parseItems?.vendor_location
  //                       );
  //                       form.setFieldsValue({
  //                         vendor_location: vendor_location[0]?.id
  //                       });
  //                       setVendorAddressValue(vendor_location[0]?.addressLine1);
  //                     }
  //                   }
  //                 });
  //               }
  //             }); */

  //                   //  console.log(vendors);
  //                 }
  //               }
  //               // setOnProceedValue(value);
  //               // setSelectedTabId(filters?.[1]?.id);
  //             } else if (
  //               documentID === 2 &&
  //               parseItems.itemdetails?.length === 0
  //               //formData1?.category_id === 2 &&
  //             ) {
  //               message.error(
  //                 'Item details could not fetched successfully. Please enter the correct document number'
  //               );
  //               setDisable(true);
  //             } else if (
  //               documentID === 3 &&
  //               parseItems.itemdetails.length === 0
  //             ) {
  //               setDisable(true);
  //               message.error(
  //                 'Item details could not fetched successfully. Please enter the correct document number'
  //               );
  //             } else {
  //               //message.error(parseItems.message);
  //               message.error(
  //                 'Item details could not fetched successfully. Please enter the correct document number'
  //               );
  //             }
  //           })

  //           .catch((error) => {
  //             const parseItems = JSON.parse(error);
  //             if (
  //               (parseItems.itemdetails.length === 0 && documentID === 3) ||
  //               (parseItems.itemdetails.length === 0 && documentID === 2)
  //             ) {
  //               message.error(
  //                 'Item details could not fetched successfully. Please enter the correct document number'
  //               );
  //             } else {
  //               // message.error(parseItems.message);
  //               message.error(
  //                 'Item details could not fetched successfully. Please enter the correct document number'
  //               );
  //             }
  //           });
  //       } else {
  //         setCheck_DocumentNumberLoading(false);
  //         message.error(
  //           'The document number has already been used for another Gate Pass request'
  //         );
  //         // form.setFieldsValue({
  //         //   doc_number: undefined
  //         // });
  //         // window.location.reload();
  //       }
  //     }
  //   );
  // };

  const onFinishTabOne = (value) => {
    console.log('TAB!!111:', value);
    value['file'] = [supportingDoc];
    value['address'] = vendorAddress[0]?.addressLine1;
    value['material_id'] =
      formData1?.methodType_id === 1 || requestbyid?.material_id === 1
        ? materials[0]?.id
        : formData1?.material_id;
    setFormTabeOneData(value);
    setSelectedTabId(filters?.[1]?.id);
  };

  useEffect(() => {
    if (formData1?.methodType_id === 1 || requestbyid?.methodType_id === 1) {
      form.setFieldsValue({
        material_id: materials[0]?.material_name
      });
    }
  }, [formData1?.methodType_id === 1, requestbyid?.methodType_id === 1]);

  useEffect(() => {
    if (
      formData1?.type_id === 1 ||
      (formData1?.type_id === undefined && requestbyid?.type_id === 1)
    ) {
      formData1['category_id'] = categories[0]?.id;
      form.setFieldsValue({
        category_id: categories[0]?.id
      });
    }
  }, [formData1?.type_id, requestbyid?.type_id === 1]);

  useEffect(() => {
    if (formData1?.type_id === 2) {
      form.setFieldsValue({
        category_id: undefined
      });
    }
  }, [formData1?.type_id]);

  //console.log('formTabeOneData', formTabeOneData); //don't make comment

  /*  const onFinishItem = (value) => {
    setFormItemData(value);
    setSelectedTabId(filters?.[2]?.id);
  }; */

  useEffect(() => {
    setSelectedTabId(filters?.[0]?.id || null);
  }, []);

  const handleOnClick = () => {
    let docsCopy = [...newDocs];

    docsCopy.push({
      supporting_docs: '',
      docs: ''
    });

    setNewDocs(docsCopy);
  };

  const handleRemove = (e, i) => {
    let uploadeddocs = [...newDocs];
    uploadeddocs.splice(i, 1);
    setNewDocs(uploadeddocs);
  };

  const handlechangeDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: e.target.files[0] };

    setSupportingDoc(newDocValues);
  };
  const handleclearDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: '' };

    setSupportingDoc(newDocValues);
  };
  const handleChangeSearch = (e) => {
    const value = e.target.value;
    //console.log('SEARCH_value', value);
    setItemSearchValue(value);
    // dispatch(searchvalues(value))
    // user?.user_details?.role === 'approver'
    //   ? dispatch(searchvalues(value))
    //   : dispatch(searchvaluesIssuer(value));
  };

  const debounceFetcherProject = useMemo(() => {
    const loadOptions = (projectSearch) => {
      setProjectSearch(projectSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const debounceFetcherProjectLocation = useMemo(() => {
    const loadOptions = (projectLocationSearch) => {
      setProjectLocationSearch(projectLocationSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (vendorSearch) => {
      setVendorSearch(vendorSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  const handleDeleteUploadedDoc = (id) => {
    const docsID = [];
    docsID?.push(id);
    const payload = {
      gpid: params.id,
      docs: docsID
    };
    dispatch(delete_uploaded_docs_sap(payload)).then((response) => {
      if (response?.payload?.success) {
        dispatch(getrequesterdata(params?.id)).then((response) => {
          if (response.payload.success) {
            setTempDocList(response?.payload?.data?.SapDocuments);
          }
        });
      }
    });
  };

  /*----For Item Template------------*/
  useEffect(() => {
    form.setFieldsValue({
      items: itemsJsonData?.map((item) => ({
        item_code: item?.Item_Code,
        uom: item?.UOM,
        quantity: item?.Item_Quantity,
        item_description: item?.Item_Description,
        value: parseFloat(item?.Item_value).toFixed(2),
        item_remarks: item?.Item_Remarks
      }))
    });
  }, [itemsJsonData]);

  /// console.log("itemsJsonData",itemsJsonData);

  const handleItemsTemplate = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const workSheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(workSheet);
    setItemsJsonData(jsonData);
    console.log(fileName);
    // e.target.value = null;
  };
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };
  const handleFetchDocument = (fileName) => {
    window?.open(
      `${process.env.REACT_APP_API_URL}/fetch-file?fileName=${fileName}`,
      '_blank'
    );
  };
  return (
    <>
      <div className={`mb-4 ${styles.container}`}>
        <div className={`${styles.page_title}`}>
          <span>Edit the request id:{requestbyid?.ticket}</span>
          <span style={{ float: 'right', marginRight: '20px' }}>
            {' '}
            <a
              href={
                user?.user_details?.roles[0] === 'issuer'
                  ? '/issuer-requester-sap'
                  : '/requesters-sap-dashboard'
              }
            >
              <Button className={`${styles.back_button}`}>
                <ArrowLeftOutlined className={`${styles.back_icon}`} />
                Back
              </Button>
            </a>
          </span>
        </div>
        <div className={`${styles.page_sub_title}`}>
          Enter the following fields to create the request to issue the gate
          pass
        </div>
        <div>
          <Radio.Group
            value={selectedTabId}
            size="middle"
            optionType="button"
            buttonStyle="solid"
            className={`${styles.tab_btn_style}`}
          >
            {filters.map((item, id) => {
              return (
                <Radio.Button
                  name="radiogroup"
                  defaultValue={item[0]?.value}
                  value={item?.id}
                  key={id}
                  className={`${styles.tab_broder_style}
                    ${selectedTabId === item?.id ? ` ${styles.selected}` : ''}
                  `}
                  onClick={() => {
                    setSelectedTabId(item?.id);
                  }}
                >
                  {item.name}
                </Radio.Button>
              );
            })}
          </Radio.Group>
          <Divider style={{ margin: 0, marginBottom: '20px' }}></Divider>
        </div>
        <Spin spinning={getrequestByidLoading}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className={`${styles.formstyles}`}
            initialValues={{ items: [''] }}
          >
            {selectedTabId === 1 && (
              <div>
                <Form form={form} onFinish={onFinishTabOne} autoComplete="off">
                  <div
                    style={{
                      fontSize: '16px',
                      color: '#2364ad',
                      fontFamily: 'SFProText Bold',
                      marginBottom: '10px'
                    }}
                  >
                    General details:
                  </div>
                  <Row gutter={16}>
                    <Col span={6}>
                      <FormItem
                        label="Method"
                        name="methodType_id"
                        rules={[
                          {
                            required: true,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          disabled //</FormItem>={requestbyid?.SapMethodTypeMasterId === 1}
                          placeholder="Select Method"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                          onChange={(value) =>
                            handleFormDataOnChange('methodType_id', value)
                          }
                        >
                          {methods_sap?.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {item?.method_type}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    {formData1?.methodType_id === 1 ||
                    (formData1?.methodType_id === undefined &&
                      requestbyid?.SapMethodTypeMasterId === 1) ? (
                      <>
                        <Col span={6}>
                          <FormItem
                            label="Plant Code and Name"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              disabled
                              value={`${requestbyid?.SapPlant?.plant_code} (${requestbyid?.SapPlant?.plant_name})`}
                              allowClear
                              placeholder="Select Plant Code and Name"
                              onChange={(value) =>
                                handleFormDataOnChange('project_id', value)
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col span={6}>
                          <FormItem
                            label="Storage Location"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              disabled
                              value={`${requestbyid?.SapStorageLocation?.code} (${requestbyid?.SapStorageLocation?.name})`}
                              allowClear
                              placeholder="Select Storage Location"
                              onChange={(value) =>
                                handleFormDataOnChange('location_id', value)
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col span={6}>
                          <FormItem
                            label="GST Number"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              value={requestbyid?.SapPlant?.gst}
                              allowClear
                              placeholder="Please enter GST number"
                              disabled
                              onChange={(e) =>
                                handleFormDataOnChange('gst', e.target.value)
                              }
                            />
                          </FormItem>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={6}>
                          <FormItem
                            label="Plant Code and Name"
                            name="project_id"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                            // onChange={(e) => onChangeProject(e.target.value)}
                          >
                            <Select
                              // disabled={
                              //   formData1?.methodType_id === 1 &&
                              //   requestbyid?.vendor_master_id !== null
                              // }
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                // console.log('input', input);
                                return option?.children
                                  ?.toString()
                                  ?.toLowerCase()
                                  ?.includes(input?.toLowerCase());
                              }}
                              onSearch={debounceFetcherProject}
                              onChange={(value) =>
                                handleFormDataOnChange('project_id', value)
                              }
                              placeholder="Select Plant Code and Name"
                            >
                              {plants?.map((item, index) => {
                                return (
                                  <Option value={item.id} key={index}>
                                    {`${item?.plant_code || ''} (${
                                      item?.plant_name
                                    })`}
                                  </Option>
                                );
                              })}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col span={6}>
                          <FormItem
                            label="Storage Location"
                            name="location_id"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Select
                              showSearch
                              // disabled={
                              //   requestbyid?.methodType_id === 1 &&
                              //   requestbyid?.vendor_master_id !== null
                              // }
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                // console.log('input', input);
                                return option?.children
                                  ?.toString()
                                  ?.toLowerCase()
                                  ?.includes(input?.toLowerCase());
                              }}
                              onSearch={debounceFetcherProjectLocation}
                              onChange={(value) =>
                                handleFormDataOnChange('location_id', value)
                              }
                              placeholder="Select Storage Location"
                            >
                              {plantLocations?.map((item, index) => {
                                return (
                                  <Option value={item.id} key={index}>
                                    {item?.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col span={6}>
                          <FormItem
                            label="GST Number"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              value={
                                gstNumber === undefined
                                  ? requestbyid?.SapPlant?.gst
                                  : gstNumber
                              }
                              allowClear
                              placeholder="Please enter GST number"
                              disabled
                              onChange={(e) =>
                                handleFormDataOnChange('gst', e.target.value)
                              }
                            />
                          </FormItem>
                        </Col>
                      </>
                    )}
                  </Row>
                  {/*  <Spin spinning={check_DocumentNumberLoading}> */}
                  <Row gutter={16}>
                    <Col span={8}>
                      <FormItem
                        label="Company"
                        //name="company"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input
                          //value={company}
                          value={
                            company === undefined
                              ? requestbyid?.company
                              : company
                          }
                          disabled
                          allowClear
                          placeholder="Enter Company"
                          onChange={(e) =>
                            handleFormDataOnChange('company', e.target.value)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label="Type"
                        name="type_id"
                        rules={[
                          {
                            required: true,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          disabled={requestbyid?.SapMethodTypeMasterId === 1}
                          placeholder="Select Type"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            // console.log('input', input);
                            return option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                          onChange={(value) =>
                            handleFormDataOnChange('type_id', value)
                          }
                        >
                          {types.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {item.type}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label="Category"
                        name="category_id"
                        rules={[
                          {
                            required: true,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          disabled={
                            requestbyid?.SapMethodTypeMasterId === 1 ||
                            (requestbyid?.SapMethodTypeMasterId === 2 &&
                              formData1?.type_id === undefined &&
                              requestbyid?.SapTypeMasterId === 1) ||
                            (requestbyid?.SapMethodTypeMasterId === 2 &&
                              formData1?.type_id === 1)
                            // (formData1?.type_id === undefined &&
                            //   requestbyid?.type_id === 1) ||
                            // formData1?.type_id === 1 ||
                            // (requestbyid?.methodType_id === 1 &&
                            //   requestbyid?.vendor_master_id !== null)
                          }
                          placeholder="Select Category"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                          onChange={(value) =>
                            handleFormDataOnChange('category_id', value)
                          }
                        >
                          {categories.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {item.category}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>{' '}
                  <Row gutter={16}>
                    {docVisibility && (
                      <>
                        <Col span={8}>
                          <FormItem
                            label="Reference documents"
                            name="document_id"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Select
                              disabled={
                                requestbyid?.SapMethodTypeMasterId === 1
                              }
                              placeholder="Select Reference Document"
                              onChange={handleChangedocument}
                            >
                              {docs
                                ? docs.map((item, index) => {
                                    return (
                                      <Option value={item.id} key={index}>
                                        {item.document_name}
                                      </Option>
                                    );
                                  })
                                : ''}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Year"
                            name="sap_year"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.date_styles}`}
                          >
                            <DatePicker
                              disabled={
                                requestbyid?.SapMethodTypeMasterId === 1
                              }
                              format={'YYYY'}
                              picker="year"
                              onChange={(_, dateValue) =>
                                handleFormDataOnChange('sap_year', dateValue)
                              }
                              disabledDate={(current) => {
                                const currentYear = moment().year();
                                const previousYear = moment()
                                  .add(-1, 'year')
                                  .year();
                                const nextYear = moment().add(1, 'year').year();
                                const selectedYear = moment(current).year();
                                return (
                                  selectedYear !== currentYear &&
                                  selectedYear !== nextYear &&
                                  selectedYear !== previousYear
                                );
                              }}
                            ></DatePicker>
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Document number"
                            name="doc_number"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                            onChange={(e) => handleChangeDocNumber(e)}
                          >
                            <Input
                              disabled
                              // disabled={
                              //   requestbyid?.SapMethodTypeMasterId === 1
                              // }
                              className={`${styles.input_lineHeight}`}
                              placeholder="Enter document number"
                            />
                          </FormItem>
                          <a
                            style={{
                              cursor: 'pointer',
                              float: 'right',
                              marginTop: '-20px',
                              fontWeight: '600'
                            }}
                            // onClick={() =>
                            //   (formData1?.category_id === 1 &&
                            //     documentID === 2) ||
                            //   (formData1?.category_id === 1 &&
                            //     documentID === 3) ||
                            //   (formData1?.category_id === undefined &&
                            //     requestbyid?.category_id === 1 &&
                            //     documentID === 2) ||
                            //   (formData1?.category_id === 1 &&
                            //     documentID === undefined &&
                            //     requestbyid?.document_master_id === 2) ||
                            //   (formData1?.category_id === undefined &&
                            //     documentID === undefined &&
                            //     requestbyid?.document_master_id === 2 &&
                            //     requestbyid?.category_id === 1) ||
                            //   (formData1?.category_id === undefined &&
                            //     requestbyid?.category_id === 1 &&
                            //     documentID === 3) ||
                            //   (formData1?.category_id === 1 &&
                            //     documentID === undefined &&
                            //     requestbyid?.document_master_id === 3) ||
                            //   (formData1?.category_id === undefined &&
                            //     documentID === undefined &&
                            //     requestbyid?.document_master_id === 3 &&
                            //     requestbyid?.category_id === 1)
                            //     ? ''
                            //     : handleGetERPData()
                            // }
                            disabled
                          >
                            {/* Fetch */}
                          </a>
                        </Col>
                      </>
                    )}
                  </Row>
                  {/*  </Spin> */}
                  <Divider className={`${styles.divider_style}`}></Divider>
                  <div
                    style={{
                      fontWeight: 600,
                      color: '#000000',
                      fontSize: '14px',
                      lineHeight: ' 20px',
                      fontFamily: 'Cerebri Sans'
                    }}
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        color: '#2364ad',
                        fontFamily: 'SFProText Bold'
                      }}
                    >
                      Shipped to:
                    </span>
                  </div>
                  <br />
                  {/*  <Spin spinning={erpLoading}> 
                  <Spin spinning={erpVendorLoading}>*/}
                  {formData1?.methodType_id === 1 ||
                  (formData1?.methodType_id === undefined &&
                    requestbyid?.SapMethodTypeMasterId === 1) ? (
                    <>
                      <Row gutter={16}>
                        <Col span={8}>
                          <FormItem
                            label={'Vendor name and code'}
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              disabled
                              value={`${requestbyid?.Vendor_Master?.vendorCode} (${requestbyid?.Vendor_Master?.vendorName})`}
                              allowClear
                              placeholder={'Vendor name and code'}
                              onChange={(value) =>
                                handleFormDataOnChange('vendor_id', value)
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Location"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              disabled
                              value={`${requestbyid?.vendor_location_name} `}
                              allowClear
                              placeholder="Enter Location"
                              onChange={(value) =>
                                handleFormDataOnChange('vendor_location', value)
                              }
                            />
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="Address"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              disabled
                              value={`${requestbyid?.vendor_location_address} `}
                              allowClear
                              placeholder="Enter Address"
                              onChange={(e) =>
                                handleFormDataOnChange(
                                  'vendor_address',
                                  e.target.value
                                )
                              }
                            />
                          </FormItem>
                        </Col>
                      </Row>{' '}
                    </>
                  ) : (
                    <Row gutter={16}>
                      <Col span={8}>
                        <FormItem
                          label="Vendor name and code"
                          name="vendor_id"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          {/* <Select
                            SelectValue={requestbyid?.Vendor_Master?.vendorName}
                            showSearch
                            allowClear={true}
                            optionFilterProp="children"
                            placeholder="Select Vendor Name"
                            //onSearch={inputVenderName}
                            onSearch={debounceFetcher}
                            onChange={(value) =>
                              handleFormDataOnChange('vendor_id', value)
                            }
                            filterOption={(input, option) => {
                              return (
                                option.key
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0 ||
                                option.title
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {vendorList?.map((item, index) => (
                              <Option
                                title={item.vendorName}
                                key={index}
                                value={item?.id}
                              >
                                <span
                                  style={{
                                    fontSize: '12px',
                                    textTransform: 'uppercase'
                                  }}
                                >
                                  <span style={{ marginRight: '4px' }}>
                                    {item?.vendorName}
                                  </span>
                                  <span>
                                    {item?.vendorCode === null ||
                                    item?.vendorCode === undefined
                                      ? ''
                                      : `(${item?.vendorCode})`}
                                  </span>
                                </span>
                              </Option>
                            ))}
                          </Select> */}
                          <Select
                            SelectValue={requestbyid?.Vendor_Master?.vendorName}
                            placeholder="Select vendor name and code"
                            //mode="multiple"
                            allowClear={true}
                            showSearch
                            optionFilterProp="children"
                            onSearch={debounceFetcher}
                            onChange={(value) =>
                              handleFormDataOnChange('vendor_id', value)
                            }
                          >
                            {vendorList?.map((item) => (
                              <Option
                                key={item?.id}
                                value={item?.id}
                                style={{
                                  fontSize: '12px',
                                  textTransform: 'uppercase'
                                }}
                              >{`${item.vendorName} ${
                                item?.vendorCode ? `(${item?.vendorCode})` : ''
                              }`}</Option>
                            ))}
                          </Select>
                        </FormItem>
                      </Col>
                      {(formData1?.vendor_id === 'other' &&
                        formData1?.methodType_id === 2) ||
                      (requestbyid?.SapMethodTypeMasterId === 2 &&
                        formData1?.vendor_id === 'other') ? (
                        ''
                      ) : (
                        <>
                          <Col span={8}>
                            <FormItem
                              label="Location"
                              name="vendor_location"
                              rules={[
                                formData1?.vendor_id === 'other'
                                  ? {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  : {
                                      required: true,
                                      message: 'Required field'
                                    }
                              ]}
                              className={`${styles.select_styles}`}
                            >
                              <Select
                                disabled={formData1?.vendor_id === 'other'}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                  return option?.children
                                    ?.toString()
                                    ?.toLowerCase()
                                    ?.includes(input?.toLowerCase());
                                }}
                                onChange={(value) =>
                                  handleFormDataOnChange(
                                    'vendor_location',
                                    value
                                  )
                                }
                                placeholder="Select Location"
                              >
                                {vendorLocation.map((item, index) => {
                                  return (
                                    <Option value={item.id} key={index}>
                                      <span
                                        style={{
                                          fontSize: '12px',
                                          textTransform: 'uppercase'
                                        }}
                                      >
                                        {' '}
                                        {item.vendorSite}, {item.city},{' '}
                                        {item.state}
                                      </span>
                                    </Option>
                                  );
                                })}
                              </Select>
                            </FormItem>
                          </Col>
                          {/*  <Col span={8}>
                                <FormItem
                                  label="Address"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles}`}
                                >
                                  <Input
                                    disabled
                                    value={
                                      vendorAddressValue === undefined
                                        ? requestbyid?.vendor_location_address
                                        : vendorAddressValue
                                    }
                                    placeholder="Enter Address"
                                    defaultValue={
                                      requestbyid?.vendor_location_address
                                    }
                                    allowClear
                                    onChange={(e) =>
                                      handleFormDataOnChange(
                                        'vendor_address',
                                        e.target.value
                                      )
                                    }
                                  />
                                </FormItem>
                              </Col> */}
                          <Col span={8}>
                            <FormItem
                              label="Address"
                              name="vendor_location_address"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.select_styles}`}
                            >
                              <Select
                                disabled={formData1?.vendor_id === 'other'}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                  return option?.children
                                    ?.toString()
                                    ?.toLowerCase()
                                    ?.includes(input?.toLowerCase());
                                }}
                                onChange={(value) =>
                                  handleFormDataOnChange(
                                    'vendor_location_address',
                                    value
                                  )
                                }
                                placeholder="Select Address"
                              >
                                {vendorLocationAddressList?.map(
                                  (item, index) => {
                                    return (
                                      <Option
                                        value={item?.vendorSiteAddress}
                                        key={index}
                                      >
                                        <span
                                          style={{
                                            fontSize: '12px',
                                            textTransform: 'uppercase'
                                          }}
                                        >
                                          {' '}
                                          {item?.vendorSiteAddress}
                                        </span>
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            </FormItem>
                          </Col>
                        </>
                      )}
                    </Row>
                  )}
                  {formData1?.methodType_id === 1 ? (
                    ''
                  ) : (formData1?.vendor_id === 'other' &&
                      formData1?.methodType_id === 2) ||
                    (requestbyid?.SapMethodTypeMasterId === 2 &&
                      formData1?.vendor_id === 'other') ? (
                    <Row gutter={16}>
                      <Col span={8}>
                        <FormItem
                          label="Vendor name"
                          name="vendorName"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            allowClear
                            placeholder="Please enter Vendor Name"
                            onChange={handleOtherVendorName}
                          />
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="Location"
                          name="vendorLocation"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            allowClear
                            placeholder="Please enter Location"
                            onChange={handleOtherVendorLocation}
                          />
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="Address"
                          name="vendorAddress"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            // value={vendorAddress[0]?.addressLine1}
                            allowClear
                            placeholder="Please enter Address"
                            onChange={handleOtherVendorAddress}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                  {/*  </Spin>
                   </Spin> */}
                  <Divider className={`${styles.divider_style}`}></Divider>
                  <div
                    style={{
                      fontWeight: 600,
                      color: '#000000',
                      fontSize: '14px',
                      lineHeight: ' 20px',
                      fontFamily: 'Cerebri Sans'
                    }}
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        color: '#2364ad',
                        fontFamily: 'SFProText Bold'
                      }}
                    >
                      {' '}
                      Shipment details:
                    </span>
                  </div>
                  <br />
                  <Row gutter={16}>
                    <Col span={8}>
                      <FormItem
                        label="Date"
                        name="date"
                        rules={[
                          {
                            required: true,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.date_styles}`}
                      >
                        <DatePicker
                          format={'DD-MMM-YYYY'}
                          onChange={(_, dateValue) =>
                            handleFormDataOnChange('date', dateValue)
                          }
                          disabledDate={(current) => {
                            return (
                              moment().add(-1, 'days') >= current ||
                              moment().add(0, 'month') <= current
                            );
                          }}
                        ></DatePicker>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      {
                        // formData1?.methodType_id === 1 ||
                        // (formData1?.methodType_id === undefined &&
                        requestbyid?.SapMethodTypeMasterId === 1 ? (
                          <FormItem
                            label="Material belongs to"
                            // name="material_id"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.input_styles}`}
                          >
                            <Input
                              className={`${styles.materialInput_styles}`}
                              disabled
                              value={materials[0]?.material_name}
                              //defaultValue={requestbyid?.material_id}
                              // placeholder="Please enter name"
                              onChange={(e) =>
                                handleFormDataOnChange(
                                  'material_id',
                                  e.target.value
                                )
                              }
                            />
                          </FormItem>
                        ) : (
                          <FormItem
                            label="Material belongs to"
                            name="material_id"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.select_styles}`}
                          >
                            <Select
                              placeholder="Select Material"
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                return option?.children
                                  ?.toString()
                                  ?.toLowerCase()
                                  ?.includes(input?.toLowerCase());
                              }}
                              onChange={(value) =>
                                handleFormDataOnChange('material_id', value)
                              }
                              // disabled={
                              //   formData1?.methodType_id === 1 ||
                              //   (formData1?.methodType_id === undefined &&
                              //     requestbyid?.SapMethodTypeMasterId === 1)
                              // }
                            >
                              {materials?.map((item, index) => {
                                return (
                                  <Option value={item.id} key={index}>
                                    {item.material_name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </FormItem>
                        )
                      }
                    </Col>
                    {/* formData1?.type_id === 1 || requestbyid?.type_id?.id === 1 */}
                    {(formData1?.type_id === undefined &&
                      formData1?.category_id === undefined &&
                      requestbyid?.SapCategoryMasterId === 1) ||
                    (formData1?.type_id === undefined &&
                      requestbyid?.SapTypeMasterId === 1) ||
                    formData1?.category_id === 1 ||
                    formData1?.type_id === 1 ? (
                      /* requestbyid?.category_id === 1 ||
                      requestbyid?.expected_date_return !== null */ <>
                        <Col span={8}>
                          <FormItem
                            label="Expected date of return"
                            name="expected_date_return"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.date_styles}`}
                          >
                            <DatePicker
                              onChange={(_, dateValue) =>
                                handleFormDataOnChange(
                                  'expected_date_return',
                                  dateValue
                                )
                              }
                              disabledDate={(current) => {
                                return moment().add(-1, 'days') >= current;
                              }}
                              format={'DD-MMM-YYYY'}
                            ></DatePicker>
                          </FormItem>
                        </Col>
                        {/*  <Col span={6}>
                          <FormItem
                            label="Actual return date"
                            name="actual_return_date"
                            rules={[
                              {
                                required: false,
                                message: 'Required field'
                              }
                            ]}
                            className={`${styles.date_styles}`}
                          >
                            <DatePicker
                              disabled
                              defaultValue={moment()}
                              format={'DD-MMM-YYYY'}
                              onChange={(_, dateValue) =>
                                handleFormDataOnChange(
                                  'actual_return_date',
                                  dateValue
                                )
                              }
                            ></DatePicker>
                          </FormItem>
                        </Col> */}
                      </>
                    ) : (
                      ''
                    )}
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <FormItem
                        label="Reason for sending"
                        name="reason_id"
                        rules={[
                          {
                            required: true,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          placeholder="Select reason"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                          onChange={(value) =>
                            handleFormDataOnChange('reason_id', value)
                          }
                        >
                          {reasons?.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {item.reason_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        label="Detailed reason"
                        name="detailed_reason"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.textarea_styles}`}
                      >
                        <TextArea
                          allowClear
                          placeholder="Enter detailed reason"
                          size="small"
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'detailed_reason',
                              e.target.value
                            )
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Divider className={`${styles.divider_style}`}></Divider>
                  <Row gutter={16}>
                    <Col span={6}>
                      <FormItem
                        label="Vehicle number"
                        name="vehicle_number"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                          /*  {
                              validator: async (_, value) => {
                                if (value?.length <= 10) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    'Not exceed more than 10 characters'
                                  );
                                }
                              }
                            } */
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input
                          allowClear
                          maxLength={20}
                          placeholder="Please enter vehicle number"
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'vehicle_number',
                              e.target.value
                            )
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem
                        label="LR number"
                        name="lr_number"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input
                          allowClear
                          placeholder="Please enter LR number"
                          onChange={(e) =>
                            handleFormDataOnChange('lr_number', e.target.value)
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem
                        label="LR Date"
                        name="lr_date"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.date_styles}`}
                      >
                        <DatePicker
                          //disabled
                          //defaultValue={moment() || ''}
                          format={'DD-MMM-YYYY'}
                          onChange={(_, dateValue) =>
                            handleFormDataOnChange('lr_date', dateValue)
                          }
                          disabledDate={(current) => {
                            return moment().add(0, 'month') <= current;
                          }}
                          // moment().add(-1, 'days') >= current ||
                        ></DatePicker>
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem
                        label="Name of the carrier person"
                        name="carrier_person_name"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.input_styles}`}
                      >
                        <Input
                          allowClear
                          placeholder="Please enter name"
                          onChange={(e) =>
                            handleFormDataOnChange(
                              'carrier_person_name',
                              e.target.value
                            )
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  {/* {requestbyid?.Documents?.length > 0 ? (
                    <div>
                      <div
                        style={{
                          fontWeight: 550,
                          color: 'rgba(0, 0, 0, 0.85)',
                          fontSize: '13px',
                          marginBottom: '8px',
                          flexDirection: 'row'
                        }}
                      >
                        <span> Uploaded documents:</span>
                        <span style={{ textAlign: 'left', marginLeft: '5px' }}>
                          {requestbyid?.Documents?.map((item, index) => (
                            <span
                              style={{ textAlign: 'left', marginLeft: '20px' }}
                              key={index}
                            >
                              <span style={{ marginRight: '2px' }}>
                                {index + 1}.
                              </span>
                              <a
                                href={item?.fileUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item?.originalName}
                              </a>
                            </span>
                          ))}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ''
                  )} */}
                  {tempDocList?.length > 0 ? (
                    <>
                      <div
                        style={{
                          fontWeight: 600,
                          color: 'rgba(0, 0, 0, 0.85)',
                          fontSize: '12px',
                          marginBottom: '8px',
                          flexDirection: 'row',
                          fontFamily: 'SFProText Bold'
                        }}
                      >
                        <span> Uploaded documents</span>

                        <ol>
                          {tempDocList.map((item, index) => (
                            <li
                              key={index}
                              style={{
                                color: '#2364ad !important',
                                marginTop: '2px'
                              }}
                            >
                              <span>
                                {/* <a
                                  className={`${styles.uploadedDoc_style}`}
                                  href={item?.fileUrl}
                                  download
                                >
                                  {item?.originalName}
                                </a> */}
                                <Button
                                  type="link"
                                  onClick={() => {
                                    handleFetchDocument(item?.fileName);
                                  }}
                                >
                                  {item?.originalName}
                                </Button>
                                <DeleteOutlined
                                  className={`${styles.uploadedDoc_icon_style}`}
                                  onClick={() =>
                                    handleDeleteUploadedDoc(item.id)
                                  }
                                />
                              </span>
                            </li>
                          ))}
                        </ol>
                      </div>

                      <Divider />
                    </>
                  ) : null}
                  {newDocs.map((item, index) => (
                    <>
                      {supportingDoc[index] !== '' &&
                      supportingDoc[index] !== undefined ? (
                        <>
                          <div key={index} className={`mb-2`}>
                            <span>
                              <Tag color="geekblue">
                                {supportingDoc[index].name}
                              </Tag>
                              <DeleteOutlined
                                style={{ color: ' rgb(215, 67, 67)' }}
                                onClick={(e) => handleclearDoc(e, index)}
                              />
                            </span>

                            {index === 0 ? (
                              <span>
                                <Button
                                  className={`${styles.upload_button}`}
                                  onClick={handleOnClick}
                                >
                                  Add Document
                                </Button>
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start'
                            }}
                          >
                            <FormItem
                              name="file"
                              rules={[
                                {
                                  required: false,
                                  message: 'Enter type'
                                }
                              ]}
                            >
                              <label
                                htmlFor="file-upload"
                                className={styles.custom_file_upload}
                              >
                                <UploadOutlined /> Upload
                              </label>
                              <Input
                                accept="*"
                                className={styles.upload}
                                type="file"
                                onChange={(e) => handlechangeDoc(e, index)}
                                id="file-upload"
                              />
                            </FormItem>

                            {index === 0 ? (
                              <span>
                                <Button
                                  className={`${styles.upload_button}`}
                                  onClick={handleOnClick}
                                >
                                  Add Document
                                </Button>
                              </span>
                            ) : (
                              <span>
                                <Button
                                  style={{
                                    padding: '6px !important',
                                    fontSize: '11px !important'
                                  }}
                                  className={`${styles.remove_button}`}
                                  onClick={(e) => handleRemove(e, index)}
                                >
                                  Remove
                                </Button>
                              </span>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  ))}
                  <Row gutter={16}>
                    <Col span={24}>
                      <FormItem
                        label="Remarks"
                        name="remarks"
                        rules={[
                          {
                            required: false,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.textarea_styles}`}
                      >
                        <TextArea
                          allowClear
                          placeholder="Enter remarks"
                          size="small"
                          onChange={(e) =>
                            handleFormDataOnChange('remarks', e.target.value)
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <FormItem
                        label="Approver"
                        name="approver_id"
                        rules={[
                          {
                            required: true,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          showSearch
                          placeholder="Select approver"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                          onSearch={debounceFetcherApprover}
                          onChange={(value) =>
                            handleFormDataOnChange('approver_id', value)
                          }
                        >
                          {approvers.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {`${item.name} (${item.email})`}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        label="Issuer"
                        name="issuer_id"
                        rules={[
                          {
                            required: true,
                            message: 'Required field'
                          }
                        ]}
                        className={`${styles.select_styles}`}
                      >
                        <Select
                          showSearch
                          placeholder="Select issuer"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            return option?.children
                              ?.toString()
                              ?.toLowerCase()
                              ?.includes(input?.toLowerCase());
                          }}
                          onSearch={debounceFetcherIssuer}
                          onChange={(value) =>
                            handleFormDataOnChange('issuer_id', value)
                          }
                        >
                          {issuers?.map((item, index) => {
                            return (
                              <Option value={item.id} key={index}>
                                {`${item.name} (${item.email})`}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                    {/*  <Col span={12}>
                        <FormItem
                          label="Issuer"
                          name="issuer_id"
                          rules={[
                            {
                              required: true,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.select_styles}`}
                        >
                          <Select
                            placeholder="Select issuer"
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return option?.children
                                ?.toString()
                                ?.toLowerCase()
                                ?.includes(input?.toLowerCase());
                            }}
                            onSearch={debounceFetcherIssuer}
                            onChange={(value) =>
                              handleFormDataOnChange('issuer_id', value)
                            }
                          >
                            {issuers.map((item, index) => {
                              return (
                                <Option value={item.id} key={index}>
                                  {`${item.name} (${item.email})`}
                                </Option>
                              );
                            })}
                          </Select>
                        </FormItem>
                      </Col> */}
                  </Row>
                </Form>
                <div>
                  <Button
                    htmlType="submit"
                    className={`${styles.first_next_button_style}
                    ${
                      selectedTabId === filters?.[1]?.id
                        ? ` ${styles.selected}`
                        : ''
                    }
                  `}
                    //disabled={disable === true}
                  >
                    Proceed to Item Details
                  </Button>
                </div>
              </div>
            )}
            {selectedTabId === 2 && (
              <div className={`${styles.margin_grey_holder}`}>
                <Row
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <div className={`${styles.items_title}`}>
                      Item details for the gate pass:
                    </div>
                  </div>
                  {formData1?.methodType_id === 1 ||
                  (formData1?.methodType_id === undefined &&
                    requestbyid?.SapMethodTypeMasterId === 1) ? (
                    ''
                  ) : (
                    <div>
                      <Row>
                        <span style={{ marginRight: '15px' }}>
                          <a onClick={downloadTemplate}>
                            Download item import template
                          </a>
                        </span>
                        <span>
                          <label
                            htmlFor="file-upload"
                            className={styles.custom_file_upload}
                          >
                            <UploadOutlined /> Browse
                          </label>
                          <Input
                            value={fileValue}
                            accept="*"
                            className={styles.upload}
                            type="file"
                            onChange={(e) => {
                              setFileValue('');
                              handleItemsTemplate(e);
                            }}
                            id="file-upload"
                          />
                          {/*  {fileName && (
                      <div>
                        <span
                          style={{
                            fontSize: '12px',
                            fontFamily: 'SFProText Regular',
                            color: '#9E9E9E'
                          }}
                        >
                          {fileName}
                        </span>
                      </div>
                    )} */}
                        </span>
                        <span>
                          <SearchBar
                            onChange={(e) => {
                              // e.preventDefault();
                              // console.log('e', e);
                              handleChangeSearch(e);
                            }}
                            className={`${styles.searchbar}`}
                            inputProps={{
                              placeholder: 'Search by item description'
                            }}
                          />
                        </span>
                      </Row>
                    </div>
                  )}
                </Row>

                {formData1?.methodType_id === 1 ||
                (formData1?.methodType_id === undefined &&
                  requestbyid?.SapMethodTypeMasterId === 1) ? (
                  <>
                    {fetchedItems?.map((item, index) => {
                      return (
                        <>
                          <Row gutter={24} key={index}>
                            <Col span={4}>
                              <FormItem
                                label="Item"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles}`}
                              >
                                <Input allowClear value={item?.item_code} />
                              </FormItem>
                            </Col>
                            <Col span={4}>
                              <FormItem
                                label="Description"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles}`}
                              >
                                <Input
                                  allowClear
                                  value={item?.item_description}
                                />
                              </FormItem>
                            </Col>
                            <Col span={4}>
                              <FormItem
                                label="UOM"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles}`}
                              >
                                <Input allowClear value={item?.uom} />
                              </FormItem>
                            </Col>
                            <Col span={4}>
                              <FormItem
                                label="Quantity"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles}`}
                              >
                                <Input allowClear value={item?.quantity} />
                              </FormItem>
                            </Col>
                            <Col span={4}>
                              <FormItem
                                label="Value"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.input_styles}`}
                              >
                                <Input allowClear value={item?.value} />
                              </FormItem>
                            </Col>
                            <Col span={4}>
                              <FormItem
                                label="Item Remarks"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Required field'
                                  }
                                ]}
                                className={`${styles.textarea_styles} ${styles.padding_styles}`}
                              >
                                <TextArea
                                  allowClear
                                  size="small"
                                  value={
                                    item?.item_remarks ? item?.item_remarks : ''
                                  }
                                  onChange={(e) =>
                                    handleRemarksChange(
                                      index,
                                      e.target.value,
                                      'item_remarks'
                                    )
                                  }
                                />
                              </FormItem>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                    <Row gutter={24}>
                      <Col span={4}>
                        <FormItem
                          label="Total Value"
                          rules={[
                            {
                              required: false,
                              message: 'Required field'
                            }
                          ]}
                          className={`${styles.input_styles}`}
                        >
                          <Input
                            allowClear
                            value={parseFloat(
                              requestbyid?.all_items_value
                            ).toFixed(2)}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ''
                )}

                {formData1?.methodType_id === 2 ||
                (formData1?.methodType_id === undefined &&
                  requestbyid?.SapMethodTypeMasterId === 2) ? (
                  <Form.List
                    name="items"
                    className={`${styles.form_list_margin}`}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <>
                            <Row
                              key={key}
                              gutter={16}
                              className={`${styles.grey_holder}  w-100 mt-1`}
                            >
                              <Col span={3}>
                                <FormItem
                                  label="Item Code"
                                  name={[name, 'item_code']}
                                  {...restField}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles} ${styles.padding_styles}`}
                                >
                                  <Input
                                    allowClear
                                    placeholder="Please enter item code"
                                    onChange={(e) =>
                                      handleChangeItem(
                                        e.target.value,
                                        'item_code'
                                      )
                                    }
                                  />
                                </FormItem>
                              </Col>
                              <Col span={4}>
                                <FormItem
                                  label="Item Description"
                                  {...restField}
                                  name={[name, 'item_description']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles} ${styles.padding_styles}`}
                                >
                                  <Input
                                    allowClear
                                    placeholder="Enter item description"
                                    onChange={(e) =>
                                      handleChangeItem(
                                        e.target.value,
                                        'item_description'
                                      )
                                    }
                                  />
                                </FormItem>
                              </Col>

                              <Col span={4}>
                                <FormItem
                                  label="UOM"
                                  {...restField}
                                  name={[name, 'uom']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles} ${styles.padding_styles}`}
                                >
                                  <Input
                                    allowClear
                                    placeholder="Please enter UOM"
                                    onChange={(e) =>
                                      handleChangeItem(e.target.value, 'uom')
                                    }
                                  />
                                </FormItem>
                              </Col>
                              <Col span={4}>
                                <FormItem
                                  label="Quantity"
                                  {...restField}
                                  name={[name, 'quantity']}
                                  rules={[
                                    {
                                      required: true,
                                      message: '' // 'Required field'
                                    },
                                    {
                                      validator: async (_, value) => {
                                        const isValid =
                                          /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/.test(
                                            value
                                          );
                                        if (parseFloat(value) <= 0) {
                                          return Promise.reject(
                                            'Enter valid number'
                                          );
                                        } else if (isValid === value) {
                                          return Promise.resolve();
                                        } else {
                                          return Promise.resolve();
                                        }
                                      }
                                    }
                                    /* {
                                      validator: async (_, value) => {
                                        let num = 0;
                                        if (
                                          num < value
                                        ) {
                                          return Promise.resolve();
                                        } else {
                                          return Promise.reject(
                                            'Enter valid number'
                                          );
                                        }
                                      }
                                    } */
                                  ]}
                                  className={`${styles.input_styles} ${styles.padding_styles}`}
                                >
                                  <Input
                                    type="number"
                                    step="0.001"
                                    min={0}
                                    onKeyPress={preventMinus}
                                    allowClear
                                    placeholder="Please enter Quantity"
                                    onChange={(e) =>
                                      handleChangeItem(
                                        e.target.value,
                                        'quantity'
                                      )
                                    }
                                  />
                                </FormItem>
                              </Col>
                              <Col span={4}>
                                <FormItem
                                  label="Value"
                                  {...restField}
                                  name={[name, 'value']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className={`${styles.input_styles} ${styles.padding_styles}`}
                                >
                                  <Input
                                    type="number"
                                    step="0.001"
                                    min={0}
                                    onKeyPress={preventMinus}
                                    allowClear
                                    placeholder="Please enter value "
                                    // onChange={(e) => {
                                    //   handleChangeItem(
                                    //     parseFloat(e.target.value),
                                    //     `value_${restField?.fieldKey}`,
                                    //     'value',
                                    //     restField?.fieldKey
                                    //   );
                                    // }}
                                    onChange={(e) => {
                                      handleChangeItem(
                                        parseFloat(e.target.value),
                                        `value_${restField?.fieldKey}`,
                                        'value',
                                        restField?.fieldKey
                                      );
                                    }}
                                  />
                                </FormItem>
                              </Col>
                              <Col span={5}>
                                <Row>
                                  <Col span={21}>
                                    <FormItem
                                      label="Item Remarks"
                                      {...restField}
                                      name={[name, 'item_remarks']}
                                      rules={[
                                        {
                                          required: false,
                                          message: 'Required field'
                                        }
                                      ]}
                                      className={`${styles.textarea_styles} ${styles.padding_styles}`}
                                    >
                                      <TextArea
                                        allowClear
                                        placeholder="Enter item remarks"
                                        size="small"
                                        onChange={(e) =>
                                          handleChangeItem(
                                            e.target.value,
                                            'item_remarks'
                                          )
                                        }
                                      />
                                    </FormItem>
                                  </Col>
                                  <Col
                                    span={3}
                                    className={`${styles.delete_icon}`}
                                  >
                                    {fields.length > 1 ? (
                                      <div
                                        className={`${styles.delete_styles}`}
                                      >
                                        <DeleteOutlined
                                          onClick={() => {
                                            setItemKey(restField?.fieldKey);
                                            remove(name);
                                          }}
                                        />
                                      </div>
                                    ) : null}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </>
                        ))}
                        <Row gutter={0}>
                          <Col span={3}>
                            <Form.Item
                              className={`${styles.formItem_marginBottom}`}
                            >
                              <Button
                                className={`${styles.add_button_style}`}
                                onClick={() => {
                                  add();
                                }}
                                icon={<PlusOutlined />}
                              >
                                Add item
                              </Button>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <FormItem
                              label="Total Value"
                              rules={[
                                {
                                  required: false,
                                  message: 'Required field'
                                }
                              ]}
                              className={`${styles.input_styles}`}
                            >
                              <Input allowClear value={totalValue} />
                            </FormItem>
                          </Col>
                        </Row>
                        {/*  <Form.Item
                          className={`${styles.formItem_marginBottom}`}
                        >
                          <Button
                            className={`${styles.add_button_style}`}
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            Add item
                          </Button>
                        </Form.Item> */}
                      </>
                    )}
                  </Form.List>
                ) : (
                  ''
                )}

                <FormItem>
                  <div className={`${styles.button_margin}`}>
                    <Button
                      loading={draftLoading}
                      className={`${styles.first_next_button_style}`}
                      htmlType="submit"
                      size="middle"
                    >
                      Submit
                    </Button>
                  </div>
                </FormItem>
              </div>
            )}
          </Form>
        </Spin>
      </div>
      {vendorERPModal ? (
        <VendorERP_Modal
          vendorERPModal={vendorERPModal}
          setVendorERPModal={setVendorERPModal}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default EditRequestedGP_SAP;
