import React from 'react';
import { Modal } from 'antd';
//import { getLoggedInUser } from 'utilities/helpers';
//import { useDispatch, useSelector } from 'react-redux';

const VendorERP_Modal = ({ vendorERPModal, setVendorERPModal }) => {
  // const user = getLoggedInUser();
  // const { itemHistory } = useSelector((state) => state.edit);
  // const dispatch = useDispatch();
  // const [itemHistotyVisible, setItemHistotyVisible] = useState(false);
  // const [itemId, setItemId] = useState();

  return (
    <>
      <Modal
        title={'Vendor not available : '}
        visible={vendorERPModal}
        width={'620px'}
        onCancel={() => setVendorERPModal(false)}
        onOk={
          () => window.location.reload()
          //setVendorERPModal(false)
        }
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div style={{ fontWeight: '600', fontSize: '14px' }}>
          Vendor code is not available in vendor master. Please proceed with
          Direct Entry.
        </div>
      </Modal>
    </>
  );
};

export default VendorERP_Modal;
