import CustomLayout from '@/common/Layout';
//import { getLoggedInUser } from 'utilities/helpers';
import EditRequestedGP_SAP from './edit_requestedGP';

const EditGP_SAP = () => {
  //const user = getLoggedInUser();
  // console.log('user>>>>', user?.user_details?.role);
  return (
    <>
      <CustomLayout sider="true">
        <EditRequestedGP_SAP />
      </CustomLayout>
    </>
  );
};

export default EditGP_SAP;
