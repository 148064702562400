import apiClient from 'utilities/apiClient';

export const requests = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.requester}/${url}`, payload, true);
};
export const create_request = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.requester}/${url}`,
    payload,
    true,
    'file'
  );
};
export const create_draft = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.requester_create_draft}/${url}`,
    payload,
    true,
    'file'
  );
};
export const create_draft_items = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.requester_create_items}/${url}`,
    payload,
    true,
    'file'
  );
};

//erp_Document/VendorData
export const erp_Document = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.erp_Document}/${url}`, payload, true);
};
