import CustomLayout from '@/common/Layout';
import GatepassRequest from './components/gatepassrequest';

const createGP = () => {
  return (
    <>
      <CustomLayout sider="true">
        <GatepassRequest />
      </CustomLayout>
    </>
  );
};

export default createGP;
