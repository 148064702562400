import { useState } from 'react';
import { Divider, Image, Layout, Menu, Tooltip } from 'antd';
import styles from './index.module.less';
import laurusSiderLogo from './images/logo.png';
import { history } from 'app/history';
import {
  AppstoreAddOutlined,
  LogoutOutlined,
  HomeOutlined,
  FileTextOutlined,
  UserOutlined,
  FileDoneOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Avatar, Typography, Dropdown } from 'antd';
import { useDispatch } from 'react-redux';
import { logout } from '@/features/login/redux/slice';
import LocalStorage from 'utilities/localStorage';
import { useMsal } from '@azure/msal-react';

const { Header, Sider, Content } = Layout;
const { Text } = Typography;
//const { SubMenu } = Menu;

const menuData = [
  {
    key: 1,
    title: 'SAP',
    subMenu: [
      {
        key: 'requesters-sap-dashboard',
        path: '/requesters-sap-dashboard',
        icon: <AppstoreAddOutlined />,
        label: 'Dashboard SAP'
      },
      {
        key: 'reports-sap',
        path: '/reports-sap',
        icon: <FileTextOutlined />,
        label: 'Reports SAP'
      }
    ]
  },
  {
    key: 2,
    title: 'Oracle',
    subMenu: [
      {
        key: 'dashboard',
        path: '/dashboard',
        icon: <AppstoreAddOutlined />,
        label: 'Dashboard'
      },
      {
        key: 'reports',
        path: '/reports',
        icon: <FileTextOutlined />,
        label: 'Reports'
      }
    ]
  }
];
const approverData = [
  {
    key: 1,
    title: 'SAP',
    subMenu: [
      {
        key: 'dashboard-approver-sap',
        path: '/dashboard-approver-sap',
        icon: <AppstoreAddOutlined />,
        label: 'Dashboard SAP'
      },
      {
        key: 'reports-sap',
        path: '/reports-sap',
        icon: <FileTextOutlined />,
        label: 'Reports SAP'
      }
    ]
  },
  {
    key: 2,
    title: 'Oracle',

    subMenu: [
      {
        key: 'gatepass-requests',
        path: '/gatepass-requests',
        icon: <AppstoreAddOutlined />,
        label: 'Dashboard'
      },
      {
        key: 'reports',
        path: '/reports',
        icon: <FileTextOutlined />,
        label: 'Reports'
      }
    ]
  }
];
const issuerData = [
  {
    key: 1,
    title: 'SAP',

    subMenu: [
      {
        key: 'dashboard-issuer-sap',
        path: '/dashboard-issuer-sap',
        icon: <AppstoreAddOutlined />,
        label: 'Dashboard SAP'
      },
      {
        key: 'issuer-requester-sap',
        path: '/issuer-requester-sap',
        icon: <AppstoreAddOutlined />,
        label: 'Requester SAP'
      },
      {
        key: 'reports-sap',
        path: '/reports-sap',
        icon: <FileTextOutlined />,
        label: 'Reports SAP'
      }
    ]
  },
  {
    key: 2,
    title: 'Oracle',
    subMenu: [
      {
        key: 'issuer-gatepass',
        path: '/issuer-gatepass',
        icon: <HomeOutlined />,
        label: 'Dashboard'
      },
      {
        key: 'issuer-requester',
        path: '/issuer-requester',
        icon: <AppstoreAddOutlined />,
        label: 'Requester'
      },
      {
        key: 'reports',
        path: '/reports',
        icon: <FileTextOutlined />,
        label: 'Reports'
      }
    ]
  }
];
const hoData = [
  {
    key: 1,
    title: 'SAP',

    subMenu: [
      {
        key: 'ho-requests_sap',
        path: '/ho-requests_sap',
        icon: <AppstoreAddOutlined />,
        label: 'SAP Dashboard'
      },
      {
        key: 'reports-sap',
        path: '/reports-sap',
        icon: <FileTextOutlined />,
        label: 'Reports SAP'
      }
    ]
  },
  {
    key: 2,
    title: 'Oracle',
    subMenu: [
      {
        key: 'ho-requests',
        path: '/ho-requests',
        icon: <AppstoreAddOutlined />,
        label: 'Dashboard'
      },
      {
        key: 'reports',
        path: '/reports',
        icon: <FileTextOutlined />,
        label: 'Reports'
      }
    ]
  }
];
const menuDataAdmin = [
  {
    key: 1,
    title: 'SAP',
    subMenu: [
      {
        key: 'requesters-sap-dashboard',
        path: '/requesters-sap-dashboard',
        icon: <AppstoreAddOutlined />,
        label: 'Dashboard SAP'
      },
      {
        key: 'reports-sap',
        path: '/reports-sap',
        icon: <FileTextOutlined />,
        label: 'Reports SAP'
      },
      {
        key: 'admin-sap',
        path: '/admin-sap',
        icon: <UserOutlined />,
        label: 'Admin SAP'
      },
      {
        key: 'reports-sap-admin',
        path: '/reports-sap-admin',
        icon: <FileDoneOutlined />,
        label: 'Admin Reports'
      }
    ]
  },
  {
    key: 2,
    title: 'Oracle',
    subMenu: [
      {
        key: 'dashboard',
        path: '/dashboard',
        icon: <AppstoreAddOutlined />,
        label: 'Dashboard'
      },
      {
        key: 'reports',
        path: '/reports',
        icon: <FileTextOutlined />,
        label: 'Reports'
      }
    ]
  }
];
const approverDataAdmin = [
  {
    key: 1,
    title: 'SAP',
    subMenu: [
      {
        key: 'dashboard-approver-sap',
        path: '/dashboard-approver-sap',
        icon: <AppstoreAddOutlined />,
        label: 'Dashboard SAP'
      },
      {
        key: 'reports-sap',
        path: '/reports-sap',
        icon: <FileTextOutlined />,
        label: 'Reports SAP'
      },
      {
        key: 'admin-sap',
        path: '/admin-sap',
        icon: <UserOutlined />,
        label: 'Admin SAP'
      },
      {
        key: 'reports-sap-admin',
        path: '/reports-sap-admin',
        icon: <FileDoneOutlined />,
        label: 'Admin Reports'
      }
    ]
  },
  {
    key: 2,
    title: 'Oracle',

    subMenu: [
      {
        key: 'gatepass-requests',
        path: '/gatepass-requests',
        icon: <AppstoreAddOutlined />,
        label: 'Dashboard'
      },
      {
        key: 'reports',
        path: '/reports',
        icon: <FileTextOutlined />,
        label: 'Reports'
      }
    ]
  }
];
const issuerDataAdmin = [
  {
    key: 1,
    title: 'SAP',

    subMenu: [
      {
        key: 'dashboard-issuer-sap',
        path: '/dashboard-issuer-sap',
        icon: <AppstoreAddOutlined />,
        label: 'Dashboard SAP'
      },
      {
        key: 'issuer-requester-sap',
        path: '/issuer-requester-sap',
        icon: <AppstoreAddOutlined />,
        label: 'Requester SAP'
      },
      {
        key: 'reports-sap',
        path: '/reports-sap',
        icon: <FileTextOutlined />,
        label: 'Reports SAP'
      },
      {
        key: 'admin-sap',
        path: '/admin-sap',
        icon: <UserOutlined />,
        label: 'Admin SAP'
      },
      {
        key: 'reports-sap-admin',
        path: '/reports-sap-admin',
        icon: <FileDoneOutlined />,
        label: 'Admin Reports'
      }
    ]
  },
  {
    key: 2,
    title: 'Oracle',
    subMenu: [
      {
        key: 'issuer-gatepass',
        path: '/issuer-gatepass',
        icon: <HomeOutlined />,
        label: 'Dashboard'
      },
      {
        key: 'issuer-requester',
        path: '/issuer-requester',
        icon: <AppstoreAddOutlined />,
        label: 'Requester'
      },
      {
        key: 'reports',
        path: '/reports',
        icon: <FileTextOutlined />,
        label: 'Reports'
      }
    ]
  }
];
const hoDataAdmin = [
  {
    key: 1,
    title: 'SAP',

    subMenu: [
      {
        key: 'ho-requests_sap',
        path: '/ho-requests_sap',
        icon: <AppstoreAddOutlined />,
        label: 'SAP Dashboard'
      },
      {
        key: 'reports-sap',
        path: '/reports-sap',
        icon: <FileTextOutlined />,
        label: 'Reports SAP'
      },
      {
        key: 'admin-sap',
        path: '/admin-sap',
        icon: <UserOutlined />,
        label: 'Admin SAP'
      },
      {
        key: 'reports-sap-admin',
        path: '/reports-sap-admin',
        icon: <FileDoneOutlined />,
        label: 'Admin Reports'
      }
    ]
  },
  {
    key: 2,
    title: 'Oracle',
    subMenu: [
      {
        key: 'ho-requests',
        path: '/ho-requests',
        icon: <AppstoreAddOutlined />,
        label: 'Dashboard'
      },
      {
        key: 'reports',
        path: '/reports',
        icon: <FileTextOutlined />,
        label: 'Reports'
      }
    ]
  }
];

const CustomLayout = ({ children, sider = true, header = true, title }) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const user = LocalStorage.getItem('gatepass_user');
  const [selectedMenuKeys] = useState(
    history.location.pathname.replace('/', '')
  );

  const sidebar_data =
    user?.user_details?.roles[0] === 'requester' &&
    user?.user_details?.is_admin === true
      ? menuDataAdmin
      : user?.user_details?.roles[0] === 'requester' &&
        user?.user_details?.is_admin === false
      ? menuData
      : user?.user_details?.roles[0] === 'issuer' &&
        user?.user_details?.is_admin === true
      ? issuerDataAdmin
      : user?.user_details?.roles[0] === 'issuer' &&
        user?.user_details?.is_admin === false
      ? issuerData
      : user?.user_details?.roles[0] === 'approver' &&
        user?.user_details?.is_admin === true
      ? approverDataAdmin
      : user?.user_details?.roles[0] === 'approver' &&
        user?.user_details?.is_admin === false
      ? approverData
      : user?.user_details?.roles[0] === 'ho' &&
        user?.user_details?.is_admin === true
      ? hoDataAdmin
      : user?.user_details?.roles[0] === 'ho' &&
        user?.user_details?.is_admin === false
      ? hoData
      : '';

  const signOutClickHandler = () => {
    instance.logoutRedirect({
      account: LocalStorage.homeAccountId,
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
      onRedirectNavigate: (url) => {
        console.log('url', url);
        dispatch(logout());
        return true;
      }
    });
  };

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => signOutClickHandler()}
        icon={<LogoutOutlined />}
        title={user?.user_details?.roles[0]}
      >
        <Text>Logout </Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className={styles.custom_layout_container} hasSider={sider}>
      {sider ? (
        <Sider
          trigger={null}
          collapsible
          collapsed={true}
          collapsedWidth={64}
          className={styles.sider_container}
        >
          <div className={styles.sider_logo}>
            {user?.user_details?.roles[0] === 'requester' ? (
              <a href={'/requesters-sap-dashboard'}>
                <Image
                  alt="laurus_sider_logo"
                  src={laurusSiderLogo}
                  preview={false}
                />
              </a>
            ) : user?.user_details?.roles[0] === 'approver' ? (
              <a href={'/gatepass-requests'}>
                <Image
                  alt="laurus_sider_logo"
                  src={laurusSiderLogo}
                  preview={false}
                />
              </a>
            ) : user?.user_details?.roles[0] === 'issuer' ? (
              <a href={'/issuer-requester'}>
                <Image
                  alt="laurus_sider_logo"
                  src={laurusSiderLogo}
                  preview={false}
                />
              </a>
            ) : (
              <a href={'/ho-requests'}>
                <Image
                  alt="laurus_sider_logo"
                  src={laurusSiderLogo}
                  preview={false}
                />
              </a>
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[selectedMenuKeys]}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '85%'
            }}
            className={`${styles.menuItems}`}
          >
            {sidebar_data?.map((item) => {
              // let isSelected = item?.key === selectedMenuKeys;
              // console.log(
              //   'isSelected######',
              //   item?.title,
              //   selectedMenuKeys,
              // );
              return (
                <Menu.SubMenu
                  key={item.key}
                  title={<span>{item.title}</span>}
                  // style={{ marginLeft: '-22px' }}
                >
                  {/* {console.log('item.title', item.title)} */}
                  {item?.subMenu?.map((subItem) => (
                    <Menu.Item key={subItem.key} icon={subItem.icon}>
                      <Link to={subItem.path}>
                        <Tooltip
                          title={subItem.label}
                          placement="topLeft"
                          arrowPointAtCenter={false}
                          color={'white'}
                          autoAdjustOverflow={true}
                        >
                          {subItem.label}
                        </Tooltip>
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            })}

            <Menu.Item
              style={{ marginTop: 'auto', display: 'hidden' }}
            ></Menu.Item>
            <Divider
              style={{ backgroundColor: '#fff', marginBottom: '-7px' }}
            ></Divider>
            <Menu.Item key="logout">
              <Dropdown overlay={menu} placement="top">
                <Avatar
                  style={{
                    backgroundColor: '#fff',
                    color: '#2364AD',
                    marginLeft: '-12px'
                  }}
                >
                  {user?.user_details?.fullname?.slice(0, 1)}
                </Avatar>
              </Dropdown>
            </Menu.Item>
          </Menu>
        </Sider>
      ) : null}
      <Layout className={styles.site_layout}>
        {header ? (
          <Header className={`df-jb-ac ${styles.site_layout_header}`}>
            <div className="sfprotext-medium font-16">{title}</div>
          </Header>
        ) : null}
        <Content className={styles.site_layout_content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;

/* import { useState } from 'react';
import { Divider, Image, Layout, Menu, Tooltip } from 'antd';
import styles from './index.module.less';
import laurusSiderLogo from './images/logo.png';
import { history } from 'app/history';
import {
  AppstoreAddOutlined,
  LogoutOutlined,
  HomeOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Avatar, Typography, Dropdown } from 'antd';
import { useDispatch } from 'react-redux';
import { logout } from '@/features/login/redux/slice';
import LocalStorage from 'utilities/localStorage';
import { useMsal } from '@azure/msal-react';

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

const menuData = [
  {
    key: 'dashboard',
    path: '/dashboard',
    icon: <AppstoreAddOutlined />,
    label: 'Dashboard'
  },
  {
    key: 'reports',
    path: '/reports',
    icon: <FileTextOutlined />,
    label: 'Reports'
  }
];

const approverData = [
  {
    key: 'gatepass-requests',
    path: '/gatepass-requests',
    icon: <AppstoreAddOutlined />,
    label: 'Dashboard'
  },
  {
    key: 'reports',
    path: '/reports',
    icon: <FileTextOutlined />,
    label: 'Reports'
  }
];
const issuerData = [
  {
    key: 'issuer-gatepass',
    path: '/issuer-gatepass',
    icon: <HomeOutlined />,
    label: 'Dashboard'
  },
  {
    key: 'issuer-requester',
    path: '/issuer-requester',
    icon: <AppstoreAddOutlined />,
    label: 'Requester'
  },
  {
    key: 'reports',
    path: '/reports',
    icon: <FileTextOutlined />,
    label: 'Reports'
  }
];
const hoData = [
  {
    key: 'ho-requests',
    path: '/ho-requests',
    icon: <AppstoreAddOutlined />,
    label: 'Dashboard'
  },
  {
    key: 'reports',
    path: '/reports',
    icon: <FileTextOutlined />,
    label: 'Reports'
  }
];

const CustomLayout = ({ children, sider = true, header = true, title }) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const user = LocalStorage.getItem('gatepass_user');
  // const userFullName = userDetails?.user_details?.full_name
  // ? userDetails.user_details?.full_name.slice(0, 1)
  // : 'U';
  const [selectedMenuKeys] = useState(
    history.location.pathname.replace('/', '')
  );

  const sidebar_data =
    user?.user_details?.roles[0] === 'requester'
      ? menuData
      : user?.user_details?.roles[0] === 'issuer'
      ? issuerData
      : user?.user_details?.roles[0] === 'approver'
      ? approverData
      : hoData;

  const signOutClickHandler = () => {
    instance.logoutRedirect({
      account: LocalStorage.homeAccountId,
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
      onRedirectNavigate: (url) => {
        console.log('url', url);
        // Return false if you would like to stop navigation after local logout
        dispatch(logout());
        return true;
      }
    });
  };
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => signOutClickHandler()}
        // key="1"
        icon={<LogoutOutlined />}
        title={user?.user_details?.roles[0]}
      >
        <Text>Logout </Text>
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout className={styles.custom_layout_container} hasSider={sider}>
      {sider ? (
        <Sider
          trigger={null}
          collapsible
          collapsed={true}
          collapsedWidth={64}
          className={styles.sider_container}
        >
          <div className={styles.sider_logo}>
            {user?.user_details?.roles[0] === 'requester' ? (
              <a href={'/dashboard'}>
                <Image
                  alt="laurus_sider_logo"
                  src={laurusSiderLogo}
                  preview={false}
                />
              </a>
            ) : user?.user_details?.roles[0] === 'approver' ? (
              <a href={'/gatepass-requests'}>
                <Image
                  alt="laurus_sider_logo"
                  src={laurusSiderLogo}
                  preview={false}
                />
              </a>
            ) : user?.user_details?.roles[0] === 'issuer' ? (
              <a href={'/issuer-requester'}>
                <Image
                  alt="laurus_sider_logo"
                  src={laurusSiderLogo}
                  preview={false}
                />
              </a>
            ) : (
              <a href={'/ho-requests'}>
                <Image
                  alt="laurus_sider_logo"
                  src={laurusSiderLogo}
                  preview={false}
                />
              </a>
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            // defaultSelectedKeys={['issuer-gatepass']}
            selectedKeys={[selectedMenuKeys]}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '85%'
            }}
            className={`${styles.menuItems}`}
          >
            {sidebar_data.map((item, index) => {
              let isSelected = item?.key === selectedMenuKeys;
              //console.log('', item?.key, selectedMenuKeys);
              return (
                <Tooltip
                  key={index}
                  title={item.label}
                  placement="topLeft"
                  arrowPointAtCenter={false}
                  color={'white'}
                  autoAdjustOverflow={true}
                >
                  <Menu.Item
                    key={item?.key}
                    // icon={item.icon}
                    className={`${styles.dashboard_icon}`}
                  >
                    <Link
                      to={item.path}
                      className={
                        isSelected ? `${styles.active}` : `${styles.inactive}`
                      }
                    >
                      {item.icon}
                    </Link>
                  </Menu.Item>
                </Tooltip>
              );
            })}

            <Menu.Item
              style={{ marginTop: 'auto', display: 'hidden' }}
            ></Menu.Item>
            <Divider
              style={{
                backgroundColor: '#fff',
                marginBottom: '-7px'
              }}
            ></Divider>
            <Menu.Item key="logout">
              <Dropdown overlay={menu} placement="top">
                <Avatar
                  style={{
                    backgroundColor: '#fff',
                    color: '#2364AD',
                    marginLeft: '-12px'
                  }}
                >
                  {user?.user_details?.fullname.slice(0, 1)}
                </Avatar>
              </Dropdown>
            </Menu.Item>
          </Menu>
        </Sider>
      ) : null}
      <Layout className={styles.site_layout}>
        {header ? (
          <Header className={`df-jb-ac ${styles.site_layout_header}`}>
            <div className="sfprotext-medium font-16">{title}</div>
          </Header>
        ) : null}
        <Content className={styles.site_layout_content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
 */

// import { useState } from 'react';
// import { Divider, Image, Layout, Menu, Tooltip } from 'antd';
// import styles from './index.module.less';
// import laurusSiderLogo from './images/logo.png';
// import { history } from 'app/history';
// import {
//   AppstoreAddOutlined,
//   LogoutOutlined,
//   HomeOutlined,
//   FileTextOutlined
// } from '@ant-design/icons';
// import { Link } from 'react-router-dom';
// import { Avatar, Typography, Dropdown } from 'antd';
// import { useDispatch } from 'react-redux';
// import { logout } from '@/features/login/redux/slice';
// import LocalStorage from 'utilities/localStorage';
// import { useMsal } from '@azure/msal-react';

// const { Header, Sider, Content } = Layout;
// const { Text } = Typography;
// const { SubMenu } = Menu;
