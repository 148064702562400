import React, { useState, useEffect } from 'react';
import CustomLayout from '@/common/Layout';
import List from './components/list';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllRequests,
  deleteRequest,
  deleteDraft
  //searchvalues
} from './redux/slice';

const Dashboard = () => {
  const { allRequests, pagination, getAllApproverLoading } = useSelector(
    (state) => state.dashboard
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter')) === null
      ? ''
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter'))
  );
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [showDateranga, setShowDateranga] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [searchRequest, setSearchRequest] = useState('');

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetRequests();
  }, [page, pageSize]);

  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        handleGetRequests();
      } else {
        setPage(1);
      }
    }
  }, [radiofilter, searchRequest]); //page,
  const handleGetRequests = () => {
    let payload = {
      page,
      per_page: pageSize,
      status: radiofilter,
      search: searchRequest
    };
    dispatch(getAllRequests(payload));
  };

  const handleDeleteRequest = (payload) => {
    const id = { gpid: payload.id };
    dispatch(deleteRequest(id)).then(function (response) {
      if (response) {
        handleGetRequests();
      }
    });
  };
  const handleDeleteDraft = (payload) => {
    const id = { gpid: payload.id };
    dispatch(deleteDraft(id)).then(function (response) {
      if (response) {
        handleGetRequests();
      }
    });
  };

  /* const handleChange = (e) => {
    const value = { ticket: e.target.value, page, status: radiofilter };
    dispatch(searchvalues(value));
  }; */

  return (
    <>
      <CustomLayout sider="true">
        <List
          allRequests={allRequests}
          pagination={pagination}
          loading={getAllApproverLoading}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          handleDeleteRequest={handleDeleteRequest}
          handleDeleteDraft={handleDeleteDraft}
          setSelectedRequest={setSelectedRequest}
          selectedRequest={selectedRequest}
          radiofilter={radiofilter}
          setRadiofilter={setRadiofilter}
          showDisablePopup={showDisablePopup}
          setShowDisablePopup={setShowDisablePopup}
          // handleChange={handleChange}
          setShowDateranga={setShowDateranga}
          showDateranga={showDateranga}
          setTriggerFilter={setTriggerFilter}
          setSearchRequest={setSearchRequest}
        />
      </CustomLayout>
    </>
  );
};

export default Dashboard;

/* 
import { Form, Input, InputNumber, Button, Select } from 'antd';

const Dashboard = () => {
  const layout = {
    labelCol: {
      span: 8
    },
    wrapperCol: {
      span: 16
    }
  };
  const { Option } = Select;
  const [form] = Form.useForm();
  const [iban, setValue] = useState('');
  const [ibanVal, setIbanVal] = useState({});
  const [val, setVal] = useState({});

  const handleChangeBeneficiary = (value) => {
  //  console.log('selected', value);
    const ben = beneficiaries.filter((b) => b.name === value);
   // console.log('ben', ben);
    setValue(ben[0]?.iban);
    setIbanVal({iban:ben[0]?.iban})
  };

  //console.log('IBANvaue', ibanVal);
 // console.log('iban', iban);
  const onFinish = (values) => {
   // console.log('Success:', values);
    setVal({...ibanVal, ...values})
  };
 console.log('Submit', val);
  var arr1 = [{name: "AAA", dep: "German"}, {name: "BBB", dep: "HIN"}];
var arr2 = [{age: "12"}, {age: "13"}];
// var arr4 = arr1.concat(arr2)
//  console.log('keys',Object.keys(arr1) );
//  console.log('values',Object.values(arr1) );
//  console.log('entries',Object.entries(arr1) );

let txt = "";
for (let x in arr1 || arr2) {
  //console.log('x:',x);
  for(let y in arr1[x] || arr2[x])
{txt += arr1[x][y] + " "+ arr2[x][y];
console.log('y:',y);
console.log('txt:',txt);}
}; 

  const beneficiaries = [
    {
      iban: 'FR76167LQSDKJLKSQJ86538089',
      name: 'FRANCILIENNE CONSEIL',
      bic: 'TRZOFR21XXX',
      beneficiaryId: '60c38ddf-63f9-4589-888b-27b7e1a50e53'
    },
    {
      iban: 'FR291001DSKLFJSLKJ8633Z17',
      name: 'MR NAMLA EMAD',
      bic: 'PSSTFRPPCNE',
      beneficiaryId: '60a11891-81ba-4ab2-9b92-ce4f461c2d50'
    }
  ];
  return (
    <Form
      {...layout}
      name="test"
      onFinish={onFinish}
      autoComplete="off"
      form={form}
    >
      <Form.Item label="Nom du bénéficiare" name="benef">
        <Select
          defaultValue=""
          style={{ width: 300, marginBottom: 20 }}
          onChange={handleChangeBeneficiary}
        >
          {beneficiaries.map((nom) => (
            <Option value={nom.name}> {nom.name} </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="IBAN">
        <Input autoComplete="off" style={{ marginBottom: 20 }}
       onchange={()=>{setIbanVal(iban)}}  value={iban}  />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
 */
