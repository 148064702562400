import React, { useState, useEffect, useCallback } from 'react';
import styles from './index.module.less';
import CustomLayout from '@/common/Layout';
import {
  Card,
  Row,
  Badge,
  Radio,
  Space,
  Popconfirm,
  Tooltip,
  Empty
} from 'antd';
import CustomTable from 'common/Content/CustomTable';
import moment from 'moment';
import SearchBar from '@/common/Content/searchBar';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAllIssuerList_Requester } from '../../redux/slice';
import {
  deleteRequest_sap,
  deleteDraft_sap
} from '../../../Requesters/dashboard_SAP/redux/slice';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import CustomButton from 'common/CustomButton';

const filtersIssuer = [
  {
    name: 'All',
    value: ''
  },
  {
    name: 'Draft',
    value: 'Draft'
  },
  {
    name: 'Requested',
    value: 'Requested'
  },
  {
    name: 'Approved',
    value: 'Approved'
  },

  {
    name: 'Rejected',
    value: 'Rejected'
  },
  {
    name: 'Generated',
    value: 'Generated'
  },
  {
    name: 'Printed',
    value: 'Printed'
  },
  {
    name: 'Partially Received',
    value: 'Partially received'
  },

  {
    name: 'Closed',
    value: 'Closed'
  },
  {
    name: 'Cancelled',
    value: 'Cancelled'
  }
];
const Issuer_Requster_SAP = () => {
  const {
    secondpagination,
    allRequestsIssuer_Requester,
    getAllIssuerList_RequesterLoading
  } = useSelector((state) => state.issuer_sap);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [radiofilter, setRadiofilter] = useState(
    JSON?.parse(window.localStorage.getItem('set_Radiofilter')) === null
      ? ''
      : JSON?.parse(window.localStorage.getItem('set_Radiofilter'))
  );
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [searchRequest, setSearchRequest] = useState('');
  console.log('selectedRequest', selectedRequest);

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllRequests();
  }, [page, pageSize]);

  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        handleGetAllRequests();
      } else {
        setPage(1);
      }
    }
  }, [radiofilter, searchRequest]);

  const handleGetAllRequests = () => {
    let payload = {
      page: page,
      per_page: pageSize,
      status: radiofilter,
      search: searchRequest
    };

    dispatch(getAllIssuerList_Requester(payload));
  };

  const handleDeleteRequest = (payload) => {
    const id = { gpid: payload.id };
    dispatch(deleteRequest_sap(id)).then(function (response) {
      if (response) {
        handleGetAllRequests();
      }
    });
  };

  const handleDeleteDraft = (payload) => {
    const id = { gpid: payload.id };
    dispatch(deleteDraft_sap(id)).then(function (response) {
      if (response) {
        handleGetAllRequests();
      }
    });
  };

  const tableDataIssuer = allRequestsIssuer_Requester?.map((item) => ({
    key: item?.id,
    id: item?.id,
    ticket: item?.ticket,
    Method_type: item?.Method_type?.method_type,
    Location: item?.Location?.location_name,
    Type: item?.SapTypeMaster?.type,
    Category: item?.SapCategoryMaster?.category,
    date: item?.date,
    status: item?.status,
    approver_status: item?.approver_status,
    issuer_status: item?.issuer_status,
    gp_number: item?.gp_number,
    Project_location: item?.SapStorageLocation?.name,
    date_of_generated: item?.date_of_generated,
    updatedAt: item?.updatedAt
  }));

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  const issuercolumns = [
    {
      title: 'Request #',
      dataIndex: 'ticket',
      key: 'ticket',
      width: '120px',
      ellipsis: true,
      sorter: (a, b) => a?.ticket?.localeCompare(b?.ticket),
      render: (ticket, record) => {
        return (
          <>
            <Tooltip
              title={ticket}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {
                <a href={`view-requests-IssuerAsRequester-sap/${record.id}`}>
                  {' '}
                  {ticket === null ? 'Not generated' : ticket}
                </a>
              }
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Gate Pass #',
      dataIndex: 'gp_number',
      key: 'gp_number',
      width: 180,
      ellipsis: {
        showTitle: false
      },
      sorter: (a, b) => a?.gp_number?.localeCompare(b?.gp_number),
      render: (gp_number) => {
        return (
          <>
            <Tooltip
              title={gp_number}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {gp_number === null ? 'Not generated' : gp_number}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Location',
      dataIndex: 'Project_location',
      key: 'Project_location',
      ellipsis: true,
      sorter: (a, b) => a?.Project_location?.localeCompare(b?.Project_location),
      render: (Project_location) => {
        return (
          <>
            <Tooltip
              title={Project_location}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {Project_location}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      ellipsis: true,
      sorter: (a, b) => a?.Type?.localeCompare(b?.Type),
      render: (Type) => {
        return (
          <>
            <Tooltip
              title={Type}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {Type}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Category',
      dataIndex: 'Category',
      key: 'Category',
      ellipsis: true,
      sorter: (a, b) => a?.Category?.localeCompare(b?.Category),
      render: (Category) => {
        if (Category === 'Returnable Gate Pass (RGP)') {
          return (
            <>
              <Tooltip
                title={Category}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                RGP
              </Tooltip>
            </>
          );
        } else if (Category === 'Non-returnable Gate Pass (NRGP)') {
          return (
            <>
              <Tooltip
                title={Category}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                NRGP
              </Tooltip>
            </>
          );
        }
      }
    },
    {
      title: 'Date',
      dataIndex: 'date_of_generated',
      key: 'date_of_generated',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`date_of_generated`]).unix() -
        moment(b[`date_of_generated`]).unix(),
      render: (date_of_generated) => (
        <>
          <Tooltip
            title={
              date_of_generated === null
                ? 'Not generated'
                : moment(date_of_generated).format('DD MMM YYYY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {date_of_generated === null
              ? 'Not generated'
              : moment(date_of_generated).format('DD MMM YYYY')}
          </Tooltip>
        </>
      )
    },
    {
      title: (
        <Tooltip
          title={'Cancelled Date'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          Cancelled Date
        </Tooltip>
      ),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a[`updatedAt`]).unix() - moment(b[`updatedAt`]).unix(),
      render: (updatedAt, record) => (
        <>
          {record?.status === 'Cancelled' ? (
            <Tooltip
              title={
                updatedAt === null
                  ? 'NA'
                  : moment(updatedAt).format('DD MMM YYYY')
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {updatedAt === null
                ? 'NA'
                : moment(updatedAt).format('DD MMM YYYY')}
            </Tooltip>
          ) : (
            'NA'
          )}
        </>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '180px',
      ellipsis: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (text, record) => (
        <Badge
          className={
            record?.status === 'Rejected'
              ? `${styles.badgeRejStyles}`
              : record.status === 'Cancelled'
              ? `${styles.badgeCanStyles}`
              : record.status === 'Requested'
              ? `${styles.badgeReqStyles}`
              : record.status === 'Pending'
              ? `${styles.badgePendStyles}`
              : record.status === 'Generated'
              ? `${styles.badgeGpGenStyles}`
              : record.status === 'Partially received'
              ? `${styles.badgePartRecivStyles}`
              : record.status === 'Printed'
              ? `${styles.badgeGpPriStyles}`
              : record.status === 'Closed'
              ? `${styles.badgeCloseStyles}`
              : `${styles.badgeStyles}`
          }
          color={
            record?.status === 'Rejected' || record?.status === 'Rejected'
              ? 'red'
              : record?.status === 'Cancelled'
              ? '#820b03'
              : record?.status === 'Requested'
              ? 'blue'
              : record?.status === 'Pending'
              ? '#046f89'
              : record?.status === 'Generated'
              ? ' #059720'
              : record?.status === 'Partially received'
              ? '#C11574'
              : record?.status === 'Printed'
              ? '#344054'
              : record?.status === 'Closed'
              ? '#18068b'
              : 'green'
          }
          text={
            record?.status === 'Rejected'
              ? record?.status?.charAt(0).toUpperCase() +
                record?.status?.slice(1)
              : record?.status?.charAt(0).toUpperCase() +
                record?.status?.slice(1)
          }
        />
      )
    },

    {
      title: 'Action',
      key: 'action',
      width: 80,
      render: (record) => (
        <Space size="middle">
          <>
            {record.status === 'Requested' || record.status === 'Rejected' ? (
              <>
                <Popconfirm
                  title="Are you sure do you want to cancel this request?"
                  visible={visibility(record)}
                  onConfirm={() => {
                    handleDeleteRequest({
                      id: record.id
                    });
                  }}
                  onCancel={() => {
                    setShowDisablePopup({});
                  }}
                  okText="Cancel"
                  cancelText="No"
                  okButtonProps={{ type: 'danger' }}
                >
                  <DeleteFilled
                    className={`${styles.delete_icon}`}
                    onClick={() => {
                      setShowDisablePopup({ [record.id]: true });
                    }}
                  />
                </Popconfirm>
                <a href={`edit-GP_SAP/${record.id}`}>
                  <EditFilled
                    className={`${styles.edit_icon}`}
                    onClick={() => {
                      setSelectedRequest(record);
                    }}
                  />
                </a>
              </>
            ) : record.status === 'Draft' ? (
              <>
                <Popconfirm
                  title="Are you sure do you want to delete this request?"
                  visible={visibility(record)}
                  onConfirm={() => {
                    handleDeleteDraft({
                      id: record.id
                    });
                  }}
                  onCancel={() => {
                    setShowDisablePopup({});
                  }}
                  okText="Delete"
                  cancelText="No"
                  okButtonProps={{ type: 'danger' }}
                >
                  <DeleteFilled
                    className={`${styles.delete_icon}`}
                    onClick={() => {
                      setShowDisablePopup({ [record.id]: true });
                    }}
                  />
                </Popconfirm>
                <a href={`draft-gatepass-SAP/${record.id}`}>
                  <EditFilled
                    className={`${styles.edit_icon}`}
                    onClick={() => {
                      setSelectedRequest(record);
                    }}
                  />
                </a>
              </>
            ) : (
              ''
            )}
          </>
        </Space>
      )
    }
  ];

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no gate pass requests to display
        </div>
      </span>
    )
  };
  return (
    <>
      <CustomLayout sider="true">
        <div className={`mb-4 ${styles.container}`}>
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <div className={`${styles.page_title}`}>
                {' '}
                Gate pass requests{' '}
                <Badge
                  count={`${secondpagination?.total_records || 0} requests`}
                  size="small"
                  className={`${styles.badgestyles}`}
                />
              </div>
              <div className={`${styles.page_sub_title}`}>
                Review gate pass requests, generate gate passes and print them
              </div>
            </div>
            <div>
              <Link to="/create-sap-gp-request">
                <CustomButton
                  size="small"
                  icon={<PlusOutlined />}
                  className={`${styles.customButton_style_secondary}`}
                  type="primary"
                >
                  <span>Request New Gate Pass</span>
                </CustomButton>
              </Link>
            </div>
          </Row>

          <Card className={`${styles.cardStyles_approver}`}>
            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Radio.Group
                  value={radiofilter}
                  size="small"
                  className={`${styles.button_import_style_issuer}`}
                >
                  {filtersIssuer.map((item, index) => {
                    return (
                      <Radio.Button
                        value={item.value}
                        key={index}
                        className={`${styles.RadioButton_import_style_issuer}`}
                        onChange={() => {
                          window.localStorage.setItem(
                            'set_Radiofilter',
                            JSON?.stringify(item.value)
                          );
                          setRadiofilter(item.value);
                          setTriggerFilter(true);
                        }}
                      >
                        {item.name}
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </div>
              <div>
                <SearchBar
                  onChange={(e) => {
                    setSearchRequest(e.target.value);
                    setTriggerFilter(true);
                  }}
                  className={`w-100 ${styles.searchbar}`}
                  justify="start"
                  align="start"
                  inputProps={{
                    placeholder: 'Search by gate pass request#'
                  }}
                />
              </div>
            </Row>

            <div className={`${styles.table_container}`}>
              <CustomTable
                locale={locale}
                loading={getAllIssuerList_RequesterLoading}
                dataSource={tableDataIssuer}
                columns={issuercolumns}
                pagination={{
                  current: page,
                  pageSize: pageSize,
                  showSizeChanger: true,
                  size: 'small',
                  total: secondpagination?.total_records || 0,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    setPageSize(pageSize);
                  }
                }}
              />
            </div>
          </Card>
        </div>
      </CustomLayout>
    </>
  );
};

export default Issuer_Requster_SAP;
