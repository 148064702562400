import React from 'react';
import { message, Modal, Typography, Button } from 'antd';
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import { Form, Input, Space } from 'antd';
//import CustomButton from '@/common/CustomButton';
import { useParams } from 'react-router-dom';
import { rejectRequest, cancelGP_HO } from '../../redux/slice';
import { useDispatch } from 'react-redux';
import { history } from '@/app/history';

const { TextArea } = Input;

const RejectModal = ({ rejectModal, setRejectModal, requester_data, role }) => {
  const [form] = Form.useForm();
  const params = useParams();

  const dispatch = useDispatch();

  const onFinish = (values) => {
    //console.log('values?.reason_for_rejection', values?.reason_for_rejection);
    const payload = {
      id: params.id,
      reason_for_rejection: values?.reason_for_rejection
    };
    const payloadHo = {
      id: params.id
    };
    {
      role === 'ho'
        ? dispatch(cancelGP_HO(payloadHo)).then(function (response) {
            if (response) {
              console.log(response);
              setRejectModal(false);
              if (response?.payload?.success) {
                // message.success(response?.payload?.msg);
                history.push('/ho-requests');
              }
            }
          })
        : role === 'approver'
        ? dispatch(rejectRequest(payload)).then(function (response) {
            if (response) {
              setRejectModal(false);
              if (response?.payload?.success) {
                message.success(response?.payload?.msg);
                history.push('/gatepass-requests');
              }
            }
          })
        : role === 'issuer'
        ? dispatch(rejectRequest(payload)).then(function (response) {
            if (response) {
              setRejectModal(false);
              if (response?.payload?.success) {
                message.success(response?.payload?.msg);
                history.push('/issuer-gatepass');
              }
            }
          })
        : '';
    }
  };

  return (
    <>
      <Modal
        title={
          role === 'ho' ? (
            <span>#{requester_data ? requester_data?.ticket : 'NA'}</span>
          ) : (
            <span> Are you sure do you want to reject the Gate Pass?</span>
          )
        }
        visible={rejectModal}
        footer={false}
        className={`${styles.not_allowed_icon}`}
        width={'500px'}
        onCancel={() => setRejectModal(false)}
      >
        {role === 'ho' ? (
          <Typography
            style={{
              fontSize: '12px',
              marginTop: '4px',
              marginBottom: '20px',
              fontFamily: 'SFProText Regular'
            }}
          >
            Are you sure you want to cancel this Gate Pass? This action cannot
            be undone.
          </Typography>
        ) : (
          ''
        )}
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          {role === 'ho' ? (
            ''
          ) : (
            <FormItem
              label="Enter reason"
              name="reason_for_rejection"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
              className={`${styles.select_styles}`}
            >
              <TextArea
                allowClear
                placeholder="Enter reason for rejecting this gate pass request"
              />
            </FormItem>
          )}
          <FormItem>
            <Space
            /*  style={{
                display: 'flex',
                justifyContent: 'center',
                justifyItems: ' center'
              }} */
            >
              {role === 'ho' ? (
                <Button
                  className={`${styles.reject_modal_button}`}
                  htmlType="submit"
                  size="small"
                >
                  Cancel Gate Pass
                </Button>
              ) : (
                <Button
                  className={`${styles.reject_modal_button}`}
                  htmlType="submit"
                  size="small"
                >
                  Reject
                </Button>
              )}
              <Button
                className={`${styles.cancel_rejectModal_button}`}
                size="small"
                onClick={() => setRejectModal(false)}
              >
                No
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default RejectModal;
