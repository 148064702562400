import React, { useEffect } from 'react';
import { Modal } from 'antd';
//import { CheckCircleOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import FormItem from 'common/FormItem';
import { Form, Space, Row, Col, Input, DatePicker, Button } from 'antd';
//import CustomButton from '@/common/CustomButton';
import { useParams } from 'react-router-dom';
//import { approveRequest,vehicleFormData} from '../../redux/slice';
//import { useDispatch } from 'react-redux';
import { history } from '@/app/history';
//import { getLoggedInUser } from 'utilities/helpers';
import moment from 'moment';
import LocalStorage from 'utilities/localStorage';

const GPModal = ({
  approveModal,
  setApproveModal,
  requester_data,
  ItemsDetails
  // vehicleFormValue,
  // setVehicleFormValue,
}) => {
  const params = useParams();
  const [form] = Form.useForm();
  // const dispatch = useDispatch();
  //const user = getLoggedInUser();

  useEffect(() => {
    const { vehicle_number, lr_number, carrier_person_name, lr_date } =
      requester_data;
    form.setFieldsValue({
      vehicle_number,
      lr_number,
      //lr_date: moment() || '',
      carrier_person_name,
      lr_date: lr_date ? moment(lr_date) : moment() // null
    });
  }, [requester_data]);

  const onFinish = (values) => {
    console.log('EditGP_Values', values);
    // setVehicleFormValue(values);
    setApproveModal(false);
    const payload = {
      gpid: params.id,
      ...values,
      requester_data,
      ItemsDetails
    };
    LocalStorage.setItem('vehicleFormValue_SAP', JSON.stringify(payload));
    history.push('/preview-genratedGP-sap');

    //dispatch(vehicleFormData(payload))

    /* const payload = {
      id: params.id
    };
    const payloadIssuer = {
      gpid: params.id,
      ...values
    };
    {
      user?.user_details?.role === 'approver'
        ? dispatch(approveRequest(payload)).then(function (response) {
            if (response) {
              setApproveModal(false);
              if (response?.payload?.success) {
                message.success(response?.payload?.msg);
                history.push('/gatepass-requests');
              }
            }
          })
        : dispatch(issuerGenrateGP_EditGP(payloadIssuer)).then(function (
            response
          ) {
            if (response) {
              setApproveModal(false);
              if (response?.payload?.success) {
                message.success(response?.payload?.msg);
                history.push('/gatepass-requests');
              }
            }
          });
    } */
  };

  return (
    <>
      <Modal
        title={<span>Initiate GP</span>}
        visible={approveModal}
        width={'990px'}
        footer={false}
        className={`${styles.check_circle_icon}`}
        onCancel={() => setApproveModal(false)}
      >
        {/*  <Typography style={{ fontSize: '11px', marginTop: '-6px' }}>
          Are you sure you want to generate to this GP? This action can not be
          undone.
        </Typography>
        <br /> */}
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <Row gutter={16}>
            <Col span={5}>
              <FormItem
                label="Vehicle Number"
                name="vehicle_number"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                  // {
                  //   validator: async (_, value) => {
                  //     if (value?.length <= 10) {
                  //       return Promise.resolve();
                  //     } else {
                  //       return Promise.reject(
                  //         'Not exceed more than 10 characters'
                  //       );
                  //     }
                  //   }
                  // }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Please enter vehicle number"
                  maxLength={20}
                  /* onChange={(e) =>
                          handleFormDataOnChange(
                            'vehicle_number',
                            e.target.value
                          )
                        } */
                />
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem
                label="LR Number"
                name="lr_number"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Please enter LR number"
                  /*  onChange={(e) =>
                          handleFormDataOnChange('lr_number', e.target.value)
                        } */
                />
              </FormItem>
            </Col>
            <Col span={4}>
              <FormItem
                label="LR Date"
                name="lr_date"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.date_styles}`}
              >
                <DatePicker
                  //disabled
                  //defaultValue={moment()}
                  format={'DD-MMM-YYYY'}
                  disabledDate={(current) => {
                    return moment().add(0, 'month') <= current;
                  }}
                  /* onChange={(_, dateValue) =>
                    handleFormDataOnChange('lr_date', dateValue)
                  } */
                ></DatePicker>
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem
                label="Name of the carrier person"
                name="carrier_person_name"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Please enter name"
                  /*  onChange={(e) =>
                          handleFormDataOnChange(
                            'carrier_person_name',
                            e.target.value
                          )
                        } */
                />
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem
                label="Waybill Number"
                name="waybillNumber"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Please waybill number"
                  /*  onChange={(e) =>
                          handleFormDataOnChange(
                            'carrier_person_name',
                            e.target.value
                          )
                        } */
                />
              </FormItem>
            </Col>
          </Row>
          <FormItem>
            <Space>
              <Button
                className={`${styles.import_style_IssuerModal}`}
                htmlType="submit"
                //  size="small"
              >
                Preview Gate Pass
              </Button>
              <Button
                className={`${styles.import_style_cancel_IssuerModal}`}
                //size="small"
                onClick={() => setApproveModal(false)}
              >
                No
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default GPModal;
