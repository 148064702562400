import CustomLayout from '@/common/Layout';
import DraftGPRequest from '../draftGatepassRequest/index';
import { getLoggedInUser } from 'utilities/helpers';

const IssuerEditDraft = () => {
  const user = getLoggedInUser();
  return (
    <>
      <CustomLayout sider="true">
        {user?.user_details?.roles[0] === 'issuer' ? <DraftGPRequest /> : ''}
      </CustomLayout>
    </>
  );
};

export default IssuerEditDraft;
