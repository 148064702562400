import apiClient from 'utilities/apiClient';
import { getLoggedInUser } from 'utilities/helpers';

const user = getLoggedInUser();

export const view_byid = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.view}/${url}`, payload, true);
};

export const view_byid_Approver = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.view}/${url}`, payload, true);
};

export const approver = (url, payload) => {
  return apiClient.get(
    `${
      user?.user_details?.role === 'approver'
        ? apiClient.Urls.approver_view
        : user?.user_details?.role === 'requester'
        ? apiClient.Urls.requester_view
        : user?.user_details?.role === 'ho'
        ? apiClient.Urls.ho_view
        : apiClient.Urls.issuer_view
    }/${url}`,
    payload,
    true
  );
};

export const hoEdit = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.view}/${url}`, payload, true);
};
export const hoCancel = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.reject}/${url}`, payload, true);
};

export const delete_requests = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.requester}/${url}`, payload, true);
};

export const reject = (url, payload) => {
  return user?.user_details?.roles[0] === 'approver'
    ? apiClient.put(`${apiClient.Urls.reject}/${url}`, payload, true)
    : apiClient.patch(
        `${apiClient.Urls.issuer_reject}/reject/${payload.id}`,
        payload,
        true
      );
};

export const approve = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.reject}/${url}`, payload, true);
};

export const submit = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.issuer_reject}/${url}`,
    payload,
    true
  );
};

export const generatedById = (payload) => {
  return apiClient.patch(
    `${
      user?.user_details?.role === 'approver'
        ? apiClient.Urls.approver_genratedGP
        : user?.user_details?.role === 'requester'
        ? apiClient.Urls.requester_genratedGP
        : apiClient.Urls.issuer_genratedGP
    }/${payload?.gpid}`,
    true
  );
};

export const issuerGenrate_EditGP = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.issuer_genratedGP}/${payload.gpid}`,
    payload,
    true
  );
};
