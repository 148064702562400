import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Image, Row, Col, Select, Input } from 'antd';
//import { CheckCircleOutlined } from '@ant-design/icons';
import styles from './plantModal.module.less';
import FormItem from 'common/FormItem';
import { Form, Space } from 'antd';
import { postMapUser, getAllLocations } from '../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
// import { history } from '@/app/history';
//import { getLoggedInUser } from 'utilities/helpers';
import adminModal_image from 'assets/images/adminModal_image.svg';
import { debounce } from 'lodash';

const { Option } = Select;
//const { TextArea } = Input;

const LocationMapForUserModal = ({
  showMapLocationForUser,
  setShowMapLocationForUser,
  selectedRequest,
  handleGetRequests
}) => {
  //const params = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  //const user = getLoggedInUser();

  const [formData1, setFormData1] = useState({});
  const [projectSearch, setProjectSearch] = useState('');

  const { postMapUserLoading, allLocations } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    const payload = {
      page: 1,
      per_page: 100,
      search: projectSearch
    };
    //if (projectSearch) payload['search'] = projectSearch;
    dispatch(getAllLocations(payload));
  }, [projectSearch]);
  console.log('allLocations', allLocations);

  const debounceFetcherProject = useMemo(() => {
    const loadOptions = (projectSearch) => {
      setProjectSearch(projectSearch);
    };
    return debounce(loadOptions, 500);
  }, []);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    setFormData1({
      ...formData1,
      [slug]: value
    });
  };

  const onFinish = () => {
    const payload = {
      storageLocationId: formData1?.location_name,
      userId: selectedRequest?.id
    };
    dispatch(postMapUser(payload)).then(function (response) {
      if (response) {
        setShowMapLocationForUser(false);
        if (response?.payload?.success) {
          handleGetRequests();
        }
      }
    });
  };

  return (
    <>
      <Modal
        title={
          <span>
            <Image src={adminModal_image} alt="icon" preview={false}></Image>
          </span>
        }
        visible={showMapLocationForUser}
        footer={false}
        className={`${styles.check_circle_icon}`}
        width={'820px'}
        onCancel={() => setShowMapLocationForUser(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Location Name"
                name="location_name"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  showSearch
                  placeholder="Select location"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                  onSearch={debounceFetcherProject}
                  onChange={(value) =>
                    handleFormDataOnChange('location_name', value)
                  }
                >
                  {allLocations?.map((item, index) => {
                    return (
                      <Option
                        value={item.id}
                        key={index}
                        style={{
                          fontSize: '13px',
                          fontWeight: 500,
                          textTransform: 'capitalize'
                        }}
                      >
                        {`${item.name} (${item.code}) `}
                        <span
                          style={{
                            fontSize: '11px'
                          }}
                        >
                          {' '}
                          {` - ${item?.SapPlant?.plant_name} (${item?.SapPlant?.plant_code})`}
                        </span>
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="User Name"
                // name="plant_name"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  disabled
                  value={`${selectedRequest?.first_name} (${selectedRequest?.email})`}
                  placeholder="Enter user name"
                  onChange={(e) =>
                    handleFormDataOnChange('user_name', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <FormItem>
            <Space className={`${styles.button_container}`}>
              <Button
                className={`${styles.import_style_cancel}`}
                size="small"
                onClick={() => setShowMapLocationForUser(false)}
              >
                Cancel
              </Button>
              <Button
                loading={postMapUserLoading}
                className={`${styles.approve_modal_button}`}
                htmlType="submit"
                size="small"
              >
                Map
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default LocationMapForUserModal;
