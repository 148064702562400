import React, { useEffect, useState } from 'react';
import { Modal, Button, Image, Row, Col, Input } from 'antd';
import styles from './plantModal.module.less';
import FormItem from 'common/FormItem';
import { Form, Space } from 'antd';
import { createCompany } from '../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import adminModal_image from 'assets/images/adminModal_image.svg';

const CompanyModal = ({
  isCompanyModalOpen,
  setIsCompanyModalOpen,
  handleGetRequests,
  selectedRequest,
  editMode,
  setEditMode
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [formData1, setFormData1] = useState({});

  const { createCompanyLoading } = useSelector((state) => state.admin);

  useEffect(() => {
    if (editMode === true) {
      form.setFieldsValue({
        company_name: selectedRequest?.company_name,
        company_code: selectedRequest?.company_code
      });
    }
  }, [selectedRequest]);

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);

    setFormData1({
      ...formData1,
      [slug]: value
    });
  };

  const onFinish = () => {
    const payload = {
      id: selectedRequest?.id,
      company_name:
        editMode === true && formData1.company_name === undefined
          ? selectedRequest?.company_name
          : formData1.company_name,
      code:
        editMode === true && formData1.company_code === undefined
          ? selectedRequest?.company_code
          : formData1.company_code
    };
    dispatch(createCompany(payload)).then(function (response) {
      if (response) {
        setIsCompanyModalOpen(false);
        setEditMode(false);
        if (response?.payload?.success) {
          handleGetRequests();
        }
      }
    });
  };

  return (
    <>
      <Modal
        title={
          <span>
            <Image src={adminModal_image} alt="icon" preview={false}></Image>
          </span>
        }
        visible={isCompanyModalOpen}
        footer={false}
        className={`${styles.check_circle_icon}`}
        width={'820px'}
        onCancel={() => setIsCompanyModalOpen(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.formstyles}`}
        >
          <Row gutter={16}>
            <Col span={12}>
              <FormItem
                label="Company Name"
                name="company_name"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter company name"
                  onChange={(e) =>
                    handleFormDataOnChange('company_name', e.target.value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Company Code"
                name="company_code"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  allowClear
                  placeholder="Enter company code"
                  onChange={(e) =>
                    handleFormDataOnChange('company_code', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
          {/* <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="GST"
                name="gst"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.input_styles}`}
              >
                <Input
                  //value={gstNumber}
                  allowClear
                  placeholder="Enter GST"
                  //disabled
                  onChange={(e) =>
                    handleFormDataOnChange('gst', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row> */}

          <FormItem>
            <Space className={`${styles.button_container}`}>
              <Button
                className={`${styles.import_style_cancel}`}
                size="small"
                onClick={() => setIsCompanyModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                loading={createCompanyLoading}
                className={`${styles.approve_modal_button}`}
                htmlType="submit"
                size="small"
              >
                {editMode === true ? 'Update' : 'Create'}
              </Button>
            </Space>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default CompanyModal;
